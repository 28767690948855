import { Component } from '@angular/core';
import {FlexModule} from "@ngbracket/ngx-layout";
import {LocationInfoStepComponent} from "../../../../prefill-section/location-info-step/location-info-step.component";

@Component({
  selector: 'app-location-filter',
  standalone: true,
  imports: [
    FlexModule,
    LocationInfoStepComponent
  ],
  templateUrl: './location-filter.component.html',
  styleUrl: './location-filter.component.scss'
})
export class LocationFilterComponent {

}

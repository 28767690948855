<lib-input [tooltip]="tooltip()" [label]="label()" [required]="required()" [readonly]="readonly()"
[requiredIndicator]="requiredIndicator()" [optionalIndicator]="optionalIndicator()"
           [valid]="formControl()?.valid ?? true">

<!--  This needs to be cleaned up-->
  @if(type()==='phone') {
    @if(formControlName()) {
      <input [autocomplete]="autocompleteType()" [formControlName]="formControlName()" mask="+0 000-000-0000||000-000-0000" [placeholder]="placeholder()">
    }
    @else {
      <input [autocomplete]="autocompleteType()" mask="+0 000-000-0000||000-000-0000" [placeholder]="placeholder()" [value]="value()">
    }
  }
  @else {
    @if(formControlName()) {
      <input [autocomplete]="autocompleteType()" [mask]="mask()"  [formControlName]="formControlName()" [placeholder]="placeholder()" [type]="type()">
    }
    @else {
      <input [autocomplete]="undefined" [mask]="mask()"  [placeholder]="placeholder()" [type]="type()" [value]="value()">
    }
  }

</lib-input>

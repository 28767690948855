import {Component, computed, effect, inject, input, signal, TemplateRef, WritableSignal} from '@angular/core';
import {QueryOptions} from "@mindpath/shared";
import {AutoCompleteModule} from "primeng/autocomplete";
import {BarComponent, IconComponent, IconType} from "@mindpath/shared";
import {FlexModule} from "@ngbracket/ngx-layout";
import {NgTemplateOutlet} from "@angular/common";
import {SharedModule} from "primeng/api";
import {OnlineSchedulerService} from "../../../../../online-scheduler.service";
import {Logger} from "@mindpath/shared";

@Component({
  selector: 'app-availability-filter-view',
  standalone: true,
  imports: [
    AutoCompleteModule,
    BarComponent,
    FlexModule,
    IconComponent,
    NgTemplateOutlet,
    SharedModule
  ],
  templateUrl: './availability-filter-view.component.html',
  styleUrl: './availability-filter-view.component.scss'
})
export class AvailabilityFilterViewComponent {
  onFilterChange = input.required<WritableSignal<QueryOptions<'/api/Availability/slots'>>>();
  timeOfDay = computed(() => {
    const search = this.finder.data.search();
    return search.timeOfDayPref;
  });
  dayOfWeek = computed(() => {
    const search = this.finder.data.search();
    return search.dayOfWeekPref;
  });
  soonest = computed(() => {
    const search = this.finder.data.search();
    return search.soonestWeekOut;
  });

  dayOptions = [
    {value: 'Monday', label: 'M'},
    {value: 'Tuesday', label: 'T'},
    {value: 'Wednesday', label: 'W'},
    {value: 'Thursday', label: 'Th'},
    {value: 'Friday', label: 'F'},
    {value: 'Saturday', label: 'S'},
    {value: 'Sunday', label: 'Su'}
  ]

  finder = inject(OnlineSchedulerService);
  openSideBar(ref: TemplateRef<any>) {
    this.finder.sidebar.set({
      template: ref,
      resetHook: () => {
        this.changeTimeOfDay(null);
        this.changeDayOfWeek(null);
        this.changeSoonest(null);
      }
    });
  }
  constructor() {
    const data = this.finder.data.search();

    effect(() => {
      const timeOfDay = this.timeOfDay();
      const dayOfWeek = this.dayOfWeek();
      const soonest = this.soonest();

      this.finder.data.search.update(search => ({
        ...search,
        timeOfDayPref: timeOfDay,
        dayOfWeekPref: dayOfWeek,
        soonestWeekOut: soonest
      }));

      this.onFilterChange().update(filters => {
        try {

          let startDate: Date | null = null;
          switch (soonest) {
            case 0:
              startDate = null;
              break;
            case 1:
              startDate = new Date();
              startDate.setDate(startDate.getDate() + 7);
              break;
            case 2:
              startDate = new Date();
              startDate.setDate(startDate.getDate() + 14);
              break;
            case 4:
              startDate = new Date();
              startDate.setMonth(startDate.getMonth() + 1);
              break;
          }

          if (!startDate) {
            const inTwoDays = new Date();
            inTwoDays.setDate(inTwoDays.getDate() + 2);
            startDate = inTwoDays;
          }

          const endDate = new Date(startDate);
          endDate.setDate(endDate.getDate() + 90);

          filters['filter.Days.Date'] = `between '${startDate.toISOString().split('T')[0]}', '${endDate.toISOString().split('T')[0]}'`;

          if (timeOfDay) {
            filters['filter.Days.Slots.TimeOfDay'] = `eq '${timeOfDay}'`;
          } else {
            delete filters['filter.Days.Slots.TimeOfDay'];
          }

          if (dayOfWeek) {
            filters['filter.Days.DayOfWeek'] = `eq '${dayOfWeek}'`;
          } else {
            delete filters['filter.Days.DayOfWeek'];
          }
          // console.log('Filters', filters);
          return {...filters};
        } catch (e) {
          Logger.Debug('AvailabilityFilter', e);
          throw e;
        }

      });
    }, {allowSignalWrites: true});
  }

  changeTimeOfDay(val: 'Morning' | 'Afternoon' | null) {
    this.finder.data.search.update(s => ({
      ...s,
      timeOfDayPref: val
    }));
  }

  changeDayOfWeek(val: 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday' | null) {
    this.finder.data.search.update(s => ({
      ...s,
      dayOfWeekPref: val
    }));
  }

  changeSoonest(val: 0 | 1 | 2 | 4 | null) {
    this.finder.data.search.update(s => ({
      ...s,
      soonestWeekOut: val ?? 0
    }));
  }

  protected readonly IconType = IconType;
}

<div class="card elevation-sm {{cardClass()}}" [style]="cardStyle()">
  <div class="card-title card-title-{{type()}} px-8 pt-8">
    @if (title(); as titleTemplate) {
      <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
    }
    <ng-content select="[slot='title']">
      @if (titleStr(); as title) {
        <h4 style="color: white">{{ title }}</h4>
      }
    </ng-content>
  </div>

  <div fxLayout="column">
    <div fxLayout="column" class="card-content" fxLayoutAlign="start start">
<!--      <ng-content></ng-content>-->
      @if (content(); as contentTemplate) {
        <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
      }
<!--      @if (config().template; as template) {-->
<!--        <ng-container [ngTemplateOutlet]="template"></ng-container>-->
<!--      } @else {-->
<!--        <ng-content select="[slot='data']"></ng-content>-->
<!--      }-->
<!--    </div>-->

<!--    <div fxLayout="row [wrap]" fxLayoutAlign="center bottom" fxLayoutGap="1rem" class="pt-12">-->
<!--      <ng-content select="[slot='footer']"></ng-content>-->
    </div>
  </div>
</div>
import { Routes } from '@angular/router';
import {FindApptSectionComponent} from "./sections/find-appt-section/find-appt-section.component";
import {PrefillSectionComponent} from "./sections/prefill-section/prefill-section.component";

export const routes: Routes = [
  {
    path: '',
    component: PrefillSectionComponent,
  },
  {
    path: 'find',
    component: FindApptSectionComponent,
  }
];

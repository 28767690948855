import {Component, computed, input, output} from '@angular/core';
import {CardComponent, TemplateFor} from "@mindpath/shared";
import {DatePipe} from "@angular/common";
import {FlexModule} from "@ngbracket/ngx-layout";
import {SlotListingComponentComponent} from "../../components/slot-listing-component/slot-listing-component.component";
import {Schema, Schemas, SuccessQueryItemType} from "@mindpath/shared";

type ApptSetting = Exclude<Schema<'TimeSlot'>['setting'],'Hybrid'|'None'>;

@Component({
  selector: 'app-availability-slots-card-view',
  standalone: true,
  imports: [CardComponent, TemplateFor, DatePipe, FlexModule, SlotListingComponentComponent],
  templateUrl: './availability-slots-card-view.component.html',
  styleUrl: './availability-slots-card-view.component.scss'
})
export class AvailabilitySlotsCardViewComponent {
  info = input.required<(Schema<'ClinicianAvailabilityInfo'>)>();
  slots = input.required<Schema<'TimeSlot'>[]>();
  date = input.required<Date>();
  isFocused = input<boolean>(false);

  cardStyle = computed(() => {
    return {
      border: this.isFocused() ? '2px solid var(--color-primary-primary)' : '1px solid var(--border-static-border-primary)',
    } satisfies Partial<CSSStyleDeclaration>;
  })


  slotClicked = output<{slot: Schema<'TimeSlot'>, setting: ApptSetting}>();

}

import {ContactType, PhoneType} from "./api/mindpath";
import {Schema} from "./api";
import {Address} from "./address";

export * from './action.type';
export * from './message-codes';
export * from './api';
export * from './address';
export * from './mesage-code-utils';

export function genPhone(type: PhoneType, number: string, isPrimary: boolean = true): Schema<'PhoneContact'> {
  return {
    contactableType: ContactType.Phone,
    isPrimary,
    number,
    type
  }
}

export function genEmail(email: string, isPrimary: boolean = true): Schema<'EmailContact'> {
  return {
    contactableType: ContactType.Email,
    address: email,
    isPrimary
  }
}

export function genAddress(addr: Address, isPrimary: boolean = true): Schema<'AddressContact'> {
  return {
    contactableType: ContactType.Address,
    address: addr,
    isPrimary
  }
}
import {
  Component,
  InputSignal,
  input,
  computed,
  AfterViewInit,
  ChangeDetectionStrategy,
  inject,
  ChangeDetectorRef, OnInit, effect, signal
} from '@angular/core';
import {BaseInputComponent, InputComponent} from "../../base/input/input.component";
import {ControlContainer, FormControl, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskDirective} from "ngx-mask";
import {CalloutComponent} from "../../callout/callout.component";
import {ActionType} from "../../../models/action.type";

@Component({
  selector: 'lib-text-input',
  standalone: true,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    NgxMaskDirective,
    CalloutComponent
  ],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.css',
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class TextInputComponent<T extends string | number = string> extends BaseInputComponent implements OnInit {

  mask = input<string | undefined>(undefined);

  parentForm = inject(FormGroupDirective, {optional: true});
  autocompleteType = input<AutoFill | undefined>(undefined);
  label = input<string | null>(null);
  tooltip = input<string | null>(null);
  value = input<T | null>(null);
  disabled = input<boolean>(false);
  readonly = input<boolean>(false);
  required = input<boolean>(true);
  requiredIndicator = input<boolean>(true);
  optionalIndicator = input<boolean>(false);
  type = input<'text' | 'email' | 'password' | 'number' | 'phone'>('text');
  placeholder = input<string>('');
  control = input<FormControl | string | null>(null, {alias: 'control'});
  formControl = computed(() => {
    const control = this.control();
    const parentForm = this.parentForm?.form;
    if (control instanceof FormControl) {
      return control;
    }
    if (typeof control === 'string') {
      return parentForm?.get(control) as FormControl;
    }
    return null;
  });
  formControlName = computed(() => {
    const control = this.control();
    if (typeof control === 'string') {
      return control;
    }
    return null;
  });
  protected valid = signal<boolean>(false);

  constructor() {
    super();
    effect(() => {
      const control = this.formControl();
      const sub = control?.valueChanges?.subscribe(() => {
        this.valid.set(control!.valid);
      });
      return () => sub?.unsubscribe();
    });
  }

  ngOnInit() {

  }

  protected readonly ActionType = ActionType;
}

import {Component, computed, effect, input, signal, Signal} from '@angular/core';
import {ProgressBarComponent} from "../progress-bar/progress-bar.component";
import {animate, style, transition, trigger} from "@angular/animations";
import {FlexModule} from "@ngbracket/ngx-layout";

@Component({
  selector: 'lib-dimmer',
  standalone: true,
  imports: [
    ProgressBarComponent,
    FlexModule
  ],
  templateUrl: './dimmer.component.html',
  styleUrl: './dimmer.component.css',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          // style({transform: 'translateX(100%)', opacity: 0}),
          // animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
          style({opacity: 0}),
          animate('150ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          // style({transform: 'translateX(0)', opacity: 1}),
          // animate('350ms', style({transform: 'translateX(100%)', opacity: 0})),
          style({opacity: 1}),
          animate('350ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class DimmerComponent {

  fullscreen = input<boolean>(false);
  dimmed = input<boolean>(false, {alias: 'dimmed'});
  title = input<string|undefined>('');
  message = input<string|undefined>('');
  showProgress = input<boolean>(true);
  dimLevel = input<number>(0.9);
  position = input<'top'|'bottom'|'center'>('center');
  minTime = input<number>(0);


  fxLayoutAlign = computed(() => {
    if (this.position() === 'top') {
      return 'center start';
    } else if (this.position() === 'bottom') {
      return 'center end';
    }
    return 'center center';
  });

  protected opacity = computed(() => {
    return this.dimLevel() * 100;
  });

}


export function debouncedSignal<T>(
  sourceSignal: Signal<T>,
  debounceTimeInMs = 0
): Signal<T> {
  const debounceSignal = signal(sourceSignal());
  effect(
    (onCleanup) => {
      const value = sourceSignal();
      const timeout = setTimeout(
        () => debounceSignal.set(value),
        debounceTimeInMs
      );

      // The `onCleanup` argument is a function which is called when the effect
      // runs again (and when it is destroyed).
      // By clearing the timeout here we achieve proper debouncing.
      // See https://angular.io/guide/signals#effect-cleanup-functions
      onCleanup(() => clearTimeout(timeout));
    },
    { allowSignalWrites: true }
  );
  return debounceSignal;
}

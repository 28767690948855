import {Component, computed, effect, inject, input, signal, TemplateRef, WritableSignal} from '@angular/core';
import {QueryOptions} from "@mindpath/shared";
import {BarComponent, IconComponent, IconType} from "@mindpath/shared";
import {FlexModule} from "@ngbracket/ngx-layout";
import {NgTemplateOutlet} from "@angular/common";
import {OnlineSchedulerService} from "../../../../../online-scheduler.service";

@Component({
  selector: 'app-gender-filter',
  standalone: true,
  imports: [
    BarComponent,
    FlexModule,
    IconComponent,
    NgTemplateOutlet
  ],
  templateUrl: './gender-filter.component.html',
  styleUrl: './gender-filter.component.scss'
})
export class GenderFilterComponent {
  onFilterChange = input.required<WritableSignal<QueryOptions<'/api/Availability/slots'>>>();
  gender = computed(() => {
    const search = this.finder.data.search();
    return search.genderPref;
  });


  finder = inject(OnlineSchedulerService);
  openSideBar(ref: TemplateRef<any>) {
    this.finder.sidebar.set({
      template: ref,
      resetHook: () => {
        this.change(null);
      }
    });

  }
  constructor() {


    effect(() => {
      const gender = this.gender();
      this.finder.data.search.update(search => ({
        ...search,
        genderPref: gender
      }));
      // this.wizardSvc.updateStep(WizardStep.ApptSearch, data => {
      //   data ??= {appt: null, additionalFilters: {} as any};
      //   return {
      //     ...data,
      //     additionalFilters: {
      //       ...data.additionalFilters,
      //       gender
      //     }
      //   };
      // });

      this.onFilterChange().update(filters => {
        if (gender) {
          filters['filter.Clinician.gender'] = `eq '${gender}'`;
        } else {
          delete filters['filter.Clinician.gender'];
        }
        return {...filters};
      });

    }, {allowSignalWrites: true});
  }

  change(val: string|null) {
    this.finder.data.search.update(s => ({
      ...s,
      genderPref: val
    }));
  }

  protected readonly IconType = IconType;
}

<ng-container>
  <button class="filter-pill "
          [class.selected]="timeOfDay() || dayOfWeek() || (soonest() !== 0 && soonest() !== null)" type="button"
          (click)="openSideBar(sideBar)">
    @if (soonest() === 0) {
      @if (timeOfDay() && dayOfWeek()) {
        Availability <span class="count">2</span>
      } @else if (timeOfDay()) {
        {{ timeOfDay() }}
      } @else if (dayOfWeek()) {
        {{ dayOfWeek() }}
      } @else {
        Availability
      }
    } @else {
      @if (timeOfDay() && dayOfWeek()) {
        Availability <span class="count">3</span>
      } @else if (timeOfDay() || dayOfWeek()) {
        Availability <span class="count">2</span>
      } @else {
        @switch (soonest()) {
          @case (1) {
            One week from now
          }
          @case (2) {
            Two weeks from now
          }
          @case (4) {
            One month from now
          }
          @default {
            Availability
          }
        }
      }
    }
  </button>
</ng-container>

<ng-template #sideBar>
  <div fxLayout="column" gap="16" style="width: 100%">

    <section>
      <h4>Availability</h4>
      <ul>
        @for (opt of [
          {
            value: 0,
            label: 'Soonest available',
          },
          {
            value: 1,
            label: 'One week from now',
          },
          {
            value: 2,
            label: 'Two weeks from now',
          },
          {
            value: 4,
            label: 'One month from now',
          },
        ]; track opt.value) {
          <li class="pt-12">
            <ng-container
              *ngTemplateOutlet="bar; context: { $implicit: opt.value, target: soonest, change: changeSoonest.bind(this), label: opt.label, centered: false, showCheck: true }"></ng-container>
          </li>
        }
      </ul>

    </section>

    <section fxLayout="column" fxLayoutAlign="start" class="pt-24">
      <h4>Time of day</h4>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center" fxLayoutGap="24" style="width: 100%" fxFill>
        @for (opt of ['Morning', 'Afternoon']; track opt) {
          <ng-container
            *ngTemplateOutlet="bar; context: { $implicit: opt, target: timeOfDay, change: changeTimeOfDay.bind(this), centered: true,
           allowUnset: true}"></ng-container>
        }
      </div>
    </section>

    <section fxLayout="column" fxLayoutAlign="start" class="pt-24">
      <h4>Day of week</h4>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="stretch center" fxLayoutAlign.lt-sm="center stretch"
           fxLayoutGap="12">
        @for (opt of dayOptions; track opt.value) {
          <ng-container
            *ngTemplateOutlet="bar; context: { $implicit: opt.value, target: dayOfWeek, change: changeDayOfWeek.bind(this), centered: true, label: opt.label,
           allowUnset: true }"></ng-container>
        }
      </div>
    </section>

  </div>


</ng-template>

<ng-template #bar let-value let-target="target" let-label="label" let-allowUnset="allowUnset" let-showCheck="showCheck"
             let-centered="centered" let-change="change">
  <lib-bar style="width: 100%; padding-top: 8px" customClass="{{target() === value ? 'selected' : ''}}"
           (click)="change(target() === value && !!allowUnset ? null : value)">
    <span fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
        <span class="bar-header" style="{{!!centered ? 'width: 100%;' : ''}}"
                [class.text-center]="!!centered">{{ label ?? value }} </span>
      @if (target() === value && !!showCheck) {
        <span class="text-primary">
          <lib-icon verticalAlign="middle" name="checkmark" [type]="IconType.Success"></lib-icon>
        </span>
      }
    </span>
  </lib-bar>
</ng-template>
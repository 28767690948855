<span fxLayout="row" gap="8" (click)="toggle()" class="checkbox-holder">
  @if(formControlName()) {
    <input #self type="checkbox" [checked]="checked()" (input)="checked.set(self.checked)" [formControlName]="formControlName()" />
    @if (label(); as label) {
      <label (click)="toggle()" class="checkbox-label" [for]="self.id">{{ label }}</label>
    }
  }
  @else {
    <input #self type="checkbox" [checked]="checked()" (input)="checked.set(self.checked)" />
    @if (label(); as label) {
      <label class="checkbox-label" [for]="self.id">{{ label }}</label>
    }
  }
</span>
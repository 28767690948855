export * from './signal.utils';
export * from './comparer.util';
export * as DataStructs from './data-structs';
export * as Signals from './signals';
export * as DateUtil from './date.utils';
export * from './object';
export * from './matchers';

export {listenForKeyboardCode} from './keyboard-code-capture';

export function debounce(func: Function, wait: number) {
  let timeout: any;
  return function (this: any, ...args: any[]) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
import {effect, Injectable, signal, WritableSignal} from '@angular/core';
import {loadApiEndpoint, loadApiEndpointResp, PossibleResponseCode, Schema} from "@mindpath/shared";
import {config} from "../config";
import {Logger} from "@mindpath/shared";


type CachedClinicianInfo = {
  isDetailed: boolean
} & (Schema<"ClinicianGeneralInfo"> | Schema<'ClinicianDetailedInfo'>);

type PendingCachedClinicianInfo = {npiNumber: number, isDetailed: boolean, promise: Promise<Schema<'ClinicianDetailedInfo'>>};

@Injectable({
  providedIn: 'root'
})
export class CliniciansDataService {

  private _snapshot: WritableSignal<(CachedClinicianInfo|PendingCachedClinicianInfo)[]> = signal([]);
  readonly snapshot = this._snapshot.asReadonly();

  constructor() {
    try {
      const json = sessionStorage.getItem('clinicians');
      if (json) {
        const items = JSON.parse(json) as CachedClinicianInfo[];
        if (Array.isArray(items)) {
          this._snapshot.set(items);
        } else {
          console.error('Invalid data in local storage for clinicians');
        }
      }
      // this.getAll();
    } catch (e) {
      Logger.Error('CliniciansDataSvc', e);
    }
    effect(() => {
      const json = JSON.stringify(this._snapshot());
      sessionStorage.setItem('clinicians', json);
    });

  }

  async getAll(): Promise<PossibleResponseCode<'/api/Clinicians', 'get', 200>['items'] | null> {

    const localStorageValue = sessionStorage.getItem('clinicians');
    if (localStorageValue) {
      const items = JSON.parse(localStorageValue) as any;
      this._snapshot.set(items);
      return items;
    }

    let hasMore = true;
    const items: ({ isDetailed: boolean } & Schema<"ClinicianGeneralInfo">)[] = [];
    do {
      const resp = await loadApiEndpointResp(config.endpoints.api, '/api/Clinicians').get({});

      if (!resp.ok) {
        if (resp.status === 500) {
          const isJson = resp.headers.get('content-type')?.includes('application/json');
          if (isJson) {
            const json = await resp.json();
            throw new Error(json.message);
          }
          throw new Error(await resp.text() || 'Unknown error');
        }
        throw new Error('Unknown error');
      }

      const json = await resp.json() as PossibleResponseCode<'/api/Clinicians', 'get', 200>;
      items.push(...json.items!.map(c => ({...c, isDetailed: false})));
      hasMore = json.hasMore!;
    } while (hasMore);

    this._snapshot.set(items);
    sessionStorage.setItem('clinicians', JSON.stringify(items));
    return items;
  }

  async getSingle(npi: number): Promise<Schema<"ClinicianDetailedInfo"> | null> {
    let clinician = this._snapshot().find(c => c.npiNumber === npi);
    if (clinician && 'isDetailed' in clinician && clinician.isDetailed) {
      return clinician as Schema<"ClinicianDetailedInfo">;
    }
    clinician = {
      npiNumber: npi,
      isDetailed: true,
      promise: loadApiEndpoint(config.endpoints.api, '/api/Clinicians/{npi}').get({
        path: {
          npi
        }
      })
    } satisfies PendingCachedClinicianInfo;

    const update = (data: PendingCachedClinicianInfo|CachedClinicianInfo) => this._snapshot.update(c => {
      const index = c.findIndex(c => c.npiNumber === npi);
      if (index !== -1) {
        c[index] = data;
        return [...c];
      }
      return [...c, data];
    });
    update(clinician);

    const resolved= await clinician.promise;
    update({...resolved, isDetailed: true});

    return resolved;
  }
}


import {inject, Pipe, PipeTransform} from '@angular/core';
import {OnlineSchedulerService} from "../online-scheduler.service";

@Pipe({
  name: 'appendAppStateParams',
  standalone: true
})
export class AppendAppStateParamsPipe implements PipeTransform {

  scheduler = inject(OnlineSchedulerService);
  transform(value: string): string {
    const insurance = this.scheduler.data.insurance();
    const demographics = this.scheduler.data.demographics();

    const insuranceId = insurance?.primary?.id;
    const planId = insurance?.primary?.planId;

    if (!insuranceId) {
      return value;
    }
    if (value.includes('?')) {
      value += '&'
    } else {
      value += '?'
    }
    return `${value}i=${insuranceId}&p=${planId}`;

  }

}

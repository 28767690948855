import {
  AfterContentInit,
  Component,
  computed,
  contentChild,
  contentChildren,
  inject,
  input,
  signal,
  TemplateRef
} from '@angular/core';
import {CardModule} from "primeng/card";
import {CardConfig} from "./card.config";
import {FlexModule} from "@ngbracket/ngx-layout";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {JsonPipe, NgTemplateOutlet} from "@angular/common";
import {ActionType} from "../../models/action.type";
import {TemplateFor} from "../../templating/template-for.directive";

@Component({
  selector: 'lib-card',
  standalone: true,
  imports: [
    CardModule,
    FlexModule,
    NgTemplateOutlet,
    JsonPipe
  ],
  templateUrl: './card.component.html',
  styleUrl: './card.component.css'
})
export class CardComponent implements AfterContentInit {

  templates = contentChildren(TemplateFor);
  title = signal<TemplateRef<any>|undefined>(undefined);
  content = signal<TemplateRef<any>|undefined>(undefined);
  cardClass = input<string>('');
  cardStyle = input<Partial<CSSStyleDeclaration>>({});

  config = input<CardConfig>({});

  manualTitle = input<string|null>(null, {alias: 'title'});
  manualContent = input<string|SafeHtml|null>(null, {alias: 'content'});
  manualType = input<ActionType|null>(null, {alias: 'type'});

  titleStr = computed(() => {
    const manualTitle = this.manualTitle();
    const configTitle = this.config()?.title;
    return manualTitle ?? configTitle;
  });

  type = computed(() => {
    const manualType = this.manualType();
    const configType = this.config()?.type;
    return manualType ?? configType;
  });

  protected sanitizer = inject(DomSanitizer);
  msg = computed(() => {
    const msg = this.config()?.msg;
    const manualContent = this.manualContent();
    const html = manualContent || msg || '';
    if (typeof html === 'string') {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    return html;
  });

  ngAfterContentInit() {
    TemplateFor.Bind(this, this.templates());
  }

}

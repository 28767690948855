import {Component, computed, CUSTOM_ELEMENTS_SCHEMA, DestroyRef, inject, signal} from '@angular/core';
import {Dropdown, DropdownModule} from "primeng/dropdown";
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {
  ButtonComponent,
  IconSet,
  IconType,
  InputComponent, JoinPipe,
  SelectOption,
  TextInputComponent
} from "@mindpath/shared";
import {InputTextModule} from "primeng/inputtext";
import {NgxMaskDirective} from "ngx-mask";
import {PaginatorModule} from "primeng/paginator";
import {PrimeTemplate} from "primeng/api";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {JsonPipe, NgStyle} from "@angular/common";
import {ActionType} from "@mindpath/shared";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {DateToStringNoHyphen, StringNoHyphenDateValidator, StringNoHyphenToDate} from "../../../../utils/date";
import {StateAbv, StatesArray} from "@mindpath/shared";
import {OnlineSchedulerService} from "../../../../online-scheduler.service";
import {
  CalloutComponent,
  GroupedElementsComponent,
  IconComponent
} from "@mindpath/shared";
import {AutoComplete, AutoCompleteModule} from "primeng/autocomplete";
import {OptionFilter, OptionItem} from "../../../../utils";
import { Gender } from 'projects/shared/src/lib/models/api/mindpath';


@Component({
  selector: 'app-general-info-demographics',
  standalone: true,
  imports: [
    DropdownModule,
    FlexModule,
    InputComponent,
    InputTextModule,
    NgxMaskDirective,
    PaginatorModule,
    GroupedElementsComponent,
    ReactiveFormsModule,
    TextInputComponent,
    NgStyle,
    CalloutComponent,
    IconComponent,
    ButtonComponent,
    JsonPipe,
    JoinPipe,
    ExtendedModule,
    AutoCompleteModule
  ],
  templateUrl: './general-info-demographics.component.html',
  styleUrl: './general-info-demographics.component.scss',
  schemas: [],
})
export class GeneralInfoDemographicsComponent {

  readonly today = new Date();
  // readonly minAge = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  // readonly maxAge = new Date(this.today.getFullYear() - 120, this.today.getMonth(), this.today.getDate());

  finder = inject(OnlineSchedulerService);
  form = signal(new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.maxLength(35), Validators.pattern(/^[a-zA-Z\-]+$/)]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(35), Validators.pattern(/^[a-zA-Z\-\s]+$/)]),
    sexAtBirth: new FormControl('', Validators.required),
    dateOfBirth: new FormControl<Date | null>(null, [Validators.required,
      StringNoHyphenDateValidator('pastOrPreset', this.today)]),
    state: new FormControl<StateAbv | ''>('', Validators.required),
  }));
  isUnderAge = computed(() => {
    const data = this.finder.data.demographics();
    if (!this.form().controls.dateOfBirth.valid) return false;
    let dateOfBirth = data?.dob ? StringNoHyphenToDate(data?.dob as any as string) : null;
    if (!dateOfBirth) return false;

    const today = new Date();
    const yearDiff = today.getFullYear() - dateOfBirth.getFullYear();
    const monthDiff = today.getMonth() - dateOfBirth.getMonth();
    let age = yearDiff;

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
      age = yearDiff - 1;
    }
    return age < 18;
  });
  outOfAgeRange = computed(() => {
    const clinician = this.finder.ensure('appt.clinician');
    const data = this.finder.data.demographics();
    if (!this.form().controls.dateOfBirth.valid) return false;
    let dateOfBirth = data?.dob ? StringNoHyphenToDate(data?.dob as any as string) : null;
    if (!dateOfBirth) return false;
    const today = new Date();
    const yearDiff = today.getFullYear() - dateOfBirth.getFullYear();
    const monthDiff = today.getMonth() - dateOfBirth.getMonth();
    let age = yearDiff;

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
      age = yearDiff - 1;
    }
    return age! < clinician.ageRange.min! || age! > clinician.ageRange.max!;
  })
  sexOptions: SelectOption<'male' | 'female'>[] = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'}
  ]
  destroyRef = inject(DestroyRef);
  errorsAsTrackingTags = signal<string[]>([]);
  selectedState = computed(() => {
    return this.finder.data.demographics()?.address.state ?? null;
  });
  isDontSeeState = computed(() => {
    return this.selectedState() === 'XX' as StateAbv;
  });
  outOfState = computed(() => {
    const state = this.selectedState();
    const apptState = this.finder.ensure('appt.location.address.state');
    // console.log(!!state?.trim()?.length, state, this.wizardSvc.appointment().location.address.state);
    return (!!state?.trim()?.length) && state !== apptState;
  });
  firedValidationCheck = signal(false);
  protected readonly IconSet = IconSet;
  protected readonly ActionType = ActionType;
  protected readonly IconType = IconType;

  #availableStates = ['AZ', 'CA', 'FL', 'NC', 'SC', 'TX'];

  readonly statesOptions = StatesArray.map(({abbreviation, name}) => {
    const option = {
      value: abbreviation,
      label: name as string,
    };
    return option;
  }).filter(o => {
    return this.#availableStates.includes(o.value);
  });
  filteredStates = signal<{value: string, label: string}[]>([{value: 'XX', label: 'I Don\'t see my state'}, ...this.statesOptions]);

  constructor() {
    const demographics = this.finder.data.demographics()!;

    const data = {
      firstName: demographics.firstName,
      lastName: demographics.lastName,
      dateOfBirth: demographics.dob ? DateToStringNoHyphen(demographics.dob) : null,
      sexAtBirth: demographics.sex,
      state: demographics.address.state!
    };
    // console.log(demographics.dob)
    // // console.log({data})
    this.form().valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        const form = this.form();
        if (!form.valid) {
          const controls = Object.entries(form.controls).map(([key, control]) => {
            return {key, errors: control.errors, value: control.value}
          }).filter(c => c.errors);

          this.errorsAsTrackingTags.set(controls.flatMap(c => {
            return Object.keys(c.errors ?? {}).map(e => `pvk_profile_error--${c.key}:${e}`);
          }));

          // console.log('invalid', form.errors, controls);
        }

        if ((val.state as any)?.value) {
          val.state = (val.state as any)?.value;
        }

        this.finder.patch('demographics', {
          address: {state: val.state! as StateAbv},
          firstName: val.firstName!,
          lastName: val.lastName!,
          dob: val.dateOfBirth ? StringNoHyphenToDate(val.dateOfBirth!) : null!,
          sex: val.sexAtBirth as Gender
        })
      });

    this.form().patchValue(data as any);
  }

  dontSeeStateClicked(event: Event, dropdown: AutoComplete) {
    dropdown.hide();
    this.finder.patch('appt.location.address.state', 'XX' as StateAbv);
    this.form().get('state')?.setValue('XX' as StateAbv);
  }

  optionValFn = (option: any) => option.value ?? option.id ?? option;
  optionLabelFn = (option: any) => {

    return option.label ?? option.name ?? option;
  };

  protected readonly OptionFilter = OptionFilter;
  protected readonly StatesArray = StatesArray;
}

<section>
  <h3>
    Confirm your appointment details
  </h3>
  <div fxLayout="column" ngClass.gt-xs="row-gap-40" ngClass.lt-sm="row-gap-16">
    <div fxLayout="column">
      <p class="heading-4">Clinician</p>
      <div fxLayout="row [wrap]" fxLayoutAlign="space-between start">
        <div fxLayout="column" fxFlex="50" fxFlex.lt-sm="100">
          <img ngSrc="{{ finder.data.appt()!.clinician!.photoUrl }}" class="clinician-pic"

               priority
               alt="{{ finder.data.appt()!.clinician! | clinicianName }}"
               width="150" height="150" style="border-radius: 50%">
          <a class="link blend pk_confirm_clinician-profile"
             href="{{ (config.endpoints.mainSite + '/clinicians/' + finder.data.appt()!.clinician!.npiNumber) | appendAppStateParams }}">{{ finder.data.appt()!.clinician! | clinicianName }}
          </a>
        </div>

        <div fxLayout="column" fxFlex="50" fxFlex.lt-sm="100">
          <h4 class="heading-4">{{ finder.data.appt()!.dateTime | date: 'EEEE, MMMM d, y':'UTC' }}</h4>
          <span>
            {{ finder.data.appt()!.dateTime | date: 'h:mm a':'UTC' | lowercase }}
            - {{ endTime() | date: 'h:mm a':'UTC' | lowercase }}</span>
        </div>
      </div>
    </div>
    <div fxLayout="column">

      <lib-input [valid]="!!chosenApptSetting()?.length && chosenApptSetting() !== null">
        <span slot="label">
          Appointment type
          @if (chosenApptSetting() === null) {
            <span class="sub-label text-body-secondary">(Please select an option)</span>
          }

        </span>
        <div fxLayout="column" gap="4" style="width: 100%">

          @if (originalApptSetting === AppointmentSettingType.Telehealth || isHybrid()) {


            <lib-bar style="padding-top: 8px" [customClass]="chosenApptSetting() === 'Telehealth' ? 'selected' : ''"
                     (click)="chosenApptSetting.set('Telehealth')">
              <input type="radio"
                     [ngStyle]="{position: 'absolute', width: 0, height: 0, opacity: 0}"
                     name="appointment-setting"
                     class="pk_confirm_change-visit--online"
                     [value]="'Telehealth'"
                     [(ngModel)]="chosenApptSetting"/>
              <span fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
              <span [class.selected]="chosenApptSetting() === 'Telehealth'">
                <div class="bar-header">Online</div>
              </span>
                @if (chosenApptSetting() === 'Telehealth') {
                  <span>
                <lib-icon verticalAlign="middle" name="checkmark" [type]="IconType.Success"></lib-icon>
              </span>
                }
            </span>
            </lib-bar>
          } @if (originalApptSetting === AppointmentSettingType.InPerson || isHybrid()) {
          <lib-bar style="padding-top: 8px" [customClass]="chosenApptSetting() === 'InPerson' ? 'selected' : ''"
                   (click)="chosenApptSetting.set('InPerson')">
            <input type="radio"
                   class="pk_confirm_change-visit--office"
                   [ngStyle]="{position: 'absolute', width: 0, height: 0, opacity: 0}"
                   name="appointment-setting"
                   [value]="'InPerson'"
                   [(ngModel)]="chosenApptSetting"/>
            <span fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
                <span [class.selected]="chosenApptSetting() === 'InPerson'">
                  <span class="bar-header pb-8"> In-person </span><br/>
                  <p [innerHTML]="finder.data.appt()!.location!.address | address"></p>
                </span>
              @if (chosenApptSetting() === 'InPerson') {
                <span>
                <lib-icon verticalAlign="middle" name="checkmark" [type]="IconType.Success"></lib-icon>
              </span>
              }
            </span>
          </lib-bar>
        }
        </div>
      </lib-input>
    </div>
    <div fxLayout="column">
      <lib-input [valid]="!!reasonType()" class="pk_confirm_change-service">
        <span slot="label">
          Type of care
        </span>
        <div fxLayout="column" gap="4" style="width: 100%">

          @if (showTherapyReason()) {
            <ng-container *ngTemplateOutlet="reasonTypeBar; context: { $implicit: 'Therapy' }"></ng-container>
          } @if (showPrescriberReason()) {
          <ng-container *ngTemplateOutlet="reasonTypeBar; context: { $implicit: 'Psychiatry' }"></ng-container>
        }


        </div>
      </lib-input>
    </div>
    <div fxLayout="column" row-gap="24">
      <lib-input [valid]="!!selectedConditionId()" label="Reason for visit" fxFill>
        <div style="width: 100%">
          <p-autoComplete
            [forceSelection]="true"
            [suggestions]="$any(filteredConditions())"
            [lazy]="true"
            [ngModel]="selectedConditionFromOptions()"
            [delay]="125"
            [fluid]="true"
            [panelStyle]="{width: '100%'}"
            [style]="{width: '100%'}"
            (onSelect)="selectedConditionId.set($event.value.id)"
            optionLabel="name"
            dataKey="id"
            appendTo="body"
            (completeMethod)="OptionFilter(possibleConditions(), filteredConditions)($event)">
            <ng-template let-item pTemplate="item">
              {{ item.name }}
            </ng-template>
            <ng-template let-item pTemplate="selectedItem">
              {{ item.name }}
            </ng-template>
            <ng-template pTemplate="empty">
              <div class="flex items-center gap-2">
                @if (!possibleConditions().length) {
                  Loading reasons...
                } @else {
                No results found
              }
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </lib-input>

      @if (isUnsupportedCondition()) {
        <div class="notice hide-empty">
          <span>
                <lib-icon name="?" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Warn"
                          [size]="8"></lib-icon>
          </span>
          <div class="notice-info">
            <h6 class="heading-6 text-heading-2 hide-empty">Unsupported Reason for Visit</h6>
            <span class="sub-text text-body-secondary hide-empty text-body-sm pb-16">
          {{ finder.data.appt()!.clinician! | clinicianName }}
              does not treat {{ selectedConditionFromOptions()?.name }}
          </span>
            <button type="button" class="btn btn-large step-next arrow-right"
                    (click)="finder.goto('find')">
              Find different clinicians
            </button>
          </div>
        </div>
      }
    </div>


    @if (!isUnsupportedCondition() && !valid() && firedValidationCheck()) {
      <lib-callout [type]="ActionType.Danger">
        <div fxLayout="column">

          @if (!chosenApptSetting()?.length || chosenApptSetting() === null) {
            <span class="pk_confirm_error--visit">
        <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                  verticalAlign="middle"></lib-icon>
        <span style="padding-left: 8px;">Please select an appointment type</span>
          </span>
          } @if (!reasonType()?.length) {
          <span class="pk_confirm_error--service">
        <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                  verticalAlign="middle"></lib-icon>
        <span style="padding-left: 8px;">Please select a type of care</span>
          </span>
        } @if (!selectedConditionFromOptions()) {
          <span class="pk_confirm_error--condition">
        <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                  verticalAlign="middle"></lib-icon>
        <span style="padding-left: 8px;">Please select a reason for visit</span>
          </span>
        }

        </div>


      </lib-callout>
    } @if (!isUnsupportedCondition()) {
      <button type="button" class="btn btn-large step-next pk_confirm_continue arrow-right"
              [disabled]="firedValidationCheck() && !valid()"
              (click)="valid() || (firedValidationCheck() && valid()) ? finder.goForward() : firedValidationCheck.set(true)">
        Continue
      </button>
  }
  </div>
</section>


<ng-template #reasonTypeBar let-type>
  <lib-bar style="padding-top: 8px" customClass="{{reasonType() === type ? 'selected' : ''}}"
           (click)="reasonType.set(type)">
    <input type="radio"
           #reasonTypeInput
           [ngStyle]="{position: 'absolute', width: 0, height: 0, opacity: 0}"
           name="appointment-setting"
           class="pk_confirm_change-service--{{type | lowercase}}"
           [value]="type"
           [(ngModel)]="reasonType"/>
    <span fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
      <span>
        <div style="display: inline-block"
             class="bar-header {{reasonType() === type ? 'selected' : ''}}">{{ type }}</div>
      </span>
      @if (reasonType() === type) {
        <span>
          <lib-icon verticalAlign="middle" name="checkmark" [type]="IconType.Success"></lib-icon>
        </span>
      }
    </span>
  </lib-bar>
</ng-template>
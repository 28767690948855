import {Component, computed, effect, inject, signal} from '@angular/core';
import {BarComponent, IconComponent, InputComponent} from "@mindpath/shared";
import {FlexModule} from "@ngbracket/ngx-layout";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {OnlineSchedulerService} from "../../../../../online-scheduler.service";
import {InsuranceDataService} from "../../../../../insurance-data.service";
import {NgStyle} from "@angular/common";
import {AppStateService} from "../../../../../app-state.service";

@Component({
  selector: 'app-insurance-filter',
  standalone: true,
  imports: [
    BarComponent,
    FlexModule,
    IconComponent,
    DropdownModule,
    ReactiveFormsModule,
    InputComponent,
    NgStyle,
    FormsModule
  ],
  templateUrl: './insurance-filter.component.html',
  styleUrl: './insurance-filter.component.scss'
})
export class InsuranceFilterComponent {

  insurances = signal<readonly {
    value: number,
    label: string,
    isTop: boolean,
    plans: { value: number, label: string }[]
  }[]>([]);
  selectedInsurance = signal<number | null>(null);
  selectedInsurancePlan = signal<number | null>(null);
  finder = inject(OnlineSchedulerService);
  state = computed(() => {
    const demographic = this.finder.data.demographics();
    return demographic?.address?.state ?? null;
  })
  insuranceSvc = inject(InsuranceDataService);
  appData = inject(AppStateService);

  plans = signal<readonly { value: number, label: string }[]>([]);

  constructor() {
    const insuranceData = this.finder.data.insurance();


    this.selectedInsurance.set(insuranceData.primary?.id ?? null);
    this.selectedInsurancePlan.set(insuranceData.primary?.planId ?? null);

    const selectedInsurance = this.selectedInsurance();
    const selectedInsurancePlan = this.selectedInsurancePlan();

    this.loadCompany(selectedInsurance, selectedInsurancePlan);

    effect(() => {
      const selectedInsurance = this.selectedInsurance();
      const selectedInsurancePlan = this.selectedInsurancePlan();
      this.loadCompany(selectedInsurance, selectedInsurancePlan);
    }, {allowSignalWrites: true});

  }

  loading = false;
  async loadCompany(insurance: number | null, plan: number | null) {
    // debugger
    if (!this.state()) {
      throw new Error('State is required');
    }
    if (this.loading) {
      return;
    }
    this.loading = true;
    const selectedInsurance = this.selectedInsurance();
    const selectedInsurancePlan = this.selectedInsurancePlan();
    let insurances = this.insurances();
    if (insurance === null && plan === null) {
      if (insurances.length) {
        this.loading = false;
        return;
      }
      insurances = (await this.insuranceSvc.getInsuranceCompanies(this.state())).map(i => ({
        value: i.id!,
        label: i.name!,
        isTop: i.isTopInsurer!,
        plans: i.plans!.map((p) => ({value: p.id!, label: p.name!}))
      }));
      this.insurances.set(insurances);
      this.loading = false;
      return;
    }


    if (!insurances.length || !insurances.find(i => i.value === insurance)) {
      insurances = (await this.insuranceSvc.getInsuranceCompanies(this.state())).map(i => ({
        value: i.id!,
        label: i.name!,
        isTop: i.isTopInsurer!,
        plans: i.plans!.map((p) => ({value: p.id!, label: p.name!}))
      }));
      this.insurances.set(insurances);
    }

    const company = insurances.find(i => i.value === insurance);
    if (!company) {
      insurance = null;
      plan = null;
      this.plans.set([]);
    } else {
      const plans = insurances.find(i => i.value === insurance)?.plans ?? [];
      this.plans.set(plans);
      if (plan && !plans.find(p => p.value === plan)) {
        plan = null;
      }
    }

    this.selectedInsurance.set(insurance);
    this.selectedInsurancePlan.set(plan);
    this.loading = false;
    this.finder.data.insurance.update(ins => {
      const current = ins.primary;
      if (current && current.id === insurance && current.planId === plan) {
        return ins;
      }
      return {
        primary: {
          id: insurance!,
          planId: plan!,
          groupNumber: null!,
          policyNumber: null!,
          holder: null!,

        },
        secondary: null
      }
    });
  }


}

@if (step() === 'location') {
  <app-location-info-step (onCompleted)="next()"></app-location-info-step>
} @else {
  <app-finder-prefill-step-group [step]="step()" (onCompleted)="next()">
  </app-finder-prefill-step-group>

  @if (step() === 'insurance') {
    <div class="pt-48" fxLayout="column" fxLayoutGap="48">
      <hr/>
      <lib-bar style="padding-top: 8px" (click)="selfPaySelected()" class="pt-16">
        <span fxLayout="row" fxLayoutAlign="space-between center">
      <span>
        <div fxLayout="column">
          <span style="display: inline-block"
                class="text-primary">
            <strong>I will self-pay</strong>
          </span>
        </div>

      </span>
    </span>
      </lib-bar>
    </div>
  }
}

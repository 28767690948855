import {Component, input} from '@angular/core';
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'lib-grouped-elements',
  standalone: true,
  imports: [
    FlexModule,
    ExtendedModule,
    FormsModule
  ],
  template: `
    <div fxFlexFill fxFlex="grow" fxLayout="row" 
         fxLayout.lt-sm="column"
         ngClass.gt-xs="gap-{{gap()}}" ngClass.lt-sm="row-gap-{{gap() / 2}}"
         fxLayoutAlign="space-between end"
         fxLayoutAlign.lt-sm="start stretch">
      <ng-content></ng-content>
    </div>
  `,
  styles: ``
})
export class GroupedElementsComponent {
  gap = input<number>(40);
}

import { Injectable } from '@angular/core';
import {
  ApiResponseType,
  loadApiEndpointResp, Logger,
  PagedApiResponseType,
  QueryOptions,
  RouteQueryParamsArgs,
  StateAbv
} from "@mindpath/shared";
import {QueryBuilder, QueryKeyValPairs} from "./online-scheduler.service";
import {config} from "../config";
import DateUtils from "../../../shared/src/lib/utils/date.utils";

@Injectable({
  providedIn: 'root'
})
export class AppointmentsDataService {

  constructor() { }


  async tryFindByDetails(info: {apptDateTime: Date, clinicianNpi: number, locationId: number, setting: 'InPerson'|'Hybrid', state: StateAbv}) {
    const dateTime = DateUtils.splitDateAndTime(info.apptDateTime);


    const filters: QueryOptions<'/api/Availability/slots'> = {
      'filter.Clinician.npi': `eq ${info.clinicianNpi}`,
      'filter.Location.LocationId': `eq ${info.locationId}`,
      'filter.Days.Slots.Setting': `eq '${info.setting}'`,
      'filter.Days.Slots.Duration': 'eq 60',
      'filter.Days.Slots.Time': `eq '${DateUtils.toTimeString(dateTime.time)}'`,
      'filter.Days.Date': `eq '${DateUtils.toDateOnlyString(dateTime.date)}'`,
    }

    const resp = await this.getAvailabilityInfo({Page: 1, PageSize: 10, options: filters});
    if (!resp.ok) {
      return null;
    }
    const json = await resp.json() as PagedApiResponseType<'/api/Availability/slots'>;
    if ((json.totalCount ?? 0) > 1) {
      throw new Error('Invalid appointment');
    }
    return json.items?.[0] ?? null;
  }

  getAvailabilityInfo(...options: QueryKeyValPairs<'/api/Availability/slots'>): Promise<Response>;

  getAvailabilityInfo(options: QueryKeyValPairs<'/api/Availability/slots'>): Promise<Response>;

  getAvailabilityInfo(options: QueryBuilder<'/api/Availability/slots'>): Promise<Response>;

  getAvailabilityInfo(options: QueryOptions<'/api/Availability/slots'>, ...kvp: QueryKeyValPairs<'/api/Availability/slots'>): Promise<Response>;

  getAvailabilityInfo(paging: {
    Page?: number,
    PageSize?: number
  }, ...options: QueryKeyValPairs<'/api/Availability/slots'>): Promise<Response>;

  getAvailabilityInfo(paging: {
    Page?: number,
    PageSize?: number,
    options: QueryOptions<'/api/Availability/slots'>
  }): Promise<Response>;

  getAvailabilityInfo(options: RouteQueryParamsArgs<'/api/Availability/slots'> | QueryOptions<'/api/Availability/slots'> | QueryBuilder<'/api/Availability/slots'> | (QueryKeyValPairs<'/api/Availability/slots'>[0]) | QueryKeyValPairs<'/api/Availability/slots'>, ...kvp: QueryKeyValPairs<'/api/Availability/slots'>): Promise<Response> {
    if ('assemble' in options && typeof options.assemble === 'function') {
      options = options.assemble();
    }

    // console.log(options);


    let arr: QueryKeyValPairs<'/api/Availability/slots'> = [];
    if ('Page' in options) {
      arr.push(['Page', options.Page]);
      arr.push(['PageSize', options.PageSize]);
      if ('options' in options) {
        options = options.options!;
      }
    }

    if (Array.isArray(options) && options.length > 0) {
      if (Array.isArray(options[0])) {
        arr.push(...options as QueryKeyValPairs<'/api/Availability/slots'>);
      } else {
        arr.push(options as QueryKeyValPairs<'/api/Availability/slots'>[0]);
      }
    } else {
      arr.push(...Object.entries(options).map(([k, v]) => [k, v]) as QueryKeyValPairs<'/api/Availability/slots'>);
    }

    arr.push(...kvp);

    const queryObj = arr.reduce((acc, [k, v]) => {
      (acc as any)[k] = v;
      return acc;
    }, {} as RouteQueryParamsArgs<'/api/Availability/slots'>);

    return loadApiEndpointResp(config.endpoints.api, '/api/Availability/slots').get({
      query: queryObj
    });
  }
}


// const a: PagedApiResponseType<'/api/Availability/slots'> = {};
// const b: ApiResponseType<'/api/Availability/slots', 'get'> = {};
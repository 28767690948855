import {Component, computed, DestroyRef, effect, HostListener, inject, signal} from '@angular/core';
import {
  ButtonComponent,
  CalloutComponent, GroupedElementsComponent,
  IconComponent,
  IconSet,
  IconType,
  InputComponent, JoinPipe,
  TextInputComponent
} from "@mindpath/shared";
import {DropdownModule} from "primeng/dropdown";
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {InputTextModule} from "primeng/inputtext";
import {NgxMaskDirective} from "ngx-mask";
import {PaginatorModule} from "primeng/paginator";
import {PrimeTemplate} from "primeng/api";
import {FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NgStyle} from "@angular/common";

import {DividerModule} from "primeng/divider";
import {StatesArray} from "@mindpath/shared";
import {InvalidDeviceCodes, OnlineSchedulerService} from "../../../../online-scheduler.service";
import {ActionType} from "@mindpath/shared";

@Component({
  selector: 'app-contact-info-demographics',
  standalone: true,
  imports: [
    ButtonComponent,
    CalloutComponent,
    DropdownModule,
    FlexModule,
    IconComponent,
    InputComponent,
    InputTextModule,
    NgxMaskDirective,
    PaginatorModule,
    GroupedElementsComponent,
    ReactiveFormsModule,
    TextInputComponent,
    NgStyle,
    DividerModule,
    JoinPipe,
    ExtendedModule
  ],
  templateUrl: './contact-info-demographics.component.html',
  styleUrl: './contact-info-demographics.component.scss'
})
export class ContactInfoDemographicsComponent {

  form = signal(new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
    phone: new FormControl('', [Validators.required, Validators.pattern(/^1?\d{10}$/),]),
    address1: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9-.\s]+$/)]),
    address2: new FormControl('', [Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9-#.\s]+$/)]),
    city: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-Z-.\s]+$/)]),
    state: new FormControl({value: '', disabled: true}),
    zip: new FormControl('', [Validators.required, Validators.pattern(/^\d{5}$/)]),
    intakeEmail: new FormControl('', [Validators.email, Validators.maxLength(50)]),
  }));

  stateOptions = StatesArray.map(s => ({label: s.name, value: s.abbreviation}));

  errorsAsTrackingTags = signal<string[]>([]);
  finder = inject(OnlineSchedulerService);

  destroyRef = inject(DestroyRef);

  readonly intakeEmailLabel = `Intake Submission Email <h5 class="heading-5"><strong>Please contact <a class="link blend" href="mailto:jeff.skwarek@mindpath.com?subject=Intake Submission Access">Jeff Skwarek</a> to be added to the intake submission list</strong></h5>`;
  firedValidationCheck = signal(false);
  showIntakeEmail = signal(false);

  mustReRequestCode = computed(() => {
    const stillValid = this.finder.tokenStillValid();
    const verifyData = this.finder.data.meta().booking?.auth?.request;
    const phone = this.form().controls.phone.value;
    let email = this.form().controls.email.value;
    if (this.showIntakeEmail()) {
      email = this.form().controls.intakeEmail.value;
    }
    if (!stillValid) {
      return true;
    }
    const type = verifyData?.deviceType;
    if (type === 'phone' && phone !== verifyData?.destination) {
      return true;
    }
    if (type === 'email' && email !== verifyData?.destination) {
      return true;
    }
    return false;
  });
  hiddenTypedCode = '';
  protected readonly IconSet = IconSet;
  protected readonly IconType = IconType;

  invalidDeviceError = computed(() => {
    const error = this.finder.error();
    if (!error) {
      return null;
    }
    return InvalidDeviceCodes.find(c => c.code === error.code) ?? null;
  })

  constructor() {

    const originalPhone = this.finder.data.meta().booking?.auth?.request?.destination;
    const intakeEmail = this.finder.data.meta().booking?.auth?.request?.intakeEmail;

    let requirePhoneChangeValidator: ValidatorFn | null = null;
    effect(() => {
      const requires = !!this.invalidDeviceError();
      const phoneCtrl = this.form().controls.phone;
      const phone = this.finder.data.meta().booking?.auth?.request?.destination

      if (!originalPhone || !requires || originalPhone !== phone) {
        if (requirePhoneChangeValidator) {
          phoneCtrl.removeValidators(requirePhoneChangeValidator);
        }
        return;
      }

      if (!requirePhoneChangeValidator) {
        requirePhoneChangeValidator = RequirePhoneChangeValidator(originalPhone);
        phoneCtrl.addValidators(requirePhoneChangeValidator);
        phoneCtrl.updateValueAndValidity({onlySelf: true, emitEvent: true});
      }

    }, {allowSignalWrites: true});

    effect(() => {
      const useIntakeEmail = this.showIntakeEmail();
      const control = this.form().controls.intakeEmail;
      if (useIntakeEmail) {
        control.addValidators(Validators.required);
      } else {
        control.removeValidators(Validators.required);
      }
      control.updateValueAndValidity({onlySelf: true, emitEvent: true});

    });

    // console.log({addressData})
    this.form().valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        const form = this.form();
        if (!form.valid) {
          const controls = Object.entries(form.controls).map(([key, control]) => {
            return {key, errors: control.errors, value: control.value}
          }).filter(c => c.errors);
          // console.log('invalid', form.errors, controls);

          this.errorsAsTrackingTags.set(controls.flatMap(c => {
            return Object.keys(c.errors ?? {}).map(e => `pvk_profile_error--${c.key}:${e}`);
          }));
        }

        this.finder.patch('demographics', {
          address: {
            address1: val.address1!,
            address2: val.address2,
            city: val.city!,
            zip: val.zip!
          },
          email: val.email!,
          phone: val.phone!
        });
      });

    this.showIntakeEmail.set(this.finder.data.meta().booking?.auth?.request?.usingIntakeEmail ?? false);

    const demographics = this.finder.data.demographics();
    const authDestType = this.finder.data.meta().booking?.auth?.request?.deviceType;

    this.form().patchValue({
      email: demographics?.email,
      phone: demographics?.phone,
      address1: demographics?.address?.address1,
      address2: demographics?.address?.address2,
      city:  demographics?.address?.city,
      state:  demographics?.address?.state,
      zip:  demographics?.address?.zip,
      intakeEmail: intakeEmail,
    });
  }

  async chooseOtpType(type: 'sms' | 'phone' | 'email') {
    if (!this.form().valid) {
      this.firedValidationCheck.set(true);
      return;
    }

    let destination = '';
    if (type === 'phone' || type === 'sms') {
      destination = this.form().controls.phone.value!;
      destination = destination?.length === 11 ? destination.slice(1) : destination!
    } else if (type === 'email') {
      destination = this.form().controls.email.value!;
    }

    if (this.showIntakeEmail()) {
      destination = this.form().controls.intakeEmail.value!;
    }

    this.finder.patch('meta.booking.auth.request', {
      deviceType: type,
      destination: destination,
      usingIntakeEmail: this.showIntakeEmail(),
    });

    if (await this.finder.requestAuthCode()) {
      this.finder.goForward();
      return;
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {

    if (!event.key) {
      this.hiddenTypedCode = '';
      return;
    }

    if (event.key === 'Escape') {
      this.hiddenTypedCode = '';
    } else {
      this.hiddenTypedCode += event.key;
    }


    if (this.hiddenTypedCode === '-sudo') {
      this.showIntakeEmail.set(true);
      const control = this.form().controls.intakeEmail
      control.addValidators(Validators.required);
      control.updateValueAndValidity({onlySelf: true, emitEvent: true});

      this.finder.data.meta.update(m => {
        return {
          ...m ??{},
          booking: {
            ...m.booking ??{},
            auth: {
              ...m.booking?.auth ?? {},
              request: {
                ...m.booking?.auth?.request ?? {},
                usingIntakeEmail: true,
              }
            }
          }
        } as any
      })
    }
  }

  protected readonly ActionType = ActionType;
}

const RequirePhoneChangeValidator = (from: string): ValidatorFn => {
  return (control) => {
    const value = control.value;
    if (value === from) {
      return {phoneChangeRequired: true};
    }
    return null;
  }
}
import {Component, computed, inject} from '@angular/core';
import {FinderPrefillStepGroupComponent} from "./finder-prefill-step-group/finder-prefill-step-group.component";
import {OnlineSchedulerService} from "../../online-scheduler.service";
import {LocationInfoStepComponent} from "./location-info-step/location-info-step.component";
import {BarComponent, IconComponent, IconSet, IconType} from "@mindpath/shared";
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {AppStateService} from "../../app-state.service";

@Component({
  selector: 'app-prefill-section',
  standalone: true,
  imports: [
    FinderPrefillStepGroupComponent,
    LocationInfoStepComponent,
    IconComponent,
    ExtendedModule,
    FlexModule,
    BarComponent
  ],
  templateUrl: './prefill-section.component.html',
  styleUrl: './prefill-section.component.scss'
})
export class PrefillSectionComponent {

  finder = inject(OnlineSchedulerService);
  appState = inject(AppStateService);
  step = computed(() => this.finder.data.meta().prefill?.step ?? 'location');


  resetAge() {

  }

  constructor() {
    this.checkStopMessages();
  }

  back() {
    // const order = ['location', 'type', 'condition', 'age', 'setting', 'insurance', 'insurancePlan'] as const;
    // const current = this.step();
    // const currentIndex = order.indexOf(current);
    // if (currentIndex === 0) {
    //   return;
    // }
    // this.finder.data.meta.update(m => ({...m, prefill: {...m.prefill, step: order[currentIndex - 1]}}));
    this.finder.goBackward();
  }

  selfPaySelected() {
    this.finder.data.insurance.set({primary: {id: -1} as any, secondary: null});
    this.next();
  }

  checkStopMessages() {

    if (this.finder.data.search().age === 'Child') {
      this.appState.stopPageData.set({
        title: 'Let\'s find your path',
        message: `Online scheduling is currently unavailable for anyone under the age of 18. Call <a
      class="link blend pk_profile_intake"
      href="tel:855-501-1004">855-501-1004</a> to schedule an appointment.`,
        action: {
          label: 'Select a different age',
          onClick: () => {
            this.finder.data.search.update(s => ({...s, age: null}));
            this.appState.stopPageData.set(null);
          }
        }
      });
      return;
    }

    if (this.finder.data.insurance().primary?.id === -1) {
      this.appState.stopPageData.set({
        title: 'Let\'s find your path',
        message: `Self-pay scheduling is unavailable online. Call <a
      class="link blend pk_profile_intake"
      href="tel:855-501-1004">855-501-1004</a> to schedule an appointment.`,
        action: {
          label: 'Return To Insurance',
          onClick: () => {
            this.finder.data.insurance.update(i => ({...i, primary: null!}));
            this.appState.stopPageData.set(null);
          }
        }
      });
      return;
    }
    this.appState.stopPageData.set(null);
  }

  next() {


    this.checkStopMessages();
    if (this.appState.stopPageData()) {
      return;
    }
    // const order = ['location', 'type', 'condition', 'age', 'setting', 'insurance', 'insurancePlan'] as const;
    // const current = this.step();
    // const currentIndex = order.indexOf(current);
    // if (currentIndex === order.length - 1) {
    //   this.finder.data.meta.update(m => ({...m, section: 'find'}));
    //   return;
    // }
    // this.finder.data.meta.update(m => ({...m, prefill: {...m.prefill, step: order[currentIndex + 1]}}));
    this.finder.goForward();
  }

  protected readonly IconType = IconType;
  protected readonly IconSet = IconSet;
}

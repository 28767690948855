import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural',
  standalone: true
})
export class PluralPipe implements PipeTransform {

  transform(value: string, args: number): unknown {
    return args === 1 ? value : value + 's';
  }

}

<div class="callout callout-{{type()}}">
  <div class="content">
    <div class="primary-info" fxLayout="column">
      @if(title()) {
        <h5 class="heading-5 btn-text-action-secondary">{{ title() }}</h5>
      }
      <ng-content>
        @if(content()) {
          <span class="sub-text text-body-secondary hide-empty text-body-sm">{{ content() }}</span>
        }
      </ng-content>
    </div>
  </div>
</div>

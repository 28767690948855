import {InputSignal, ModelSignal, Signal, TemplateRef, Type, WritableSignal} from "@angular/core";
import {SettingFilterComponent} from "./setting-filter/setting-filter.component";
import {QueryOptions} from "@mindpath/shared";
import {ServiceFilterViewComponent} from "./service-filter-view/service-filter-view.component";
import {AvailabilityFilterViewComponent} from "./availability-filter-view/availability-filter-view.component";
import {GenderFilterComponent} from "./gender-filter/gender-filter.component";
import {LanguageFilterComponent} from "./language-filter/language-filter.component";

export type Filter = Type<{
  onFilterChange: InputSignal<WritableSignal<QueryOptions<'/api/Availability/slots'>>>;
}>;

export const Filters: readonly Filter[] = [
  SettingFilterComponent,
  // ServiceFilterViewComponent,
  // AvailabilityFilterViewComponent,
  // GenderFilterComponent,
  // LanguageFilterComponent,
];
@let appt = finder.data.appt()! ;
<section>
  <h3>
    Your appointment has been reserved
    <span class="pl-16">
        <lib-icon [size]="32" verticalAlign="middle" name="checkmark" [type]="IconType.Success"></lib-icon>
      </span>
  </h3>
  <h5 class="text-secondary" style="font-weight: normal">
    We look forward to your visit with {{ appt.clinician | clinicianName }}.
  </h5>
  <div fxLayout="column" ngClass.gt-xs="row-gap-40" ngClass.lt-sm="row-gap-16" class="pt-32">

    <div fxLayout="row" fxLayout.xs="column"  fxLayoutAlign.lt-sm="center" fxLayoutGap="24px">
      <div fxLayout="column" fxFlex="50" fxFlex.lt-sm="100">
        <h4 class="heading-4">{{ appt.dateTime | date: 'EEEE, MMMM d, y':'UTC' }}</h4>
        <span>
          {{ appt.dateTime | date: 'h:mm a':'UTC' | lowercase }}
          - {{ endTime() | date: 'h:mm a':'UTC' | lowercase }}</span>
        @if (icsContent(); as ics) {
          <a class="link blend pk_reserved_calendar pt-4" [href]="ics.url">
            <i class="pi pi-calendar"></i>
            Add to calendar
          </a>
        }
      </div>

      <div fxLayout="column" fxFlex="50" fxFlex.lt-sm="100">
        <h4 class="heading-4">{{ isInPersonAppt() ? 'In-person' : 'Online' }} Visit</h4>
        @if (isInPersonAppt()) {
          <a class="link blend pk_reserved_directions"
             href="geo:{{location().address!.coordinates?.lat ?? 0}},{{location().address!.coordinates?.long ?? 0}}"
             [innerHTML]="location().address | address"></a>
        } @else {
          <span>
            You'll receive a link to join the video call three days before your appointment.
          </span>
        }
      </div>
    </div>
    <p-divider></p-divider>
    @if (hasAttachmentUploads()) {
      <p-fieldset legend="Insurance Card - Upload">
        <app-attachment-upload-progress-view></app-attachment-upload-progress-view>
      </p-fieldset>
      <p-divider></p-divider>
    }

    @if (finder.data.meta().booking.completionData?.formsLink; as formsLink) {
      <div class="notice hide-empty">
        <div class="notice-info">
          <h4 class="heading-4 heading-2 hide-empty" style="font-weight: bold">Prepare for your appointment. </h4>
          <span class="sub-text text-body-secondary hide-empty text-body-sm pb-16">
        Complete these forms before your appointment. You will also receive an email reminder.
        </span>
          <a class="btn pk_reserved_paperwork" [href]="formsLink">
            Fill out patient paperwork
          </a>
        </div>
      </div>
    }


  </div>
</section>
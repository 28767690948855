import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, booleanAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ContentChildren, NgModule } from '@angular/core';
import { TranslationKeys, PrimeTemplate, SharedModule } from 'primeng/api';
import { TimesCircleIcon } from 'primeng/icons/timescircle';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const _c0 = ["*"];
function Chip_div_0_img_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "img", 6);
    i0.ɵɵlistener("error", function Chip_div_0_img_2_Template_img_error_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.imageError($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("src", ctx_r1.image, i0.ɵɵsanitizeUrl)("alt", ctx_r1.alt);
  }
}
function Chip_div_0_ng_template_3_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 8);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r1.icon);
    i0.ɵɵproperty("ngClass", "p-chip-icon");
    i0.ɵɵattribute("data-pc-section", "icon");
  }
}
function Chip_div_0_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Chip_div_0_ng_template_3_span_0_Template, 1, 4, "span", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r1.icon);
  }
}
function Chip_div_0_div_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("data-pc-section", "label");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.label);
  }
}
function Chip_div_0_ng_container_6_ng_container_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 13);
    i0.ɵɵlistener("click", function Chip_div_0_ng_container_6_ng_container_1_span_1_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.close($event));
    })("keydown", function Chip_div_0_ng_container_6_ng_container_1_span_1_Template_span_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.onKeydown($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵclassMap(ctx_r1.removeIcon);
    i0.ɵɵproperty("ngClass", "p-chip-remove-icon");
    i0.ɵɵattribute("data-pc-section", "removeicon")("aria-label", ctx_r1.removeAriaLabel);
  }
}
function Chip_div_0_ng_container_6_ng_container_1_TimesCircleIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "TimesCircleIcon", 14);
    i0.ɵɵlistener("click", function Chip_div_0_ng_container_6_ng_container_1_TimesCircleIcon_2_Template_TimesCircleIcon_click_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.close($event));
    })("keydown", function Chip_div_0_ng_container_6_ng_container_1_TimesCircleIcon_2_Template_TimesCircleIcon_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext(4);
      return i0.ɵɵresetView(ctx_r1.onKeydown($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵclassMap("p-chip-remove-icon");
    i0.ɵɵattribute("data-pc-section", "removeicon")("aria-label", ctx_r1.removeAriaLabel);
  }
}
function Chip_div_0_ng_container_6_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Chip_div_0_ng_container_6_ng_container_1_span_1_Template, 1, 5, "span", 11)(2, Chip_div_0_ng_container_6_ng_container_1_TimesCircleIcon_2_Template, 1, 4, "TimesCircleIcon", 12);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.removeIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.removeIcon);
  }
}
function Chip_div_0_ng_container_6_span_2_1_ng_template_0_Template(rf, ctx) {}
function Chip_div_0_ng_container_6_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Chip_div_0_ng_container_6_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Chip_div_0_ng_container_6_span_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 15);
    i0.ɵɵlistener("click", function Chip_div_0_ng_container_6_span_2_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.close($event));
    })("keydown", function Chip_div_0_ng_container_6_span_2_Template_span_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r1.onKeydown($event));
    });
    i0.ɵɵtemplate(1, Chip_div_0_ng_container_6_span_2_1_Template, 1, 0, null, 16);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵattribute("data-pc-section", "removeicon")("aria-label", ctx_r1.removeAriaLabel);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.removeIconTemplate);
  }
}
function Chip_div_0_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Chip_div_0_ng_container_6_ng_container_1_Template, 3, 2, "ng-container", 5)(2, Chip_div_0_ng_container_6_span_2_Template, 2, 3, "span", 10);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.removeIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.removeIconTemplate);
  }
}
function Chip_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵprojection(1);
    i0.ɵɵtemplate(2, Chip_div_0_img_2_Template, 1, 2, "img", 3)(3, Chip_div_0_ng_template_3_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(5, Chip_div_0_div_5_Template, 2, 2, "div", 4)(6, Chip_div_0_ng_container_6_Template, 3, 2, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const iconTemplate_r6 = i0.ɵɵreference(4);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.styleClass);
    i0.ɵɵproperty("ngClass", ctx_r1.containerClass())("ngStyle", ctx_r1.style);
    i0.ɵɵattribute("data-pc-name", "chip")("aria-label", ctx_r1.label)("data-pc-section", "root");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.image)("ngIfElse", iconTemplate_r6);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", ctx_r1.label);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.removable);
  }
}
const theme = ({
  dt
}) => `
.p-chip {
    display: inline-flex;
    align-items: center;
    background: ${dt('chip.background')};
    color: ${dt('chip.color')};
    border-radius: ${dt('chip.border.radius')};
    padding: ${dt('chip.padding.y')} ${dt('chip.padding.x')};
    gap: ${dt('chip.gap')};
}

.p-chip-icon {
    color: ${dt('chip.icon.color')};
    font-size: ${dt('chip.icon.font.size')};
    width: ${dt('chip.icon.size')};
    height: ${dt('chip.icon.size')};
}

.p-chip-image {
    border-radius: 50%;
    width: ${dt('chip.image.width')};
    height: ${dt('chip.image.height')};
    margin-left: calc(-1 * ${dt('chip.padding.y')});
}

.p-chip:has(.p-chip-remove-icon) {
    padding-right: ${dt('chip.padding.y')};
}

.p-chip:has(.p-chip-image) {
    padding-top: calc(${dt('chip.padding.y')} / 2);
    padding-bottom: calc(${dt('chip.padding.y')} / 2);
}

.p-chip-remove-icon {
    cursor: pointer;
    font-size: ${dt('chip.remove.icon.font.size')};
    width: ${dt('chip.remove.icon.size')};
    height: ${dt('chip.remove.icon.size')};
    color: ${dt('chip.remove.icon.color')};
    border-radius: 50%;
    transition: outline-color ${dt('chip.transition.duration')}, box-shadow ${dt('chip.transition.duration')};
    outline-color: transparent;
}

.p-chip-remove-icon:focus-visible {
    box-shadow: ${dt('chip.remove.icon.focus.ring.shadow')};
    outline: ${dt('chip.remove.icon.focus.ring.width')} ${dt('chip.remove.icon.focus.ring.style')} ${dt('chip.remove.icon.focus.ring.color')};
    outline-offset: ${dt('chip.remove.icon.focus.ring.offset')};
}
`;
const classes = {
  root: 'p-chip p-component',
  image: 'p-chip-image',
  icon: 'p-chip-icon',
  label: 'p-chip-label',
  removeIcon: 'p-chip-remove-icon'
};
class ChipStyle extends BaseStyle {
  name = 'chip';
  theme = theme;
  classes = classes;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵChipStyle_BaseFactory;
    return function ChipStyle_Factory(__ngFactoryType__) {
      return (ɵChipStyle_BaseFactory || (ɵChipStyle_BaseFactory = i0.ɵɵgetInheritedFactory(ChipStyle)))(__ngFactoryType__ || ChipStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ChipStyle,
    factory: ChipStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChipStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * Chip represents people using icons, labels and images.
 *
 * [Live Demo](https://www.primeng.org/chip)
 *
 * @module chipstyle
 *
 */
var ChipClasses;
(function (ChipClasses) {
  /**
   * Class name of the root element
   */
  ChipClasses["root"] = "p-chip";
  /**
   * Class name of the image element
   */
  ChipClasses["image"] = "p-chip-image";
  /**
   * Class name of the icon element
   */
  ChipClasses["icon"] = "p-chip-icon";
  /**
   * Class name of the label element
   */
  ChipClasses["label"] = "p-chip-label";
  /**
   * Class name of the remove icon element
   */
  ChipClasses["removeIcon"] = "p-chip-remove-icon";
})(ChipClasses || (ChipClasses = {}));

/**
 * Chip represents people using icons, labels and images.
 * @group Components
 */
class Chip extends BaseComponent {
  /**
   * Defines the text to display.
   * @group Props
   */
  label;
  /**
   * Defines the icon to display.
   * @group Props
   */
  icon;
  /**
   * Defines the image to display.
   * @group Props
   */
  image;
  /**
   * Alt attribute of the image.
   * @group Props
   */
  alt;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Whether to display a remove icon.
   * @group Props
   */
  removable = false;
  /**
   * Icon of the remove element.
   * @group Props
   */
  removeIcon;
  /**
   * Callback to invoke when a chip is removed.
   * @param {MouseEvent} event - Mouse event.
   * @group Emits
   */
  onRemove = new EventEmitter();
  /**
   * This event is triggered if an error occurs while loading an image file.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onImageError = new EventEmitter();
  visible = true;
  removeIconTemplate;
  get removeAriaLabel() {
    return this.config.getTranslation(TranslationKeys.ARIA)['removeLabel'];
  }
  /**
   * Used to pass all properties of the chipProps to the Chip component.
   * @group Props
   */
  get chipProps() {
    return this._chipProps;
  }
  set chipProps(val) {
    this._chipProps = val;
    if (val && typeof val === 'object') {
      //@ts-ignore
      Object.entries(val).forEach(([k, v]) => this[`_${k}`] !== v && (this[`_${k}`] = v));
    }
  }
  _chipProps;
  _componentStyle = inject(ChipStyle);
  templates;
  ngOnChanges(simpleChanges) {
    super.ngOnChanges(simpleChanges);
    if (simpleChanges.chipProps && simpleChanges.chipProps.currentValue) {
      const {
        currentValue
      } = simpleChanges.chipProps;
      if (currentValue.label !== undefined) {
        this.label = currentValue.label;
      }
      if (currentValue.icon !== undefined) {
        this.icon = currentValue.icon;
      }
      if (currentValue.image !== undefined) {
        this.image = currentValue.image;
      }
      if (currentValue.alt !== undefined) {
        this.alt = currentValue.alt;
      }
      if (currentValue.style !== undefined) {
        this.style = currentValue.style;
      }
      if (currentValue.styleClass !== undefined) {
        this.styleClass = currentValue.styleClass;
      }
      if (currentValue.removable !== undefined) {
        this.removable = currentValue.removable;
      }
      if (currentValue.removeIcon !== undefined) {
        this.removeIcon = currentValue.removeIcon;
      }
    }
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'removeicon':
          this.removeIconTemplate = item.template;
          break;
        default:
          this.removeIconTemplate = item.template;
          break;
      }
    });
  }
  containerClass() {
    return {
      'p-chip p-component': true
    };
  }
  close(event) {
    this.visible = false;
    this.onRemove.emit(event);
  }
  onKeydown(event) {
    if (event.key === 'Enter' || event.key === 'Backspace') {
      this.close(event);
    }
  }
  imageError(event) {
    this.onImageError.emit(event);
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵChip_BaseFactory;
    return function Chip_Factory(__ngFactoryType__) {
      return (ɵChip_BaseFactory || (ɵChip_BaseFactory = i0.ɵɵgetInheritedFactory(Chip)))(__ngFactoryType__ || Chip);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Chip,
    selectors: [["p-chip"]],
    contentQueries: function Chip_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    inputs: {
      label: "label",
      icon: "icon",
      image: "image",
      alt: "alt",
      style: "style",
      styleClass: "styleClass",
      removable: [2, "removable", "removable", booleanAttribute],
      removeIcon: "removeIcon",
      chipProps: "chipProps"
    },
    outputs: {
      onRemove: "onRemove",
      onImageError: "onImageError"
    },
    features: [i0.ɵɵProvidersFeature([ChipStyle]), i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 1,
    consts: [["iconTemplate", ""], [3, "ngClass", "class", "ngStyle", 4, "ngIf"], [3, "ngClass", "ngStyle"], ["class", "p-chip-image", 3, "src", "alt", "error", 4, "ngIf", "ngIfElse"], ["class", "p-chip-label", 4, "ngIf"], [4, "ngIf"], [1, "p-chip-image", 3, "error", "src", "alt"], [3, "class", "ngClass", 4, "ngIf"], [3, "ngClass"], [1, "p-chip-label"], ["tabindex", "0", "class", "p-chip-remove-icon", "role", "button", 3, "click", "keydown", 4, "ngIf"], ["tabindex", "0", "role", "button", 3, "class", "ngClass", "click", "keydown", 4, "ngIf"], ["tabindex", "0", "role", "button", 3, "class", "click", "keydown", 4, "ngIf"], ["tabindex", "0", "role", "button", 3, "click", "keydown", "ngClass"], ["tabindex", "0", "role", "button", 3, "click", "keydown"], ["tabindex", "0", "role", "button", 1, "p-chip-remove-icon", 3, "click", "keydown"], [4, "ngTemplateOutlet"]],
    template: function Chip_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵtemplate(0, Chip_div_0_Template, 7, 11, "div", 1);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.visible);
      }
    },
    dependencies: () => [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, TimesCircleIcon],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Chip, [{
    type: Component,
    args: [{
      selector: 'p-chip',
      template: `
        <div
            [ngClass]="containerClass()"
            [class]="styleClass"
            [ngStyle]="style"
            *ngIf="visible"
            [attr.data-pc-name]="'chip'"
            [attr.aria-label]="label"
            [attr.data-pc-section]="'root'"
        >
            <ng-content></ng-content>
            <img class="p-chip-image" [src]="image" *ngIf="image; else iconTemplate" (error)="imageError($event)" [alt]="alt" />
            <ng-template #iconTemplate
                ><span *ngIf="icon" [class]="icon" [ngClass]="'p-chip-icon'" [attr.data-pc-section]="'icon'"></span
            ></ng-template>
            <div class="p-chip-label" *ngIf="label" [attr.data-pc-section]="'label'">{{ label }}</div>
            <ng-container *ngIf="removable">
                <ng-container *ngIf="!removeIconTemplate">
                    <span
                        tabindex="0"
                        *ngIf="removeIcon"
                        [class]="removeIcon"
                        [ngClass]="'p-chip-remove-icon'"
                        [attr.data-pc-section]="'removeicon'"
                        (click)="close($event)"
                        (keydown)="onKeydown($event)"
                        [attr.aria-label]="removeAriaLabel"
                        role="button"
                    ></span>
                    <TimesCircleIcon
                        tabindex="0"
                        *ngIf="!removeIcon"
                        [class]="'p-chip-remove-icon'"
                        [attr.data-pc-section]="'removeicon'"
                        (click)="close($event)"
                        (keydown)="onKeydown($event)"
                        [attr.aria-label]="removeAriaLabel"
                        role="button"
                    />
                </ng-container>
                <span
                    *ngIf="removeIconTemplate"
                    tabindex="0"
                    [attr.data-pc-section]="'removeicon'"
                    class="p-chip-remove-icon"
                    (click)="close($event)"
                    (keydown)="onKeydown($event)"
                    [attr.aria-label]="removeAriaLabel"
                    role="button"
                >
                    <ng-template *ngTemplateOutlet="removeIconTemplate"></ng-template>
                </span>
            </ng-container>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [ChipStyle]
    }]
  }], null, {
    label: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    image: [{
      type: Input
    }],
    alt: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    removable: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    removeIcon: [{
      type: Input
    }],
    onRemove: [{
      type: Output
    }],
    onImageError: [{
      type: Output
    }],
    chipProps: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class ChipModule {
  static ɵfac = function ChipModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ChipModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ChipModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, TimesCircleIcon, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChipModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TimesCircleIcon, SharedModule],
      exports: [Chip, SharedModule],
      declarations: [Chip]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Chip, ChipClasses, ChipModule, ChipStyle };

<form [formGroup]="form()" class="flex flex-col" row-gap="24">
  <lib-input label="Insurance company" [valid]="form().controls['insuranceId'].valid">
    <p-dropdown
      class="pk_insurance-details{{trackingFieldPrefix()}}_company"
      [options]="companyOptions()"
      [loading]="!companyOptions()"
      [ngStyle]="{width: '100%'}"
      styleClass="input-field form-field"
      formControlName="insuranceId"
      optionLabel="name"
      optionValue="id"
      name="insuranceCompany"
      [filter]="true"
      filterBy="name"
      [showClear]="false"
      placeholder="Select"
    />
  </lib-input>
  @if (!!companyOptions()) {
    @if (!isSecondaryInsForm()) {
      @if (isSelfPay()) {
        <div class="notice hide-empty">
      <span>
            <lib-icon name="?" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Warn" [size]="8"></lib-icon>
      </span>
          <div class="notice-info">
            <h6 class="heading-6 text-heading-2 hide-empty">Out-of-network</h6>
            <span class="sub-text text-body-secondary hide-empty text-body-sm pb-16">
            If you choose to self-pay, call <a class="link blend pk_insurance-details_intake"
                                               href="tel:855-501-1004">855-501-1004</a> to schedule an appointment. Self-pay is not available for patients with Medicare or Medicaid.
          </span>
          </div>
        </div>
      } @else if (isIdontSeeMyInsurance()) {
        <div class="notice hide-empty">
      <span>
            <lib-icon name="?" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Warn" [size]="8"></lib-icon>
      </span>
          <div class="notice-info">
            <h6 class="heading-6 text-heading-2 hide-empty">Out-of-network</h6>
            <span class="sub-text text-body-secondary hide-empty text-body-sm pb-16">
            Call <a class="link blend pk_insurance-details_intake"
                    href="tel:855-501-1004">855-501-1004</a> to schedule your appointment. Be sure to have your insurance information ready.
          </span>
          </div>
        </div>

      } @else if (outOfNetwork() || unsupportedInsurance()) {
        <div class="notice hide-empty">
      <span>
            <lib-icon name="?" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Warn" [size]="8"></lib-icon>
      </span>
          <div class="notice-info">
            <h6 class="heading-6 text-heading-2 hide-empty">Out-of-network</h6>
            <span class="sub-text text-body-secondary hide-empty text-body-sm pb-16">
            Online scheduling is currently not available for this plan. Call us to schedule your appointment at 855-501-1004. Be sure to have your insurance information ready.
          </span>
            <button type="button" class="btn btn-large step-next"
                    (click)="finder.goto('find')">
              View in-network clinicians
              <lib-icon [size]="16" class="icon" verticalAlign="top" name="chevron-right"
                        [type]="IconType.None"></lib-icon>
            </button>
            <span>Call <a class="link blend pk_insurance-details_intake"
                          href="tel:855-501-1004">855-501-1004</a> to see if self-pay is an options</span>
          </div>
        </div>
      }
    }

    @if (isSecondaryInsForm() || (hasSelectedInsurance() && !unsupportedInsurance() && !outOfNetwork())) {
      <lib-input label="Insurance plan" [valid]="form().controls['insurancePlanId'].valid">
        <p-dropdown
          class="pk_insurance-details{{trackingFieldPrefix()}}_plan"
          [options]="planOptions()"
          [loading]="!planOptions()"
          [ngStyle]="{width: '100%'}"
          styleClass="input-field form-field"
          formControlName="insurancePlanId"
          optionLabel="name"
          optionValue="id"
          dataKey="id"
          name="insurancePlan"
          [filter]="true"
          filterBy="name"
          [showClear]="false"
          placeholder="Select"
        />
      </lib-input>

      @if (!isSecondaryInsForm() && planBookingStatusError() === 'DoNotSchedule') {
        <div class="notice hide-empty">
      <span>
            <lib-icon name="?" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Warn" [size]="8"></lib-icon>
      </span>
          <div class="notice-info">
            <h6 class="heading-6 text-heading-2 hide-empty">Out-of-network</h6>
            <span class="sub-text text-body-secondary hide-empty text-body-sm pb-16">
            Online scheduling is currently not available for this plan. Call us to schedule your appointment at <a class="link blend pk_insurance-details_intake" href="tel:855-501-1004">855-501-1004</a>. Be sure to have your insurance information ready.
          </span>
            <button type="button" class="btn btn-large step-next"
                    (click)="finder.goto('find')">
              View in-network clinicians
              <lib-icon [size]="16" class="icon" verticalAlign="top" name="chevron-right"
                        [type]="IconType.None"></lib-icon>
            </button>
            <span>Call <a class="link blend pk_insurance-details_intake"
                          href="tel:855-501-1004">855-501-1004</a> to see if self-pay is an options</span>
          </div>
        </div>

      } @else if (!isSecondaryInsForm() && planBookingStatusError() === 'NeedsVerification') {
        <div class="notice hide-empty">
      <span>
            <lib-icon name="?" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Warn" [size]="8"></lib-icon>
      </span>
          <div class="notice-info">
            <h6 class="heading-6 text-heading-2 hide-empty">Additional information required</h6>
            <span class="sub-text text-body-secondary hide-empty text-body-sm pb-16">
            Call <a class="link blend pk_insurance-details_intake"
                    href="tel:855-501-1004">855-501-1004</a> to verify your insurance.
          </span>
          </div>
        </div>

      } @else {
        @if (!this.isSecondaryInsForm() && this.form().get('insuranceId')?.valid) {
          <div class="notice hide-empty">
      <span>
          <lib-icon name="checkmark" verticalAlign="top" [set]="IconSet.Mindpath" [type]="IconType.Success"
                    [size]="8"></lib-icon>
      </span>
            <div class="notice-info">
              <h6 class="heading-6 text-heading-2 hide-empty">In-network</h6>
              <span class="sub-text text-body-secondary hide-empty text-body-sm">
            Your insurance appears to be in-network. We will confirm coverage before your appointment as benefits can vary by plan.
          </span>
            </div>
          </div>
        }
        <lib-grouped-elements>
          <lib-text-input label="Member ID" control="memberNumber" class="pk_insurance-details_id"></lib-text-input>
          <lib-text-input label="Group number" control="groupNumber" [optionalIndicator]="true"
                          class="pk_insurance-details_group"
                          [required]="false"></lib-text-input>
        </lib-grouped-elements>

        <lib-checkbox-bar control="isNotPolicyHolder" title="I'm not the policyholder"
                          class="pk_insurance-details{{trackingFieldPrefix()}}_policyholder-check"
                          (checkedChange)="isNotHolder.set($event)">
        </lib-checkbox-bar>

        @if (isNotHolder()) {
          <lib-grouped-elements>
            <lib-text-input control="policyHolderName"
                            class="pk_insurance-details{{trackingFieldPrefix()}}_policyholder-name"
                            fxFlex label="Policyholder's name"></lib-text-input>
            <lib-input label="Policyholder's date of birth"
                       [valid]="form().controls['policyHolderDob'].valid">
              <input type="text" pInputText
                     mask="00/00/0000"
                     class="pk_insurance-details{{trackingFieldPrefix()}}_policyholder-dob"
                     autocomplete="bday"
                     placeholder="MM/DD/YYYY" formControlName="policyHolderDob">
            </lib-input>
          </lib-grouped-elements>
        }

        @if (secondaryFormTemplate(); as secondaryForm) {
          <lib-checkbox-bar control="hasSecondaryInsurance" title="I have secondary insurance"
                            class="pk_insurance-details_secondary"
                            (checkedChange)="hasSecondaryInsurance.set($event)">
            <ng-template templateFor="notice">
              <ng-container [ngTemplateOutlet]="secondaryForm"></ng-container>
            </ng-template>
          </lib-checkbox-bar>
        }
      }
    }
  }
</form>

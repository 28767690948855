import { Pipe, PipeTransform } from '@angular/core';
import {Schema, SuccessQueryItemType} from "../models/api/api-call";
import {ClinicianType} from "../models/api/mindpath";

@Pipe({
  name: 'clinicianSpecialty',
  standalone: true
})
export class ClinicianSpecialtyPipe implements PipeTransform {

  transform(value: ClinicianType): string {
    switch (value) {
      case ClinicianType.None:
        return '';
      case ClinicianType.InpatientOnlyProvider:
        return 'Inpatient Only';
      case ClinicianType.Prescriber:
        return 'Psychiatrist';
      case ClinicianType.Therapist:
      case ClinicianType.Paraprofessional:
      case ClinicianType.ABA:
        return value;
      case ClinicianType.AssociateProvisionallyLicensedProvider:
        return 'Associate Provisionally Licensed Provider';
    }
  }

}

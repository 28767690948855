<div fxLayout="column" fxLayoutGap="18">

  @for (group of grouped(); track group.setting) {

    @if (group.availability.length || showNoAvailability()) {
      <div fxLayout="column" class="availability-type" fxLayoutAlign="start start">
        <span style="font-size: 12px; line-height: calc(var(--size-12) * 1); text-align: left" fxLayout="row"
              fxLayoutAlign="start center">
          @if (group.setting === 'InPerson') {
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12" width="12" height="12"
                 style="color: var(--color-primary-primary)">
              <path fill="currentColor"
                    d="M10.25 2H8.5v-.25a.25.25 0 0 0-.25-.25h-4.5a.25.25 0 0 0-.25.25V2H1.75a.25.25 0 0 0-.25.25v8c0 .138.112.25.25.25H4.5V8.375c0-.207.168-.375.375-.375h2.25c.207 0 .375.168.375.375V10.5h2.75a.25.25 0 0 0 .25-.25v-8a.25.25 0 0 0-.25-.25M4.5 7h-1V6h1zm0-2h-1V4h1zm2 2h-1V6h1zm0-2h-1V4h1zm2 2h-1V6h1zm0-2h-1V4h1z"></path></svg>
            <span class="text-primary" style="font-size: var(--size-12); line-height: calc(var(--size-12) * 1); ">&nbsp;<strong>In-person</strong></span>
          } @else {
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12" width="12" height="12"
                 style="color: var(--color-primary-primary)">
              <path fill="currentColor"
                    d="M1.6 2c-.332 0-.6.273-.6.611V9.89c0 .338.268.611.6.611h7.1c.332 0 .6-.274.6-.611V2.61A.605.605 0 0 0 8.7 2zm9.6.611a.3.3 0 0 0-.21.087L9.5 4.138v4.223l1.497 1.447.005.005a.297.297 0 0 0 .41-.014.3.3 0 0 0 .088-.216V2.917a.3.3 0 0 0-.088-.216.3.3 0 0 0-.212-.09"></path></svg>
            <span class="text-primary" style="font-size: var(--size-12); line-height: calc(var(--size-12) * 1); ">&nbsp;<strong>Online</strong></span>
          }
        </span>
        <div fxLayout="row [wrap]" fxLayout.lt-sm="column" gap="8" class="pt-8">
          @for (slot of group.availability; track slot.time) {
            <div fxLayout="column"
                 (click)="emitClick(slot, group.setting)">
              <span class="availability-slot">{{ timeDate(slot.time) | date: 'hh:mm a' | lowercase }}</span>
            </div>
          } @empty {
            <span class="no-availability" style="font-size: 16px">No availability</span>
          }
        </div>
      </div>
    }
  }
</div>

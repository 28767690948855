import {Component, computed, inject, input, output} from '@angular/core';
import {Schema} from "@mindpath/shared";
import {DatePipe, LowerCasePipe} from "@angular/common";
import {FlexModule} from "@ngbracket/ngx-layout";
import {OnlineSchedulerService} from "../../../../../online-scheduler.service";
import {AppointmentSettingType} from "../../../../../../../../shared/src/lib/models/api/mindpath";
import {InsuranceDataService} from "../../../../../insurance-data.service";

type ApptSetting = Exclude<Schema<'TimeSlot'>['setting'],'Hybrid'|'None'>;

@Component({
  selector: 'app-slot-listing-component',
  standalone: true,
  imports: [
    DatePipe,
    FlexModule,
    LowerCasePipe
  ],
  templateUrl: './slot-listing-component.component.html',
  styleUrl: './slot-listing-component.component.scss'
})
export class SlotListingComponentComponent {
  slots = input.required<Schema<'TimeSlot'>[]>();

  slotClicked = output<{slot: Schema<'TimeSlot'>, setting: ApptSetting}>();
  showNoAvailability = input<boolean>(false);

  finder = inject(OnlineSchedulerService);
  insuranceDataSvc = inject(InsuranceDataService);

  emitClick(slot: Schema<'TimeSlot'>, setting: ApptSetting) {

    this.slotClicked.emit({slot, setting});
    // const data = this.wizard.data().steps;
    // this.wizard.argsSignal.update(args => {
    //
    //   const userData: Wizard.UserData = {
    //     appointment: {
    //       date: undefined,
    //       duration: 0,
    //       location: undefined,
    //       setting: undefined
    //     },
    //     clinician: undefined,
    //     conditionId: undefined,
    //     insuranceId: undefined,
    //     planId: undefined,
    //     reason: undefined,
    //     state:
    //     visitPreference: undefined
    //   };

      // return {
      //   ...args,
      //   data: userData
      // }
    // })

  }

  timeDate(time: string) {
    const today = new Date();
    return new Date(today.toDateString() + ' ' + time);
  }


  grouped = computed(() => {
    return Object.entries(this.slots().reduce((acc, a) => {
      let settings: ApptSetting[];
      if (a.setting === 'Hybrid') {
        settings = [AppointmentSettingType.InPerson, AppointmentSettingType.Telehealth];
      } else {
        settings = [a.setting];
      }
      settings.forEach(setting => {
        if (!acc[setting]) {
          acc[setting] = [];
        }
        acc[setting].push(a);
      });
      return acc;
    }, {
      InPerson: [],
      Telehealth: []
    } as Record<ApptSetting, Schema<'TimeSlot'>[]>)).map(([setting, availability]) => ({setting: setting as ApptSetting,availability}));
  });
}

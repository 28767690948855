import {Component, input} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";


@Component({
  selector: 'lib-icon',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.css'
})
export class IconComponent {

  name = input.required<string>();
  set = input<IconSet>(IconSet.Mindpath);
  alt = input<string|null>(null);
  size = input<4|8|12|16|32|64>(16);
  // colorCss = input<string|null>(null);
  colorClass = input<string|null>(null);
  type = input<IconType|null>(IconType.None);
  verticalAlign = input<'top'|'middle'|'bottom'|null>(null);

  protected readonly IconSet = IconSet;
  protected readonly IconType = IconType;
}

export enum IconSet {
  Mindpath = 'mindpath',
  Text = 'text',
}

export enum IconType {
  None = 'none',
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warn = 'warn',
}

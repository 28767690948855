import {
  Component,
  computed, DestroyRef,
  effect,
  inject,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
  TemplateRef
} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FormControl, FormGroup, ReactiveFormsModule, RequiredValidator, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {
  CheckboxBarComponent,
  CheckboxComponent, DateInputComponent, IconComponent, IconSet, IconType,
  InputComponent,
  SelectInputComponent,
  TextInputComponent,
  CalloutComponent
} from "@mindpath/shared";
import {DropdownModule} from "primeng/dropdown";
import {JsonPipe, NgStyle, NgTemplateOutlet} from "@angular/common";
import {isMessageCode, MessageCode} from "@mindpath/shared";
import {ButtonComponent} from "@mindpath/shared";
import {ActionType} from "@mindpath/shared";
import {FlexModule} from "@ngbracket/ngx-layout";
import {InputTextModule} from "primeng/inputtext";
import {NgxMaskDirective} from "ngx-mask";
import {TemplateFor, GroupedElementsComponent} from "@mindpath/shared";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {OnlineSchedulerService, SupportHtml} from "../../../online-scheduler.service";
import {InsuranceDataService} from "../../../insurance-data.service";
import {IMessageCode, Schemas, Schema} from "@mindpath/shared";
import {OptionItem} from "../../../utils";

@Component({
  selector: 'app-insurance-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    GroupedElementsComponent,
    TextInputComponent,
    SelectInputComponent,
    CheckboxComponent,
    InputComponent,
    CheckboxBarComponent,
    DateInputComponent,
    DropdownModule,
    CalloutComponent,
    NgStyle,
    IconComponent,
    ButtonComponent,
    FlexModule,
    InputTextModule,
    NgxMaskDirective,
    ButtonComponent,
    TemplateFor,
    NgTemplateOutlet,
    JsonPipe
  ],
  templateUrl: './insurance-form.component.html',
  styleUrl: './insurance-form.component.scss'
})
export class InsuranceFormComponent implements OnInit, OnDestroy {

  private subs = [] as (Subscription|undefined)[];
  form = input.required<FormGroup>();
  secondaryFormTemplate = input<TemplateRef<any>|null>(null);

  trackingFieldPrefix = computed(() => {
    return this.isSecondaryInsForm() ? '_secondary' : '';
  });

  isSecondaryInsForm = computed(() => {
    return !this.secondaryFormTemplate();
  });

  hasSelectedInsurance = computed(() => {
    const selector = this.isSecondaryInsForm() ? 'secondary' : 'primary';
    let insuranceData = this.finder.data.insurance()?.[selector];
    if (!insuranceData) return false;
    return !!insuranceData.id;
  });

  protected finder = inject(OnlineSchedulerService);
  protected insuranceDataSvc = inject(InsuranceDataService);

  protected planOptions = signal<(Schema<'InsurancePlan'> & OptionItem)[]>([]);


  isNotHolder = signal(false);
  showErrors = input.required<boolean>();
  hasSecondaryInsurance = signal(false);

  // insuranceQuery!: QueryService<IIntakeItemGenericInfo>;
  // insurancePlanQuery!: QueryService<IIntakeItemGenericInfo>;

  selectedInsuranceId = signal<number|null>(null);
  selectedPlanId = signal<number|null>(null);
  destroyRef = inject(DestroyRef);
  companyOptions = signal<OptionItem[]>([]);
  constructor() {

    const state = this.finder.ensure('demographics.address.state');
    this.insuranceDataSvc.getInsuranceCompanies(state).then(ins => {
      if (this.isSecondaryInsForm()) {
        this.companyOptions.set(ins.map(i => ({id: i.id!, name: i.name!})));
      } else {
        this.companyOptions.set([{id: -1, name: 'I don\'t see my insurance'}, {id: -2, name: 'Self-Pay'}, ...ins.map(i => ({id: i.id!, name: i.name!}))]);
      }
    });

    effect(() => {
      const selectedInsuranceId = this.selectedInsuranceId();
      if (!selectedInsuranceId) return;
      if (selectedInsuranceId === -1 || selectedInsuranceId === -2) {
        this.planOptions.set([]);
        return;
      }
      this.insuranceDataSvc.getInsuranceCompany(selectedInsuranceId).then(ins => {
        this.planOptions.set(ins!.plans!.map(p => ({...p, id: p.id!, name: p.name!})));
      });

    });

    let insuranceIdSubj: Subscription|undefined;
    let planIdSubj: Subscription|undefined;
    effect(() => {
      const form = this.form();
      if (!form) return;
      if (insuranceIdSubj) insuranceIdSubj.unsubscribe();
      if (planIdSubj) planIdSubj.unsubscribe();

      this.selectedInsuranceId.set(form.get('insuranceId')?.value ?? null);
      this.selectedPlanId.set(form.get('insurancePlanId')?.value ?? null);
      planIdSubj = form.get('insurancePlanId')?.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((val) => {
          this.selectedPlanId.set(val ?? null);
        });

      insuranceIdSubj = form.get('insuranceId')?.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((val) => {
          this.selectedInsuranceId.set(val ?? null);
      })
    }, {allowSignalWrites: true});

    effect(() => {
      const hasSecondaryInsurance = this.hasSecondaryInsurance();
      const ctrl = this.form().get('hasSecondaryInsurance');
      ctrl?.setValue(hasSecondaryInsurance);
    });

    effect(() => {
      const isNotHolder = this.isNotHolder();
      const ctrl = this.form().get('isNotPolicyHolder');
      ctrl?.setValue(isNotHolder);
    });

    effect(() => {
      const options = this.planOptions();
      const planInput = this.form().get('insurancePlanId');
      if (!planInput) return;
      if (!options?.length) {
        planInput.disable();
      } else {
        planInput.enable();
      }
    });

  }


  isSelfPay = computed(() => {
    return this.selectedInsuranceId() === -2;
  });

  isIdontSeeMyInsurance = computed(() => {
    return this.selectedInsuranceId() === -1;
  });
  outOfNetwork = input.required<boolean>();
  unsupportedInsurance = input.required<string|null>();

  planBookingStatusError = computed(() => {
    const planId = this.selectedPlanId();
    if (!planId) return null;
    const plans = this.planOptions();
    if (!plans || isMessageCode(plans)) return null;
    const plan = plans.find(p => p.id === planId);
    if (!plan?.bookingStatus) return null;
    return plan.bookingStatus !== 'OkayToSchedule' ? plan.bookingStatus : null;
  });

  readonly relationShipOptions = [
    {id: 1, name: 'Self'},
    {id: 2, name: 'Spouse'},
    // {id: 3, name: 'Child'},
    {id: 3, name: 'Other'}
  ];

  async ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.forEach(s => s?.unsubscribe());
  }


  static Insurances = [] as any[];
  static InsurancePlans = [] as any[];
  protected readonly ActionType = ActionType;
  protected readonly IconSet = IconSet;
  protected readonly IconType = IconType;
}

function parseDate(val: unknown): Date {
  const date = tryParseDate(val);
  if (!date) {
    throw new Error('Invalid date');
  }
  return date;
}

function tryParseDate(val: unknown): Date | null {
  if (typeof val === 'string') {
    if (val.length === 10) {
      val += 'T00:00:00';
    }
    const date = new Date(val);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date;
  } else if (val instanceof Date) {
    if (isNaN(val.getTime())) {
      return null;
    }
    return val;
  } else if (typeof val === 'number') {
    // check for timestamp length
    if (val.toString().length !== 13) {
      return null;
    }
    const date = new Date(val);
    if (isNaN(date.getTime())) {
      return null;
    }
  }
  return null;
}

function tryParseTime(val: unknown): [number, number] | null {
  if (typeof val === 'string') {
    const [hours, minutes] = val.split(':').map(Number);
    if (!isNaN(hours) && !isNaN(minutes) && hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
      return [hours, minutes];
    }
  }
  return null;
}

function fromDateAndTime(date: Date | string, time: string | [number, number]): Date {
  date = tryParseDate(date)!;
  if (!date) {
    throw new Error('Invalid date');
  }
  if (typeof time === 'string') {
    time = parseTime(time);
  }
  const [hours, minutes] = time;
  const newDate = new Date(date);
  newDate.setHours(hours, minutes);
  return newDate;
}

function parseTime(time: string): [number, number] {
  const parts = tryParseTime(time);
  if (!parts) {
    throw new Error('Invalid time');
  }
  return parts;
}

function splitDateAndTime(dateTime: Date): { date: Date, time: [number, number] } {
  return {
    date: removeTime(dateTime),
    time: extractTime(dateTime),
  }
}

function extractTime(dateTime: Date): [number, number] {
  return [dateTime.getHours(), dateTime.getMinutes()];
}

function removeTime(date: Date): Date {
  return new Date(date.toDateString());
}

function asUtcEquivalent(date: Date): Date {
  const offset = date.getTimezoneOffset();
  date = new Date(date);
  date.setMinutes(date.getMinutes() + offset);
  return date;
}

export default {
  asUtcEquivalent,
  tryParseDate,
  tryParseTime,
  parseDate,
  parseTime,
  fromDateAndTime,
  removeTime,
  extractTime,
  splitDateAndTime,
  toDateOnlyString: (date: Date): string => date.toISOString().slice(0, 10),
  toTimeString: (date: Date | [number, number]): string =>
    Array.isArray(date) ? `${date[0].toString().padStart(2, '0')}:${date[1].toString().padStart(2, '0')}` : date.toTimeString().slice(0, 5),
}
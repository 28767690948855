import {
  ApplicationConfig,
  importProvidersFrom, ImportProvidersSource,
  provideExperimentalZonelessChangeDetection,
  provideZoneChangeDetection
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {provideEnvironmentNgxMask} from "ngx-mask";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {provideScreenSizeVars} from "@mindpath/shared";
import {Environments, LoadConfig, provideConfig} from "../config";
import {NgxPiwikProModule} from "@piwikpro/ngx-piwik-pro";

const config = await LoadConfig();

const moduleProviders: ImportProvidersSource[] = [];

if (!config.isLocal && config.piwik) {
  moduleProviders.push(NgxPiwikProModule.forRoot(config.piwik.siteId, config.piwik.url));
}

export const appConfig: ApplicationConfig = {
  providers: [
    // provideZoneChangeDetection({ eventCoalescing: true }),
    provideExperimentalZonelessChangeDetection(),
    provideAnimationsAsync(),
    provideConfig(),
    provideEnvironmentNgxMask(),
    provideHttpClient(
      withInterceptors([
        // wizardHttpHooksInterceptor(args.urlMap)
      ])
    ),
    provideScreenSizeVars(),
    provideRouter(routes),
    importProvidersFrom(moduleProviders)
  ]
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
  standalone: true
})
export class PhonePipe implements PipeTransform {

  transform(value: string|undefined): string {
    if (!value) return '';
    const val = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    if (val.length !== 12) return value;
    return val;
  }

}

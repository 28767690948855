import {Component, computed, effect, inject, input, output, TemplateRef, WritableSignal} from '@angular/core';
import {BarComponent, IconComponent, IconType, InputComponent, QueryOptions, SortDirection} from "@mindpath/shared";
import {FlexModule} from "@ngbracket/ngx-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgIf, NgStyle} from "@angular/common";
import {OnlineSchedulerService} from "../../../../../online-scheduler.service";


@Component({
  selector: 'app-setting-filter',
  standalone: true,
  imports: [
    FlexModule,
    ReactiveFormsModule,
    NgStyle,
    FormsModule,
    BarComponent,
    InputComponent,
    IconComponent,
    NgIf
  ],
  templateUrl: './setting-filter.component.html',
  styleUrl: './setting-filter.component.scss'
})
export class SettingFilterComponent {

  onFilterChange = input.required<WritableSignal<QueryOptions<'/api/Availability/slots'>>>();

  finder = inject(OnlineSchedulerService);
  selectedSetting = computed(() => {
    return this.finder.data.search().settingPref;
  })
  hasItems = output<boolean>();
  protected readonly IconType = IconType;

  constructor() {

    effect(() => {
      const setting = this.selectedSetting();
      this.hasItems.emit(true);

      this.finder.data.search.update(search => {
        return {
          ...search,
          settingPref: (setting as any) === 'Hybrid' ? null : setting
        }
      });

      this.onFilterChange().update(filters => {

        if (setting !== 'Telehealth') {
          const loc = this.finder.data.demographics()?.address.coordinates!;
          filters['filter.Location.distance'] = `within 5000 miles of ${loc.lat},${loc.long}`;
          filters['sort.Location.distance'] = SortDirection.Asc;
        } else {
          delete filters['filter.Location.distance'];
          delete filters['sort.Location.distance'];
        }

        if (setting === 'Telehealth' || setting === 'InPerson') {
          filters['filter.Days.Slots.Setting'] = `eq '${setting}'`;
        } else {
          delete filters['filter.Days.Slots.Setting'];
        }

        return {...filters};
      })
    }, {allowSignalWrites: true});
  }

  openSideBar(sidebar: TemplateRef<any>) {
    this.finder.sidebar.set({
      template: sidebar,
      resetHook: () => {
        this.change(null);
      }
    });

    // this.finder.sidebarTemplate.set(sidebar);
    // this.finder.sideBarResetHook.set(() => {
    //   this.selectedSetting.set(null);
    // });
  }

  change(val: any|null) {
    this.finder.data.search.update(s => ({
      ...s,
      settingPref: val as any
    }));
  }
}

import {Component, computed, effect, inject, OnInit, signal} from '@angular/core';
import {FlexLayoutModule, FlexModule} from "@ngbracket/ngx-layout";
import {
  ApiResponseType, loadApiEndpoint, loadApiEndpointResp, PagedApiResponseType,
  QueryOptions,
  Schema
} from "@mindpath/shared";
import {SidebarModule} from "primeng/sidebar";
import {AsyncPipe, NgTemplateOutlet} from "@angular/common";
import {Button} from "primeng/button";
import {provideAnimations} from "@angular/platform-browser/animations";
import {OnlineSchedulerService} from "../../../online-scheduler.service";
import {SearchFilterBarComponent} from "./search-filter-bar/search-filter-bar.component";
import {
  ButtonComponent,
  IconComponent,
  IconType,
  InnerScrollableComponent, ViewScrolling
} from "@mindpath/shared";
import {AvailabilityCardViewComponent} from "./views/availability-card-view/availability-card-view.component";

import {FormsModule} from "@angular/forms";
import {Drawer} from "primeng/drawer";
import {TemplateFor} from "@mindpath/shared";
import {DividerModule} from "primeng/divider";
import {screenSize} from "@mindpath/shared";
import {CliniciansDataService} from "../../../clinicians-data.service";
import { debounce } from 'projects/shared/src/lib/utils';
import {Logger} from "@mindpath/shared";
import {AppointmentsDataService} from "../../../appointments-data.service";
import {AppointmentSettingType} from "../../../../../../shared/src/lib/models/api/mindpath";

@Component({
  selector: 'app-search-page',
  standalone: true,
  imports: [
    FlexModule,
    AvailabilityCardViewComponent,
    SidebarModule,
    NgTemplateOutlet,
    ButtonComponent,
    SearchFilterBarComponent,
    FormsModule,
    InnerScrollableComponent,
    IconComponent,
    TemplateFor,
    Drawer,
    DividerModule,
    FlexLayoutModule,
    AsyncPipe
  ],
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.scss',
  providers: [provideAnimations()]
})
export class SearchPageComponent implements OnInit {
  title = 'ClinicianFinder';


  resetFilters() {
    this.filters.set({

    });
    this.scheduler.data.search.set({
      age: null,
      clinicianTypePref: null,
      conditionId: -1,
      dayOfWeekPref: null,
      genderPref: null,
      languagePref: null,
      settingPref: null,
      soonestWeekOut: 0,
      timeOfDayPref: null
    })
    this.scheduler.sidebar.set(null);
  }
  sidebarVisible = true;
  scheduler = inject(OnlineSchedulerService);
  appointmentSvc = inject(AppointmentsDataService);
  clinicianSvc = inject(CliniciansDataService);

  filters = signal<QueryOptions<'/api/Availability/slots'>>({});

  loading = false;
  // filters = signal<QueryOptions<'/api/Availability/slots'>|null>(null)
  items = signal<readonly Schema<'ClinicianAvailabilityInfo'>[]|null>(null);
  // additionalFilters = signal<{
  //   language?: string;
  //   gender?: string;
  //   startDate?: Date;
  //   timeOfDay?: string;
  //   dayOfWeek?: string;
  // }>({});
  totalPages = signal(0);
  pageNumbers = computed(() => {
    return Array.from({length: this.totalPages()}, (_, i) => i + 1);
  });
  currentPage = signal(1);
  pageSize = signal(25);
  totalCount = signal(0);

  protected readonly IconType = IconType;
  protected readonly matchMedia = matchMedia;
  protected readonly screenSize = screenSize;

  constructor() {
    // effect(() => {
    //   const filters = this.filters();
    //   this.pullPage(filters)
    // }, {allowSignalWrites: true});

    this.scheduler.showLoading('Looking for matches...', 'Please wait while we find available clinicians');
    effect(() => {

      const filters = this.filters();
      const ins = this.scheduler.data.insurance();
      const demographic = this.scheduler.data.demographics();
      // console.log('FiltersChanged', filters);
      this.pullPage({...filters}, 1);
    }, {allowSignalWrites: true});

  }

  filterPillClicked(filter: 'setting' | 'services' | 'availability' | 'gender' | 'languages') {
    // this.scheduler.sidebarTemplate.set(this.scheduler.templates[filter]);
  }

  async apptChosen(appt: Schema<'ClinicianAvailabilityInfo'>, date: Date, slot: Schema<'TimeSlot'>, setting: AppointmentSettingType.InPerson | AppointmentSettingType.Telehealth) {
    const clinician = await this.clinicianSvc.getSingle(appt.clinician.npi);
    // const dateTime = new Date(date.toDateString() + ' ' + slot.time);
    // const clinician = (await this.clinicianSvc.getSingle(appt.clinician.npi))!;
    // const time = slot.time.split(':');
    const dateTimeStr = `${date.toISOString().slice(0, 10)}T${slot.time}Z`;

    const dateTime = new Date(dateTimeStr);

    this.scheduler.data.appt.set({
      clinician: clinician!,
      slot,
      originalSetting: slot.setting,
      chosenSetting: setting,
      dateTime,
      duration: slot.duration,
      location: appt.location!,
      conditionId: this.scheduler.data.search().conditionId!,
      typeOfCare: this.scheduler.data.search().clinicianTypePref ?? null!,
    });
    this.scheduler.goForward();

  }

  nextPage() {
    this.pullPage(this.filters(), this.currentPage() + 1);
  }
  prevPage() {
    this.pullPage(this.filters(), this.currentPage() - 1);
  }

  viewScrolling = inject(ViewScrolling);
  pullPage = debounce(async (filters: QueryOptions<'/api/Availability/slots'>, page: number)=> {
    if (this.loading) {
      return;
    }
    const section = this.scheduler.data.meta().section;
    if (section !== 'find') {
      return;
    }
    this.loading = true;
    this.scheduler.showLoading('Looking for matches...', 'Please wait while we find available clinicians');

    const data = this.scheduler.data;
    const insuranceData = data.insurance().primary;
    const state = data.demographics()?.address.state;
    const age = data.search().age;
    filters = {
      ...filters,
    };

    if (age) {
      filters['filter.Clinician.AgeRange'] = `eq '${age}'`;
    }

    if (state) {
      filters['filter.Location.Address.State'] = `eq '${state}'`;
    }

    if (insuranceData) {
      if (insuranceData.id) {
        filters['filter.Clinician.acceptedInsurance'] = `eq ${insuranceData.id}`;
      }
      if (insuranceData.planId) {
        filters['filter.Clinician.acceptedInsurancePlans'] = `eq ${insuranceData.planId}`;
      }
    }

    filters['filter.Days.Slots.Duration'] = 'eq 60';

    Logger.Debug('SearchPage', 'pullPage', filters);
    // this.loading.set(false);
    // return;

    try {
      const resp = await this.appointmentSvc.getAvailabilityInfo({Page: page, PageSize: 10, options: filters});
      if (resp.ok) {
        const data = await resp.json() as PagedApiResponseType<'/api/Availability/slots'>;
        this.items.set(data.items!);
        this.totalPages.set(data.totalPages!);
        this.totalCount.set(data.totalCount!);
        this.currentPage.set(data.currentPage!);
      }
    } catch (e) {
      Logger.Error('SearchPage', e);
    } finally {
      this.loading = false;
      this.scheduler.hideLoading();
    }

    this.viewScrolling.scrollTo({top: 0, behavior: 'smooth'});

  }, 150);

  async ngOnInit() {
    // this.loadAllClinicians();
    // this.pullPage(this.filters());
  }

  async loadAllClinicians() {
    // this.scheduler.loadClinicians().then(resp => {
    //   this.clinicians.set(resp!);
    // });
  }

}

type P = '/api/Availability';

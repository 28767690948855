<ng-container>
  <button class="filter-pill selected" type="button" (click)="openSideBar(sideBar)">

  @switch (selectedSetting()) {
    @case ('InPerson') {
      In-person
    }
    @case ('Telehealth') {
      Online
    }
    @default {
      In-person or Online
    }
  }
  </button>
</ng-container>

<ng-template #sideBar>
  <section>
    <h4>Appointment Type</h4>
    <ul>

      @for (option of [
        {type: 'Hybrid', msg: 'In-person or Online', icon: null},
        {type: 'InPerson', msg: 'In-person', icon: 'building'},
        {type: 'Telehealth', msg: 'Online', icon: 'online-meeting'},
      ]; track option.type) {
        @let selected = (!selectedSetting() && option.type === 'Hybrid' || selectedSetting() === option.type);
        <li class="pt-12">
          <lib-bar customClass="{{selected ? 'selected' : ''}}"
                   (click)="change($any(option.type));">
        <span fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
          <span fxLayout="row" fxLayoutAlign="start center" gap="12" class="bar-header">

            @if (option.icon) {
              <lib-icon name="{{option.icon}}" verticalAlign="middle"
                        [size]="32"></lib-icon>
            }
            {{ option.msg }}
          </span>
          @if (selected) {
            <span>
                <lib-icon verticalAlign="middle" name="checkmark" [type]="IconType.Success"></lib-icon>
              </span>
          }
        </span>
          </lib-bar>
        </li>
      }
    </ul>


  </section>
</ng-template>
<ng-container>
  <button class="filter-pill " [class.used]="isSelected()" type="button" (click)="openSideBar(sideBar)">
      @switch (typeOfCare()) {
        @case ('Therapy') {
          @if(selectedConditionId() === -1) {
            Therapy
          } @else {
            Type of care <span class="count">2</span>
          }
        }
        @case ('Psychiatry') {
          @if(selectedConditionId() === -1) {
            Psychiatry
          } @else {
            Type of care <span class="count">2</span>
          }
        }
        @default {
          Type of care
          @if(selectedConditionId() !== -1) {
            <span class="count">2</span>
          }
        }
      }
  </button>
</ng-container>

<ng-template #sideBar>
  <div fxLayout="column" gap="16" style="width: 100%;">

    <section>
      <h4>Type of care</h4>
      <ul>
        @for(opt of ['Therapy', 'Psychiatry']; track opt) {
          <li class="pt-12">
            <lib-bar style="padding-top: 8px" customClass="{{typeOfCare() === opt ? 'selected' : ''}}"
                     (click)="changeType(typeOfCare() === opt ? null : $any(opt))">
            <span fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                <span fxLayout="row" fxLayoutAlign="start center" gap="12" class="bar-header">
                  {{ opt }}
                </span>
              @if (typeOfCare() === opt) {
                <span class="text-primary">
                  <lib-icon verticalAlign="middle" name="checkmark" [type]="IconType.Success"></lib-icon>
                </span>
              }
            </span>
            </lib-bar>
          </li>
        }
      </ul>
    </section>

    <section fxLayout="column" gap="4" style="width: 100%" class="pt-24">
      <h4>Reason for visit</h4>
      <lib-bar style="padding-top: 8px" customClass="{{selectedConditionId() === -1 ? 'selected' : ''}}"
               (click)="changeCondition(-1)">
        <span fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                <span fxLayout="row" fxLayoutAlign="start center" gap="12" class="bar-header">
                  General Evaluation
                </span>
          @if (selectedConditionId() === -1) {
            <span class="text-primary">
                  <lib-icon verticalAlign="middle" name="checkmark" [type]="IconType.Success"></lib-icon>
                </span>
          }
            </span>

      </lib-bar>

      <h5 class="text-secondary pt-8">
        Search
      </h5>
      <p-autoComplete
        [forceSelection]="true"
        [suggestions]="$any(filteredConditions())"
        [lazy]="true"
        [delay]="125"
        [ngModel]="selectedConditionFromOptions()"
        placeholder="All topics"
        [style]="{width: '100%'}"
        optionLabel="name"
        dataKey="id"
        (onSelect)="changeCondition($event.value)"
        (completeMethod)="filter($event)">
        <ng-template let-item pTemplate="item">
          {{ item.name }}
        </ng-template>
        <ng-template let-item pTemplate="selectedItem">
          {{ item.name }}
        </ng-template>
        <ng-template pTemplate="empty">
          <div class="flex items-center gap-2">
            No results found
          </div>
        </ng-template>
      </p-autoComplete>

      <ul>
        @for (opt of conditionOptions(); track opt.id) {
          <li class="pt-4">
            <button type="button" class="link text text-primary text-left option"
                    [class.selected]="selectedConditionId() === opt.id"
                    (click)="changeCondition(opt.id);">
              <strong>{{ opt.name }}</strong>
            </button>
          </li>
        }
      </ul>

    </section>
  </div>

</ng-template>
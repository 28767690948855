import {AbstractControl, ValidationErrors} from "@angular/forms";

export const DateToStringNoHyphen = (date?: Date|string): string => {
  if (!date) return '';
  // console.log({date})
  date = typeof date === 'string' ? new Date(date) : date;
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month < 10 ? '0' + month : month}${day < 10 ? '0' + day : day}${year}`;
}
export const StringNoHyphenToDate = (val: string|Date): Date => {
  if (val instanceof Date) return val;
  // console.log({val})
  const month = val.slice(0, 2);
  const day = val.slice(2, 4);
  const year = val.slice(4, 8);
  const newVal = new Date(`${year}-${month}-${day}`);
  // console.log({newVal});
  return ChangeTzToUTC(newVal);
}

export function DateToDateOnlyString(date: Date|string): string {
  date = typeof date === 'string' ? new Date(date) : date
  return date.toISOString().slice(0, 10);
}

export const ChangeTzToUTC = (date: Date): Date => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export const StringNoHyphenDateValidator = (type: 'min' | 'max' | 'pastOrPreset'|'past'|'future', date: Date) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const val = control.value;
    if (!val) return null;
    const dateStr = val.toString();
    if (dateStr.length !== 8) return {pattern: true};

    const dateStrWithSlash = `${dateStr.slice(0, 2)}/${dateStr.slice(2, 4)}/${dateStr.slice(4, 8)}`;
    // console.log({dateStrWithSlash})
    if (!MM_DD_YYYY_Regex.test(dateStrWithSlash)) {
      // console.log('invalid date', {dateStrWithSlash, MM_DD_YYYY_Regex});
      return {
        invalidDate: true
      };
    }

    const dateVal = StringNoHyphenToDate(dateStr);
    if (type === 'pastOrPreset' && dateVal > date) return {pastOrPresent: true};
    if (type === 'past' && dateVal >= date) return {past: true};
    if (type === 'future' && dateVal <= date) return {future: true};
    if (type === 'min' && dateVal < date) return {minDate: true};
    if (type === 'max' && dateVal > date) return {maxDate: true};
    return null;
  }
}

export const MM_DD_YYYY_Regex = /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

const dateRegex = /^(?:(0[1-9]|1[0-2])\/(0[1-9]|1\d|2[0-8])\/(\d{4})|(?:02\/29\/((?:(?!0000)\d{4})|(?:\d{2}(?:[2468][048]|[13579][26])))))|(?:(0[13-9]|1[0-2])\/(29|30)\/(\d{4}))|(?:(0[13578]|1[02])\/31\/(\d{4}))$/;

import {Component, computed, effect, inject, input, OnInit, output, signal} from '@angular/core';
import {ButtonComponent, CheckboxBarComponent, IconComponent, IconType, LinkComponent} from "@mindpath/shared";
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {OnlineSchedulerService} from "../../../../online-scheduler.service";

@Component({
  selector: 'app-eligibility',
  standalone: true,
  imports: [
    CheckboxBarComponent,
    ButtonComponent,
    LinkComponent,
    FlexModule,
    IconComponent,
    ExtendedModule
  ],
  templateUrl: './eligibility.component.html',
  styleUrl: './eligibility.component.scss'
})
export class EligibilityComponent implements OnInit {

  isValid = computed(() => {
    return this.finder.data.meta().booking?.eligibility?.every(a => !a.checked) ?? false;
  });

  protected readonly FilterQuestions = FilterQuestions;

  answerChanged(title: string, value: boolean) {
    this.finder.data.meta.update(meta => {
      const newAnswers = [...meta.booking?.eligibility ?? []];
      const index = newAnswers.findIndex(a => a.id === title);
      newAnswers[index] = {...newAnswers[index], checked: value};

      return ({
        ...meta ?? {},
        booking: {
          ...meta?.booking ?? {} as any,
          eligibility: newAnswers,
        }

      });
    });
  }

  isChecked(title: string) {
    return this.finder.data.meta().booking?.eligibility?.find(a => a.id === title)?.checked ?? false;
  }

  finder = inject(OnlineSchedulerService);

  constructor() {

    const data = this.finder.data.meta().booking?.eligibility ?? [];
    const missing = FilterQuestions.filter(q => !data.some(d => d.id === q.title));
    if (missing.length > 0) {
      this.finder.data.meta.update(meta => {
        return ({
          ...meta ?? {},
          booking: {
            ...meta?.booking ?? {} as any,
            eligibility: data.concat(missing.map((d, i) => ({id: d.title, checked: false}))),
          }
        });
      });
    }
  }


  ngOnInit() {

  }

  protected readonly IconType = IconType;
}
const unableToProceedMsg = 'We are unable to proceed with scheduling an appointment';
export const FilterQuestions = [
  {
    title: 'You are scheduling an appointment for someone else',
    details: '',
    trackingTag: 'pk_prescreen_someone-else',
    stopMsg: {
      title: unableToProceedMsg,
      details: 'Online scheduling on behalf of another person is currently unavailable. Call <a class="link blend pk_prescreen_intake" href="tel:855-501-1004">855-501-1004</a> to schedule an appointment.',
    }
  },
  {
    title: 'You have visited Mindpath Health or one of the practices listed below in the last 12 months',
    details: 'Community Psychiatry, MindPath Care Centers, Carolina Partners, Centers for Adult and Child Psychiatry (CFAP), Metro NBI, or Psychiatric Centers of San Diego (PCSD), and Acacia Counseling and Wellness.',
    trackingTag: 'pk_prescreen_recent-visit',
    stopMsg: {
      title: unableToProceedMsg,
      details: 'You are a current patient. Log into the patient portal or call your clinician\'s office to schedule an appointment.',
    }
  },
  {
    title: 'You have been hospitalized or visited the ER for psychiatric reasons more than twice within the last 4 weeks',
    details: '',
    trackingTag: 'pk_prescreen_hospitalized',
    stopMsg: {
      title: unableToProceedMsg,
      details: 'We are unable to schedule an appointment at this time. Please refer to the plan of care from your discharge planner or contact the facility that discharged you for help finding services.',
    }
  },
  {
    title: 'You are seeking assistance with disability paperwork or a work-related injury',
    details: '',
    trackingTag: 'pk_prescreen_disability',
    stopMsg: {
      title: unableToProceedMsg,
      details: 'We are unable to schedule appointments for disability paperwork or work-related injuries. Call your primary care doctor or employer for assistance with further resources.'
    }
  }
]

// type Mutable<T> =  { -readonly [P in keyof T]: T[P] };
// type Mutable<T> = T extends readonly any[] ?  {-readonly [P in keyof T]: T[P]} : T;

type Writeable<T> = { -readonly [P in keyof T]: T[P] };


import {computed, Injectable, signal, WritableSignal} from '@angular/core';
import {loadApiEndpoint, Logger, Schema} from "@mindpath/shared";
import {config} from "../config";
import {PendingDataCollection, WrapAsCachedPromise} from "./utils/pending-data";

@Injectable({
  providedIn: 'root'
})
export class InsuranceDataService {

  private cached = new PendingDataCollection<Schema<'DetailedInsurance'>>({cacheKey: 'insurances'});

  constructor() {
    this.getInsuranceCompanies = WrapAsCachedPromise(this.getInsuranceCompanies);
    this.getInsuranceCompany = WrapAsCachedPromise(this.getInsuranceCompany);
  }

  public readonly snapshot = computed(() => {
    const resolved = this.cached.resolved();
    return resolved.toSorted((a, b) => a.name.localeCompare(b.name));
  })

  async getInsuranceCompany(id: number): Promise<Schema<'DetailedInsurance'> | undefined> {
    const cached = this.snapshot().find(i => i.id === id);
    if (cached) {
      return cached;
    }

    try {
      return this.cached.upsert(loadApiEndpoint(config.endpoints.api, '/api/Insurances/{id}').get({
        path: {id}
      }));
    } catch (e) {
      Logger.Error('InsuranceDataService', e);
      return undefined;
    }
  }

  async getInsuranceCompanies(state?: string | null): Promise<Schema<'DetailedInsurance'>[]> {

    if (state) {
      const cached = this.snapshot().filter(i => i.plans.some(p => p.state === state));
      if (cached.length) {
        return cached;
      }
    }

    let pageNumber = 1;
    let items: Schema<'DetailedInsurance'>[] = [];
    while (true) {
      let query: any = {
        Page: pageNumber++,
      };
      if (state) {
        query['filter.state'] = `'${state}'`;
      }
      const page = await loadApiEndpoint(config.endpoints.api, '/api/Insurances').get({
        query
      });
      const pageItems: readonly Schema<'DetailedInsurance'>[] = page.items;
      items.push(...this.cached.upsert(pageItems));
      if (!page.hasMore) {
        break;
      }
    }
    items.sort((a, b) => a.name.localeCompare(b.name));
    items = items.map(i => ({...i, plans: i.plans.toSorted((a, b) => a.name.localeCompare(b.name))}));

    return items;
  }
}

import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, forwardRef, EventEmitter, signal, inject, booleanAttribute, numberAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ViewChild, ContentChildren, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { AutoFocusModule } from 'primeng/autofocus';
import { CheckIcon } from 'primeng/icons/check';
import { ObjectUtils } from 'primeng/utils';
import { MinusIcon } from 'primeng/icons/minus';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const _c0 = ["input"];
const _c1 = () => ({
  "p-checkbox-input": true
});
const _c2 = a0 => ({
  checked: a0,
  class: "p-checkbox-icon"
});
function Checkbox_ng_container_4_ng_container_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 8);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", ctx_r1.checkboxIcon);
    i0.ɵɵattribute("data-pc-section", "icon");
  }
}
function Checkbox_ng_container_4_ng_container_1_CheckIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "CheckIcon", 9);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-checkbox-icon");
    i0.ɵɵattribute("data-pc-section", "icon");
  }
}
function Checkbox_ng_container_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Checkbox_ng_container_4_ng_container_1_span_1_Template, 1, 2, "span", 7)(2, Checkbox_ng_container_4_ng_container_1_CheckIcon_2_Template, 1, 2, "CheckIcon", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.checkboxIcon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.checkboxIcon);
  }
}
function Checkbox_ng_container_4_MinusIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "MinusIcon", 9);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-checkbox-icon");
    i0.ɵɵattribute("data-pc-section", "icon");
  }
}
function Checkbox_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Checkbox_ng_container_4_ng_container_1_Template, 3, 2, "ng-container", 4)(2, Checkbox_ng_container_4_MinusIcon_2_Template, 1, 2, "MinusIcon", 6);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.checked);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1._indeterminate());
  }
}
function Checkbox_5_ng_template_0_Template(rf, ctx) {}
function Checkbox_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Checkbox_5_ng_template_0_Template, 0, 0, "ng-template");
  }
}
const theme = ({
  dt
}) => `
.p-checkbox {
    position: relative;
    display: inline-flex;
    user-select: none;
    vertical-align: bottom;
    width: ${dt('checkbox.width')};
    height: ${dt('checkbox.height')};
}

.p-checkbox-input {
    cursor: pointer;
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    z-index: 1;
    outline: 0 none;
    border: 1px solid transparent;
    border-radius: ${dt('checkbox.border.radius')};
}

.p-checkbox-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${dt('checkbox.border.radius')};
    border: 1px solid ${dt('checkbox.border.color')};
    background: ${dt('checkbox.background')};
    width: ${dt('checkbox.width')};
    height: ${dt('checkbox.height')};
    transition: background ${dt('checkbox.transition.duration')}, color ${dt('checkbox.transition.duration')}, border-color ${dt('checkbox.transition.duration')}, box-shadow ${dt('checkbox.transition.duration')}, outline-color ${dt('checkbox.transition.duration')};
    outline-color: transparent;
    box-shadow: ${dt('checkbox.shadow')};
}

.p-checkbox-icon {
    transition-duration: ${dt('checkbox.transition.duration')};
    color: ${dt('checkbox.icon.color')};
    font-size: ${dt('checkbox.icon.size')};
    width: ${dt('checkbox.icon.size')};
    height: ${dt('checkbox.icon.size')};
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
    border-color: ${dt('checkbox.hover.border.color')};
}

.p-checkbox-checked .p-checkbox-box {
    border-color: ${dt('checkbox.checked.border.color')};
    background: ${dt('checkbox.checked.background')};
}

.p-checkbox-checked .p-checkbox-icon {
    color: ${dt('checkbox.icon.checked.color')};
}

.p-checkbox-checked:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
    background: ${dt('checkbox.checked.hover.background')};
    border-color: ${dt('checkbox.checked.hover.border.color')};
}

.p-checkbox-checked:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-icon {
    color: ${dt('checkbox.icon.checked.hover.color')};
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible) .p-checkbox-box {
    border-color: ${dt('checkbox.focus.border.color')};
    box-shadow: ${dt('checkbox.focus.ring.shadow')};
    outline: ${dt('checkbox.focus.ring.width')} ${dt('checkbox.focus.ring.style')} ${dt('checkbox.focus.ring.color')};
    outline-offset: ${dt('checkbox.focus.ring.offset')};
}

.p-checkbox-checked:not(.p-disabled):has(.p-checkbox-input:focus-visible) .p-checkbox-box {
    border-color: ${dt('checkbox.checked.focus.border.color')};
}

.p-checkbox.ng-invalid.ng-dirty > .p-checkbox-box {
    border-color: ${dt('checkbox.invalid.border.color')};
}

.p-checkbox.p-variant-filled .p-checkbox-box {
    background: ${dt('checkbox.filled.background')};
}

.p-checkbox-checked.p-variant-filled .p-checkbox-box {
    background: ${dt('checkbox.checked.background')};
}

.p-checkbox-checked.p-variant-filled:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
    background: ${dt('checkbox.checked.hover.background')};
}

.p-checkbox.p-disabled {
    opacity: 1;
}

.p-checkbox.p-disabled .p-checkbox-box {
    background: ${dt('checkbox.disabled.background')};
    border-color: ${dt('checkbox.checked.disabled.border.color')};
}

.p-checkbox.p-disabled .p-checkbox-box .p-checkbox-icon {
    color: ${dt('checkbox.icon.disabled.color')};
}
`;
const classes = {
  root: ({
    instance,
    props
  }) => ['p-checkbox p-component', {
    'p-checkbox-checked': instance.checked,
    'p-disabled': props.disabled,
    'p-invalid': props.invalid,
    'p-variant-filled': props.variant ? props.variant === 'filled' : instance.config.inputStyle === 'filled' || instance.config.inputVariant === 'filled'
  }],
  box: 'p-checkbox-box',
  input: 'p-checkbox-input',
  icon: 'p-checkbox-icon'
};
class CheckboxStyle extends BaseStyle {
  name = 'checkbox';
  theme = theme;
  classes = classes;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵCheckboxStyle_BaseFactory;
    return function CheckboxStyle_Factory(__ngFactoryType__) {
      return (ɵCheckboxStyle_BaseFactory || (ɵCheckboxStyle_BaseFactory = i0.ɵɵgetInheritedFactory(CheckboxStyle)))(__ngFactoryType__ || CheckboxStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CheckboxStyle,
    factory: CheckboxStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboxStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * Checkbox is an extension to standard checkbox element with theming.
 *
 * [Live Demo](https://www.primeng.org/checkbox/)
 *
 * @module checkboxstyle
 *
 */
var CheckboxClasses;
(function (CheckboxClasses) {
  /**
   * Class name of the root element
   */
  CheckboxClasses["root"] = "p-checkbox";
  /**
   * Class name of the box element
   */
  CheckboxClasses["box"] = "p-checkbox-box";
  /**
   * Class name of the input element
   */
  CheckboxClasses["input"] = "p-checkbox-input";
  /**
   * Class name of the icon element
   */
  CheckboxClasses["icon"] = "p-checkbox-icon";
})(CheckboxClasses || (CheckboxClasses = {}));
const CHECKBOX_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Checkbox),
  multi: true
};
/**
 * Checkbox is an extension to standard checkbox element with theming.
 * @group Components
 */
class Checkbox extends BaseComponent {
  /**
   * Value of the checkbox.
   * @group Props
   */
  value;
  /**
   * Name of the checkbox group.
   * @group Props
   */
  name;
  /**
   * When present, it specifies that the element should be disabled.
   * @group Props
   */
  disabled;
  /**
   * Allows to select a boolean value instead of multiple values.
   * @group Props
   */
  binary;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Used to define a string that labels the input element.
   * @group Props
   */
  ariaLabel;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  tabindex;
  /**
   * Identifier of the focus input to match a label defined for the component.
   * @group Props
   */
  inputId;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Inline style of the input element.
   * @group Props
   */
  inputStyle;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Style class of the input element.
   * @group Props
   */
  inputClass;
  /**
   * When present, it specifies input state as indeterminate.
   * @group Props
   */
  indeterminate = false;
  /**
   * Form control value.
   * @group Props
   */
  formControl;
  /**
   * Icon class of the checkbox icon.
   * @group Props
   */
  checkboxIcon;
  /**
   * When present, it specifies that the component cannot be edited.
   * @group Props
   */
  readonly;
  /**
   * When present, it specifies that checkbox must be checked before submitting the form.
   * @group Props
   */
  required;
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @group Props
   */
  autofocus;
  /**
   * Value in checked state.
   * @group Props
   */
  trueValue = true;
  /**
   * Value in unchecked state.
   * @group Props
   */
  falseValue = false;
  /**
   * Specifies the input variant of the component.
   * @group Props
   */
  variant = 'outlined';
  /**
   * Callback to invoke on value change.
   * @param {CheckboxChangeEvent} event - Custom value change event.
   * @group Emits
   */
  onChange = new EventEmitter();
  /**
   * Callback to invoke when the receives focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onFocus = new EventEmitter();
  /**
   * Callback to invoke when the loses focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onBlur = new EventEmitter();
  inputViewChild;
  templates;
  get checked() {
    return this._indeterminate() ? false : this.binary ? this.model === this.trueValue : ObjectUtils.contains(this.value, this.model);
  }
  get containerClass() {
    return {
      'p-checkbox p-component': true,
      'p-checkbox-checked p-highlight': this.checked,
      'p-disabled': this.disabled,
      'p-variant-filled': this.variant === 'filled' || this.config.inputStyle() === 'filled'
    };
  }
  _indeterminate = signal(undefined);
  checkboxIconTemplate;
  model;
  onModelChange = () => {};
  onModelTouched = () => {};
  focused = false;
  _componentStyle = inject(CheckboxStyle);
  ngOnChanges(changes) {
    super.ngOnChanges(changes);
    if (changes.indeterminate) {
      this._indeterminate.set(changes.indeterminate.currentValue);
    }
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'icon':
          this.checkboxIconTemplate = item.template;
          break;
      }
    });
  }
  updateModel(event) {
    let newModelValue;
    /*
     * When `formControlName` or `formControl` is used - `writeValue` is not called after control changes.
     * Otherwise it is causing multiple references to the actual value: there is one array reference inside the component and another one in the control value.
     * `selfControl` is the source of truth of references, it is made to avoid reference loss.
     * */
    const selfControl = this.injector.get(NgControl, null, {
      optional: true,
      self: true
    });
    const currentModelValue = selfControl && !this.formControl ? selfControl.value : this.model;
    if (!this.binary) {
      if (this.checked || this._indeterminate()) newModelValue = currentModelValue.filter(val => !ObjectUtils.equals(val, this.value));else newModelValue = currentModelValue ? [...currentModelValue, this.value] : [this.value];
      this.onModelChange(newModelValue);
      this.model = newModelValue;
      if (this.formControl) {
        this.formControl.setValue(newModelValue);
      }
    } else {
      newModelValue = this._indeterminate() ? this.trueValue : this.checked ? this.falseValue : this.trueValue;
      this.model = newModelValue;
      this.onModelChange(newModelValue);
    }
    if (this._indeterminate()) {
      this._indeterminate.set(false);
    }
    this.onChange.emit({
      checked: newModelValue,
      originalEvent: event
    });
  }
  handleChange(event) {
    if (!this.readonly) {
      this.updateModel(event);
    }
  }
  onInputFocus(event) {
    this.focused = true;
    this.onFocus.emit(event);
  }
  onInputBlur(event) {
    this.focused = false;
    this.onBlur.emit(event);
    this.onModelTouched();
  }
  focus() {
    this.inputViewChild.nativeElement.focus();
  }
  writeValue(model) {
    this.model = model;
    this.cd.markForCheck();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    setTimeout(() => {
      this.disabled = val;
      this.cd.markForCheck();
    });
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵCheckbox_BaseFactory;
    return function Checkbox_Factory(__ngFactoryType__) {
      return (ɵCheckbox_BaseFactory || (ɵCheckbox_BaseFactory = i0.ɵɵgetInheritedFactory(Checkbox)))(__ngFactoryType__ || Checkbox);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Checkbox,
    selectors: [["p-checkbox"]],
    contentQueries: function Checkbox_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function Checkbox_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputViewChild = _t.first);
      }
    },
    inputs: {
      value: "value",
      name: "name",
      disabled: [2, "disabled", "disabled", booleanAttribute],
      binary: [2, "binary", "binary", booleanAttribute],
      ariaLabelledBy: "ariaLabelledBy",
      ariaLabel: "ariaLabel",
      tabindex: [2, "tabindex", "tabindex", numberAttribute],
      inputId: "inputId",
      style: "style",
      inputStyle: "inputStyle",
      styleClass: "styleClass",
      inputClass: "inputClass",
      indeterminate: [2, "indeterminate", "indeterminate", booleanAttribute],
      formControl: "formControl",
      checkboxIcon: "checkboxIcon",
      readonly: [2, "readonly", "readonly", booleanAttribute],
      required: [2, "required", "required", booleanAttribute],
      autofocus: [2, "autofocus", "autofocus", booleanAttribute],
      trueValue: "trueValue",
      falseValue: "falseValue",
      variant: "variant"
    },
    outputs: {
      onChange: "onChange",
      onFocus: "onFocus",
      onBlur: "onBlur"
    },
    features: [i0.ɵɵProvidersFeature([CHECKBOX_VALUE_ACCESSOR, CheckboxStyle]), i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
    decls: 6,
    vars: 29,
    consts: [["input", ""], [3, "ngClass"], ["type", "checkbox", 3, "focus", "blur", "change", "value", "checked", "disabled", "readonly", "ngClass"], [1, "p-checkbox-box"], [4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "styleClass", 4, "ngIf"], ["class", "p-checkbox-icon", 3, "ngClass", 4, "ngIf"], [1, "p-checkbox-icon", 3, "ngClass"], [3, "styleClass"]],
    template: function Checkbox_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 1)(1, "input", 2, 0);
        i0.ɵɵlistener("focus", function Checkbox_Template_input_focus_1_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onInputFocus($event));
        })("blur", function Checkbox_Template_input_blur_1_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onInputBlur($event));
        })("change", function Checkbox_Template_input_change_1_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.handleChange($event));
        });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵtemplate(4, Checkbox_ng_container_4_Template, 3, 2, "ng-container", 4)(5, Checkbox_5_Template, 1, 0, null, 5);
        i0.ɵɵelementEnd()();
      }
      if (rf & 2) {
        i0.ɵɵstyleMap(ctx.style);
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", ctx.containerClass);
        i0.ɵɵattribute("data-p-highlight", ctx.checked)("data-p-checked", ctx.checked)("data-p-disabled", ctx.disabled);
        i0.ɵɵadvance();
        i0.ɵɵstyleMap(ctx.inputStyle);
        i0.ɵɵclassMap(ctx.inputClass);
        i0.ɵɵproperty("value", ctx.value)("checked", ctx.checked)("disabled", ctx.disabled)("readonly", ctx.readonly)("ngClass", i0.ɵɵpureFunction0(26, _c1));
        i0.ɵɵattribute("id", ctx.inputId)("name", ctx.name)("tabindex", ctx.tabindex)("required", ctx.required)("aria-labelledby", ctx.ariaLabelledBy)("aria-label", ctx.ariaLabel);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", !ctx.checkboxIconTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngTemplateOutlet", ctx.checkboxIconTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(27, _c2, ctx.checked));
      }
    },
    dependencies: () => [i1.NgClass, i1.NgIf, i1.NgTemplateOutlet, CheckIcon, MinusIcon],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Checkbox, [{
    type: Component,
    args: [{
      selector: 'p-checkbox',
      template: `
        <div
            [style]="style"
            [class]="styleClass"
            [ngClass]="containerClass"
            [attr.data-p-highlight]="checked"
            [attr.data-p-checked]="checked"
            [attr.data-p-disabled]="disabled"
        >
            <input
                #input
                [attr.id]="inputId"
                type="checkbox"
                [value]="value"
                [attr.name]="name"
                [checked]="checked"
                [attr.tabindex]="tabindex"
                [disabled]="disabled"
                [readonly]="readonly"
                [attr.required]="required"
                [attr.aria-labelledby]="ariaLabelledBy"
                [attr.aria-label]="ariaLabel"
                [style]="inputStyle"
                [class]="inputClass"
                [ngClass]="{ 'p-checkbox-input': true }"
                (focus)="onInputFocus($event)"
                (blur)="onInputBlur($event)"
                (change)="handleChange($event)"
            />
            <div class="p-checkbox-box">
                <ng-container *ngIf="!checkboxIconTemplate">
                    <ng-container *ngIf="checked">
                        <span *ngIf="checkboxIcon" class="p-checkbox-icon" [ngClass]="checkboxIcon" [attr.data-pc-section]="'icon'"></span>
                        <CheckIcon *ngIf="!checkboxIcon" [styleClass]="'p-checkbox-icon'" [attr.data-pc-section]="'icon'" />
                    </ng-container>
                    <MinusIcon *ngIf="_indeterminate()" [styleClass]="'p-checkbox-icon'" [attr.data-pc-section]="'icon'" />
                </ng-container>
                <ng-template
                    *ngTemplateOutlet="checkboxIconTemplate; context: { checked: checked, class: 'p-checkbox-icon' }"
                ></ng-template>
            </div>
        </div>
    `,
      providers: [CHECKBOX_VALUE_ACCESSOR, CheckboxStyle],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None
    }]
  }], null, {
    value: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    binary: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    tabindex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    inputId: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    inputStyle: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    inputClass: [{
      type: Input
    }],
    indeterminate: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    formControl: [{
      type: Input
    }],
    checkboxIcon: [{
      type: Input
    }],
    readonly: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    required: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    autofocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    trueValue: [{
      type: Input
    }],
    falseValue: [{
      type: Input
    }],
    variant: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }],
    onFocus: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    inputViewChild: [{
      type: ViewChild,
      args: ['input']
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class CheckboxModule {
  static ɵfac = function CheckboxModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CheckboxModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: CheckboxModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, AutoFocusModule, CheckIcon, MinusIcon, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, AutoFocusModule, CheckIcon, MinusIcon],
      exports: [Checkbox, SharedModule],
      declarations: [Checkbox]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CHECKBOX_VALUE_ACCESSOR, Checkbox, CheckboxClasses, CheckboxModule, CheckboxStyle };

import {Component, computed, input} from '@angular/core';

@Component({
  selector: 'lib-progress-bar',
  standalone: true,
  imports: [],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.css'
})
export class ProgressBarComponent {

  value = input.required<number>();
  max = input.required<number>();
  protected progress = computed(() => {
    const value = this.value();
    const max = this.max();
    return value / max * 100;
  })
  indeterminate = input(false);

}

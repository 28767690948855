<div fxLayout="column" row-gap="8">
  <app-search-filter-bar [(filters)]="filters"></app-search-filter-bar>
  @if (totalCount()) {
    <span style="font-size: 14px;" class="text-secondary">
      <span style="position: relative; padding-right: 8px;">
        <svg style="transform: translateY(3px)" width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 16 16"><g clip-path="url(#status-check_svg__a)"><circle cx="8" cy="8" r="7.5" fill="#C5F4F0"
                                                                                  stroke="#19D2C3"></circle><g
          clip-path="url(#status-check_svg__b)"><path fill="#41145F"
                                                      d="m11.81 4.583-.718-.505a.426.426 0 0 0-.604.118L6.971 9.57 5.354 7.896a.423.423 0 0 0-.613 0l-.614.637a.463.463 0 0 0 0 .637l2.486 2.576a.83.83 0 0 0 .555.254c.197 0 .395-.128.523-.32l4.234-6.473a.46.46 0 0 0-.116-.624Z"></path></g></g><defs><clipPath
          id="status-check_svg__a"><path fill="#fff" d="M0 0h16v16H0z"></path></clipPath><clipPath
          id="status-check_svg__b"><path fill="#fff" d="M4 4h8v8H4z"></path></clipPath></defs></svg>
      </span>
      <!--      <lib-icon style="display: inline-block" [size]="8" verticalAlign="middle" name="checkmark"-->
      <!--                [type]="IconType.Success"></lib-icon>&nbsp;-->
      <strong>Showing {{ totalCount() }} In-network Clinicians</strong>
    </span>
  }
  <hr class="secondary" style="width: 100%"/>
  @let appointments = items();
  <div fxLayout="row [wrap]" fxLayoutAlign="start start" fxLayout.lt-sm="column" fxLayoutAlign.lt-md="center" fxLayoutAlign.lt-sm="start center" gap="16">
    @for (appt of appointments; track appt.clinician.npi.toString() + appt.location.locationId.toString()) {
      <app-availability-card-view class="availability-card"
                                  (slotClicked)="apptChosen(appt, $event.date, $event.slot, $event.setting)"
                                  fxFlex="450px"
                                  fxFlex.xs="calc(100% - 16px)"
                                  fxFlex.sm="calc(100% - 16px)"
                                  fxFlex.md="calc(50% - 16px)"
                                  fxFlex.lg="calc(50% - 16px)"
                                  [ngClass]="{maxWidth: '450px'}"
                                  [info]="appt"
      ></app-availability-card-view>
    }

    @if (appointments && !appointments.length) {
      <h2 class="pt-32">No clinicians match your selected preferences.</h2>
      <h5 class="text-secondary" style="font-weight: 400">Widen your search by using fewer filters or
        <button type="button" class="link text blend arrow-right"
                style="color: var(--color-primary-primary); font-weight: 400"
                (click)="resetFilters()">Reset Filters
        </button>
      </h5>
    }

  </div>

  @if (totalPages() > 1) {
    <div fxLayout="row" fxLayoutAlign="center center" gap="4">

      @if (currentPage() !== 1) {
        <button class="btn btn-white btn-circle btn-icon arrow-left" type="button" (click)="prevPage()">
        </button>
      } @else {
        <span class="page-arrow"></span>
      }

      @for (num of pageNumbers(); track num) {
        <button type="button" class="page-num text"
                [class.link]="currentPage() !== num"
                [class.selected]="currentPage() === num" (click)="currentPage() === num ? null : pullPage(filters(), num)">
          {{ num }}
        </button>
      }
      @if (currentPage() !== totalPages()) {
        <button class="page-arrow btn btn-white btn-circle btn-icon arrow-right" type="button" (click)="nextPage()">
        </button>
      } @else {
        <span class="page-arrow"></span>
      }
    </div>
    <!--    <app-pagination [totalPages]="totalPages()" [currentPage]="currentPage()" (pageChange)="pageChange($event)"></app-pagination>-->
  }

</div>

<p-drawer [visible]="!!scheduler.sidebar()"
          styleClass="{{screenSize().is('lt-sm') ? 'sidebar-full' : 'sidebar'}}" appendTo="body"
          [fullScreen]="screenSize().is('lt-sm')"
          (onHide)="scheduler.sidebar.set(null)" position="right" [showCloseIcon]="false">
  @if (scheduler.sidebar(); as sideBar) {
    <lib-inner-scrollable>

      @if (sideBar.resetHook; as resetHook) {
        <ng-template templateFor="header">
          <div fxLayout="row" fxLayoutAlign="space-between center" class="py-12 px-24"
               style="border-bottom: 1px solid gray">
            <button type="button" class="link text blend"
                    style="color: var(--color-primary-primary); font-weight: 500"
                    (click)="resetFilters()">Reset Filters
            </button>

            <button type="button" class="link text blend" (click)="scheduler.sidebar.set(null)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
                      d="M21 3 3 21M3 3l18 18"></path>
              </svg>
            </button>
          </div>
        </ng-template>
      }


      <div class="py-12 px-24 pt-24">
        <ng-container [ngTemplateOutlet]="sideBar.template"></ng-container>
      </div>
      <!--      <div style="height: 100%;">-->
      <!--        <div style="height: calc(100% - 96px); overflow-y: auto">-->
      <!--        </div>-->

      <ng-template templateFor="footer">
        <div style="width: 100%; margin: auto 0;  border-top: 1px solid gray" fxLayout="row"
             fxLayoutAlign="center" class="py-12 px-24">
          <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%" fxLayout.lt-sm="column"
               fxLayoutAlign.lt-sm="start center" gap="12" row-gap="12">
            @if (scheduler.sidebar()?.actions; as actions) {
              @for (btn of actions; track btn) {
                <button type="button" class="btn" style="width: 75%"
                        (click)="btn.action(); scheduler.sidebar.set(null)">{{ btn.label }}
                </button>
              }
            } @else {
              <button type="button" class="btn" style="width: 75%"
                      (click)="scheduler.sidebar.set(null)">View Results
              </button>
            }
          </div>

        </div>
      </ng-template>
    </lib-inner-scrollable>
  }
</p-drawer>

import { Pipe, PipeTransform } from '@angular/core';
import {Schema, SuccessQueryItemType} from "../models";

@Pipe({
  name: 'clinicianName',
  standalone: true
})
export class ClinicianNamePipe implements PipeTransform {

  transform(value: Schema<'ClinicianGeneralInfo'>|Schema<'ClinicianDetailedInfo'>| {firstName: string, lastName: string, credentials: string}): string {
    return ClinicianNamePipe.FormatName(value);
  }

  public static FormatName(value: Schema<'ClinicianGeneralInfo'>|Schema<'ClinicianDetailedInfo'>| {firstName: string, lastName: string, credentials: string}): string {
    if (!value) {
      return '';
    }
    let credentials = '';
    if ('credentials' in value && value.credentials.length) {
      credentials = `, ${value.credentials}`;
    }

    const firstName = 'firstName' in value ? value.firstName : value.contactDetails?.firstName ?? '';
    const lastName = 'lastName' in value ? value.lastName : value.contactDetails?.lastName ?? '';

    return `${firstName} ${lastName}${credentials}`;
  }

}

import {Component, computed, inject, input} from '@angular/core';
import {MaintenanceCfg} from "./maintenance";
import {UnsafeAllowHtmlPipe} from "../../pipes";
import {FlexLayoutModule} from "@ngbracket/ngx-layout";

@Component({
  selector: 'lib-maintenance',
  standalone: true,
  imports: [
    UnsafeAllowHtmlPipe,
    FlexLayoutModule
  ],
  templateUrl: './maintenance.component.html',
  styleUrl: './maintenance.component.css'
})
export class MaintenanceComponent {

  config = input.required<MaintenanceCfg|boolean>();
  appName = input.required<string>();
  supportNumber = input.required<string>();
  logoPath = input.required<string>();

  title = computed(() => {
    const config = this.config();
    const appName = this.appName();
    return typeof config === 'object' ? config.title : `${appName} is currently unavailable`
  });

  message = computed(() => {
    const config = this.config();
    const appName = this.appName();
    const phone = this.supportNumber();

    const msg = `<p>We apologize for the inconvenience. Our website is currently under maintenance. Call <a class="link text blend"
        href="tel:${phone}">${phone}</a> Monday-Friday 6am-9pm (EST).</p>`;

    return typeof config === 'object' ? config.message : msg;
  });

  extraMessages = computed(() => {
    const config = this.config();
    return typeof config === 'object' ? config.extraMessages : [];
  });



}

import {computed, effect, inject, Injectable, Signal, signal, TemplateRef, WritableSignal} from '@angular/core';
import {
  genAddress,
  genEmail,
  HandleResponse,
  IMessageCode,
  loadApiEndpointResp,
  Logger,
  MessageCode, genPhone,
  QueryOptions,
  RouteQueryParamsArgs,
  Schema,
  UrlPaths, AppointmentSettingType
} from '@mindpath/shared';
import {SIGNAL} from "@angular/core/primitives/signals";
import {DateToDateOnlyString} from "./utils/date";
import {Wizard, WizardApiStubs} from "./wizard";
import {config} from "../config";
import {Gender, PhoneType} from "@mindpath/shared";
import {
  PageViewsService,
  DataLayerService,
  CustomDimensionsService,
  CustomEventsService
} from "@piwikpro/ngx-piwik-pro";
import {DimensionTypes, GetDimension, GetPageView} from "./piwik";
import {PiwikService} from "./piwik/piwik.service";

export const SupportHtml = `Please contact support at <a class="link blend pk_dialog_intake" href="tel:855-501-1004">855-501-1004</a> for assistance.`;

export const ValidStates = ['AZ', 'CA', 'FL', 'NC', 'SC', 'TX'] as const;

export const BookingSteps = ['eligibility', 'data-check', 'demographics-1', 'demographics-2', 'otp', 'insurance', 'insurance-cards', 'final'] as const;

export const PrefillSteps = ['location', 'type', 'condition', 'age', 'setting', 'insurance', 'insurancePlan'] as const;

export const InvalidDeviceCodes = [
  MessageCode.Invalid_Phone_Number,
  MessageCode.Not_A_Mobile_Number,
  MessageCode.Unsupported_Phone_Carrier,
  MessageCode.Invalid_Email_Address
];

export const ErrorCodeDisplays: {
  [C: string]: {
    title: string,
    message?: string | null
  }
} = {
  [MessageCode.Unknown_Browser_Exception.code]: {
    title: 'Error',
    message: 'An error occurred while processing your request.'
  },
  [MessageCode.Too_Many_Code_Requests.code]: {
    title: 'Too Many Attempts',
    message: 'You have exceeded the number of attempts to verify your code'
  },
  [MessageCode.Too_many_attempts.code]: {
    title: 'Too Many Attempts',
    message: 'You have exceeded the number of attempts to process this request'
  },
  [MessageCode.Code_Expired.code]: {
    title: 'Code Expired',
    message: 'The code sent to you has expired'
  },
  [MessageCode.Invalid_Phone_Number.code]: {
    title: 'Invalid Device',
    message: MessageCode.Invalid_Phone_Number.description,
  },
  [MessageCode.Not_A_Mobile_Number.code]: {
    title: 'Invalid Device',
    message: MessageCode.Not_A_Mobile_Number.description,
  },
  [MessageCode.Unsupported_Phone_Carrier.code]: {
    title: 'Invalid Device',
    message: MessageCode.Unsupported_Phone_Carrier.description,
  },
  [MessageCode.Invalid_Email_Address.code]: {
    title: 'Invalid Device',
    message: MessageCode.Invalid_Email_Address.description,
  },
  [MessageCode.Appointment_Conflict.code]: {
    title: 'Unable To Schedule Appointment',
    message: 'We are unable to schedule your appointment at this time, the date and time slot are no longer available.<br/><br/>Please click the button below to see additional times.'
  }
};

@Injectable({
  providedIn: 'root'
})
export class OnlineSchedulerService {

  dimming = signal<{
    dimmed: boolean,
    title: string,
    message?: string,
    asStopMessage: boolean,
    dimLevel: number,
    action: { label: string, onClick: () => void } | null
  }>({
    action: null,
    asStopMessage: false,
    dimLevel: 0.9,
    dimmed: false,
    title: ""
  });
  sidebar = signal<{
    template: TemplateRef<any>,
    resetHook?: () => void,
    actions?: { label: string, action: () => void }[]
  } | null>(null);
  data = {
    appt: signal<SelectedAppointmentInfo | null>(null),
    demographics: signal<DemographicsInfo | null>(null),
    meta: signal<MetaInfo>({section: 'prefill'} as any),
    search: signal<SearchInfo>({
      conditionId: null,
      clinicianTypePref: null,
      settingPref: null,
      age: null,
      languagePref: null,
      genderPref: null,
      timeOfDayPref: null,
      dayOfWeekPref: null,
      soonestWeekOut: null
    }),
    insurance: signal<{ primary: InsuranceInfo | null, secondary: InsuranceInfo | null }>({
      primary: null,
      secondary: null
    })
  }



  piwik = inject(PiwikService);

  errors = computed(() => {

      const {demographics, insurance, appt} = this.data;
      const errors:
        PropErrors<{
          appt: Omit<SelectedAppointmentInfo, 'clinician'> & { clinician: { npiNumber: number } },
          demographics: DemographicsInfo,
          insurance: SignalValue<OnlineSchedulerService['data']['insurance']>
        }> = {
        // const errors: PropErrors<DemographicsInfo> = {
        appt: {
          originalSetting: null!,
          chosenSetting: null!,
          slot: {
            time: null,
            timeOfDay: null,
            duration: null,
            setting: null
          },
          dateTime: null,
          duration: null,
          location: {
            address: {
              address1: null,
              city: null,
              state: null,
              zip: null
            },
            externalLocationId: null,
            locationId: null,
            emailAddress: null,
            phoneNumber: null
          },
          clinician: {
            npiNumber: null
          },
          conditionId: null!,
          typeOfCare: null!,
        },
        demographics: {
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          dob: null,
          sex: null,
          address: {
            address1: null,
            city: null,
            state: null,
            zip: null,
            coordinates: {
              lat: null,
              long: null
            },
          }
        },
        insurance: {
          primary: null,
          secondary: null
        }
      }
      return errors;
    }
  );
  error = computed(() => this.data.meta().error ?? null);
  tokenStillValid = computed(() => {
    const auth = this.data.meta().booking?.auth;
    const demographics = this.data.demographics();
    if (!auth?.token?.length) {
      return false;
    }
    const requestedDestination = auth.request?.destination;
    const requestedType = auth.request?.deviceType;
    if (!requestedDestination || !requestedType) return false;
    switch (requestedType) {
      case 'phone':
      case 'sms':
        if (demographics?.phone?.toLowerCase() !== requestedDestination.toLowerCase()) {
          Logger.Debug('OnlineScheduler', 'Phone mismatch', demographics?.phone, requestedDestination);
          return false;
        }
        break;
      case 'email':
        if (demographics?.email?.toLowerCase() !== requestedDestination.toLowerCase()) {
          Logger.Debug('OnlineScheduler', 'Email mismatch', demographics?.email, requestedDestination);
          return false;
        }
        break;
    }

    const expiresAt = new Date(auth.tokenExpiresAt);
    return expiresAt > new Date() && auth.token.length > 0;
  });
  insuranceCardFiles = signal<{
    primary: { front: File | null, back: File | null },
    secondary: { front: File | null, back: File | null }
  }>({primary: {front: null, back: null}, secondary: {front: null, back: null}});
  #listeners: Map<FlattenObjectKeys<OnlineSchedulerService['data']>, Signal<any>> = new Map();
  #errorSignals: Map<FlattenObjectKeys<SignalValue<OnlineSchedulerService['errors']>>, Signal<string[] | null>> = new Map();

  constructor() {
    (window as any).app = this;
    const {search, demographics, insurance, appt, meta} = this.#load();
    this.data.search.set(search);
    this.data.demographics.set(demographics);
    this.data.insurance.set(insurance);
    this.data.appt.set(appt);
    this.data.meta.set(meta);

    effect(() => {
      const search = this.data.search();
      const demographics = this.data.demographics();
      const insurance = this.data.insurance();
      const appt = this.data.appt();
      const meta = this.data.meta();
      // console.log('detected change', {search, demographics, insurance, appt, meta});
      this.#save({meta, search, demographics, insurance, appt});
    });
  }

  private get intakeData() {
    const demographics = this.data.demographics();
    const insurance = this.data.insurance();
    const appt = this.data.appt();
    const booking = this.data.meta().booking;

    let insurances: Wizard.PatientInsurance[] = [];

    const dateOnly = (d: any) => {
      if (!d) {
        return null;
      }

      if (typeof d !== 'string') {
        if (d instanceof Date) {
          return d.toISOString().slice(0, 10);
        }
        d = String(d);
      }

      const month = d.slice(0, 2);
      const day = d.slice(2, 4);
      const year = d.slice(4, 8);
      return `${year}-${month}-${day}`;
    }

    if (insurance.primary?.id && insurance.primary?.planId && insurance.primary?.policyNumber) {
      insurances = [{
        insuranceId: insurance.primary!.id,
        planId: insurance.primary!.planId,
        memberId: insurance.primary!.policyNumber!,
        groupNumber: insurance.primary!.groupNumber!,
        isNotPolicyHolder: !!insurance.primary!.holder!,
        holderFullName: insurance.primary!.holder?.name!,
        holderDateOfBirth: dateOnly(insurance.primary?.holder?.dob) as any,
        isPrimary: true,
        omitAttachment: !insurance.primary.hasCard
      }]

      if (insurance.secondary) {
        const secondary = insurance.secondary;
        insurances.push({
          insuranceId: secondary!.id!,
          planId: secondary!.planId!,
          memberId: secondary!.policyNumber!,
          groupNumber: secondary!.groupNumber!,
          isNotPolicyHolder: !!secondary!.holder!,
          holderFullName: secondary!.holder?.name!,
          holderDateOfBirth: dateOnly(secondary!.holder?.dob) as any,
          isPrimary: false,
          omitAttachment: !secondary.hasCard
        });
      }

    }
    let phoneNumber = demographics!.phone!;
    if (phoneNumber.length === 11 && phoneNumber.startsWith('1')) {
      phoneNumber = phoneNumber.slice(1);
    }
    let intakeReqData: Wizard.IntakeRequest = {
      id: booking.id!,
      hold: {
        clinicianNpi: appt!.clinician.npiNumber,
        date: appt!.dateTime,
        duration: appt!.duration,
        locationId: appt!.location.locationId,
        setting: appt!.chosenSetting as any,
      },
      patient: {
        contactDetails: {
          firstName: demographics!.firstName!,
          lastName: demographics!.lastName!,
          phoneNumbers: [
            {
              type: 'mobile',
              number: phoneNumber
            }
          ],
          emails: [{address: demographics!.email!}],
          addresses: [{
            address: {
              address1: demographics!.address!.address1!,
              address2: demographics!.address!.address2!,
              city: demographics!.address!.city!,
              state: demographics!.address!.state!,
              zip: demographics!.address!.zip!
            }
          }],
        },
        sexAtBirth: demographics!.sex! as any,
        dateOfBirth: dateOnly(demographics!.dob as any) as any
      },
      insurances,
      reason: {
        conditionId: appt!.conditionId,
        reasonType: appt!.typeOfCare,
      }
    };

    return intakeReqData;
  }

  reset() {
    localStorage.removeItem('online-scheduler');
    sessionStorage.removeItem('online-scheduler');
    const {search, demographics, insurance, appt, meta} = this.#load();
    this.data.search.set(search);
    this.data.demographics.set(demographics);
    this.data.insurance.set(insurance);
    this.data.appt.set(appt);
    this.data.meta.set(meta);
    this.goto('prefill', 'location');
  }

  uploadFile(token: string, file: FormData | ReadableStream) {
    const data = this.data.meta().booking;
    const id = data.id!;
    // console.log('Uploading-wiz', this.#data().intakeToken!, this.#data().intakeToken!.id, data);
    return WizardApiStubs.uploadAttachment({token, expiresAt: 0}, id, file);
  }

  async submitIntake() {
    try {
      this.showLoading('Scheduling Appointment', 'Please wait while we schedule your appointment.');
      const intakeData = this.intakeData;
      const token = this.data.meta().booking?.auth?.token;
      if (!token) {
        throw new Error('No token found');
      }
      // if (!this.tokenStillValid()) {
      //   this.showError(MessageCode.Unknown_Browser_Exception, 'Session Expired', 'Your verification code has expired. Please request a new one.', {
      //     action: {
      //       label: 'Request New Code',
      //       onClick: () => {
      //         this.requestAuthCode().then(success => {
      //           if (success) {
      //             this.goto('book', 'otp');
      //             return;
      //           }
      //           this.goto('book', 'demographics-2');
      //         });
      //       }
      //     }
      //   });
      //   return;
      // }

      const resp = await loadApiEndpointResp(config.endpoints.api, '/api/intake/requests/{id}/hold').post({
        body: intakeData,
        path: {id: this.data.meta().booking?.id!},
        fetchOptions: {
          headers: {
            Authorization: `Bearer ${token}`,
            ['Content-Type']: 'application/json'
          }
        },
      });

      const {success, error} = await HandleResponse<NonNullable<Schema<'FinalizedIntakeRequestInfo'>>>(resp);
      if (success) {
        this.data.meta.update(m => {
          return {
            ...m,
            booking: {
              ...m.booking,
              completionData: success as any
            }
          }
        });
        this.hideLoading();
        return true;
      }

      if (error.code === MessageCode.Appointment_Conflict.code || error.code === MessageCode.Slot_Taken.code) {
        const displayInfo = ErrorCodeDisplays[error.code]!;
        this.showError(error, displayInfo.title, displayInfo.message, {
          noSupport: true,
          action: {
            label: 'View Available Times',
            onClick: () => {
              this.goto('find');
              this.clearError();
            }
          }
        });
        return false;
      }
      this.showError(error, 'Unable To Schedule Appointment', 'We are unable to schedule your appointment at this time.');
      return false;
    } catch (e) {
      Logger.Error('OnlineScheduler', e);
      this.showError(MessageCode.Unknown_Exception, 'Unable To Schedule Appointment', 'We are unable to schedule your appointment at this time.');
      return false;
    }

  }


  // workWith<TPath extends FlattenObjectKeys<OnlineSchedulerService['data']>>(path: TPath): WritableSignal<ExtractTypeAtPath<OnlineSchedulerService['data'], TPath>> {
  // }

  hideLoading(options?: { ignoreError: boolean }) {
    if (!options?.ignoreError && this.error()) {
      console.debug('Error still exists, not hiding loading');
      return;
    }

    this.dimming.set({
      dimmed: false,
      title: '',
      asStopMessage: false,
      dimLevel: 0.9,
      action: null
    });
  }

  showError(code: IMessageCode, title: string, message?: string | null, options?: {
    noSupport?: boolean, action?: {
      label: string,
      onClick: () => void
    } | null
  }) {
    title ??= MessageCode.Unknown_Browser_Exception.description ?? 'An Unknown Error Occurred';
    message ??= '';

    this.data.meta.update(meta => {
      return {
        ...meta,
        error: code
      }
    });

    let noSupport = options?.noSupport ?? false;
    if (message.includes('tel:') || message.includes('mailto:')) {
      noSupport = true;
    }

    if (message === MessageCode.Unknown_Exception.description) {
      message = '';
      noSupport = false;
    }

    if (!noSupport || message?.trim()?.length === 0) {
      message ??= '';
      message += `<br/>${SupportHtml}`;
    }

    message = `<p class="text-primary">${message}</p>`

    this.dimming.set({
      dimmed: true,
      title,
      message,
      asStopMessage: true,
      dimLevel: 0.9,
      action: options?.action ?? null
    });
  }

  showLoading(title: string, msg?: string) {
    this.dimming.set({
      dimmed: true,
      title,
      message: msg,
      asStopMessage: false,
      dimLevel: 0.9,
      action: null
    });
  }

  listenTo<TPath extends FlattenObjectKeys<OnlineSchedulerService['data']>>(path: TPath): Signal<ExtractTypeAtPath<OnlineSchedulerService['data'], TPath>> {
    if (!this.#listeners.has(path)) {
      const pathParts = path.split('.') as (keyof typeof this.data)[];
      const first = pathParts.shift()!;
      this.#listeners.set(path, computed(() => {
        let current: any = this.data[first]();
        for (const part of pathParts) {
          if (!current) {
            return null;
          }
          current = current[part];
        }
        return current;
      }));
    }
    return this.#listeners.get(path)!;
  }

  errorsFor<TPath extends FlattenObjectKeys<SignalValue<OnlineSchedulerService['errors']>>>(path: TPath) {
    if (!this.#errorSignals.has(path)) {
      const pathParts = path.split('.') as (keyof SignalValue<typeof this.errors>)[];
      this.#errorSignals.set(path, computed<string[] | null>(() => {
        const errors = this.errors();
        let current: any = errors;
        for (const part of pathParts) {
          current = current[part];
        }
        return current;
      }));
    }
    return this.#errorSignals.get(path)!;
  }

  ensure<TPath extends FlattenObjectKeys<OnlineSchedulerService['data']>>(path: TPath): DeepNonNullable<NonNullable<SignalValue<DataTypeAt<TPath>>>> {
    const lastKey = path.split('.').pop()!;
    const val = this.#buildObjTree(path).parent[lastKey];
    if (typeof val === 'function' && SIGNAL in val) {
      return val() as any;
    }
    return val;
    // return this.#merge(path, existing);
  }

  distance(coord: Schema<'Coordinates'>, userCoord: Schema<'Coordinates'>): number | null {

    if (!userCoord) return null;

    const {lat, long} = userCoord
    if (coord.lat === null || coord.long === null || lat === null || long === null) {
      return null;
    }
    return Math.sqrt((lat! - coord.lat!) ** 2 + (long! - coord.long!) ** 2);
  }

  goto<TSection extends keyof PageStepsMap>(section: TSection, step?: PageStepsMap[TSection]) {

    const meta = this.data.meta();
    this.sidebar.set(null);
    if (meta.section === section) {
      const currentStep = section === 'prefill' ? meta.prefill?.step : meta.booking?.step;
      if (step === currentStep) {
        console.debug('Already on this step', section, currentStep);
        return;
      }
    }


    this.data.meta.update(meta => {
      const prefill = meta.prefill ?? {} as any;
      const booking = meta.booking ?? {} as any;
      let resumableTo: typeof meta.resumableTo = undefined;

      if (section === 'prefill') {
        const currentStepIndex = PrefillSteps.indexOf(meta.resumableTo?.step ?? 'location');
        const newStepIndex = PrefillSteps.indexOf(step! as any);
        const resumeStepIndex = Math.max(currentStepIndex, newStepIndex);
        prefill.step = step as any;
        resumableTo = {section, step: PrefillSteps[resumeStepIndex]};
      } else if (section === 'book') {
        booking!.step = step as any;
      } else if (section ==='find') {
        resumableTo = {section: 'find'}
      }

      this.piwik.trackPage(section, step!);

      return {
        ...meta,
        section,
        prefill,
        booking,
        resumableTo
      }

    });
  }

  goBackward() {
    this.sidebar.set(null);
    const section = this.data.meta().section;
    // console.log('Going backward', section);
    if (section === 'prefill') {
      const step = this.data.meta().prefill?.step ?? 'location';

      const index = PrefillSteps.indexOf(step!);
      if (index === 0) {
        this.goto('prefill', 'location');
      } else {
        this.goto('prefill', PrefillSteps[index - 1] as any);
      }
    } else if (section === 'find') {
      this.goto('prefill', 'insurancePlan');
    } else if (section === 'book') {
      const step = this.data.meta().booking?.step ?? 'eligibility';
      let index = BookingSteps.indexOf(step!);
      if (index === BookingSteps.length - 1) {
        console.debug('Already at the end');
        return;
      }
      const prevStep = BookingSteps[index - 1];
      if (prevStep === 'otp') {
        index--;
      }
      if (index === 0) {
        this.goto('find');
      } else {
        this.goto('book', BookingSteps[index - 1] as any);
      }
    }
  }

  goForward() {
    this.sidebar.set(null);
    const section = this.data.meta().section;
    if (!section || section === 'prefill') {
      const step = this.data.meta().prefill?.step ?? 'location';

      const index = PrefillSteps.indexOf(step!);
      if (index === PrefillSteps.length - 1) {
        this.goto('find');
      } else {
        this.goto('prefill', PrefillSteps[index + 1] as any);
        return;
      }
    }
    if (section === 'find') {
      this.goto('book', 'eligibility');
    }
    if (section === 'book') {
      const bookingData = this.data.meta().booking;
      const step = bookingData?.step ?? 'eligibility';

      let index = BookingSteps.indexOf(step!);
      const nextStep = BookingSteps[index + 1];

      if (nextStep === 'otp' && this.tokenStillValid() && bookingData?.auth?.multiFactorComplete) {
        index++;
      }
      if (index === BookingSteps.length - 1) {
        return;
      } else {
        this.goto('book', BookingSteps[index + 1] as any);
      }
    }
  }

  async verifyCode(code: string): Promise<boolean> {
    this.showLoading('Verifying Appointment Details', 'Please wait while we verify the appointment details.');

    const bookingData = this.ensure('meta.booking');
    if (!bookingData.auth.request?.destination) {
      console.error('No destination found when verifying code');
      this.data.meta.update(meta => {
        return {
          ...meta,
          error: MessageCode.Unknown_Browser_Exception
        }
      });
      return false;
    }

    const apptData = this.ensure('appt');
    const demographics = this.ensure('demographics');

    const body: Schema<'CheckInitializedIntakeRequest'> = {
      otpCode: code,
      id: bookingData.id,
      patient: {
        clinicianNpi: apptData.clinician.npiNumber,
        dateOfBirth: DateToDateOnlyString(demographics.dob),
        contactDetails: {
          firstName: demographics.firstName,
          lastName: demographics.lastName,
          phoneNumbers: [
            genPhone(PhoneType.Mobile, demographics.phone)
          ],
          emails: [
            genEmail(demographics.email)
          ],
          addresses: [genAddress(demographics.address)]
        },
        sexAtBirth: demographics.sex,
      }
    }
    const resp = await loadApiEndpointResp(config.endpoints.api, '/api/intake/requests/{id}').put({
      body,
      path: {
        id: bookingData.id
      },
      fetchOptions: {
        headers: {
          'Authorization': `Bearer ${bookingData.auth.token}`
        }
      }
    });
    const {success, error} = await HandleResponse<NonNullable<Schema<'IExposedIntakeRequestInfo'>>>(resp);

    if (success) {
      this.hideLoading({ignoreError: true});
      this.clearError();
      this.patch('meta.booking.auth', {
        token: success.token!,
        tokenExpiresAt: new Date(success.expiresAt!),
        multiFactorComplete: true
      });
      return true;
    }
    const displayData = ErrorCodeDisplays[error.code];
    if (error.code === MessageCode.Too_many_attempts.code) {
      this.showError(error, displayData!.title, displayData!.message);
    } else if (error.code === MessageCode.Code_Expired.code) {
      this.showError(error, displayData!.title, displayData!.message, {
        action: {
          label: 'Request New Code',
          onClick: () => {
            this.clearError();
            this.patch('meta.booking.auth.request', {});
            this.requestAuthCode();
          }
        }
      });
    } else if (error.code === MessageCode.Incorrect_Code.code) {
      // const retryToken = error.data.retryToken;
      // this.patch('meta.booking.auth', {
      //   // token: retryToken,
      // });
      this.data.meta.update(meta => {
        return {
          ...meta,
          error
        }
      });
      this.hideLoading({ignoreError: true});
    } else {
      this.#showGeneralIntakeError(error, 'update');
    }
    return false;
  }

  async requestAuthCode(): Promise<boolean> {
    this.showLoading('Sending Code', 'Please wait while we send you a verification code');
    const authData = this.ensure('meta.booking.auth.request');
    const apptData = this.ensure('appt');
    // console.log(apptData, this.data.appt());
    const demographics = this.ensure('demographics');
    this.patch('meta.booking', {
      auth: {
        request: {
          at: new Date()
        } as any,
      } as any
    });
    const body: Schema<'IntakeInitializationRequest'> = {
      otpTarget: authData.deviceType as any,
      manualOtpDestination: authData.destination,
      patient: {
        clinicianNpi: apptData.clinician.npiNumber,
        dateOfBirth: DateToDateOnlyString(demographics.dob),
        contactDetails: {
          firstName: demographics.firstName,
          lastName: demographics.lastName,
          phoneNumbers: [
            genPhone(PhoneType.Mobile, demographics.phone)
          ],
          emails: [
            genEmail(demographics.email)
          ],
          addresses: [
            genAddress(demographics.address)
          ]
        },
        sexAtBirth: demographics.sex,
      }
    }
    const resp = await loadApiEndpointResp(config.endpoints.api, '/api/intake/requests').post({
      body
    });
    const {success, error} = await HandleResponse<NonNullable<Schema<'IExposedIntakeRequestInfo'>>>(resp);
    if (success) {
      const expiresTime = new Date(success.expiresAt!);
      this.clearError();
      this.hideLoading({ignoreError: true});
      this.patch('meta.booking', {
        id: success.id,
        auth: {
          request: {
            at: new Date()
          } as any,
          token: success.token!,
          tokenExpiresAt: expiresTime,
          multiFactorComplete: false
        }
      });
      // this.data.meta.update(meta => {
      //   console.log({meta})
      //   return {
      //     ...meta,
      //     booking: {
      //       ...meta?.booking ?? {},
      //       id: success.id!,
      //       auth: {
      //         ...meta?.booking?.auth ?? {},
      //         request: {
      //           ...meta?.booking?.auth?.request ?? {},
      //           at: new Date(),
      //         },
      //         token: success.token!,
      //         tokenExpiresAt: expiresTime,
      //       }
      //     }
      //   } as any
      // });
      return true;
    }

    const errorDisplay = ErrorCodeDisplays[error.code];

    const invalidDeviceCode = InvalidDeviceCodes.find(msg => msg.code === error.code);

    if (invalidDeviceCode) {
      this.showError(error, errorDisplay!.title, errorDisplay!.message, {
        action: {
          label: 'Change Device',
          onClick: () => {
            this.patch('meta.booking.auth.request', {});
            this.goto('book', 'demographics-2')
          }
        }
      });
    } else if (error?.code === MessageCode.Too_Many_Code_Requests.code) {
      this.showError(error, errorDisplay!.title, errorDisplay!.message);
    } else {
      this.showError(error, 'Error Sending Code', 'An error occurred while sending the verification code.');
    }
    return false;
  }

  clearError() {
    this.data.meta.update(meta => {
      return {
        ...meta,
        error: null
      }
    });
    this.hideLoading({ignoreError: true});
  }

  patch<TPath extends FlattenObjectKeys<OnlineSchedulerService['data']>>(path: TPath, value: DeepPartial<DataTypeAt<TPath>> | ((data: DataTypeAt<TPath>) => DataTypeAt<TPath>)) {
    // console.log('Patching', path, value);

    const merged = this.#merge(path, value);
    const key = path.split('.').shift()!;
    const signal = this.data[key as keyof OnlineSchedulerService['data']] as WritableSignal<any>;
    // console.log({key, signal, data: merged[key]});
    signal.set({...merged[key]});
    // console.log('Merged', merged);
    const parts = path.split('.').splice(1);
    let current = merged[key];
    for (const part of parts) {
      current = current[part];
    }

    return current;
  }

  #showGeneralIntakeError(error: IMessageCode, action: 'final' | 'update') {

    let title: string;
    let description = error.description;
    let btn: { label: string, onClick: () => void } | null = null;

    if (action === 'final') {
      title = 'Unable To Schedule Appointment';
      if (!(description ?? '').trim().length) {
        description = 'We are unable to schedule your appointment at this time.';
      }

      if (error.code === MessageCode.Appointment_Conflict.code || error.code === MessageCode.Slot_Taken.code) {
        const displayInfo = ErrorCodeDisplays[error.code];
        title = displayInfo!.title;
        description = displayInfo!.message!;
        btn = {
          label: 'View Available Times',
          onClick: () => {
            this.goto('find');
            this.clearError();
          }
        }
      }
    } else {
      title = 'Unable To Verify Appointment Details';
    }

    if (error.code.endsWith('0000')) {
      description = ErrorCodeDisplays[MessageCode.Unknown_Browser_Exception.code]!.message!;
    }

    this.showError(error, title, description, {
      action: btn
    });
  }

  #save(data: {
    meta: MetaInfo,
    search: SearchInfo,
    demographics: DemographicsInfo | null,
    insurance: { primary: InsuranceInfo | null, secondary: InsuranceInfo | null },
    appt: SelectedAppointmentInfo | null
  }) {
    // console.log(new Error().stack);
    // console.log('Saving', data);
    const json = JSON.stringify(data);
    this.piwik.trackDimensions(data);
    // localStorage.setItem('online-scheduler', json);
    sessionStorage.setItem('online-scheduler', json);
  }

  // update<TPath extends FlattenObjectKeys<OnlineSchedulerService['data']>>(path: TPath, value: DataTypeAt<TPath>|((data: DataTypeAt<TPath>) => DataTypeAt<TPath>)) {
  //   if (typeof value === 'function') {
  //     const existing = this.#buildObjTree(path);
  //     value = value(existing) as any;
  //   }
  //   const key = path.split('.').shift()!;
  //   const signal = this.data[key as keyof OnlineSchedulerService['data']] as WritableSignal<any>;
  //   signal.set(value);
  //
  // }

  #load(): {
    meta: MetaInfo,
    search: SearchInfo,
    demographics: DemographicsInfo,
    insurance: { primary: InsuranceInfo | null, secondary: InsuranceInfo | null },
    appt: SelectedAppointmentInfo | null
  } {
    const json = sessionStorage.getItem('online-scheduler');
    if (!json) {
      return {
        meta: {
          entryPoint: 'home',
          section: 'prefill',
          prefill: {
            step: 'location'
          },
          booking: {
            id: null!,
            step: 'eligibility',
            eligibility: [],
          },
        },
        search: {
          conditionId: null,
          clinicianTypePref: null,
          settingPref: null,
          age: null,
          languagePref: null,
          genderPref: null,
          timeOfDayPref: null,
          dayOfWeekPref: null,
          soonestWeekOut: null
        },
        demographics: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          dob: null!,
          sex: null!,
          address: {
            address1: null!,
            address2: undefined,
            city: null!,
            state: null!,
            zip: null!,
            coordinates: {
              lat: 0,
              long: 0
            }
          }
        },
        insurance: {
          primary: null,
          secondary: null
        },
        appt: null
      };
    }
    return NaiveDateDeserializer(JSON.parse(json));
  }

  #buildObjTree<TPath extends FlattenObjectKeys<OnlineSchedulerService['data']>>(path: TPath): {
    tree: any,
    parent: any
    prop: string;
  } {
    // console.group(path);
    const parts = path.split('.');
    const tree: any = {...this.data};

    // const key = parts.shift()!;
    let parent: any = tree;
    //
    // current = current[key]();
    // tree[key] = current;
    // console.log(key, current);

    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];
      let newCurrent = parent[part];
      if (typeof newCurrent === 'function' && SIGNAL in newCurrent) {
        newCurrent = newCurrent() as any;
      }

      parent[part] = newCurrent = {...newCurrent ?? {}};
      // console.log(part, newCurrent);
      parent = newCurrent;
    }
    // console.groupEnd();
    return {tree, parent: parent, prop: parts[parts.length - 1]};
  }

  #setOnjTree<TPath extends FlattenObjectKeys<OnlineSchedulerService['data']>>(path: TPath, val: any): any {
    const parts = path.split('.');
    const {tree} = this.#buildObjTree(path);
    const last = parts[parts.length - 1];
    tree[last] = val;
    return tree;
  }

  #merge<TPath extends FlattenObjectKeys<OnlineSchedulerService['data']>>(path: TPath, value: DeepPartial<DataTypeAt<TPath>> | ((data: DataTypeAt<TPath>) => DataTypeAt<TPath>)): any {
    try {


      let {tree, parent, prop: lastKey} = this.#buildObjTree(path);
      if (typeof value === 'function') {
        value = value(parent) as any;
      }
      // console.log({path, tree, value, parent});


      const mergeStack: any[] = [];
      const merge = (obj: any, patch: any) => {
        mergeStack.push([obj, patch]);
        if (typeof patch === 'number' || typeof patch === 'string' || typeof patch === 'boolean') {
          mergeStack.pop();
          return patch;
        }

        if (Array.isArray(patch) || patch instanceof Date || patch instanceof RegExp || patch instanceof Map || patch instanceof Set) {
          mergeStack.pop();
          return patch;
        }
        // console.log(mergeStack);
        if (obj === null || obj === undefined) {
          mergeStack.pop();
          return patch;
        }
        for (const key in patch) {
          const target = obj[key];
          if (typeof patch[key] === 'object' && patch[key] !== null) {
            obj[key] = merge(obj[key], patch[key]);
          } else {
            obj[key] = patch[key];
          }
        }
        mergeStack.pop();
        return obj;
      }

      let last = parent[lastKey];
      if (typeof last === 'function' && SIGNAL in last) {
        last = merge(last(), value);
      }

      if (last === null || last === undefined) {
        parent[lastKey] = value;
      } else {
        parent[lastKey] = merge(last, value);
      }
      return tree;
    } catch (e: any) {
      Logger.Error('OnlineScheduler', e);
      throw e;
    }
  }
}

function NaiveDateDeserializer<T extends Record<string, any> | Record<string, any>[]>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map(NaiveDateDeserializer) as any;
  }

  for (const key in obj) {
    // console.log('Checking', key, obj[key]);
    if (key.toString().startsWith('date') || key.toLowerCase() === 'dob') {
      // console.log('Attempting to parse', key, obj[key]);
      const val = (obj as any)[key];
      if (!val) continue;
      const newDate = new Date(val);
      if (newDate.toString() !== 'Invalid Date') {
        (obj as any)[key] = newDate;
        continue;
      }
      Logger.Warn('OnlineScheduler', 'Skipping possibly invalid date', (obj as any)[key]);
    }
    const val = (obj as any)[key];
    if (typeof val !== 'object') {
      continue;
    }
    NaiveDateDeserializer(val);
  }
  return obj;
}

type DeepNonNullable<T> = {
  [P in keyof T]-?: T[P] extends (infer U)[] ? DeepNonNullable<U>[] :
    T[P] extends (...args: any[]) => any ? T[P] :
      T[P] extends object ? DeepNonNullable<T[P]> : T[P];
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[] ? DeepPartial<U>[] : T[P] extends object ? DeepPartial<T[P]> : T[P];
};
type DataTypeAt<TPath extends FlattenObjectKeys<OnlineSchedulerService['data']>> = SignalValue<ExtractTypeAtPath<OnlineSchedulerService['data'], TPath>>


export interface PageStepsMap {
  prefill: typeof PrefillSteps[number],
  find: never,
  book: typeof BookingSteps[number]
}

export interface MetaInfo {
  error?: IMessageCode | null,
  entryPoint: 'appointment' | 'home',
  section: 'prefill' | 'find' | 'book';
  resumableTo?: {
    section: 'prefill',
    step: PageStepsMap['prefill']
  } | {
    section: 'find',
    step?: undefined;
  }
  prefill: {
    step: PageStepsMap['prefill']
  }
  booking: {
    id: string,
    eligibility: { id: string, checked: boolean }[],
    step: PageStepsMap['book'],
    auth?: {
      request?: {
        at: Date,
        deviceType: 'sms' | 'email' | 'phone',
        destination: string,
        usingIntakeEmail?: boolean;
        intakeEmail?: string;
      }
      token: string
      tokenExpiresAt: Date,
      multiFactorComplete: boolean,
    },
    completionData?: {
      formsLink: string,
      attachments: AttachmentToken[]
    }
  }

}

export interface SearchInfo {
  conditionId: number | null,
  clinicianTypePref: 'Therapy' | 'Psychiatry' | null,
  settingPref: 'InPerson' | 'Telehealth' | null,
  age: 'Child' | 'Adult' | 'Senior' | null,
  languagePref: string | null,
  genderPref: string | null,
  timeOfDayPref: 'Morning' | 'Afternoon' | null,
  dayOfWeekPref: 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday' | null,
  soonestWeekOut: 0 | 1 | 2 | 4 | null;
}


export interface InsuranceInfo {
  readonly id: number;
  readonly planId: number;
  readonly groupNumber: string;
  readonly policyNumber: string;
  readonly holder: {
    name: string;
    dob: Date;
  } | null;
  readonly hasCard?: boolean | null;
}

export interface DemographicsInfo {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phone: string;
  readonly dob: Date;
  readonly sex: Gender;
  readonly address: Schema<'Address'>
}

export interface SelectedAppointmentInfo {
  readonly clinician: Schema<'ClinicianDetailedInfo'>;
  readonly slot: Schema<'TimeSlot'>;
  readonly originalSetting: Schema<'TimeSlot'>['setting'];
  readonly chosenSetting: AppointmentSettingType.InPerson|AppointmentSettingType.Telehealth;
  readonly dateTime: Date;
  readonly duration: number;
  readonly location: Schema<'LocationInfo'>;
  readonly typeOfCare: 'Therapy' | 'Psychiatry';
  readonly conditionId: number;
}

export type DeepSignalValue<T> = T extends Signal<infer U> ? DeepSignalValue<U> : T extends any[] ? DeepSignalValue<T[number]>[] : T extends Record<string, any> ? {
  [K in keyof T]: DeepSignalValue<T[K]>
} : T;
export type SignalValue<T> = T extends Signal<infer U> ? U : T;
// type FlattenObjectKeysOrig<T, Key = keyof T> =
//   Key extends string ? T[Key] extends Record<string, unknown> ? `${Key}.${FlattenObjectKeys<T[Key]>}` : Key : never;
// type FlattenObjectKeys<T, Key = keyof T> =
//   T extends Signal<infer U> ? FlattenObjectKeys<U> :
//     Key extends string ? T[Key] extends Record<string, unknown> | null ? `${Key}.${FlattenObjectKeys<T[Key]>}` : Key : never;

export type EachKeyAs<TObj, TAs> = TObj extends string | number | Date | boolean ? TAs :
  TObj extends any[] ? EachKeyAs<TObj[number], TAs> :
    {
      [K in keyof TObj]: EachKeyAs<TObj[K], TAs>
    };

type PropErrors<T> = EachKeyAs<T, (string[] | null)>;
type ExtractArrayType<T> = T extends (infer U)[] ? U : T;
type ExcludedTypes = Date | Set<any> | Map<any, any>;
type FlattenObjectKeys<T> = {
  [K in keyof T]-?: T[K] extends Signal<infer U>
    ? U extends ExcludedTypes
      ? `${K & string}` // Do not flatten if it's an excluded type
      : U extends Record<string, any>
        ? `${K & string}` | `${K & string}.${FlattenObjectKeys<U>}` // Include both the parent key and nested keys
        : U extends null
          ? never
          : `${K & string}`
    : T[K] extends ExcludedTypes
      ? `${K & string}` // Do not flatten if it's an excluded type
      : T[K] extends Record<string, any>
        ? `${K & string}` | `${K & string}.${FlattenObjectKeys<T[K]>}` // Include both the parent key and nested keys
        : T[K] extends null
          ? never
          : ExtractArrayType<T[K]> extends infer V
            ? `${K & string}`
            : K & string;
}[keyof T];


type ExtractTypeAtPath<T, P extends string> =
  P extends '' | null | undefined ? T :
    P extends `${infer K}.${infer Rest}` ?
      T extends Signal<infer U> ? ExtractTypeAtPath<U, P> :
        T extends (infer U)[] ? ExtractTypeAtPath<U, P> :
          K extends keyof T ? ExtractTypeAtPath<T[K], Rest> :
            never :
      P extends `${infer K}[]` ?
        K extends keyof T ?
          T[K] extends any[] ?
            T[K][number] :
            never :
          never :
        T extends Signal<infer U> ? ExtractTypeAtPath<U, P> :
          P extends keyof T ?
            T[P] :
            never;
// `Invalid path: ${(T extends string ? T : '$')} ${P}`;


type IntakeSaveResp<TAction extends 'final' | 'update' | 'code'> =
  TAction extends 'final'
    ? { formsLink?: string, attachments: AttachmentToken[] }
    : Token & { id: string; };

export interface Token {
  readonly token: string;
  readonly expiresAt: number;
}

export interface AttachmentToken extends Token {
  readonly data: {
    forPrimary: boolean;
    side: 'front' | 'back';
  }
}

export interface QueryBuilder<P extends UrlPaths, F extends Partial<QueryOptions<P>> = QueryOptions<P>> {
  and: <T extends keyof F>(field: T, value: F[T]) => QueryBuilder<P, Exclude<F, T>>;
  assemble: () => QueryOptions<P>;
}

export type QueryKeyValPairs<P extends UrlPaths> = [keyof RouteQueryParamsArgs<P>, RouteQueryParamsArgs<P>[keyof RouteQueryParamsArgs<P>]][];

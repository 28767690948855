import {Component, computed, DestroyRef, effect, inject, output, signal} from '@angular/core';
import {NgxMaskDirective} from "ngx-mask";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {
  ButtonComponent,
  CalloutComponent,
  DimmerComponent, GroupedElementsComponent,
  IconComponent,
  IconSet,
  IconType, JoinPipe
} from "@mindpath/shared";
import {MaxDateValidator, MinDateValidator} from "@mindpath/shared";
import {MessagesModule} from "primeng/messages";
import {PrimeTemplate} from "primeng/api";
import {isMessageCode} from "@mindpath/shared";
import {ActionType} from "@mindpath/shared";
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {JsonPipe, NgTemplateOutlet} from "@angular/common";
import {DividerModule} from "primeng/divider";
import {OnlineSchedulerService} from "../../../../online-scheduler.service";
import {DateToStringNoHyphen, StringNoHyphenDateValidator, StringNoHyphenToDate} from "../../../../utils/date";
import {InsuranceDataService} from "../../../../insurance-data.service";
import {CliniciansDataService} from "../../../../clinicians-data.service";
import {InsuranceFormComponent} from "../../insurance-form/insurance-form.component";

@Component({
  selector: 'app-insurance-info-capture',
  standalone: true,
  imports: [
    NgxMaskDirective,
    ReactiveFormsModule,
    GroupedElementsComponent,
    ButtonComponent,
    MessagesModule,
    DimmerComponent,
    CalloutComponent,
    IconComponent,
    FlexModule,
    NgTemplateOutlet,
    DividerModule,
    JsonPipe,
    JoinPipe,
    ExtendedModule,
    InsuranceFormComponent
  ],
  templateUrl: './insurance-info-capture.component.html',
  styleUrl: './insurance-info-capture.component.scss'
})
export class InsuranceInfoCaptureComponent {

  readonly today = new Date();

  finder = inject(OnlineSchedulerService);
  insuranceSvc = inject(InsuranceDataService);
  clinicianSvc = inject(CliniciansDataService);

  primaryForm = new FormGroup({
    insuranceId: new FormControl<number | undefined>(undefined, Validators.required),
    insurancePlanId: new FormControl<number | undefined>(undefined, Validators.required),
    memberNumber: new FormControl('', Validators.required),
    groupNumber: new FormControl(''),
    isNotPolicyHolder: new FormControl<boolean>(false),
    policyHolderName: new FormControl(''),
    policyHolderDob: new FormControl<Date | undefined>(undefined, [
      StringNoHyphenDateValidator('pastOrPreset', this.today)]),
    hasSecondaryInsurance: new FormControl<boolean>(false),
    // relationshipToPolicyHolder: new FormControl<string|undefined>(undefined),
  });
  secondaryForm = new FormGroup({
    insuranceId: new FormControl<number | undefined>(undefined, Validators.required),
    insurancePlanId: new FormControl<number | undefined>(undefined, Validators.required),
    memberNumber: new FormControl('', Validators.required),
    groupNumber: new FormControl(''),
    isNotPolicyHolder: new FormControl<boolean>(false),
    policyHolderName: new FormControl(''),
    policyHolderDob: new FormControl<Date | undefined>(undefined, [
      StringNoHyphenDateValidator('pastOrPreset', this.today)]),
    // relationshipToPolicyHolder: new FormControl<string|undefined>(undefined),
  });

  form = new FormGroup({
    primary: this.primaryForm,
    secondary: this.secondaryForm
  });

  subs: Subscription[] = [];
  destroyRef = inject(DestroyRef);
  actionBtnClick = output();

  formValid = signal(false);

  isValid = computed(() => {
    const formValid = this.formValid();
    const hasSecondaryInsurance = this.primaryForm.controls.hasSecondaryInsurance.value;
    const primaryOutOfNetworkInsurance = this.primaryOutOfNetworkInsurance();
    const secondaryOutOfNetworkInsurance = this.secondaryOutOfNetworkInsurance();
    const unsupportedInsurance = this.unsupportedInsurance();
    if (!formValid) {
      return false;
    }
    if (primaryOutOfNetworkInsurance) {
      return false;
    }
    if (hasSecondaryInsurance && secondaryOutOfNetworkInsurance) {
      return false;
    }
    if (!unsupportedInsurance) {
      return true;
    }
    if (unsupportedInsurance.primary) {
      return false;
    }
    return !(hasSecondaryInsurance && unsupportedInsurance.secondary);

  });

  constructor() {

    const data = this.finder.data.insurance();

    effect(() => {
      const companies = this.insuranceSvc.snapshot();
      // const selectedInsuranceId = this.primaryForm.controls.insuranceId.value;
      if (!Array.isArray(companies)) {
        return;
      }
      const plan = companies.find(p => p.id === data.primary?.id);
      if (!plan && this.primaryForm.controls.insurancePlanId.valid) {
        this.primaryForm.controls.insurancePlanId.reset();
      }
    }, {allowSignalWrites: true});



    this.primaryForm.controls.isNotPolicyHolder.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        const nameControl = this.primaryForm.controls.policyHolderName;
        const dobControl = this.primaryForm.controls.policyHolderDob;
        // const relationshipControl = this.primaryForm.controls.relationshipToPolicyHolder;
        const controls = [nameControl, dobControl];

        controls.forEach(control => {
          if (val) {
            control?.addValidators(Validators.required);
          } else {
            control?.removeValidators(Validators.required);
          }
          control?.updateValueAndValidity({onlySelf: true, emitEvent: true});
        });
      });
    this.primaryForm.controls.hasSecondaryInsurance.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        if (val) {
          this.secondaryForm.enable()
        } else {
          this.secondaryForm.disable();
        }
      });


    this.secondaryForm.controls.isNotPolicyHolder.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        const nameControl = this.secondaryForm.controls.policyHolderName;
        const dobControl = this.secondaryForm.controls.policyHolderDob;
        // const relationshipControl = this.secondaryForm.controls.relationshipToPolicyHolder;

        const controls = [nameControl, dobControl];

        controls.forEach(control => {
          if (val) {
            control?.addValidators(Validators.required);
          } else {
            control?.removeValidators(Validators.required);
          }
          control?.updateValueAndValidity({onlySelf: true, emitEvent: true});
        });

      });

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        this.formValid.set(this.form.valid);
        if (!this.form.valid) {
          const controls = Object.entries(this.primaryForm.controls).map(e => [true, ...e] as [boolean, string, FormControl])
            .concat(Object.entries(this.secondaryForm.controls).map(e => [false, ...e] as [boolean, string, FormControl]))
            .map(([isPrimary, key, control]) => {
            return {isPrimary, key, errors: control.errors, value: control.value}
          }).filter(c => c.errors);
          // console.log('invalid', this.form.errors, controls);
          this.errorsAsTrackingTags.set(controls.flatMap(c => {
            const secondaryInfix = c.isPrimary ? '' : '_secondary';
            return Object.keys(c.errors ?? {}).map(e => `pvk_insurance-details${secondaryInfix}_error--${c.key}:${e}`);
          }));
        }
        this.primaryOutOfNetworkInsurance.set((val.primary?.insuranceId ?? 0) < 0);
        this.secondaryOutOfNetworkInsurance.set((val.secondary?.insuranceId ?? 0) < 0);


        this.finder.data.insurance.set({
          primary: {
            id: val.primary?.insuranceId!,
            planId: val.primary?.insurancePlanId!,
            groupNumber: val.primary?.groupNumber!,
            policyNumber: val.primary?.memberNumber!,
            holder: val.primary?.isNotPolicyHolder ? {
              name: val.primary?.policyHolderName!,
              dob: val.primary?.policyHolderDob ? StringNoHyphenToDate(val.primary?.policyHolderDob!) : null!,
            } : null,
            hasCard: data.primary?.hasCard!,
          },
          secondary: val.primary?.hasSecondaryInsurance ? {
            id: val.secondary?.insuranceId!,
            planId: val.secondary?.insurancePlanId!,
            groupNumber: val.secondary?.groupNumber!,
            policyNumber: val.secondary?.memberNumber!,
            holder: val.secondary?.isNotPolicyHolder ? {
              name: val.secondary?.policyHolderName!,
              dob: val.secondary?.policyHolderDob ? StringNoHyphenToDate(val.secondary?.policyHolderDob!) : null!,
            } : null,
            hasCard: data.secondary?.hasCard!,
          } : null
        })

      });

    this.primaryOutOfNetworkInsurance.set((data.primary?.id ?? 0) < 0);
    this.secondaryOutOfNetworkInsurance.set((data.secondary?.id ?? 0) < 0);
    // console.log('data', data);
    this.form.patchValue({
      primary: {
        insuranceId: data.primary?.id,
        insurancePlanId: data.primary?.planId,
        memberNumber: data.primary?.policyNumber,
        groupNumber: data.primary?.groupNumber,
        isNotPolicyHolder: !!data.primary?.holder,
        policyHolderName: data.primary?.holder?.name,
        policyHolderDob: data.primary?.holder?.dob ? DateToStringNoHyphen(data.primary?.holder?.dob) as any : undefined,
        hasSecondaryInsurance: !!data.secondary,
      },
      secondary: {
        insuranceId: data.secondary?.id,
        insurancePlanId: data.secondary?.planId,
        memberNumber: data.secondary?.policyNumber,
        groupNumber: data.secondary?.groupNumber,
        isNotPolicyHolder: !!data.secondary?.holder,
        policyHolderName: data.secondary?.holder?.name,
        policyHolderDob: data.secondary?.holder ? DateToStringNoHyphen(data.secondary?.holder?.dob) as any : undefined,
      }
    });
    // console.log('form', this.form.value);

    this.formValid.set(this.form.valid);

  }

  ngOnInit() {
  }



  async nextPage() {
    if (!this.isValid()) {
      this.firedValidationCheck.set(true);
      return;
    }
    this.finder.goForward();
  }

  manualDateChange(date: Date | string) {

  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  primaryOutOfNetworkInsurance = signal(false);
  secondaryOutOfNetworkInsurance = signal(false);

  unsupportedInsurance = computed<{primary?: string, secondary?: string} | null>(() => {
    const insurances = this.finder.data.insurance();
    const clinician = this.finder.data.appt()?.clinician;
    const companies = this.insuranceSvc.snapshot();
    if (!insurances?.primary?.id) {
      return null;
    }

    if (!clinician) {
      return null;
    }

    if (clinician.acceptedInsuranceIds!.includes(insurances.primary.id)) {
      return null;
    }
    const insuranceItem = companies.find(c => c.id === insurances.primary!.id)!;
    if (!insuranceItem) {
      return null;
    }
    return {primary: insuranceItem.name!};
  })

  protected readonly ActionType = ActionType;
  protected readonly IconSet = IconSet;
  protected readonly IconType = IconType;

  firedValidationCheck = signal(false);
  errorsAsTrackingTags = signal<string[]>([]);
}

import {
  AfterViewChecked, AfterViewInit,
  Component,
  computed, DestroyRef,
  effect, ElementRef,
  inject,
  input,
  model, OnDestroy,
  OnInit,
  output,
  signal,
  TemplateRef,
  viewChild, ViewChildren
} from '@angular/core';
import {CardComponent, TemplateFor} from "@mindpath/shared";
import {
  PagedApiResponseType,
  SuccessQueryItemType
} from "@mindpath/shared";
import {ClinicianNamePipe} from "@mindpath/shared";
import {DatePipe, DecimalPipe, JsonPipe, NgOptimizedImage, ViewportScroller} from "@angular/common";
import {FlexLayoutModule, FlexModule} from "@ngbracket/ngx-layout";
import {ClinicianSpecialtyPipe} from "@mindpath/shared";
import {PluralPipe} from "@mindpath/shared";
import {SkeletonModule} from "primeng/skeleton";
import {
  AvailabilitySlotsCardViewComponent
} from "../availability-slots-card-view/availability-slots-card-view.component";
import {PrimeTemplate} from "primeng/api";
import {DividerModule} from "primeng/divider";
import {SlotListingComponentComponent} from "../../components/slot-listing-component/slot-listing-component.component";
import {ButtonComponent, IconComponent, IconSet} from "@mindpath/shared";
import {Schema, Schemas} from "@mindpath/shared";
import {OnlineSchedulerService} from "../../../../../online-scheduler.service";
import {CliniciansDataService} from "../../../../../clinicians-data.service";
import {config} from "../../../../../../config";
import {AppendAppStateParamsPipe} from "../../../../../pipes/append-app-state-params.pipe";


@Component({
  selector: 'app-availability-card-view',
  standalone: true,
  imports: [
    CardComponent,
    ClinicianNamePipe,
    ClinicianSpecialtyPipe,
    PluralPipe,
    ButtonComponent,
    IconComponent,
    NgOptimizedImage, FlexModule, DecimalPipe, TemplateFor, SlotListingComponentComponent, SkeletonModule, DatePipe, AvailabilitySlotsCardViewComponent, DividerModule, FlexLayoutModule, AppendAppStateParamsPipe, JsonPipe
  ],
  templateUrl: './availability-card-view.component.html',
  styleUrl: './availability-card-view.component.scss'
})
export class AvailabilityCardViewComponent implements OnInit, AfterViewInit, OnDestroy {

  dayHolder = viewChild<ElementRef<HTMLDivElement>>('dayHolder');
  sideBarTemplate = viewChild<TemplateRef<any>>('sideBar');

  slotClicked = output<{
    date: Date,
    slot: Schema<'TimeSlot'>,
    setting: Exclude<Schema<'TimeSlot'>['setting'],'Hybrid'|'None'>
  }>();

  info = input.required<Schema<'ClinicianAvailabilityInfo'>>();
  clinician = signal<Schema<'ClinicianGeneralInfo'>|null>(null);


  fetchSelf = input<boolean>(false);

  finder = inject(OnlineSchedulerService);
  clinicianSvc = inject(CliniciansDataService);
  groupPage = signal<number>(0);
  totalGroups = computed(() => {
    const groups = this.grouped();
    if (groups === null) {
      return 0;
    }
    return Math.ceil(groups.length / 5);
  });
  viewableGroups = computed(() => {
    const groups = this.grouped();
    const page = this.groupPage();
    const holderSize = this.holderSize();
    if (groups === null) {
      return null;
    }
    // if (!holderSize) {
    //   return null;
    // }
    // const cardSize = 100;
    // const cardPadding = 8;
    // const maxCardsPerRow = Math.floor(holderSize.width / (cardSize + cardPadding));
    // const maxRows = 2;
    //
    // const actualRows = Math.min(maxRows, Math.ceil(groups.length / maxCardsPerRow));

    // return groups.slice(page * maxCardsPerRow * actualRows, page * maxCardsPerRow * actualRows + maxCardsPerRow * actualRows);

    return groups.slice(page * 5, page * 5 + 5);
  });



  grouped = computed(() => {

    const availability = this.info().days;
    if (availability === null) {
      return null;
    }

    return Object.entries(availability.reduce((acc, a) => {

      if (!acc[a.date]) {
        acc[a.date] = [];
      }
      acc[a.date].push(...a.slots!);
      return acc;
    }, {} as Record<string, Schema<'TimeSlot'>[]>)).map(([date, appts]) => ({
      date: new Date(date),
      appts: appts,
      totalCount: appts.length
    })).sort((a, b) => a.date.getTime() - b.date.getTime());
  });
  protected readonly IconSet = IconSet;

  distanceFromUserInMiles(coords: Schema<'Coordinates'>) {
    const userLoc = this.finder.data.demographics()?.address.coordinates;
    if (!userLoc) {
      return null;
    }
    const deg2rad = (deg: number) => {
      return deg * (Math.PI / 180);
    }

    const lat1 = userLoc.lat;
    const lon1 = userLoc.long;
    const lat2 = coords.lat;
    const lon2 = coords.long;

    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return Math.round(d * 0.621371);
  }

  scroller = inject(ViewportScroller);
  focusedDate = signal<Date|null>(null);
  dateClicked(date: Date) {
    this.focusedDate.set(date);
    setTimeout(() => {
      // this.scroller.scrollToAnchor('focused');
      document.getElementById("focused")?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100);
    this.finder.sidebar.set({
      template: this.sideBarTemplate()!,
      actions: [
        {
          label: 'View All Availability',
          action: () => {
            window.location.href = `${config.endpoints.mainSite}/clinician/${this.clinician()?.npiNumber}`;
          }
        }
      ]
    });

  }
  destroyRef = inject(DestroyRef);
  // resizeObserver: ResizeObserver;

  constructor() {
    effect(async () => {
      const info = this.info();
      let clinician = this.clinician();
      if (clinician?.id === info.clinician.id) {
        return;
      }
      clinician = await this.clinicianSvc.getSingle(info.clinician.npi);
      this.clinician.set(clinician);
    }, {allowSignalWrites: true});

    // this.resizeObserver = new ResizeObserver((entries) => {
    //   for (const entry of entries) {
    //     const boundingRect = entry.target.getBoundingClientRect();
    //     this.holderSize.set({
    //       width: boundingRect.width,
    //       height: boundingRect.height
    //     });
    //   }
    // });

    // effect(() => {
    //   const holder = this.dayHolder();
    //   if (holder) {
    //     this.holderSize.set({
    //       width: holder.nativeElement.getBoundingClientRect().width,
    //       height: holder.nativeElement.getBoundingClientRect().height
    //     });
    //     this.resizeObserver.observe(holder.nativeElement);
    //   }
    // });


  }

  ngOnInit() {

  }

  ngOnDestroy() {
    // this.resizeObserver.disconnect();
  }

  ngAfterViewInit() {
    // const holder = this.dayHolder();
    // if (!holder) {
    //   return null;
    // }
    // return holder.nativeElement.getBoundingClientRect();
  }

  holderSize = signal<{width: number, height: number}|null>(null);

  protected readonly config = config;
}

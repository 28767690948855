@if (options().main; as main) {
  <section>
    <h3>
      {{ main.labels.title }}
    </h3>
    @if (main.labels.subTitle) {
      <h5 class="text-secondary" style="font-weight: normal">
        {{ main.labels.subTitle }}
      </h5>
    }
    <div fxLayout="column" ngClass.gt-sm="row-gap-40" ngClass.lt-sm="row-gap-16" class="pt-16">
      @if (options().bars; as bars) {
        <ul>
          @for (option of bars.options; track option.value) {

            <li ngClass.sm="pt-12" ngClass.lt-sm="pt-8" ngClass.gt-sm="pt-16">
              <lib-bar style="height: var(--size-80);"
                       (click)="bars.selected.set($any(option.value)); selection.emit(option.value);">
                <span fxLayout="row" fxLayoutAlign="space-between center">
                    <span fxLayout="column" fxLayoutAlign="start start">
                      <div fxLayout="row" fxLayoutAlign="start center" gap="12">
                          @if (option.icon) {
                            <lib-icon colorClass="text-primary-light" name="{{option.icon}}" verticalAlign="middle"
                                      [size]="32"></lib-icon>
                          }
                        <span class="text-primary">
                          <strong>{{ option.label }}</strong>
                        </span>
                      </div>
                      @if(option.description) {
                        <span class="text-secondary">
                        {{ option.description }}
                      </span>
                      }
                    </span>
                </span>
              </lib-bar>
            </li>
          }
        </ul>
      }
      @if (options().autoComplete; as autoComplete) {
        <div fxLayout="column">
          @if (autoComplete.label; as label) {
            <h3 class="heading-3 text-body-secondary">
              {{ label }}
            </h3>
          }
          <lib-input [valid]="true" fxFlex style="width: 100%">
            <p-autoComplete #autoElem
                            [suggestions]="$any(filteredAutoCompleteOptions())"
                            [lazy]="true"
                            [fluid]="true"
                            [forceSelection]="true"
                            (onFocus)="autoElem.value ? autoElem.show() : null"
                            (onSelect)="autoCompleteSelected($event);"
                            [delay]="125"
                            [panelStyle]="{marin: '24px', width: '100%'}"
                            [placeholder]="(autoComplete?.placeholder ?? '')"
                            [style]="{width: '100%'}"
                            [optionLabel]="optionLabel"
                            appendTo="body"
                            [optionValue]="optionValue"
                            (completeMethod)="OptionFilter(autoComplete.options, filteredAutoCompleteOptions)($event)">
              <ng-template let-item pTemplate="item">
                {{ item.label }}
              </ng-template>
              <ng-template pTemplate="empty">
                <div class="flex items-center gap-2">
                  No results found
                </div>
              </ng-template>
            </p-autoComplete>
          </lib-input>
          @if (autoComplete.hinting; as hinting) {

            <br/>
            <p class="text-secondary">{{hinting.label}}</p>
            <ul class="option-list">
              @for (opt of autoCompleteHintedOptions(); track opt.value) {
                <li class="pt-4">
                  <button type="button" class="text-primary link text blend !absolute left-0 top-8 step-back pt-12"
                          style="text-align: left"
                          (click)="autoComplete.selected.set(opt.value); selection.emit(opt.value)">
                    <p><strong>{{ opt.label }}</strong></p>
                  </button>
                </li>
              }
            </ul>
          }
        </div>
      }

    </div>
  </section>
}
@if (options().footer; as footer) {
  <section class="pt-16">
    @if (footer.title) {
      <h4>
        {{ footer.title }}
      </h4>
    }
    @if (footer.subTitle) {
      <p>
        {{ footer.subTitle }}
      </p>
    }
  </section>

}
import {WritableSignal} from "@angular/core";
import {AutoCompleteCompleteEvent} from "primeng/autocomplete";

export interface OptionItem {
  readonly id: number;
  readonly name: string;
}

export function OptionFilter<T extends {value: any, label: string}|{id: number, name: string}>(options: readonly T[], signal: WritableSignal<readonly T[]>, ...alwaysIncluded: T[]) {

  return (event: AutoCompleteCompleteEvent) => {

    const query = event.query;
    const filtered = options.filter(option => {
      let target = (option as any).name ?? (option as any).label;
      if ((target).toLowerCase().includes(query.toLowerCase())) {
        return true;
      }
      target = (option as any).id ?? (option as any).value;
      return (target).toString().toLowerCase().includes(query.toLowerCase()) || target === query;
    });
    signal.set([...alwaysIncluded, ...filtered]);
  }
}
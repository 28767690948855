import {Component, computed, input, InputSignal, Signal} from '@angular/core';
import {IconComponent, IconSet, IconType} from "../icon/icon.component";
import {FormControl} from "@angular/forms";
import {TooltipModule} from "primeng/tooltip";

export abstract class BaseInputComponent {

  public abstract label: InputSignal<string|null>;
  public abstract tooltip: InputSignal<string|null>;

  protected readonly IconType = IconType;
}

@Component({
  selector: 'lib-input',
  standalone: true,
  imports: [
    IconComponent,
    TooltipModule
  ],
  templateUrl: './input.component.html',
  styleUrl: './input.component.css'
})
export class InputComponent extends BaseInputComponent {
  label = input<string|null>(null);
  tooltip = input<string|null>(null);
  disabled = input<boolean>(false);
  readonly = input<boolean>(false);
  required = input<boolean>(true);
  requiredIndicator = input<boolean>(true);
  optionalIndicator = input<boolean>(false);
  valid = input.required<boolean>();
  customClass = input<string|null>('');

  protected readonly IconSet = IconSet;
}




import {PageStepsMap} from "../online-scheduler.service";
import {Dimensions} from "@piwikpro/tracking-base-library";
import {AppointmentSettingType, ClinicianType, Gender, State} from "@mindpath/shared";
export enum DimensionTypes {
  State = 4,
  TypeOfCare  = 5,
  Age = 6,
  ApptSettingFilter = 7,
  Insurance = 8,
  Plan = 9,
  Latitude = 10,
  Longitude = 11,
  Condition = 13,
  GenderFilter = 14,
  LanguageFilter = 15,
}

export interface CustomDimensionsMap {
  [DimensionTypes.State]: State,
  [DimensionTypes.TypeOfCare]: ClinicianType.Therapist|ClinicianType.Prescriber,
  [DimensionTypes.Age]: 'Child' | 'Adult'| 'Senior',
  [DimensionTypes.ApptSettingFilter]: AppointmentSettingType.InPerson|AppointmentSettingType.Telehealth,
  [DimensionTypes.Insurance]: number,
  [DimensionTypes.Plan]: number,
  [DimensionTypes.Latitude]: number,
  [DimensionTypes.Longitude]: number,
  [DimensionTypes.Condition]: number,
  [DimensionTypes.GenderFilter]: Gender,
  [DimensionTypes.LanguageFilter]: string,
}

export interface EventMap {
  meta: {
    'resumed': {
      section: string,
      step: string|undefined,
    }

  },
  directory: {
    'clinician-total': {
      type: number,
      subKey: 'in-network' | 'out-of-network',
    }
  },
  schedule: {
    'change_location': {
      from: string|null|undefined,
      to: string,
    }
  }
}

export const PageViews = {
  Prefill: {
    Location: 'Finder - Location',
    LocationInvalid: 'Finder - Invalid Location',
    Age: 'Finder - Age',
    AgeInvalid: 'Finder - Invalid Age',
    Insurance: 'Finder - Insurance Group',
    InsuranceNotFound: 'Finder - Insurance Group (Not Found)',
    InsuranceSelfPay: 'Finder - Insurance Self-Pay',
    InsurancePlan: 'Finder - Insurance',
    InsurancePlanNotFound: 'Finder - Insurance Plan (Not Found)',
    Services: 'Finder - Services',
    Evaluation: 'Finder - Evaluation',
    VisitType: 'Finder - Visit Type',
  },
  Find: 'Finder - Clinicians',
  Book: {
    Eligibility: 'Booking - Eligibility',
    DataCheck: 'Booking - Data Check',
    Demographics1: 'Booking - Demographics 1',
    Demographics2: 'Booking - Demographics 2',
    OTP: 'Booking - OTP',
    Insurance: 'Booking - Insurance',
    InsuranceCards: 'Booking - Insurance Cards',
    Final: 'Booking - Final',
  }
}

export function GetTrackEvent<TSection extends keyof EventMap, TEvent extends keyof EventMap[TSection]>(section: TSection, event: TEvent, data: EventMap[TSection][TEvent]): [string, string, string|undefined, value?: number|undefined, dimensions?: Dimensions|undefined] {
  if (section === 'directory') {
    if (event === 'clinician-total') {
      const typedData = data as EventMap['directory']['clinician-total'];
      return [section, event, typedData.subKey, typedData.type, undefined];
    }
  }
  if (section === 'schedule') {
    if (event === 'change_location') {
      const typedData = data as EventMap['schedule']['change_location'];
      return [section, event, `${typedData.from} to ${typedData.to}`];
    }
  }
  if (section === 'meta') {
    if (event === 'resumed') {
      const typedData = data as EventMap['meta']['resumed'];
      return [section, event, `${typedData.section} - ${typedData.step}`];
    }
  }
  console.error('Unknown event', section, event);
  return [`unknown`,`\`${section}\` - \`${event.toString()}\``, JSON.stringify(data)];

}

export function GetDimension<TDim extends keyof CustomDimensionsMap>(dimension: TDim, value: CustomDimensionsMap[TDim]): [number, CustomDimensionsMap[TDim]] {
  return [dimension, value];
}

export function GetPageView<TSection extends keyof PageStepsMap>(section: TSection, step: PageStepsMap[TSection]): string {
  if (section === 'prefill') {
    switch (step) {
      case "location":
        return PageViews.Prefill.Location;
      case "type":
        return PageViews.Prefill.Services;
      case "condition":
        return PageViews.Prefill.Evaluation;
      case "age":
        return PageViews.Prefill.Age;
      case "setting":
        return PageViews.Prefill.VisitType;
      case "insurance":
        return PageViews.Prefill.Insurance;
      case "insurancePlan":
        return PageViews.Prefill.InsurancePlan;
    }
  }
  if (section === 'find') {
    return PageViews.Find;
  }
  if (section === 'book') {
    switch (step) {
      case "eligibility":
        return PageViews.Book.Eligibility;
      case "data-check":
        return PageViews.Book.DataCheck;
      case "demographics-1":
        return PageViews.Book.Demographics1;
      case "demographics-2":
        return PageViews.Book.Demographics2;
      case "otp":
        return PageViews.Book.OTP;
      case "insurance":
        return PageViews.Book.Insurance;
      case "insurance-cards":
        return PageViews.Book.InsuranceCards;
      case "final":
        return PageViews.Book.Final;
    }
  }
  console.error('Unknown page view', section, step);
  return `Unknown - \`${section}\` - \`${step}\``;
}


import {Component, computed, effect, inject, signal} from '@angular/core';
import {
  ActionType,
  ButtonComponent,
  CalloutComponent, CheckboxComponent,
  IconComponent,
  IconSet,
  IconType,
  InputComponent
} from "@mindpath/shared";
import {FormsModule} from "@angular/forms";
import {MessageService, PrimeNGConfig} from "primeng/api";
import {FileSelectEvent} from "primeng/fileupload";
import {DividerModule} from "primeng/divider";
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {JsonPipe, NgTemplateOutlet} from "@angular/common";
import {FieldsetModule} from "primeng/fieldset";
import {InsuranceFormComponent} from "../../insurance-form/insurance-form.component";
import {TemplateFor} from "@mindpath/shared";
import {OnlineSchedulerService} from "../../../../online-scheduler.service";
import {CardComponent} from "@mindpath/shared";

@Component({
  selector: 'app-insurance-cards-capture',
  standalone: true,
  imports: [
    ButtonComponent,
    FormsModule,
    InsuranceFormComponent,
    CalloutComponent,
    DividerModule,
    FlexModule,
    IconComponent,
    InputComponent,
    NgTemplateOutlet,
    FieldsetModule,
    TemplateFor,
    CheckboxComponent,
    JsonPipe,
    ExtendedModule,
    CardComponent
  ],
  templateUrl: './insurance-cards-capture.component.html',
  styleUrl: './insurance-cards-capture.component.scss'
})
export class InsuranceCardsCaptureComponent {

  finder = inject(OnlineSchedulerService);

  showSecondaryInsurance = computed(() => {
    return !!this.finder.data.insurance().secondary;
  });

  cards = signal<readonly [CardPair, CardPair]>([{}, {}]);
  isValid = computed(() => {
    const data = this.finder.data.insurance();
    const cards = this.cards();
    const showSecondary = this.showSecondaryInsurance();

    if (showSecondary && !data.secondary?.hasCard && !cards[1]) return false;

    return cards.every((c, i) => {
      if (i === 1 && !showSecondary) return true;
      if (c?.omit ?? false) return true;
      if (!c.front || !c.back) return false;
      return (c.front.size ?? 0) <= MAX_FILE_SIZE_MB * 1024 * 1024 && (c.back.size ?? 0) <= MAX_FILE_SIZE_MB * 1024 * 1024;
    })
  });
  firedValidationCheck = signal(false);
  protected readonly IconType = IconType;
  protected readonly ActionType = ActionType;
  protected readonly IconSet = IconSet;

  constructor() {
    const startData = this.finder.data.insurance();

    const primaryCard: CardPair = {omit: startData.primary?.hasCard === false};
    const secondaryCard: CardPair = startData.secondary ? {omit: startData.secondary?.hasCard === false} : {};
    this.cards.set([
      primaryCard,
      secondaryCard
    ]);

    effect(() => {
      const cards = this.cards();
      const hasPrimaryCard = typeof cards[0].omit === 'boolean' ? !cards[0].omit : null;
      const hasSecondaryCard = typeof cards[1].omit === 'boolean' ? !cards[1].omit : null;
      this.finder.data.insurance.update(ins => {
        const secondary = ins.secondary ? {
          ...ins.secondary,
          hasCard: hasSecondaryCard
        } : null;

        return {
          ...ins,
          primary: {
            ...ins.primary,
            hasCard: hasPrimaryCard
          },
          secondary
        } as any;
      })

    }, {allowSignalWrites: true});

  }

  onFileChange(event: Event, index: number, side: 'front' | 'back') {
    const file = (event.target as HTMLInputElement).files![0];
    const objectUrl = URL.createObjectURL(file);
    // console.log('file change', index, side, file, objectUrl);
    const totalSize = file.size;
    this.cards.update(c => {
      const newCards: [CardPair, CardPair] = [...c];
      newCards[index][side] = {file, objectUrl, size: totalSize};
      return newCards;
    });
  }

  clearCardSide(index: number, side: 'front' | 'back') {
    this.cards.update(c => {
      const newCards: [CardPair, CardPair] = [...c];
      newCards[index][side] = undefined;
      return newCards;
    })
  }

  setOmitCardState(index: number, omit: boolean) {
    this.cards.update(c => {
      const newCards: [CardPair, CardPair] = [...c];
      // console.log({index, omit})
      newCards[index].omit = omit;
      return newCards;
    })
  }

  async submitApplication() {
    const cards = this.cards();
    try {
      const cardFiles = {
        primary: cards[0]?.omit ? {front: null, back: null} : {
          front: cards[0].front?.file ?? null,
          back: cards[0].back?.file ?? null
        },
        secondary: cards[1]?.omit ? {front: null, back: null} : {
          front: cards[1].front?.file ?? null,
          back: cards[1].back?.file ?? null
        }
      };
      this.finder.insuranceCardFiles.set(cardFiles);

      if(await this.finder.submitIntake()) {
        this.finder.goForward();
      }
    } catch (e) {

    }
  }


}

const MAX_FILE_SIZE_MB = 10;

export type CardFile = { file: File, objectUrl: string, size: number };
export type CardPair = { omit?: boolean|null, front?: CardFile, back?: CardFile };
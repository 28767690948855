import {Component, input} from '@angular/core';
import {BarComponent, CheckboxComponent, IconComponent, IconType} from "../base";
import {ActionType} from "../../models/action.type";
import {FlexModule} from "@ngbracket/ngx-layout";

@Component({
  selector: 'lib-callout',
  standalone: true,
  imports: [
    BarComponent,
    CheckboxComponent,
    IconComponent,
    FlexModule
  ],
  templateUrl: './callout.component.html',
  styleUrl: './callout.component.scss'
})
export class CalloutComponent {

  type = input.required<ActionType>();
  title = input<string>();
  content = input<string|null>(null);
}

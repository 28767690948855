import {APP_INITIALIZER, Provider, signal, WritableSignal} from "@angular/core";
import {MaintenanceCfg} from "@mindpath/shared";
import {Logger, LoggerConfig, LogLevel} from "@mindpath/shared";

declare var window: any;

export enum Environments {
  Local =  'local',
  Staging = 'staging',
  Prod = 'prod'
}

class ConfigImp implements Config {

  readonly appName!: string;
  readonly logging?: LoggerConfig | LogLevel;
  readonly support!: { phone: string };

  public environment: Environments = Environments.Local;

  public get isProd() {
    return this.environment === Environments.Prod;
  }

  public get isLocal() {
    return this.environment === Environments.Local;
  }

  public get isStaging() {
    return this.environment === Environments.Staging;
  }

  public readonly endpoints: EndpointsConfig = {} as any;
  public readonly maintenance: boolean|MaintenanceCfg = false;

  readonly inMaintenance = signal<boolean>(false);

  public loadConfig(json: Config) {
    Object.assign(this, json);
    if (this.isProd) {
      Logger.PatchGlobal();
    }
    if (this.logging) {
      Logger.Configure(this.logging);
    } else if (this.isProd) {
      Logger.Configure(LogLevel.Error);
    }
    // Logger.Log('config', this);
    // console.warn(this);
    this.inMaintenance.update(() => {
      if (typeof this.maintenance === 'boolean') {
        return this.maintenance;
      }
      const inMaintenance = this.maintenance?.enabled;
      if (typeof inMaintenance === 'boolean') {
        return inMaintenance;
      }
      return !!inMaintenance;
    })

  }
}

const configImp = new ConfigImp();
export const config: Config = configImp;

let loaded = false;
export async function LoadConfig() {
  if (loaded) {
    return config;
  }
  const response = await fetch('/config.json?_=' + Date.now());
  const json = await response.json();
  loaded = true;
  console.log('config', json);
  configImp.loadConfig(json);
  return config;
}

export function provideConfig() {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: () => LoadConfig,
    deps: []
  } satisfies Provider
}


export interface Config {
  readonly isProd: boolean;
  readonly isLocal: boolean;
  readonly isStaging: boolean;
  readonly environment: Environments;
  readonly endpoints: EndpointsConfig;
  readonly maintenance: MaintenanceCfg|boolean;
  readonly inMaintenance: WritableSignal<boolean>
  readonly appName: string;
  readonly support: {
    phone: string;
  }
  readonly logging?: LoggerConfig|LogLevel;
  readonly piwik?: {
    siteId: string;
    url: string;
  }
}

export interface EndpointsConfig {
  api: string;
  mainSite: string;
}


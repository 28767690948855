import {
  AfterViewInit,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  OnInit,
  output,
  signal,
  viewChild
} from '@angular/core';
import {NgxMaskDirective} from "ngx-mask";
import {FormArray, FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {data} from "autoprefixer";
import {
  BarComponent,
  ButtonComponent,
  CalloutComponent, GroupedElementsComponent, IconComponent, IconType, InputComponent, LinkComponent,
  NumberCodeInputComponent
} from "@mindpath/shared";
import {InputOtpModule} from "primeng/inputotp";
import {ActionType} from "@mindpath/shared";
import {PhonePipe} from "@mindpath/shared";
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {InvalidDeviceCodes, OnlineSchedulerService, SupportHtml} from "../../../../online-scheduler.service";
import {IMessageCode, MessageCode} from "@mindpath/shared";
import {Logger} from "@mindpath/shared";

@Component({
  selector: 'app-device-verification',
  standalone: true,
  imports: [
    NgxMaskDirective,
    GroupedElementsComponent,
    ReactiveFormsModule,
    NumberCodeInputComponent,
    ButtonComponent,
    InputOtpModule,
    FormsModule,
    CalloutComponent,
    PhonePipe,
    BarComponent,
    LinkComponent,
    FlexModule,
    IconComponent,
    InputComponent,
    ExtendedModule
  ],
  templateUrl: './device-verification.component.html',
  styleUrl: './device-verification.component.scss'
})
export class DeviceVerificationComponent implements OnInit, AfterViewInit {

  finder = inject(OnlineSchedulerService);
  phoneNumber = this.finder.listenTo('demographics.phone');
  email = this.finder.listenTo('demographics.email');
  destinationType = this.finder.listenTo('meta.booking.auth.request.deviceType');
  deviceDestination = this.finder.listenTo('meta.booking.auth.request.destination');

  requestNewDeviceStr = computed(() => {
    switch (this.destinationType()) {
      case 'email':
        return 'email';
      case 'phone':
        return 'phone call';
      case 'sms':
        return 'text message';
    }
    Logger.Error('DeviceVerification', 'Unknown destination type', this.destinationType());
    this.finder.showError(MessageCode.Unknown_Browser_Exception, 'Unknown Error', 'An error occurred while loading the page');
    return '';
  });

  isInvalidCode = computed(() => {
    const error = this.finder.error();
    // console.log({error, isInvalidCode: error?.code === MessageCode.Incorrect_Code.code});
    return error?.code === MessageCode.Incorrect_Code.code;
  });

  isExpiredCode = computed(() => {
    const error = this.finder.error();
    // console.log({error, isInvalidCode: error?.code === MessageCode.Incorrect_Code.code});
    return error?.code === MessageCode.Code_Expired.code;
  });

  timeUntilNewCode = signal(this.#calcTimeUntil());

  code = signal<string | null>(null);

  expireInStr = computed(() => {
    const expireIn = this.expiresAtInMinutes();
    return `This code will expire in ${expireIn} minute${expireIn === 1 ? '' : 's' }.`
  });
  expiresAtInMinutes = computed(() => {
    const meta = this.finder.data.meta();
    let expiresAt = meta.booking?.auth?.tokenExpiresAt;
    if (!expiresAt) {
      return 5;
    }
    expiresAt = new Date(expiresAt);

    return Math.ceil((expiresAt.getTime() - Date.now()) / 1000 / 60);
  });
  firedValidationCheck = signal(false);
  protected readonly ActionType = ActionType;
  protected readonly SupportHtml = SupportHtml;
  protected readonly IconType = IconType;

  validDigit = (digit: string | null | undefined) => !(digit === undefined || digit === null || digit.trim() === '' || isNaN(+digit));

  isValid = computed(() => {
    const code = this.code()?.trim();
    if (code?.length !== 6) {
      return false;
    }
    // console.log(code.split(''))
    return code.split('').every(this.validDigit);
  });

  constructor() {
    const meta = this.finder.data.meta();
    let requestedAt = meta.booking?.auth?.request?.at;
    setInterval(() => {
      this.timeUntilNewCode.set(this.#calcTimeUntil());
    }, 1000);

    effect(() => {
      const isExpiredCode = this.isExpiredCode();
      if (isExpiredCode) {
        this.firedValidationCheck.set(false);
        this.code.set(null);
      }
    }, {allowSignalWrites: true});
  }

  #calcTimeUntil() {
    const meta = this.finder.data.meta();
    let requestedAt = meta.booking?.auth?.request?.at;
    if (!requestedAt) {
      return undefined;
    }
    requestedAt = new Date(requestedAt);

    const waitSeconds = 15;

    const timeSinceRequest = Date.now() - requestedAt.getTime();
    const secondsSinceRequest = (waitSeconds) - Math.floor(timeSinceRequest / 1000);
    const minSinceRequest = Math.floor(secondsSinceRequest / 60);

    // console.log({minSinceRequest, secondsSinceRequest})

    if (secondsSinceRequest < 1 && minSinceRequest < 1) {
      return undefined;
    }

    if (minSinceRequest < 1) {
      const unit = secondsSinceRequest === 1 ? 'second' : 'seconds';
      return {amount: secondsSinceRequest, unit, totalSec: secondsSinceRequest};
    }
    const unit = minSinceRequest === 1 ? 'minute' : 'minutes';
    return {amount: minSinceRequest, unit, totalSec: secondsSinceRequest};
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
  }

  async requestVerificationCode() {
    const timeuntilNew = this.timeUntilNewCode();
    // if the time until new code is greater than 15 seconds don't request a new code
    if (timeuntilNew && timeuntilNew.totalSec > 1) {
      return;
    }

    const destination = this.deviceDestination();
    if (!destination) {
      Logger.Error('DeviceVerification', 'No destination provided');
      this.finder.showError(MessageCode.Unknown_Exception, 'Unknown Error', 'An unknown error occurred while sending the verification code');
      return;
    }
    await this.finder.requestAuthCode();
  }

  async verifyCode() {
    this.firedValidationCheck.set(true);
    const code = this.code()!;
    if (await this.finder.verifyCode(code)) {
      this.finder.goForward();
    }

  }
}

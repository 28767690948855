<section>
  <h3>
    Upload your insurance card{{ showSecondaryInsurance() ? 's' : '' }}
  </h3>
  <h5 class="text-secondary" style="font-weight: normal">
    Submitting images of your insurance card{{ showSecondaryInsurance() ? 's' : '' }} will help speed up the check-in
    process for your visit.
  </h5>
  <div fxLayout="column" ngClass.gt-xs="row-gap-40" ngClass.lt-sm="row-gap-16">
    @if (showSecondaryInsurance()) {
      <p-fieldset legend="Primary Insurance Card">
        <ng-container
          *ngTemplateOutlet="cardForm; context: {$implicit: cards()[0], index: 0, primaryOrSecondary: 'primary'}"></ng-container>
      </p-fieldset>

      <p-fieldset legend="Secondary Insurance Card">
        <ng-container
          *ngTemplateOutlet="cardForm; context: {$implicit: cards()[1], index: 1, primaryOrSecondary: 'secondary'}"></ng-container>
      </p-fieldset>
    } @else {
      <lib-card>
        <ng-template templateFor="content">
          <ng-container
            *ngTemplateOutlet="cardForm; context: {$implicit: cards()[0], index: 0, primaryOrSecondary: 'primary'}"></ng-container>
        </ng-template>
      </lib-card>
    }
  </div>

  <ng-template #cardForm let-info let-index="index" let-primaryOrSecondary="primaryOrSecondary">
    <div fxLayout="column" fxLayoutAlign="start stretch" row-gap="24" class="p-16" style="width: 100%">

      <!--    <lib-card>-->
      <div fxLayout="row" fxLayoutAlign="center start" gap="32" fxLayout.lt-sm="column"
           fxLayoutAlign.lt-sm="start center">
        <div fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayoutAlign="center">
          <div>
            <ng-container
              *ngTemplateOutlet="cardInput; context: { $implicit: info, index: index, side: 'front', primaryOrSecondary: primaryOrSecondary }"></ng-container>
          </div>
        </div>
        <div fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayoutAlign="center">
          <div>
          <ng-container
            *ngTemplateOutlet="cardInput; context: { $implicit: info, index: index, side: 'back', primaryOrSecondary: primaryOrSecondary }"></ng-container>
          </div>
        </div>

      </div>
      <p-divider></p-divider>

      <span fxLayout="row" fxLayoutAlign.lt-sm="center" fxLayoutAlign="start">
        <lib-checkbox [checked]="info.omit" (checkedChange)="setOmitCardState(index, $event)"
                      class="pk_insurance-upload_{{primaryOrSecondary}}_access"
                      label="I don't have access to this card"
        ></lib-checkbox>
        <!--        <span class="pl-16">I don't have this card</span>-->
      </span>
    </div>
  </ng-template>

  <ng-template #cardInput let-card let-index="index" let-side="side" let-primaryOrSecondary="primaryOrSecondary">
    <div fxLayout="column" fxLayoutAlign="start center">
      <lib-input [valid]="!!card[side]?.file" [required]="!card.omit" [disabled]="!!card.omit"
                 class="pk_insurance-upload_{{primaryOrSecondary}}_{{side}}" style="width: 100%"
      >
      <span class="input-label text-left" [class.disabled]="!!card.omit"
            slot="label">{{ side === 'front' ? 'Front' : 'Back' }}</span>
      </lib-input>
      <div fxLayout="column" fxLayoutAlign="start center" [class.disabled]="!!card.omit">
        @if (card[side]?.objectUrl) {
          <div fxLayout="column" style="display: inline-block;">
            <img role="presentation" [alt]="card[side]?.file.name" [src]="card[side]?.objectUrl"/>
            <span class="file-label">{{ card[side]?.file.name }}</span>
          </div>
        } @else {
          <div class="img-holder" fxLayout="column" fxLayoutAlign="center">
            <span>Select a file or drag and drop here</span>
          </div>
        }

        <span class="sub-label">
              (File size limit: 10MB)
            </span>

        <div fxLayout="row" gap="24">
          @if (card[side]?.file) {
            <button type="button" class="btn step-next blend pk_insurance-upload_{{primaryOrSecondary}}_{{side}}-remove"
                    (click)="clearCardSide(index, side); cardInput.value = ''!">
              Remove {{ side === 'front' ? 'Front' : 'Back' }}
            </button>
          } @else {
            <button type="button" class="btn step-next blend pk_insurance-upload_{{primaryOrSecondary}}_{{side}}-add"
                    (click)="cardInput.click()">
              Choose {{ side === 'front' ? 'Front' : 'Back' }}
            </button>
          }
        </div>
        <input type="file" (change)="onFileChange($event, index, side)" accept="image/*" style="display: none"
               #cardInput>

      </div>
    </div>
  </ng-template>

  @if (firedValidationCheck() && !isValid()) {
    <lib-callout [type]="ActionType.Danger">
      <div fxLayout="column">

        @if (cards()[0]; as card) {
          @if (!card.omit) {
            @if (!card.front?.file) {
              <span class="pvk_insurance_upload_primary_front_error--required">
                <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                          verticalAlign="middle"></lib-icon>
                <span
                  style="padding-left: 8px;">Please select a front image for the{{ showSecondaryInsurance() ? ' primary' : '' }}
                  insurance card</span>
              </span>
            }
            @if (!card.back?.file) {
              <span class="pvk_insurance_upload_primary_back_error--required">
                <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                          verticalAlign="middle"></lib-icon>
                <span
                  style="padding-left: 8px;">Please select a back image for the{{ showSecondaryInsurance() ? ' primary' : '' }}
                  insurance card</span>
              </span>
            }

          }
        }
        @if (showSecondaryInsurance() && cards()[1]; as card) {
          @if (!card.omit) {
            @if (!card.front?.file) {
              <span class="pvk_insurance_upload_secondary_front_error--required">
                <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                          verticalAlign="middle"></lib-icon>
                <span style="padding-left: 8px;">Please select a front image for the secondary insurance card</span>
              </span>
            }
            @if (!card.back?.file) {
              <span class="pvk_insurance_upload_secondary_back_error--required">
                <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                          verticalAlign="middle"></lib-icon>
                <span style="padding-left: 8px;">Please select a back image for the secondary insurance card</span>
              </span>
            }

          }
        }
      </div>

    </lib-callout>
  }

  <button type="button" class="btn btn-large step-next pk_insurance_upload_submit arrow-right mt-16"
          [disabled]="firedValidationCheck() && !isValid()"
          (click)="isValid() || (firedValidationCheck() && isValid()) ? submitApplication() : firedValidationCheck.set(true)">
    Submit
  </button>


</section>

import {effect, inject, Injectable} from '@angular/core';
import {DeepSignalValue, OnlineSchedulerService, PageStepsMap} from "../online-scheduler.service";
import {DimensionTypes, EventMap, GetPageView, GetTrackEvent} from "./index";
import {
  CustomDimensionsService,
  CustomEventsService,
  DataLayerService,
  PageViewsService,
  UserManagementService
} from "@piwikpro/ngx-piwik-pro";
import {InsuranceDataService} from "../insurance-data.service";
import {ConditionsDataService} from "../conditions-data.service";

@Injectable({
  providedIn: 'root'
})
export class PiwikService {

  private pageView = inject(PageViewsService);
  private dataLayer = inject(DataLayerService);
  private customDimensions = inject(CustomDimensionsService);
  private customEvents = inject(CustomEventsService);
  private userManagement = inject(UserManagementService);

  private insuranceData = inject(InsuranceDataService);
  private conditions = inject(ConditionsDataService);

  constructor() {
    // (async () => {
    //   const userId = await this.userManagement.getUserId();
    //   const visitorId = await this.userManagement.getVisitorId();
    //   const visitorInfo = await this.userManagement.getVisitorInfo();
    //   console.log({userId, visitorId, visitorInfo});
    // })();

  }

  trackEvent<TSection extends keyof EventMap, TEvent extends keyof EventMap[TSection]>(section: TSection, event: TEvent, data: EventMap[TSection][TEvent]) {
    this.customEvents.trackEvent(...GetTrackEvent(section, event, data));
  }

  trackPage<TSection extends keyof PageStepsMap>(section: TSection, step: PageStepsMap[TSection]) {
    this.pageView.trackPageView(GetPageView(section, step));
  }

  trackDimensions(data: DeepSignalValue<OnlineSchedulerService['data']>) {
    const state = data.demographics?.address?.state;
    const typeOfCare = data.appt?.typeOfCare ?? data.search.clinicianTypePref;
    const age = data.search.age;
    const setting = data.search.settingPref;
    const insurance = data.insurance.primary?.id ?? 0;
    const plan = data.insurance.primary?.planId;
    const lat = data.demographics?.address?.coordinates?.lat;
    const long = data.demographics?.address?.coordinates?.long;
    let condition = data.appt?.conditionId ?? data.search.conditionId;
    const gender = data.search.genderPref;
    const language = data.search.languagePref;

    let insuranceName: string|undefined = undefined;
    let planName: string|undefined = undefined;
    if (insurance > 0) {
      const insuranceItem = this.insuranceData.snapshot().filter(i => i.id === insurance)[0];
      insuranceName = insuranceItem?.name ?? insurance.toString();
      if (plan) {
        planName = insuranceItem?.plans?.filter(p => p.id === plan)[0]?.name ?? plan.toString();
      }
    }

    let conditionName: string|undefined = undefined;
    if (condition) {
      if (condition === -1) {
        conditionName = 'General Evaluation';
      } else {
        conditionName = this.conditions.snapshot().filter(c => c.id === condition)[0]?.name ?? condition.toString();
      }
    }

    const dimensions = {
      [DimensionTypes.State]: state,
      [DimensionTypes.TypeOfCare]: typeOfCare,
      [DimensionTypes.Age]: age,
      [DimensionTypes.ApptSettingFilter]: setting,
      [DimensionTypes.Insurance]: insuranceName,
      [DimensionTypes.Plan]: planName,
      [DimensionTypes.Latitude]: lat?.toString(),
      [DimensionTypes.Longitude]: long?.toString(),
      [DimensionTypes.Condition]: conditionName,
      [DimensionTypes.GenderFilter]: gender,
      [DimensionTypes.LanguageFilter]: language
    };

    Object.entries(dimensions).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        this.customDimensions.setCustomDimensionValue(key, value);
      } else {
        this.customDimensions.deleteCustomDimension(key);
      }
    });
  }
}

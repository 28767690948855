import {Component, computed, effect, HostListener, inject, signal, untracked} from '@angular/core';
import {Params, Router, RouterOutlet} from '@angular/router';
import {FlexLayoutModule, FlexModule} from "@ngbracket/ngx-layout";
import {
  CardComponent,
  DimmerComponent,
  InnerScrollableComponent, MaintenanceComponent, MessageCode,
  ProgressBarComponent, StateAbvs, StatesArray
} from "@mindpath/shared";
import {JsonPipe, NgComponentOutlet} from "@angular/common";
import {TemplateFor} from "@mindpath/shared";
import {screenSize} from "@mindpath/shared";
import {PrimeNGConfig} from "primeng/api";
import {Aura} from "primeng/themes/aura";
import {
  BookingSteps,
  ErrorCodeDisplays,
  OnlineSchedulerService, PrefillSteps,
  SelectedAppointmentInfo
} from "./online-scheduler.service";
import {FindApptSectionComponent} from "./sections/find-appt-section/find-appt-section.component";
import {PrefillSectionComponent} from "./sections/prefill-section/prefill-section.component";
import {BookingSectionComponent} from "./sections/booking-section/booking-section.component";
import {ActionType} from "@mindpath/shared";
import {UnsafeAllowHtmlPipe} from "@mindpath/shared";
import {
  InsuranceFilterComponent
} from "./sections/find-appt-section/search-page/search-filter-bar/insurance-filter/insurance-filter.component";
import {InsuranceDataService} from "./insurance-data.service";
import {States} from "@mindpath/shared";
import {
  LocationFilterComponent
} from "./sections/find-appt-section/search-page/search-filter-bar/location-filter/location-filter.component";
import {listenForKeyboardCode} from "@mindpath/shared";
import { config } from '../config';
import {Logger} from "@mindpath/shared";
import {injectQueryParams} from "ngxtension/inject-query-params";
import DateUtils from "../../../shared/src/lib/utils/date.utils";
import {AppointmentsDataService} from "./appointments-data.service";
import {CliniciansDataService} from "./clinicians-data.service";
import {AppendAppStateParamsPipe} from "./pipes/append-app-state-params.pipe";
import {AppStateService} from "./app-state.service";


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FlexModule, InnerScrollableComponent, NgComponentOutlet, TemplateFor,
    CardComponent,
    UnsafeAllowHtmlPipe,
    MaintenanceComponent,
    DimmerComponent, ProgressBarComponent, FindApptSectionComponent, PrefillSectionComponent, BookingSectionComponent, JsonPipe, InsuranceFilterComponent, LocationFilterComponent, AppendAppStateParamsPipe, FlexLayoutModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Accord';


  queryParams = injectQueryParams();

  primeNgConfig: PrimeNGConfig = inject(PrimeNGConfig);
  finder = inject(OnlineSchedulerService);
  appState = inject(AppStateService);
  clinicianSvc = inject(CliniciansDataService);
  appointmentSvc = inject(AppointmentsDataService);
  router = inject(Router);

  dimmerMsg = computed(() => {
    const dimmer = this.finder.dimming();
    const isStopMsg = dimmer.asStopMessage;
    const title = dimmer.title;
    const message = dimmer.message;

    if (isStopMsg) {
      return {title: undefined, message: undefined};
    }
    return {title, message};
  });
  insuranceData = inject(InsuranceDataService);
  stateFullName = computed(() => {
    const state = this.finder.data.demographics()?.address?.state;
    if (!state) {
      return '';
    }
    try {
      this.insuranceData.getInsuranceCompanies(state);
    } catch (e) {
      Logger.Error('AppComponent', e);
    }
    return (States as any)[state as any];
  });
  selectedInsuranceItem = computed(() => {
    const insurances = this.insuranceData.snapshot();
    const id = this.finder.data.insurance().primary?.id;
    // console.log('Selected Insurance Item', id, insurances);
    if (!id) {
      return null;
    }
    const found = insurances.find(i => i.id === id);
    if (found) {
      return found;
    }
    this.insuranceData.getInsuranceCompany(id).then(c => {
      // console.log('Insurance Company', c);
    });
    return null;
  });
  protected readonly screenSize = screenSize;
  protected readonly ActionType = ActionType;


  canShowBackBtn = computed(() => {
    const stopPage = this.appState.stopPageData();
    const meta = this.finder.data.meta();

    if (stopPage) {
      return false;
    }

    if (meta.section === 'find') {
      return false;
    }
    if (meta.section === 'prefill' && meta.prefill.step === 'location') {
      return false;
    }
    if (meta.section === 'book') {
      if (meta.booking.step === 'final' || (meta.booking.step === 'eligibility' && meta.entryPoint === 'appointment')) {
        return false;
      }
    }

    return true;

  });

  totalStepCount = computed(() => {
    const meta = this.finder.data.meta();
    if (meta.entryPoint === 'appointment') {
      return BookingSteps.length;
    }
    return PrefillSteps.length + 1 + BookingSteps.length;
  });

  currentStepNumber = computed(() => {
    const meta = this.finder.data.meta();

    if (meta.section === 'find') {
      return PrefillSteps.length;
    }
    if (meta.section === 'prefill') {
      return PrefillSteps.findIndex(s => s === meta.prefill.step);
    }
    if (meta.section === 'book') {
      const num = BookingSteps.findIndex(s => s === meta.booking.step);
      return meta.entryPoint === 'appointment' ? num : num + PrefillSteps.length + 1;
    }
    return 0;
  });


  async #loadAppointmentFromParams(query: Params) {

    window.history.replaceState({}, document.title, window.location.pathname);


    const tryNumber = (val: unknown) => {
      if (typeof val === 'string') {
        val = parseInt(val, 10);
      }
      if (typeof val === 'number') {
        return val > 0 ? val : null;
      }
      return null;
    }


    // http://localhost:4200/?i=1&ip=2&cl=1902327075&ad=2024-11-07&as=Telehealth&at=08:30&al=219&s=CA
    const insuranceId = tryNumber(query['i']);

    // if there is no insurance id, there is nothing to do as that is needed from the main site to view a clinicians schedule
    //   hence we can just return as they came in through plain "schedule now" main page link
    if (!insuranceId) {
      // this.router.navigate(['/']);
      return;
    }
    this.finder.reset();
    // this.router.navigate(['/'], { queryParams: {}, replaceUrl: true });


    // this.router.navigate(['/']);

    let state: string | undefined = query['s'];
    state = StateAbvs.includes(state?.toUpperCase() as any) ? state : undefined;
    // if the state was not passed or is not a valid state, we should not proceed (technically we could get it by loading the location, but that is not the point of this page. We want all params to be passed in for simplicity sake)
    if (!state) {
      this.finder.showError(MessageCode.Unknown_Browser_Exception, 'Unable to retrieve appointment', 'There was an error retrieving the appointment');
      console.error('State not passed or invalid');
      return;
    }

    try {
      this.finder.showLoading('Loading Appointment', 'Please wait while we load the selected appointment');
      this.finder.patch('meta.entryPoint', 'appointment');

      const planId = tryNumber(query['p']);
      const apptDate = DateUtils.tryParseDate(query['ad']);
      const clinicianNpi = tryNumber(query['cl']);
      const apptTime = DateUtils.tryParseTime(query['at']);
      let apptSetting: string | undefined = query['as']?.toLowerCase();
      const apptLocationId = tryNumber(query['al']);

      if (apptSetting === 'inperson') {
        apptSetting = 'InPerson';
      } else if (apptSetting === 'telehealth') {
        apptSetting = 'Telehealth';
      } else {
        apptSetting = undefined;
      }

      const hasSuppliedAll = !!apptDate && !!apptTime && !!apptSetting && !!apptLocationId && !!state && !!clinicianNpi;
      // if we have not received all the required params, we should not proceed
      if (!hasSuppliedAll) {

        this.finder.showError(MessageCode.Unknown_Browser_Exception, 'Unable to retrieve appointment', 'There was an error retrieving the appointment');
        console.error('Missing required params', {apptDate, apptTime, apptSetting, apptLocationId, state, clinicianNpi});
        return;
      }
      this.finder.patch('demographics.address.state', state as any);
      this.finder.data.insurance.set({
        primary: {id: insuranceId, planId: planId} as any,
        secondary: null,
      });


      const dateTime = DateUtils.fromDateAndTime(apptDate!, apptTime!);
      const appt = await this.appointmentSvc.tryFindByDetails({
        apptDateTime: dateTime,
        clinicianNpi: clinicianNpi,
        locationId: apptLocationId!,
        setting: apptSetting! as any,
        state: state as any
      });

      if (!appt) {
        this.finder.showError(MessageCode.Unknown_Browser_Exception, 'Unable to retrieve appointment', 'There was an error retrieving the appointment');
        console.error('Appointment not found');
        return;
      }

      const slot = appt.days![0]!.slots![0]!;

      const clinician = await this.clinicianSvc.getSingle(appt.clinician.npi);

      this.finder.data.appt.set({
        clinician: clinician!,
        slot,
        originalSetting: slot.setting,
        chosenSetting: apptSetting as any,
        dateTime,
        duration: slot.duration,
        location: appt.location!,
        conditionId: null!,
        typeOfCare: null!,
      });

      this.finder.goto('book', 'eligibility');
      this.finder.hideLoading();
    } catch (e) {
      this.finder.showError(MessageCode.Unknown_Browser_Exception, 'Unable to retrieve appointment', 'There was an error retrieving the appointment');
      console.error(e);
    }
  }

  constructor() {

    this.primeNgConfig.theme.set({
      preset: Aura,
      options: {
        darkModeSelector: '.my-app-dark',
        cssLayer: true,
      }
    });
    this.#loadAppState();

    const startingState = this.finder.data.demographics()?.address?.state;
    let lastState = startingState;
    effect(() => {
      const state = this.finder.data.demographics()?.address?.state;
      // console.log('State Changed', state, lastState);
      if (state !== lastState) {
        lastState = state;
        if (state) {
          try {
            this.insuranceData.getInsuranceCompanies(state);
          } catch (e) {
            Logger.Error('AppComponent', e);
          }
        }
        this.finder.data.insurance.set({
          primary: null,
          secondary: null
        });
      }
    }, {allowSignalWrites: true});

  }

  async #loadAppState() {
    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());

    await this.#loadAppointmentFromParams(queryParams);


  }

  openSidebar(rtmplat: any) {
    this.finder.sidebar.set({
      template: rtmplat,
      resetHook: () => {
      }
    })
  }


  @HostListener('document:keyup', ['$event'])
  listenForMaintenanceBackdoor = listenForKeyboardCode('--letmein', () => {
    config.inMaintenance.set(false);
  });
  protected readonly config = config;

  // Listen for click events on the document to check for external link clicks
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = (event.target as HTMLElement).closest('a');
    if (target && target.href && !target.href.startsWith(window.location.origin)) {
      if (!this.promptLeaveConfirm()) {
        event.preventDefault();
      }
    }
  }

  // Listen for history navigation events (back/forward buttons)
  @HostListener('window:popstate')
  onPopState(event: PopStateEvent) {

    if (!this.promptLeaveConfirm()) {
      event.preventDefault();
    }
  }

  promptLeaveConfirm() {
    const meta = this.finder.data.meta();
    const section = meta.section;
    if (section !== 'find' && section !== 'book') {
      return true;
    }
    if (section === 'book' && meta.booking.step === 'final') {
      return true;
    }
    return confirm('You will lose your progress if you leave this page. Are you sure you want to leave?');
  }
}

<lib-card [class.focused]="isFocused()" [cardStyle]="cardStyle()">
  <ng-template templateFor="title">
    <span class="text-primary" style="font-size: var(--size-18)"> <span >{{ date() | date:'EEEE':'UTC' }}</span>, <h4 class="heading-4">{{ date() | date:'MMMM d':'UTC' }}</h4></span>
  </ng-template>
  <ng-template templateFor="content">
    <div class="p-12">
      <app-slot-listing-component
        [slots]="slots()"
        [showNoAvailability]="false" (slotClicked)="slotClicked.emit($event)"></app-slot-listing-component>
    </div>
  </ng-template>
</lib-card>
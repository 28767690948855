import { Pipe, PipeTransform } from '@angular/core';
import {Address, Schema} from "../models";

@Pipe({
  name: 'address',
  standalone: true
})
export class AddressPipe implements PipeTransform {

  transform(address: Schema<'Address'>|Address, options?: boolean|{inline?: boolean, align?: 'left'|'right'}): string {

    if (typeof options === 'boolean') {
      options = {inline: options};
    }

    return FormatAddress(address, options);
  }

}


export function FormatAddress(address: Schema<'Address'>|Address, options?: {inline?: boolean, align?: 'left'|'right'}): string {
  if (!address) {
    return '';
  }
  const align = options?.align ? ' text-' + options.align : '';

  const address1 = address.address1?.toProperCase();
  const address2 = address.address2?.toProperCase();
  const city = address.city?.toProperCase();
  const state = address.state.toUpperCase();
  const zip = address.zip;

  if (options?.inline) {
    const parts = [address1, address2, city, state, zip];
    return parts.filter(p => p).join(', ');
  }
  const topParts = [address1, address2];
  const bottomParts = [city, state, zip];
  const top = topParts.filter(p => p).join(', ');
  const bottom = bottomParts.filter(p => p).join(' ');
  let content = '';
  if (top) {
    content += `<span class="text${align}">${top}</span>`;
  }
  if (bottom) {
    if (top) {
      content += '<br/>';
    }
    content += `<span class="text${align}">${bottom}</span>`;
  }
  return content;
}

declare global {
  interface String {
    toProperCase(): string;
  }
}

String.prototype.toProperCase = function() {
  return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}


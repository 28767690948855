import {
  AfterContentInit,
  AfterViewInit,
  Component,
  contentChildren, ElementRef, Injectable,
  signal,
  TemplateRef,
  viewChild
} from '@angular/core';
import {TemplateFor} from "../../templating";
import {FlexModule} from "@ngbracket/ngx-layout";
import {NgTemplateOutlet} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ViewScrolling {

  scrollTo: (options?: ScrollToOptions) => void = (options) => {
    window.scrollTo(options ?? {top: 0, behavior: 'smooth'});
  }
}

@Component({
  selector: 'lib-inner-scrollable',
  standalone: true,
  imports: [
    FlexModule,
    NgTemplateOutlet
  ],
  templateUrl: './inner-scrollable.component.html',
  styleUrl: './inner-scrollable.component.css',
  providers: [ViewScrolling]
})
export class InnerScrollableComponent implements AfterContentInit {

  templates = contentChildren(TemplateFor);
  header = signal<TemplateRef<any>|undefined>(undefined);
  footer = signal<TemplateRef<any>|undefined>(undefined);

  contentElement = viewChild.required<ElementRef<HTMLDivElement>>('content');

  constructor(scrolling: ViewScrolling) {
    scrolling.scrollTo = this.scrollTo.bind(this);
  }

  scrollTo(options?: ScrollToOptions) {
    this.contentElement().nativeElement.scrollTo(options);
  }

  scrollToTop() {
    this.scrollTo({top: 0, behavior: 'smooth'});
  }

  ngAfterContentInit() {
    TemplateFor.Bind(this, this.templates());
  }
}


<div class="input flex flex-column {{customClass()}}" row-gap="8" style="width: 100%;">
  <span class="input-label-container">
    <label class="input-label heading-5" [class.valid]="valid()" [class.invalid]="!valid()" [class.required]="requiredIndicator() && required()">
      <ng-content select="[slot='label']">
        <span [innerHTML]="label()"></span>
      </ng-content>
      @if(!required() && optionalIndicator()) {
        <span class="optional-indicator sub-label text-body-secondary">(optional)</span>
      }
    </label>
    @if(tooltip()?.trim()?.length) {
      <span [pTooltip]="tooltip()!" [autoHide]="false" [hideDelay]="350" class="pl-8">
        <lib-icon [size]="8" name="?" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"  verticalAlign="middle"></lib-icon>
      </span>
    }
  </span>
  <div class="input-field" [class.valid]="valid()" [class.invalid]="!valid()" flex-children-equal>
    <ng-content></ng-content>
  </div>

</div>

import {computed, effect, Signal, signal, WritableSignal} from "@angular/core";


export interface Options {
  readonly cacheKey?: string;
}

const pendingSymbol = Symbol();

function isPendingItem<T>(item: T|PendingItem<T>): item is PendingItem<T> {
  return typeof item === 'object' && item && pendingSymbol in item;
}

interface PendingItem<T> {
  readonly promise: Promise<T>;
  cancellation?: () => void;
  [pendingSymbol]: true;
}

export function WrapAsCachedPromise<TFunc extends (...args: any[]) => Promise<T>, T>(func: TFunc): TFunc {
  let cachedPromiseByArgs: {args: any[], promise: Promise<T>}[] = [];
  const findByArgs = (args: any[]): {args: any[], promise: Promise<T>} | undefined => {
    return cachedPromiseByArgs.find(i => i.args.length === args.length && i.args.every((a, i) => a === args[i]));
  }


  return new Proxy(func, {
    apply: (target, thisArg, args) => {
      let cachedPromise = findByArgs(args);
      if (cachedPromise) {
        return cachedPromise.promise;
      }
      cachedPromise = {
        args,
        promise: target.apply(thisArg, args)
      }
      cachedPromiseByArgs.push(cachedPromise);
      cachedPromise.promise.then(() => {
        cachedPromiseByArgs = cachedPromiseByArgs.filter(i => i !== cachedPromise);
      });
      return cachedPromise.promise;
    }
  });
}

export class PendingDataCollection<T extends {id: string|number}> {

  private _snapshot: WritableSignal<ReadonlyArray<T|PendingItem<T>>> = signal([]);

  constructor(private readonly options?: Options) {
    if (options?.cacheKey) {
      try {
        const json = sessionStorage.getItem(options.cacheKey);
        if (json) {
          const items = JSON.parse(json) as T[];
          if (Array.isArray(items)) {
            this._snapshot.set(items);
          } else {
            console.error(`Invalid data in local storage for ${options.cacheKey}`);
          }
        }
      } catch (e) {
        console.error(`Error loading data from local storage for ${options.cacheKey}`, e);
      }
    }

    effect(() => {
      this.#save(this.resolved());
    });
  }

  #save(items: ReadonlyArray<T>): void {
    if (this.options?.cacheKey) {
      const json = JSON.stringify(items);
      sessionStorage.setItem(this.options.cacheKey, json);
    }
  }

  public remove(item: T|Promise<T>): void {
    this._snapshot.update(items => items.filter(i => i !== item));
  }

  #mapToItem = (item: T|Promise<T>): T|PendingItem<T> => {
    if (item instanceof Promise) {
      return {
        promise: item,
        [pendingSymbol]: true
      };
    }
    return item;
  }

  public replace(items: readonly (T|Promise<T>)[]): void {
    const mapped = items.map(this.#mapToItem);
    this._snapshot.set(mapped);
    mapped.filter(isPendingItem).forEach(pendingItem => {
      pendingItem.promise.then(resolvedItem => {
        this._snapshot.update(items => items.map(i => i === pendingItem ? resolvedItem : i));
      });
    });
  }

  public upsert(item: Promise<T>): Promise<T>;
  public upsert(item: T): T;
  public upsert(item: readonly Promise<T>[]): Promise<T>[];
  public upsert(item: readonly T[]): T[];
  public upsert(item: T|Promise<T>|(readonly (T|Promise<T>)[])): T|Promise<T>|((T|Promise<T>)[]) {

    if (Array.isArray(item)) {
      const mapped = item.map(this.#mapToItem);
      this._snapshot.update(items => {
        const indexes = mapped.map(item => isPendingItem(item) ? -1 : items.findIndex(i => isPendingItem(i) ? -1 : i.id === item.id));
        const newItems = [...items];
        indexes.forEach((index, i) => {
          if (index === -1) {
            newItems.push(mapped[i]);
          } else {
            newItems[index] = mapped[i];
          }
        });
        return newItems;
      });
      mapped.filter(isPendingItem).forEach(pendingItem => {
        pendingItem.promise.then(resolvedItem => {
          this._snapshot.update(items => {
            const existingIndex = items.findIndex(i => isPendingItem(i) ? -1 : i.id === resolvedItem.id);
            if (existingIndex !== -1) {
              const newItems = [...items];
              newItems[existingIndex] = resolvedItem;
              return newItems;
            }
            return items.map(i => i === pendingItem ? resolvedItem : i);
          });
        });
      });
      return item;
    }

    const mapped = this.#mapToItem(item as T|Promise<T>);

    if (isPendingItem(mapped)) {
      mapped.promise.then(resolvedItem => {
        this._snapshot.update(items => {
          const index = items.findIndex(i => isPendingItem(i) ? -1 : i.id === resolvedItem.id);
          if (index !== -1) {
            const newItems = [...items];
            newItems[index] = resolvedItem;
            return newItems;
          }
          return items.map(i => i === mapped ? resolvedItem : i);
        });
      });
      return mapped.promise;
    }

    this._snapshot.update(items => {
      const index = items.findIndex(i => isPendingItem(i) ? -1 : i.id === mapped.id);
      if (index !== -1) {
        const newItems = [...items];
        newItems[index] = mapped;
        return newItems;
      }
      return [...items, mapped];
    });

    return mapped;
  }

  public readonly snapshot = this._snapshot.asReadonly();

  public readonly resolved = computed<ReadonlyArray<T>>(() => {
    return this.snapshot().filter(item => typeof item !== 'object' || !item || !(pendingSymbol in item)) as T[];
  });

  public readonly pending = computed<ReadonlyArray<Promise<T>>>(() => {
    return this.snapshot().filter(item => typeof item === 'object' && item && pendingSymbol in item).map(item => (item as PendingItem<T>).promise);
  });
}
import {APP_INITIALIZER, computed, Provider, Signal, signal} from "@angular/core";
import {MediaMarshaller, MediaObserver, BreakPoint} from "@ngbracket/ngx-layout";

const breakpoints = signal<BreakPoint[]>([]);
const observer = signal<MediaObserver|null>(null);
const marshaller = signal<MediaMarshaller|null>(null);
export const screenSize: Signal<any> = computed(() => {
  // breakpoints are just used to track changes since MediaObserver ovservable only fires on activations
  const bp = breakpoints();
  const obs = observer();
  const mshlr = marshaller();
  if (!obs) {
    return {
      is: (val: string|readonly string[]) => false
    }
  }
  return ({
    is: (val: string|readonly string[]) => obs.isActive(val as string|string[]),
    toString: () => mshlr?.activatedAlias ?? null,
    [Symbol.toPrimitive]: () => mshlr?.activatedAlias ?? null,
    valueOf: () => mshlr?.activatedAlias ?? null,
  });
})

export function provideScreenSizeVars() {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (obs: MediaObserver, mshlr: MediaMarshaller) => {
      return () => {
        observer.set(obs);
        marshaller.set(mshlr);

        obs.asObservable().subscribe(() => {
          // console.log('size change', mshlr.activatedBreakpoints, mshlr.activatedAlias);
          breakpoints.set(mshlr.activatedBreakpoints.slice());
        })
      };
    },
    deps: [MediaObserver, MediaMarshaller]
  } satisfies Provider
}
<ng-container>
  <button class="filter-pill" [class.selected]="!!language()" type="button" (click)="openSideBar(sideBar)">


    @if (language(); as lang) {
      {{ lang }}
    } @else {
      Language
    }
  </button>
</ng-container>

<ng-template #sideBar>
  <section>

    <h4>Clinician's Language</h4>
    <ul>

      @for (opt of languages; track opt) {
        <li class="pt-12">
          <lib-bar style="padding-top: 8px" customClass="{{language() === opt ? 'selected' : ''}}"
                   (click)="changeLang(language() === opt ? null : opt)">
            <span fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                <span fxLayout="row" fxLayoutAlign="start center" gap="12" class="bar-header">
                  {{ opt }}
                </span>
              @if (language() === opt) {
                <span class="text-primary">
                  <lib-icon verticalAlign="middle" name="checkmark" [type]="IconType.Success"></lib-icon>
                </span>
              }
            </span>
          </lib-bar>
        </li>
      }
    </ul>
  </section>
</ng-template>

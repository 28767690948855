import {Component, computed, inject, input, signal} from '@angular/core';
import {OnlineSchedulerService} from "../../online-scheduler.service";
import {EligibilityComponent} from "./steps/eligibility/eligibility.component";
import {AppointmentDetailsCheckComponent} from "./steps/appointment-details-check/appointment-details-check.component";
import {GeneralInfoDemographicsComponent} from "./steps/general-info-demographics/general-info-demographics.component";
import {ContactInfoDemographicsComponent} from "./steps/contact-info-demographics/contact-info-demographics.component";
import {DeviceVerificationComponent} from "./steps/device-verification/device-verification.component";
import {InsuranceInfoCaptureComponent} from "./steps/insurance-info-capture/insurance-info-capture.component";
import {InsuranceCardsCaptureComponent} from "./steps/insurance-cards-capture/insurance-cards-capture.component";
import {BookingConfirmationComponent} from "./steps/booking-confirmation/booking-confirmation.component";
import {InsuranceDataService} from "../../insurance-data.service";
import {MessageCode} from "@mindpath/shared";
import {AppStateService} from "../../app-state.service";

@Component({
  selector: 'app-booking-section',
  standalone: true,
  imports: [
    EligibilityComponent,
    AppointmentDetailsCheckComponent,
    GeneralInfoDemographicsComponent,
    ContactInfoDemographicsComponent,
    DeviceVerificationComponent,
    InsuranceInfoCaptureComponent,
    InsuranceCardsCaptureComponent,
    BookingConfirmationComponent,
  ],
  templateUrl: './booking-section.component.html',
  styleUrl: './booking-section.component.scss'
})
export class BookingSectionComponent {

  finder = inject(OnlineSchedulerService);
  insuranceDataSvc = inject(InsuranceDataService);
  appState = inject(AppStateService);
  step = computed(() => this.finder.data.meta().booking?.step ?? 'eligibility');

  loading = signal(true);

  constructor() {
    this.checkIfCanBook();
  }


  async checkIfCanBook() {
    try {
      const insuranceInfo = this.finder.data.insurance();
      if (!insuranceInfo.primary?.id || !insuranceInfo.primary?.planId) {
        return;
      }

      const insurance = await this.insuranceDataSvc.getInsuranceCompany(insuranceInfo.primary.id);
      if (!insurance) {
        return;
      }
      const plan = insurance.plans!.find(p => p.id === insuranceInfo.primary?.planId);

      if (!plan || plan.bookingStatus === 'OkayToSchedule') {
        return;
      }

      if (plan.bookingStatus === 'NeedsVerification') {
        this.appState.stopPageData.set({
          title: 'Additional information required',
          message: `Call <a class="link blend pk_insurance-details_intake"
                    href="tel:855-501-1004">855-501-1004</a> to verify your insurance.`,
          action: {
            label: 'Return To Clinicians',
            onClick: () => {
              this.finder.goto('find');
              this.appState.stopPageData.set(null);
            }
          }
        });
      }

      if (plan.bookingStatus === 'DoNotSchedule') {
        this.appState.stopPageData.set({
          title: 'Additional information required',
          message: `Online scheduling is currently not available for this plan. Call us to schedule your appointment at <a class="link blend pk_insurance-details_intake" href="tel:855-501-1004">855-501-1004</a>. Be sure to have your insurance information ready.`,
          action: {
            label: 'Return To Clinicians',
            onClick: () => {
              this.finder.goto('find');
              this.appState.stopPageData.set(null);
            }
          }
        });

      }
    } catch (e: any) {
      console.error(e);
    } finally {
      this.loading.set(false);
    }

  }
}

import * as i0 from '@angular/core';
import { Injectable, inject, booleanAttribute, Directive, Optional, Input, HostListener, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
import { ObjectUtils } from 'primeng/utils';
import * as i1 from '@angular/forms';
const theme = ({
  dt
}) => `
.p-inputtext {
    font-family: inherit;
    font-feature-settings: inherit;
    font-size: 1rem;
    color: ${dt('inputtext.color')};
    background: ${dt('inputtext.background')};
    padding: ${dt('inputtext.padding.y')} ${dt('inputtext.padding.x')};
    border: 1px solid ${dt('inputtext.border.color')};
    transition: background ${dt('inputtext.transition.duration')}, color ${dt('inputtext.transition.duration')}, border-color ${dt('inputtext.transition.duration')}, outline-color ${dt('inputtext.transition.duration')}, box-shadow ${dt('inputtext.transition.duration')};
    appearance: none;
    border-radius: ${dt('inputtext.border.radius')};
    outline-color: transparent;
    box-shadow: ${dt('inputtext.shadow')};
}

.p-inputtext:enabled:hover {
    border-color: ${dt('inputtext.hover.border.color')};
}

.p-inputtext:enabled:focus {
    border-color: ${dt('inputtext.focus.border.color')};
    box-shadow: ${dt('inputtext.focus.ring.shadow')};
    outline: ${dt('inputtext.focus.ring.width')} ${dt('inputtext.focus.ring.style')} ${dt('inputtext.focus.ring.color')};
    outline-offset: ${dt('inputtext.focus.ring.offset')};
}

.p-inputtext.p-invalid {
    border-color: ${dt('inputtext.invalid.border.color')};
}

.p-inputtext.p-variant-filled {
    background: ${dt('inputtext.filled.background')};
}

.p-inputtext.p-variant-filled:enabled:focus {
    background: ${dt('inputtext.filled.focus.background')};
}

.p-inputtext:disabled {
    opacity: 1;
    background: ${dt('inputtext.disabled.background')};
    color: ${dt('inputtext.disabled.color')};
}

.p-inputtext::placeholder {
    color: ${dt('inputtext.placeholder.color')};
}

.p-inputtext-sm {
    font-size: ${dt('inputtext.sm.font.size')};
    padding: ${dt('inputtext.sm.padding.y')} ${dt('inputtext.sm.padding.x')};
}

.p-inputtext-lg {
    font-size: ${dt('inputtext.lg.font.size')};
    padding: ${dt('inputtext.lg.padding.y')} ${dt('inputtext.lg.padding.x')};
}

.p-inputtext-fluid {
    width: 100%;
}

/* For PrimeNG */
.p-inputtext.ng-invalid.ng-dirty {
    border-color: ${dt('inputtext.invalid.border.color')};
}
`;
const classes = {
  root: ({
    instance,
    props
  }) => ['p-inputtext p-component', {
    'p-filled': instance.filled,
    'p-inputtext-sm': props.size === 'small',
    'p-inputtext-lg': props.size === 'large',
    'p-invalid': props.invalid,
    'p-variant-filled': props.variant === 'filled',
    'p-inputtext-fluid': props.fluid
  }]
};
class InputTextStyle extends BaseStyle {
  name = 'inputtext';
  theme = theme;
  classes = classes;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵInputTextStyle_BaseFactory;
    return function InputTextStyle_Factory(__ngFactoryType__) {
      return (ɵInputTextStyle_BaseFactory || (ɵInputTextStyle_BaseFactory = i0.ɵɵgetInheritedFactory(InputTextStyle)))(__ngFactoryType__ || InputTextStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: InputTextStyle,
    factory: InputTextStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputTextStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * InputText renders a text field to enter data.
 *
 * [Live Demo](https://www.primeng.org/inputtext/)
 *
 * @module inputtextstyle
 *
 */
var InputTextClasses;
(function (InputTextClasses) {
  /**
   * The class of root element
   */
  InputTextClasses["root"] = "p-inputtext";
})(InputTextClasses || (InputTextClasses = {}));

/**
 * InputText directive is an extension to standard input element with theming.
 * @group Components
 */
class InputText extends BaseComponent {
  ngModel;
  /**
   * Specifies the input variant of the component.
   * @group Props
   */
  variant = 'outlined';
  /**
   * Spans 100% width of the container when enabled.
   * @group Props
   */
  fluid;
  /**
   * Defines the size of the component.
   * @group Props
   */
  size;
  filled;
  _componentStyle = inject(InputTextStyle);
  get hasFluid() {
    const nativeElement = this.el.nativeElement;
    const fluidComponent = nativeElement.closest('p-fluid');
    return ObjectUtils.isEmpty(this.fluid) ? !!fluidComponent : this.fluid;
  }
  constructor(ngModel) {
    super();
    this.ngModel = ngModel;
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.updateFilledState();
    this.cd.detectChanges();
  }
  ngDoCheck() {
    this.updateFilledState();
  }
  onInput() {
    this.updateFilledState();
  }
  updateFilledState() {
    this.filled = this.el.nativeElement.value && this.el.nativeElement.value.length || this.ngModel && this.ngModel.model;
  }
  static ɵfac = function InputText_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || InputText)(i0.ɵɵdirectiveInject(i1.NgModel, 8));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: InputText,
    selectors: [["", "pInputText", ""]],
    hostAttrs: [1, "p-inputtext", "p-component"],
    hostVars: 10,
    hostBindings: function InputText_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("input", function InputText_input_HostBindingHandler($event) {
          return ctx.onInput($event);
        });
      }
      if (rf & 2) {
        i0.ɵɵclassProp("p-filled", ctx.filled)("p-variant-filled", ctx.variant === "filled" || ctx.config.inputStyle() === "filled")("p-inputtext-fluid", ctx.hasFluid)("p-inputtext-sm", ctx.size === "small")("p-inputtext-lg", ctx.size === "large");
      }
    },
    inputs: {
      variant: "variant",
      fluid: [2, "fluid", "fluid", booleanAttribute],
      size: "size"
    },
    features: [i0.ɵɵProvidersFeature([InputTextStyle]), i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputText, [{
    type: Directive,
    args: [{
      selector: '[pInputText]',
      host: {
        class: 'p-inputtext p-component',
        '[class.p-filled]': 'filled',
        '[class.p-variant-filled]': 'variant === "filled" || config.inputStyle() === "filled"',
        '[class.p-inputtext-fluid]': 'hasFluid',
        '[class.p-inputtext-sm]': 'size === "small"',
        '[class.p-inputtext-lg]': 'size === "large"'
      },
      providers: [InputTextStyle]
    }]
  }], () => [{
    type: i1.NgModel,
    decorators: [{
      type: Optional
    }]
  }], {
    variant: [{
      type: Input
    }],
    fluid: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    size: [{
      type: Input
    }],
    onInput: [{
      type: HostListener,
      args: ['input', ['$event']]
    }]
  });
})();
class InputTextModule {
  static ɵfac = function InputTextModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || InputTextModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: InputTextModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputTextModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [InputText],
      declarations: [InputText]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { InputText, InputTextClasses, InputTextModule, InputTextStyle };

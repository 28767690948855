import {computed, inject, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'unsafeAllowHtml',
  standalone: true
})
export class UnsafeAllowHtmlPipe implements PipeTransform {

  protected sanitizer = inject(DomSanitizer);
  transform(value: string|null|undefined) {
    if (!value) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}

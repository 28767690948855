import {computed, Injectable, signal, WritableSignal} from "@angular/core";
import {
  IApiEndpoint,
  IApiEndpointRouteParams, loadApiEndpoint,
  loadApiEndpointResp,
  PossibleResponse,
  PossibleResponseCode,
  Schema, SchemaCondition
} from "@mindpath/shared";
import {config} from "../config";
import { Logger } from "@mindpath/shared";
import {Pending} from "../../../shared/src/lib/components/step-number-bar/step-number.stories";
import {PendingDataCollection, WrapAsCachedPromise} from "./utils/pending-data";


@Injectable({
  providedIn: 'root'
})
export class ConditionsDataService {


  readonly #cached = new PendingDataCollection<Schema<'Condition'>>({cacheKey: 'conditions'});
  constructor() {
    this.getAllConditions = WrapAsCachedPromise(this.getAllConditions);
    this.getCondition = WrapAsCachedPromise(this.getCondition);
    this.getAllConditions().then(() => {}).catch(e => {
      Logger.Error('ConditionsDataService', e);
    });
  }

  readonly snapshot = computed(() => {
    const items = this.#cached.resolved();
    return this.#sortItems(items);
  })

  #sortItems(items: readonly Schema<'Condition'>[]) {
    return items.toSorted((a, b) => {
      const aSort = a.sortOrder;
      const bSort = b.sortOrder;
      if (aSort && bSort) {
        return aSort - bSort;
      }
      if (aSort) {
        return -1;
      }
      if (bSort) {
        return 1;
      }
      return a.name!.localeCompare(b.name!);
    });
  }

  async getAllConditions(): Promise<Schema<'Condition'>[]|null> {


    let hasMore = true;
    const items:  SchemaCondition[] = [];
    do {
      const page = await loadApiEndpoint(config.endpoints.api, '/api/Conditions').get({});
      items.push(...page.items);
      hasMore = page.hasMore!;
    } while (hasMore);
    this.#cached.replace(items);
    return this.#sortItems(items);

  }

  async getCondition(path: IApiEndpointRouteParams<'/api/Conditions/{id}'>['get']): Promise<PossibleResponseCode<'/api/Conditions/{id}', 'get', 200>|null> {
    const cached = this.#cached.resolved().find(c => c.id === path.id);
    if (cached) {
      return cached;
    }
    return this.#cached.upsert(loadApiEndpoint(config.endpoints.api, '/api/Conditions/{id}').get({
      path
    }))
  }
}

type responses = PossibleResponse<'/api/Conditions/{id}', 'get'>

type ConditionEndpoints = IApiEndpoint<'/api/Conditions/{id}'>

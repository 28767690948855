@if ((dimmed())) {
  <div class="backdrop-blur-xs bg-tan/{{opacity()}} dimmer {{position()}}" fxLayout="row" fxFlex
       [class.fullscreen]="fullscreen()"
       [fxLayoutAlign]="fxLayoutAlign()"
       [@enterAnimation]>

    <div class="content" row-gap="16" fxLayout="column" fxFlex fxLayoutAlign="start center">
      <ng-content></ng-content>
      @if (title()) {
        <h2 class="title heading-1 text-center pb-16 border-b-2" [innerHTML]="title()"></h2>
      }
      @if (message()) {
        <p class="paragraph message text-center heading-4 text-white pb-64" [innerHTML]="message()"></p>
      }
      @if (showProgress()) {
        <lib-progress-bar class="flex width-full" [indeterminate]="true" [max]="0" [value]="0"></lib-progress-bar>
      }
    </div>
  </div>


}


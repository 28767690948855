import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  Component,
  computed,
  contentChildren,
  inject,
  input,
  model,
  signal,
  TemplateRef
} from '@angular/core';
import {CheckboxComponent} from "../base/checkbox/checkbox.component";
import {BarComponent} from "../base/bar/bar.component";
import {IconComponent, IconSet, IconType} from "../base/icon/icon.component";
import {ButtonComponent} from "../base/button/button.component";
import {DomSanitizer} from "@angular/platform-browser";
import {ControlContainer, FormControl, FormGroupDirective} from "@angular/forms";
import {TemplateFor} from "../../templating/template-for.directive";
import {NgTemplateOutlet} from "@angular/common";
import {FlexModule} from "@ngbracket/ngx-layout";

@Component({
  selector: 'lib-checkbox-bar',
  standalone: true,
  imports: [
    CheckboxComponent,
    BarComponent,
    IconComponent,
    ButtonComponent,
    NgTemplateOutlet,
    FlexModule
  ],
  templateUrl: './checkbox-bar.component.html',
  styleUrl: './checkbox-bar.component.css',
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class CheckboxBarComponent implements AfterContentInit {


  templates = contentChildren(TemplateFor);
  notice = signal<TemplateRef<any>|undefined>(undefined);

  titleContent = input.required<string>({alias: 'title'});
  subTitle = input<string|null>(null);
  noticeTitle = input<string|null>(null);
  noticeContent = input<string|null>(null);
  checked = model<boolean>(false);
  protected readonly IconSet = IconSet;
  protected readonly IconType = IconType;
  sanitizer = inject(DomSanitizer);

  parentForm = inject(FormGroupDirective, {optional: true});
  control = input<FormControl | string | null>(null, {alias: 'control'});
  noticeType = input<'none'|'info' | 'warning' | 'error'|'success'>('none');

  ngAfterContentInit() {
    TemplateFor.Bind(this, this.templates());
    // console.log('CheckboxBarComponent.ngAfterContentInit', this.titleContent(), {templates: this.templates(),notice: this.notice()});
  }
}

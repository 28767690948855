

type TaggedLogFunc = (tag: string, ...data: any[]) => void;
type LogFunc = (...data: any[]) => void;

const ErrorOverrides = ['ERROR', 'Unhandled Error', 'AssertionError', 'Unhandled Promise', 'enableProdMode'];

export enum LogLevel {
  Debug,
  Verbose,
  Info,
  Warn,
  Error,
  Critical
}

export const LogLevelStrMap = {
  [LogLevel.Debug]: 'debug',
  [LogLevel.Verbose]: 'verbose',
  [LogLevel.Info]: 'info',
  [LogLevel.Warn]: 'warn',
  [LogLevel.Error]: 'error',
  [LogLevel.Critical]: 'critical',
} as const;

export interface ILogger {
  readonly log: LogFunc;
  readonly error: LogFunc;
  readonly warn: LogFunc;
  readonly group: LogFunc;
  readonly groupCollapsed: LogFunc;
  readonly groupEnd: LogFunc;
}

class LoggerImp implements ILogger {

  public readonly log = (...data: any[]) => !this.silenced ? Logger.Log(this.tag, ...data) : () => {};
  public readonly error = (...data: any[]) => !this.silenced ? Logger.Error(this.tag, ...data) : () => {};
  public readonly warn = (...data: any[]) => !this.silenced ? Logger.Warn(this.tag, ...data) : () => {};
  public readonly group = (...data: any[]) => !this.silenced ? Logger.Group(this.tag, ...data) : () => {};
  public readonly groupCollapsed = (...data: any[]) => !this.silenced ? Logger.GroupCollapsed(this.tag, ...data) : () => {};
  public readonly groupEnd: () => void = () => !this.silenced ? originalConsoleFuncs.groupEnd.bind(console) : () => {};
  // public meta: {[key: string]: any} = {};
  private _tag: string;

  public silenced = false;

  public get tag() {
    return this._tag;
  }

  public constructor(tag: string) {
    this._tag = tag;
  }

  public changeTag(tag: string, style: string|string[]|null = null) {
    // @ts-ignore
    Object.apply(this, Logger.Register(tag, style));
    this._tag = tag;
  }


}

const originalConsoleFuncs: Console = {...console};

export interface LoggerConfig {
  [tag: string]: LogLevel;
}

export abstract class Logger {

  private static Config: LoggerConfig|LogLevel = LogLevel.Debug;

  private static CanLog(level: LogLevel, tag: string|Error) {
    if (typeof Logger.Config === 'number') {
      return level >= Logger.Config;
    }
    return Logger.Config[tag instanceof Error ? tag.name : tag] ?? Logger.Config[''] ?? LogLevel.Debug;
  }

  public static Configure(cfg: LoggerConfig|LogLevel) {
    Logger.Config = cfg;
  }

  public static PatchGlobal() {
    console.assert = (...data: any[]) => Logger.Assert('`global`', data[1]);
    console.log = (...data: any[]) => Logger.Log('`global`', ...data);
    console.warn = (...data: any[]) => Logger.Warn('`global`', ...data);
    console.debug = (...data: any[]) => Logger.Debug('`global`', ...data);
    console.error = (...data: any[]) => Logger.Error('`global`', ...data);
    console.info = (...data: any[]) => Logger.Info('`global`', ...data);
    console.group = (...data: any[]) => Logger.Group('`global`', ...data);
    console.groupCollapsed = (...data: any[]) => Logger.GroupCollapsed('`global`', ...data);
  }

  private static Tags: string[][] = [];
  private static ForceTags = false;
  private static Logs: { [tag: string]: any[] } = {};
  private static originalAssert: (value: any, message?: string | Error) => void;

  static Register(tag: string, style: string|string[]|null = null) {
    style = style ?? '';
    if (style instanceof Array) {
      style = style.join(';');
    }
    const tagIndex = Logger.Tags.findIndex(t => t[0] === tag);
    if (tagIndex !== -1) {
      Logger.Tags[tagIndex] = [tag, style];
    } else {
      Logger.Tags.push([tag, style]);
    }
    return new LoggerImp(tag);
  }

  static Override(forceTags?: boolean) {

    Logger.ForceTags = forceTags ?? false;
    Logger.Tags.push(['Unhandled Error', 'background-color: white; color: red']);

    // const _ = new ConsoleOverride(originalConsole);
  }

  static Count(tag: string) {
    return Logger.Logs[tag]?.length ?? 0;
  }

  private static TryOverride(level: LogLevel, func: TaggedLogFunc, tag: string|Error, ...data: any[]) {
    if (!Logger.CanLog(level, tag)) {
      return;
    }

    func = func.bind(console);
    const timestamp = new Date().toISOString();
    const traceobj = new Error("").stack!.split("\n")[2].split("/").slice(-1)[0].split(":");
    const file = traceobj[0];
    const line = `${traceobj[1]}:${traceobj[2].endsWith(")") ? traceobj[2].split(")")[0] : traceobj[2]}`;
    data.unshift(file + ":" + line + " >>");
    let colorTag: string|undefined;

    switch (level) {
      case LogLevel.Error:
        colorTag = 'color: red;';
        break;
      case LogLevel.Warn:
        colorTag = 'color: orange;';
        break;
      case LogLevel.Info:
        colorTag = 'color: blue;';
        break;
      case LogLevel.Critical:
        colorTag = 'color: red; font-weight: bold;';
        break;
    }
    if (colorTag) {
      data.splice(1, 0, colorTag);
      colorTag = '%c';
    } else {
      colorTag = '';
    }


    const origTag = tag;
    if (tag instanceof Error) {
      data = [tag, ...data];
      tag = 'Unhandled Error';
    }
    // @ts-ignore
    tag = Logger.Tags.find(t => t[0] === tag) ?? tag;
    if (tag instanceof Array) {
      if (tag.length === 2) {
        if (!colorTag) {
          data.splice(1, 0, tag[1]);
          colorTag = '%c';
        } else {
          data[1] = tag[1];
        }
      }

      func(`[${timestamp}] ${LogLevelStrMap[level].toUpperCase()}${colorTag}::${tag[0]}`, ...data);
    } else {
      if (tag === 'ERROR') {
        return;
      }

      if (Logger.ForceTags && !ErrorOverrides.find(e => {
        if (origTag instanceof Error) {
          return ErrorOverrides.includes(origTag.name);
        } else {
          return (tag as string).includes(e);
        }
      })) {
        // We could do normal assert but this is useful for breakpoints to track down non-compliant logs
        const _ = false;
        console.assert(false, `Missing required console tag for ${tag}`);
      }

      func(`[${timestamp}] ${LogLevelStrMap[level].toUpperCase()}${colorTag}::${tag}`, ...data);
    }
  }

  static Assert(value: any, message?: string | Error): void {
    if (!value) { // Used for assert breakpoint support
      const _ = false;
    }
    Logger.originalAssert(value, message);
  }

  static Group(tag: string, ...data: any[]) {
    Logger.TryOverride(LogLevel.Debug, originalConsoleFuncs.group, tag, ...data);
  }

  static GroupCollapsed(tag: string, ...data: any[]) {
    Logger.TryOverride(LogLevel.Debug, originalConsoleFuncs.group, tag, ...data);
  }

  static Log(tag: string, ...data: any[]) {
    Logger.TryOverride(LogLevel.Debug, originalConsoleFuncs.log, tag, ...data);
  }

  static Warn(tag: string, ...data: any[]) {
    Logger.TryOverride(LogLevel.Warn, originalConsoleFuncs.warn, tag, ...data);
  }

  static Debug(tag: string, ...data: any[]) {
    Logger.TryOverride(LogLevel.Debug, originalConsoleFuncs.debug, tag, ...data);
  }

  // static Exception(tag: string, ...data: any[]) {
  //   LoggerService.TryOverride(originalConsole.exception, tag, ...data);
  // }

  static Error(tag: string, ...data: any[]) {
    Logger.TryOverride(LogLevel.Error, originalConsoleFuncs.error, tag, ...data);
  }

  static Info(tag: string, ...data: any[]) {
    Logger.TryOverride(LogLevel.Info, originalConsoleFuncs.info, tag, ...data);
  }

}

export interface LogMessage {
  readonly timestamp: number;
  readonly id: string;
  readonly level: LogLevel;
  readonly context: string;
  readonly message: string;
  readonly subMessage: string | null;
  readonly stack: string;
  readonly data: {
    message: any | null;
    context: any | null;
  }
}

type ILogContextParent = {
  readonly name: string,
  readonly data: any | null,
  readonly parent: ILogContextParent | null
};

export interface ILogContext extends Disposable {
  readonly id: string;
  readonly name: string;
  readonly data: any | null;
  readonly parent: ILogContextParent | null

  log(level: LogLevel, message: string, subMessage?: string, data?: any): void;

  subContext(name: string, data?: any): ILogContext;
}

export interface IContextLogger {
  readonly rootContext: ILogContext;
}

export type LoggerSaveFunc = (ctx: ILogContext, message: LogMessage) => void;

interface LogContextArgs {
  readonly name: string;
  readonly data: any | null;
  readonly parent: ILogContext | null;
  readonly hooks: {
    readonly onLog: (ctx: ILogContext, message: LogMessage) => void;
    readonly onEnter: (ctx: ILogContext) => void;
    readonly onExit: (ctx: ILogContext) => void;
  }
}

class LogContext implements ILogContext {
  readonly id = crypto.randomUUID();
  readonly #subContexts: { [id: string]: ILogContext } = {};
  readonly #options: LogContextArgs;

  constructor(args: LogContextArgs) {
    this.#options = args;
  }

  get parent(): (ILogContext & { readonly data: any | null }) | null {
    if (!this.#options.parent) {
      return null;
    }
    return {
      ...this.#options.parent,
      data: Object.freeze(this.#options.parent.data),
    }
  }

  get name(): string {
    return this.#options.name;
  }

  get data(): any {
    return this.#options.data;
  }

  subContext(name: string, data?: any): ILogContext {
    const id = crypto.randomUUID();
    const context = new LogContext({
      name,
      data,
      parent: this,
      hooks: {
        ...this.#options.hooks,
        onExit: (ctx) => {
          if (ctx.id === context.id) {
            delete this.#subContexts[id];
          }
          this.#options.hooks.onExit(ctx);
        }
      },
    });
    this.#subContexts[id] = context;
    return context
  }

  log(level: LogLevel, message: string, subMessage?: string | undefined, data?: any) {
    const stack = new Error().stack ?? '';
    const id = crypto.randomUUID();
    const logMessage: LogMessage = {
      timestamp: Date.now(),
      id,
      level,
      context: this.#options.name,
      message,
      subMessage: subMessage ?? null,
      stack,
      data: {
        message: data ?? null,
        context: this.#fullContextData(),
      }
    };
    this.#options.hooks.onLog(this, logMessage);
  }

  [Symbol.dispose](): void {
    for (const id in this.#subContexts) {
      this.#subContexts[id][Symbol.dispose]();
    }
    this.#options.hooks.onExit(this);
  }

  #fullContextData(): any {
    const data: any = {};

    let current: ILogContextParent | null = this;
    while (current) {
      if (current.data) {
        data[current.name] = current.data;
      }
      current = current.parent;
    }
    return data;
  }
}


export const ConsoleLoggerHooks: LogContextArgs['hooks'] = {
  onLog: (ctx, message) => {
    console.group(`[${message.timestamp}] ${LogLevelStrMap[message.level].toUpperCase()} [${message.context}] ${message.message} ${message.subMessage ?? ''}`);
    console.dir(message.data);
    switch (message.level) {
      case LogLevel.Debug:
        console.debug(message.stack);
        break;
      case LogLevel.Verbose:
        console.log(message.stack);
        break;
      case LogLevel.Info:
        console.info(message.stack);
        break;
      case LogLevel.Warn:
        console.warn(message.stack);
        break;
      case LogLevel.Error:
      case LogLevel.Critical:
        console.error(message.stack);
        break;
    }
    console.groupEnd();
  },
  onEnter: (ctx) => {
    console.group(`${ctx.name}`);
  },
  onExit: (ctx) => {
    console.groupEnd();
  },
};

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

const rootHooksArr: {
  -readonly [P in keyof LogContextArgs['hooks']]: LogContextArgs['hooks'][P][]
} = {
  onLog: [ConsoleLoggerHooks.onLog],
  onEnter: [ConsoleLoggerHooks.onEnter],
  onExit: [ConsoleLoggerHooks.onExit],
};

const rootHooks: Writeable<LogContextArgs['hooks']> = {
  get onLog(): LogContextArgs['hooks']['onLog'] {
    return (ctx, message) => rootHooksArr.onLog.forEach(hook => hook(ctx, message));
  },
  set onLog(value) {
    rootHooksArr.onLog.push(value);
  },
  get onEnter(): LogContextArgs['hooks']['onEnter'] {
    return (ctx) => rootHooksArr.onEnter.forEach(hook => hook(ctx));
  },
  set onEnter(value) {
    rootHooksArr.onEnter.push(value);
  },
  get onExit(): LogContextArgs['hooks']['onExit'] {
    return (ctx) => rootHooksArr.onExit.forEach(hook => hook(ctx));
  },
  set onExit(value) {
    rootHooksArr.onExit.push(value);
  },
};

const rootLogger: ILogContext = new LogContext({
  name: 'root',
  data: null,
  parent: null,
  hooks: rootHooks,
});

export const ContextLogger: ILogContext & {hooks: Writeable<LogContextArgs['hooks']> } = {...rootLogger, hooks: rootHooks};



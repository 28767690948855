<section>
  <h3>
    Do any of the following apply to you?
  </h3>
  <div fxLayout="column" ngClass.gt-xs="row-gap-40" ngClass.lt-sm="row-gap-16">
    <ul>
      @for (question of FilterQuestions; track question.title; let index = $index) {
        <li ngClass.gt-xs="pt-16" ngClass.lt-sm="pt-8">
        <lib-checkbox-bar [title]="question.title"
                          [class]="question.trackingTag"
                          [subTitle]="question.details"
                          [noticeTitle]="question.stopMsg.title"
                          [noticeContent]="question.stopMsg.details"
                          [checked]="isChecked(question.title)"
                          (checkedChange)="answerChanged(question.title, $event)" noticeType="error">

        </lib-checkbox-bar>
        </li>

      }
    </ul>

  @if (isValid()) {

    <button type="button" class="btn btn-large step-next pk_prescreen_acknowledge arrow-right"
            [disabled]="!isValid()"
            (click)="finder.goForward()">
      Acknowledge
    </button>
  }

  </div>
</section>

import {Component, computed, effect, inject} from '@angular/core';
import {SearchPageComponent} from "./search-page/search-page.component";
import {OnlineSchedulerService} from "../../online-scheduler.service";

@Component({
  selector: 'app-find-appt-section',
  standalone: true,
  imports: [
    SearchPageComponent
  ],
  templateUrl: './find-appt-section.component.html',
  styleUrl: './find-appt-section.component.scss'
})
export class FindApptSectionComponent {

  finder = inject(OnlineSchedulerService);

  isReady = computed(() => {
    const addr = this.finder.data.demographics()?.address;

    if (!addr?.state) {
      return false;
    }
    return !!addr.coordinates?.lat && !!addr.coordinates?.long;
  });

  constructor() {
    effect(() => {
      const ready = this.isReady();
      if (ready) return;

      this.finder.data.meta.update(meta => ({
        ...meta,
        prefill: {
          ...meta.prefill,
          step: 'location'
        },
        section: 'prefill'
      }));
    }, {allowSignalWrites: true});

  }

}

<lib-bar>
  <div class="content">
    <lib-checkbox #checkbox [(checked)]="checked" [control]="control()"></lib-checkbox>
    <div class="primary-info" (click)="checkbox.toggle()">
      <h5 class="heading-5 btn-text-action-secondary">{{ titleContent() }}</h5>
      <span class="sub-text text-body-secondary hide-empty text-body-sm">{{ subTitle() }}</span>
    </div>

  </div>
  @if (checked() && (noticeTitle()?.length || noticeContent()?.length || notice())) {
    <div class="notice" fxFlex>
      @if (noticeType() !== 'none') {
        <span>
      @switch (noticeType()) {
        @case ('error') {
          <lib-icon name="!" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Error" [size]="8"></lib-icon>
        }
        @case ('warning') {
          <lib-icon name="?" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Warn" [size]="8"></lib-icon>
        }
        @case ('info') {
          <lib-icon name="+" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Info" [size]="8"></lib-icon>
        }
        @case ('success') {
          <lib-icon name="checkmark" verticalAlign="top" [set]="IconSet.Mindpath" [type]="IconType.Success"
                    [size]="8"></lib-icon>
        }

      }
    </span>
      }
      @if (noticeTitle()?.length || noticeContent()?.length || notice()) {
        <div class="notice-info">
          <h6 class="heading-6 text-heading-2 hide-empty">{{ noticeTitle() }}</h6>
          <span class="sub-text text-body-secondary hide-empty text-body-sm">
        <ng-content select="[slot='notice-content']">
          <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(noticeContent() ?? '')"></span>
        </ng-content>
            @if (notice(); as noticeContent) {
              <ng-container [ngTemplateOutlet]="noticeContent"></ng-container>
            }
      </span>
        </div>
      }
    </div>
  }
</lib-bar>

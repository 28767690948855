import {Component, computed, effect, inject, input, output, Signal, signal} from '@angular/core';
import {FinderPrefill, FindPrefillComponent} from "../../../Views/find-prefill/find-prefill.component";
import {ConditionsDataService} from "../../../conditions-data.service";
import {InsuranceDataService} from "../../../insurance-data.service";
import {CliniciansDataService} from "../../../clinicians-data.service";
import {OnlineSchedulerService, SignalValue} from "../../../online-scheduler.service";
import {BarComponent} from "@mindpath/shared";
import {FlexModule} from "@ngbracket/ngx-layout";

@Component({
  selector: 'app-finder-prefill-step-group',
  standalone: true,
  imports: [
    FindPrefillComponent,
    BarComponent,
    FlexModule
  ],
  templateUrl: './finder-prefill-step-group.component.html',
  styleUrl: './finder-prefill-step-group.component.scss'
})
export class FinderPrefillStepGroupComponent {

  conditionsSvc = inject(ConditionsDataService);
  insuranceSvc = inject(InsuranceDataService);
  cliniciansSvc = inject(CliniciansDataService);
  schedulerSvc = inject(OnlineSchedulerService);

  step = input.required<string>();
  onCompleted = output();

  conditions = computed(() => {
    return this.conditionsSvc.snapshot().map(c => ({value: c.id, label: c.name}));
  })
  insurances = computed(() => {
    const state = this.state();
    const insurances = this.insuranceSvc.snapshot().filter(i => i.plans.some(p => p.state === state));
    return insurances!.map(i => ({
      value: i.id!,
      label: i.name!,
      isTop: i.isTopInsurer!,
      plans: i.plans!.map(p => ({value: p.id!, label: p.name!}))
    }))
  })

  selectedCondition = signal<number | null>(null);
  selectedAge = signal<'Child' | 'Adult' | 'Senior' | null>(null);
  selectedSetting = signal<'InPerson' | 'Telehealth' | null>(null);
  selectedClinicianType = signal<'Psychiatry' | 'Therapy' | null>(null);
  selectedInsurance = signal<number | null>(null);
  selectedInsurancePlan = signal<number | null>(null);

  steps: Signal<{ [key: string]: FinderPrefill }> = computed(() => {
    const conditions = this.conditions();
    const insurances = this.insurances();
    const selectedInsurance = this.selectedInsurance();

    const selectedIns = insurances.find(i => i.value === selectedInsurance);
    return {
      'type': {
        main: {
          labels: {
            title: 'What type of mental health care are you looking for?',
          },
        },
        bars: {
          options: [
            {
              label: 'Psychiatry',
              value: 'Psychiatry',
              description: 'Medication management and evaluations to treat various conditions, including depression, anxiety, and ADHD.'
            },
            {
              label: 'Therapy',
              value: 'Therapy',
              description: 'Therapy and consultations to help individuals, couples, and families work through life\'s challenges, including grief, trauma, relationship issues, and stress.'
            }
          ],
          selected: this.selectedClinicianType,
        },
        footer: {
          subTitle: 'If you\'re unsure, choose an option above. You can change this selection later. Our team will work with you to guide your mental health journey.'
        }
      },
      'condition': {
        main: {
          labels: {
            title: 'Help us guide you. What is the main reason for your visit?',
          },
        },
        bars: {
          options: [{
            label: 'General evaluation',
            description: 'A clinician will evaluate your symptoms, listen to your concerns, and find a treatment plan that best supports your mental health journey.',
            value: -1
          }],
          selected: this.selectedCondition,
        },
        autoComplete: {
          options: conditions,
          selected: this.selectedCondition,
          placeholder: 'Search for a condition',
          hinting: {
            label: 'Commonly searched',
            options: conditions.slice(0, 5).map(c => c.value)
          }
        }
      },
      'age': {
        main: {
          labels: {
            title: 'What is the patient\'s age?',
          },
        },
        bars: {
          options: [
            {
              label: 'Child or adolescent (0-17)',
              value: 'Child'
            },
            {
              label: 'Adult (18-64)',
              value: 'Adult'
            },
            {
              label: 'Senior (65+)',
              value: 'Senior'
            }
          ],
          selected: this.selectedAge,
        },
        footer: {
          title: 'Why do we ask this?',
          subTitle: 'Some clinicians focus on specific age groups. This selection helps us guide your path.'
        }
      },
      'setting': {
        main: {
          labels: {
            title: 'Would you prefer an in-person or online visit?',
          },
        },
        bars: {
          options: [
            {
              label: 'In-person',
              value: 'InPerson',
              icon: 'building'
            },
            {
              label: 'Online (more availability)',
              value: 'Telehealth',
              icon: 'online-meeting'
            },
            {
              label: 'I am open to either',
              value: null,
            }
          ],
          selected: this.selectedSetting,
        },
        footer: {
          title: 'What\'s the difference?',
          subTitle: 'Online appointments may offer increased scheduling availability. In-person appointments allow for face-to-face communication at an office near you.'
        }
      },
      'insurance': {
        main: {
          labels: {
            title: 'What is your insurance company?\n',
          },
        },
        autoComplete: {
          placeholder: 'Search insurance companies',
          options: insurances,
          selected: this.selectedInsurance,
          hinting: {
            label: 'Popular insurers',
            options: insurances.filter(i => i.isTop).map(i => i.value)
          }
        }
      },
      'insurancePlan': {
        main: {
          labels: {
            title: `What is your ${selectedIns?.label} plan?`,
          },
        },
        autoComplete: {
          placeholder: 'Search plans',
          options: selectedIns?.plans ?? [],
          selected: this.selectedInsurancePlan,
          hinting: {
            label: `Most common plans for ${selectedIns?.label}`,
            options: (selectedIns?.plans ?? []).slice(0, 5).map(p => p.value)
          }
        }
      }
    }
  });

  state = computed(() => {
    const demographic = this.schedulerSvc.data.demographics();
    return demographic?.address?.state ?? null;
  })

  constructor() {

    const searchData = this.schedulerSvc.data.search();

    this.selectedCondition.set(searchData.conditionId);
    this.selectedSetting.set(searchData.settingPref);
    this.selectedClinicianType.set(searchData.clinicianTypePref);
    this.selectedAge.set(searchData.age);

    const insuranceData = this.schedulerSvc.data.insurance();
    this.selectedInsurance.set(insuranceData.primary?.id ?? null);
    this.selectedInsurancePlan.set(insuranceData.primary?.planId ?? null);

  }

  protected save() {

    const condition = this.selectedCondition();
    const setting = this.selectedSetting();
    const type = this.selectedClinicianType();
    const age = this.selectedAge();
    const selectedInsurance = this.selectedInsurance();
    const selectedInsurancePlan = this.selectedInsurancePlan();

    this.schedulerSvc.data.insurance.set({
      primary: {
        id: selectedInsurance!,
        planId: selectedInsurancePlan!,
        groupNumber: null!,
        policyNumber: null!,
        holder: null!,

      },
      secondary: null
    });

    this.schedulerSvc.data.search.update(s => ({
      ...s,
      conditionId: condition,
      settingPref: setting,
      clinicianTypePref: type,
      age
    }))
  }


}

import {computed, effect, inject, Injectable, Signal, signal, WritableSignal} from '@angular/core';
import {InsuranceDataService} from "./insurance-data.service";
import {CliniciansDataService} from "./clinicians-data.service";
import {OnlineSchedulerService, ValidStates} from "./online-scheduler.service";
import {Schema, Schemas} from "@mindpath/shared";
import {ConditionsDataService} from "./conditions-data.service";
import {Logger} from "@mindpath/shared";

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  readonly stopPageData = signal<{title: string, message: string, action: {
      label: string,
      onClick: () => void,
  }}|null>(null);

  readonly #options: ApptStateOptionItems;
  private insuranceSvc = inject(InsuranceDataService);
  private clinicianSvc = inject(CliniciansDataService);
  private scheduler = inject(OnlineSchedulerService);
  state = computed(() => {
    const data = this.scheduler.data.demographics()?.address?.state;
    return data ? data : null;
  });
  isStateValid = computed(() => {
    const state = this.state();
    return state && ValidStates.includes(state as any);
  });
  clinician = computed(() => {
    const data = this.scheduler.data.appt()?.clinician;
    return data ? data : null;
  });
  insurance = computed(() => {
    const selectedInsurance = this.scheduler.data.insurance()?.primary?.id;
    const companies = this.options.insurance.companies();
    return companies?.find(c => c.id === selectedInsurance) ?? null;
  });
  private conditionSvc = inject(ConditionsDataService);

  constructor() {
    this.#options = {
      insurance: {
        companies: signal<ReadonlyArray<Schema<'DetailedInsurance'>> | null>([]),
        plans: computed(() => {
          const insurance = this.insurance();
          return insurance?.plans ?? null;
        }),
      },
      states: signal(ValidStates).asReadonly(),
      conditions: this.conditionSvc.snapshot,
    }

    effect(() => {
      const insuranceId = this.scheduler.data.insurance()?.primary?.id;

    }, {allowSignalWrites: true});

  }

  get options(): WritableToSignal<ApptStateOptionItems> {
    return this.#options;
  }

  chooseCondition(condition: Schema<'Condition'> | number) {
    this.scheduler.patch('search.conditionId', typeof condition === 'number' ? condition : condition.id ?? -1);
  }

  clearLocation() {
    this.scheduler.data.demographics.update(d => {
      return {
        ...d ?? {} as any,
        address: {

        }
      }
    });
  }

  chooseLocation(state: typeof ValidStates[number], coords: Schema<'Coordinates'>, address?: Schema<'Address'>) {
    const currentState = this.state();
    if (currentState === state) {
      return;
    }


    this.scheduler.data.demographics.update(data => {
      return {
        ...data ?? {} as any,
        address: {
          ...address ?? {},
          state,
          coords,
        },
      } as any
    });
  }

  chooseInsurance(insurance: Schema<'DetailedInsurance'> | number) {
    const insuranceId = typeof insurance === 'number' ? insurance : insurance.id
    this.scheduler.data.insurance.update(data => {
      return {
        ...data ?? {} as any,
        primary: {
          ...data.primary ?? {},
          id: insuranceId,
        },
      } as any
    });
    this.#loadInsuranceCompany(insuranceId);
  }

  async #loadInsuranceCompany(id: number | null) {
    try {
      if (!id) {
        this.#options.insurance.companies.set([]);
        return;
      }

      const company = await this.insuranceSvc.getInsuranceCompany(id);
      if (!company) {
        return;
      }
      this.#options.insurance.companies.update(companies => {
        const newCompanies = [...companies ?? []]
        const index = newCompanies.findIndex(c => c.id === id);
        if (index === -1) {
          newCompanies.push(company);
        } else {
          newCompanies[index] = company;
        }
        return newCompanies;
      });
    } catch (e: any) {
      Logger.Error('AppStateSvc', e);
    }
  }

}

type WritableToSignal<T> = T extends WritableSignal<infer U> ? Signal<U> : T extends Record<string, any> ? {
  [K in keyof T]: WritableToSignal<T[K]>
} : T;

interface ApptStateOptionItems {
  readonly insurance: {
    readonly companies: WritableSignal<ReadonlyArray<Schema<'DetailedInsurance'>> | null>;
    readonly plans: Signal<ReadonlyArray<Schema<'InsurancePlan'>> | null>;
  },
  readonly states: Signal<typeof ValidStates>;
  readonly conditions: Signal<ReadonlyArray<Schema<'Condition'>> | null>;
}
@if (!loading()) {
  @switch (step()) {
    @case ('eligibility') {
      <app-eligibility></app-eligibility>
    }
    @case ('data-check') {
      <app-appointment-details-check></app-appointment-details-check>
    }
    @case ('demographics-1') {
      <app-general-info-demographics></app-general-info-demographics>
    }
    @case ('demographics-2') {
      <app-contact-info-demographics></app-contact-info-demographics>
    }
    @case ('otp') {
      <app-device-verification></app-device-verification>
    }
    @case ('insurance') {
      <app-insurance-info-capture></app-insurance-info-capture>
    }
    @case ('insurance-cards') {
      <app-insurance-cards-capture></app-insurance-cards-capture>
    }
    @case ('final') {
      <app-booking-confirmation></app-booking-confirmation>
    }
    @default {
      {{ step() }}
    }
  }
}
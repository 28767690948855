@if(mode() === 'blend') {
  <div class="blend {{customClass()}}">
    A
    <ng-content></ng-content>
    B
  </div>
} @else {
  <button type="button" class="bar {{mode()}} {{customClass()}}">
   <ng-content></ng-content>
  </button>
}

import {Component, computed, effect, inject, input, model, signal, TemplateRef, WritableSignal} from '@angular/core';
import {isMessageCode, QueryOptions, Schema} from "@mindpath/shared";

import {FlexModule} from "@ngbracket/ngx-layout";
import {LowerCasePipe, NgStyle, NgTemplateOutlet} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AutoCompleteCompleteEvent, AutoCompleteModule} from "primeng/autocomplete";
import {SharedModule} from "primeng/api";
import {BarComponent, IconComponent, IconType, InputComponent} from "@mindpath/shared";
import {OnlineSchedulerService} from "../../../../../online-scheduler.service";
import {ConditionsDataService} from "../../../../../conditions-data.service";

@Component({
  selector: 'app-service-filter-view',
  standalone: true,
  imports: [
    FlexModule,
    LowerCasePipe,
    ReactiveFormsModule,
    NgTemplateOutlet,
    AutoCompleteModule,
    SharedModule,
    FormsModule,
    BarComponent,
    InputComponent,
    IconComponent,
    NgStyle
  ],
  templateUrl: './service-filter-view.component.html',
  styleUrl: './service-filter-view.component.scss'
})
export class ServiceFilterViewComponent {

  selectedConditionFromOptions = computed(() => {
    const selectedConditionId = this.selectedConditionId();
    return this.conditionOptions().find(c => c.id === selectedConditionId);
  });

  onFilterChange = input.required<WritableSignal<QueryOptions<'/api/Availability/slots'>>>();
  typeOfCare = computed(() => {
    const search = this.finder.data.search();
    return search.clinicianTypePref;
  });

  selectedConditionId = computed(() => {
    const search = this.finder.data.search();
    return search.conditionId;
  });

  finder = inject(OnlineSchedulerService);
  conditionSvc = inject(ConditionsDataService);
  openSideBar(ref: TemplateRef<any>) {
    this.finder.sidebar.set({
      template: ref,
      resetHook: () => {
        this.changeType(null);
        this.changeCondition(-1);
      }
    });
    // this.finder.sidebarTemplate.set(ref);
    // this.finder.sideBarResetHook.set(() => {
    //   this.typeOfCare.set(null);
    //   this.selectedConditionId.set(-1);
    // });
  }

  isSelected = computed(() => {
    const isGeneralEval = this.selectedConditionId() === -1;
    const typeOfCare = this.typeOfCare();
    return !isGeneralEval || !!typeOfCare;
  });

  optionLabel = (option: {id: number, name?: string, label?: string}) => option.name || option.label || '';

  optionValue = (option: {id: number, value?: string, name?: string, label?: string}) => option.id || option.value || null as any;

  constructor() {

    effect(() => {
      const typeOfCare = this.typeOfCare();
      const selectedConditionId = this.selectedConditionId();

      this.finder.data.search.update(search => ({
        ...search,
        clinicianTypePref: typeOfCare,
        conditionId: selectedConditionId
      }));

      this.onFilterChange().update(filters => {
        if (typeOfCare) {
          filters['filter.Clinician.type'] = `eq '${this.typeOfCare() === 'Therapy' ? 'Therapist' : 'Prescriber'}'`;
        } else {
          delete filters['filter.Clinician.type'];
        }
        if (selectedConditionId && selectedConditionId > 0) {
          filters['filter.Clinician.supportedConditions'] = `eq ${selectedConditionId}`;
        } else {
          delete filters['filter.Clinician.supportedConditions'];
        }
        return {...filters};
      })
    }, {allowSignalWrites: true});
  }

  conditionOptions = computed(() => {
    return this.conditionSvc.snapshot().map(c => ({id: c.id, name: c.name}));
  });


  filteredConditions = signal<{id: number, name: string}[]>([]);

  commonlySelected = computed(() => {
    return this.conditionOptions().slice(0, 3);
  })

  filter(event: AutoCompleteCompleteEvent) {
    this.filteredConditions.set(this.conditionOptions().filter(option => option.name.toLowerCase().includes(event.query.trim().toLowerCase())));
  }

  changeType(val: string|null) {
    this.finder.data.search.update(s => ({
      ...s,
      clinicianTypePref: val as any
    }));
  }
  changeCondition(val: number|Schema<'Condition'>|null) {

    if (typeof val === 'object' && val !== null) {
      val = val.id;
    }
    this.finder.data.search.update(s => ({
      ...s,
      conditionId: val
    }));
  }

  protected readonly IconType = IconType;
}

<ul>
  @for (card of cards(); track card!.file; let i = $index) {
    <!-- it should always be there, just using the 'as' binding here since we're not on 18.1 yet with '@let' binding -->
    @if (card && uploadStatuses()[i]; as status) {
      <li>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <div fxLayout="row" fxLayoutAlign="start center">

            @switch (status.state()) {
              @case ('uploading') {
                <p-progressSpinner
                  [style]="{width: '1.75rem', height: '1.75rem'}"
                  strokeWidth="8"
                  animationDuration="750ms"
                ></p-progressSpinner>
              }
              @case ('complete') {
                <lib-icon [size]="16" name="checkmark" [set]="IconSet.Mindpath" verticalAlign="middle"
                          [type]="IconType.Success"></lib-icon>
              }
              @case ('error') {
                <lib-icon [size]="16" name="!" [set]="IconSet.Text" verticalAlign="middle"
                          [type]="IconType.Error"></lib-icon>
              }
            }
            @if (card.isPrimary) {
              <span class="pl-8 text-gray-600">{{ hasSecondary() ? 'Primary ' : '' }}
                Insurance Card - {{ card.side | titlecase }}</span>
            } @else {
              <span class="pl-8 text-gray-600">Secondary Insurance Card - {{ card.side | titlecase }}</span>
            }
          </div>
<!--          @if (status.state() !== 'error') {-->
<!--            <div fxLayout="row" fxLayoutAlign="start center">-->
<!--              <span class="text-gray-600">{{ status.progress | async }}%</span>-->
<!--            </div>-->
<!--          }-->
        </div>
      </li>
    }
  }
</ul>
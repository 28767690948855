import {Directive, inject, input, QueryList, TemplateRef} from '@angular/core';
import {SIGNAL} from "@angular/core/primitives/signals";

@Directive({
  standalone: true,
  selector: '[templateFor]'
})
export class TemplateFor {

  readonly name = input.required<string>({alias: 'templateFor'});
  readonly template = inject(TemplateRef<any>);

  getType(): string {
    return this.name();
  }


  static Bind<T>(item: InferTemplateProps<T>, queryList: readonly TemplateFor[] | undefined) {
    if (!queryList) {
      console.error('No query list')
      return;
    }
    queryList.forEach((template: TemplateFor) => {
      const name = template.getType();
      if (!(name in item)) {
        return;
      }
      const existing = (item as any)[name];
      if (existing && typeof existing === 'function' && SIGNAL in existing) {
          existing.set(template.template);
        return;
      }
      (item as any)[name] = template.template;

    })
  }

}

type TemplateRefKeys<T> = {
  [K in keyof T]: T[K] extends TemplateRef<any> ? K : never;
}[keyof T];

type RestrictedIndexSignature<T> = {
  [K in TemplateRefKeys<T>]: TemplateRef<any>;
};

export type InferTemplateProps<T> = {
  [K in keyof T as T[K] extends TemplateRef<any> ? K : never]: T[K];
} & RestrictedIndexSignature<T>;


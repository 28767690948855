
// export enum LogLevel {
//   Debug,
//   Info,
//   Warning,
//   Error,
//   Critical
// }

import {LogLevel} from "../logging";

export interface IMessageCode {
  readonly code: string;
  readonly description: string|null;
  readonly level: LogLevel;
  readonly traceId?: string;
  readonly data?: any|null;
  with(msg: Partial<IMessageCode>): IMessageCode;
}

export class MessageCode implements IMessageCode {

  public with(msg: Partial<IMessageCode>): IMessageCode {
    return new MessageCode(msg.code ?? this.code, msg.description ?? this.description, msg.level ?? this.level);
  }

  constructor(public readonly code: string, public readonly description: string|null, public readonly level: LogLevel) {}
  /**
   * Unknown Wordpress Exception
   **/
  public static readonly WPS0000: IMessageCode = new MessageCode('WPS0000', 'An Unknown Error Occurred', LogLevel.Error);
  public static readonly Unknown_Wordpress_Exception: IMessageCode = MessageCode.WPS0000;

  /**
   * Unknown Browser Exception
   **/
  public static readonly BSR0000: IMessageCode = new MessageCode('BSR0000', 'An Unknown Error Occurred', LogLevel.Error);
  public static readonly Unknown_Browser_Exception: IMessageCode = MessageCode.BSR0000;

  /**
   * Unknown Exception
   **/
  public static readonly MPH0000: IMessageCode = new MessageCode('MPH0000', 'An Unknown Error Occurred', LogLevel.Error);
  public static readonly Unknown_Exception: IMessageCode = MessageCode.MPH0000;

  /**
   * Unknown Intake Exception
   **/
  public static readonly ITK0000: IMessageCode = new MessageCode('ITK0000', 'An Unknown Error Occurred', LogLevel.Error);
  public static readonly Unknown_Intake_Exception: IMessageCode = MessageCode.ITK0000;

  /**
   * Attempt to register an active patient
   **/
  public static readonly ITK0001: IMessageCode = new MessageCode('ITK0001', null, LogLevel.Error);
  public static readonly Attempt_to_register_an_active_patient: IMessageCode = MessageCode.ITK0001;

  /**
   * Attempt to register an in-active patient
   **/
  public static readonly ITK0002: IMessageCode = new MessageCode('ITK0002', null, LogLevel.Error);
  public static readonly Attempt_to_register_an_in_active_patient: IMessageCode = MessageCode.ITK0002;

  /**
   * Multiple intake matches found during registration
   **/
  public static readonly ITK0003: IMessageCode = new MessageCode('ITK0003', null, LogLevel.Error);
  public static readonly Multiple_intake_matches_found_during_registration: IMessageCode = MessageCode.ITK0003;

  /**
   * Appointment Conflict
   **/
  public static readonly ITK0004: IMessageCode = new MessageCode('ITK0004', null, LogLevel.Error);
  public static readonly Appointment_Conflict: IMessageCode = MessageCode.ITK0004;

  /**
   * Slot Taken
   **/
  public static readonly ITK0005: IMessageCode = new MessageCode('ITK0005', 'This appointment is no longer available. Please select another time.', LogLevel.Error);
  public static readonly Slot_Taken: IMessageCode = MessageCode.ITK0005;

  /**
   * Insurance Company Not Found
   **/
  public static readonly ITK0006: IMessageCode = new MessageCode('ITK0006', null, LogLevel.Error);
  public static readonly Insurance_Company_Not_Found: IMessageCode = MessageCode.ITK0006;

  /**
   * Insurance Plan Not Found
   **/
  public static readonly ITK0007: IMessageCode = new MessageCode('ITK0007', null, LogLevel.Error);
  public static readonly Insurance_Plan_Not_Found: IMessageCode = MessageCode.ITK0007;

  /**
   * Attempt to update non-existent request record
   **/
  public static readonly ITK0008: IMessageCode = new MessageCode('ITK0008', null, LogLevel.Error);
  public static readonly Attempt_to_update_non_existent_request_record: IMessageCode = MessageCode.ITK0008;

  /**
   * Missing Insurance
   **/
  public static readonly ITK0009: IMessageCode = new MessageCode('ITK0009', null, LogLevel.Error);
  public static readonly Missing_Insurance: IMessageCode = MessageCode.ITK0009;

  /**
   * Appointment Setting Mismatch
   **/
  public static readonly ITK0010: IMessageCode = new MessageCode('ITK0010', null, LogLevel.Error);
  public static readonly Appointment_Setting_Mismatch: IMessageCode = MessageCode.ITK0010;

  /**
   * Unknown Auth Exception
   **/
  public static readonly ATH0000: IMessageCode = new MessageCode('ATH0000', null, LogLevel.Error);
  public static readonly Unknown_Auth_Exception: IMessageCode = MessageCode.ATH0000;

  /**
   * Too many attempts
   **/
  public static readonly ATH0001: IMessageCode = new MessageCode('ATH0001', 'You have exceeded the maximum number of verification attempts', LogLevel.Error);
  public static readonly Too_many_attempts: IMessageCode = MessageCode.ATH0001;

  /**
   * Incorrect Code
   **/
  public static readonly ATH0002: IMessageCode = new MessageCode('ATH0002', 'The code you entered is incorrect.', LogLevel.Error);
  public static readonly Incorrect_Code: IMessageCode = MessageCode.ATH0002;

  /**
   * Code Expired
   **/
  public static readonly ATH0003: IMessageCode = new MessageCode('ATH0003', 'The code you entered has expired.', LogLevel.Error);
  public static readonly Code_Expired: IMessageCode = MessageCode.ATH0003;

  /**
   * Too Many Code Requests
   **/
  public static readonly ATH0004: IMessageCode = new MessageCode('ATH0004', 'You have exceeded the maximum number of code requests.', LogLevel.Error);
  public static readonly Too_Many_Code_Requests: IMessageCode = MessageCode.ATH0004;

  /**
   * Unknown Notification Exception
   **/
  public static readonly NTF0000: IMessageCode = new MessageCode('NTF0000', null, LogLevel.Error);
  public static readonly Unknown_Notification_Exception: IMessageCode = MessageCode.NTF0000;

  /**
   * Invalid Phone Number
   **/
  public static readonly NTF0001: IMessageCode = new MessageCode('NTF0001', 'The phone number you entered is not a valid U.S. phone number.', LogLevel.Error);
  public static readonly Invalid_Phone_Number: IMessageCode = MessageCode.NTF0001;

  /**
   * Not A Mobile Number
   **/
  public static readonly NTF0002: IMessageCode = new MessageCode('NTF0002', 'The phone number you entered is not a mobile number.', LogLevel.Error);
  public static readonly Not_A_Mobile_Number: IMessageCode = MessageCode.NTF0002;

  /**
   * Unsupported Phone Carrier
   **/
  public static readonly NTF0003: IMessageCode = new MessageCode('NTF0003', 'The phone carrier you entered is not supported.', LogLevel.Error);
  public static readonly Unsupported_Phone_Carrier: IMessageCode = MessageCode.NTF0003;

  /**
   * Invalid Email Address
   **/
  public static readonly NTF0004: IMessageCode = new MessageCode('NTF0004', 'The email address you entered is not a valid.', LogLevel.Error);
  public static readonly Invalid_Email_Address: IMessageCode = MessageCode.NTF0004;



  public static FromCode(code: string): IMessageCode|null {

    const codeObj = (() => {
      switch (code) {
        case "WPS0000":
          return MessageCode.WPS0000;
        case "BSR0000":
          return MessageCode.BSR0000;
        case "MPH0000":
          return MessageCode.MPH0000;
        case "ITK0000":
          return MessageCode.ITK0000;
        case "ITK0001":
          return MessageCode.ITK0001;
        case "ITK0002":
          return MessageCode.ITK0002;
        case "ITK0003":
          return MessageCode.ITK0003;
        case "ITK0004":
          return MessageCode.ITK0004;
        case "ITK0005":
          return MessageCode.ITK0005;
        case "ITK0006":
          return MessageCode.ITK0006;
        case "ITK0007":
          return MessageCode.ITK0007;
        case "ITK0008":
          return MessageCode.ITK0008;
        case "ITK0009":
          return MessageCode.ITK0009;
        case "ITK0010":
          return MessageCode.ITK0010;
        case "ATH0000":
          return MessageCode.ATH0000;
        case "ATH0001":
          return MessageCode.ATH0001;
        case "ATH0002":
          return MessageCode.ATH0002;
        case "ATH0003":
          return MessageCode.ATH0003;
        case "ATH0004":
          return MessageCode.ATH0004;
        case "NTF0000":
          return MessageCode.NTF0000;
        case "NTF0001":
          return MessageCode.NTF0001;
        case "NTF0002":
          return MessageCode.NTF0002;
        case "NTF0003":
          return MessageCode.NTF0003;
        case "NTF0004":
          return MessageCode.NTF0004;
        default:
          return null;
      }
    })();

    if (!codeObj) {
      return null;
    }
    return codeObj.with({});
  }
  public static Dupe(msg: IMessageCode): IMessageCode {
    return new MessageCode(msg.code, msg.description, msg.level);
  }
}

export const isMessageCode = (obj: any): obj is IMessageCode => {
  return obj !== undefined && obj instanceof MessageCode || (obj && obj.code && obj.description && obj.level);
}
import {
  AfterViewInit,
  Component,
  computed, effect,
  input,
  model,
  OnInit,
  output,
  signal,
  Signal,
  viewChild,
  WritableSignal
} from '@angular/core';
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {BarComponent, IconComponent, InputComponent} from "@mindpath/shared";
import {AutoComplete, AutoCompleteModule, AutoCompleteSelectEvent} from "primeng/autocomplete";
import {SharedModule} from "primeng/api";
import {OptionFilter} from "../../utils";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-find-prefill',
  standalone: true,
  imports: [
    ExtendedModule,
    FlexModule,
    BarComponent,
    IconComponent,
    AutoCompleteModule,
    InputComponent,
    SharedModule,
    FormsModule
  ],
  templateUrl: './find-prefill.component.html',
  styleUrl: './find-prefill.component.scss'
})
export class FindPrefillComponent implements OnInit, AfterViewInit {

  autoElem = viewChild<AutoComplete>('autoElem');

  options = input.required<FinderPrefill>();
  protected filteredAutoCompleteOptions: WritableSignal<{value: any, label: string}[]> = signal([]);
  protected readonly OptionFilter = OptionFilter;
  protected autoCompleteHintedOptions = computed(() => {
    const optionsToShow = this.options().autoComplete?.hinting?.options;
    const options = this.options().autoComplete?.options;
    if (!optionsToShow || !options) {
      return [];
    }
    return options.filter(o => optionsToShow.includes(o.value));
  });

  selection = output<any>();

  optionLabel = (option: any) => option.label;
  optionValue = (option: any) => option.value;

  ngOnInit() {
  }

  ngAfterViewInit() {
    // this.autoElem()?.clear();
  }

  autoCompleteSelected(event: AutoCompleteSelectEvent) {
    const autoComplete = this.options().autoComplete;
    if (!autoComplete) {
      return;
    }
    autoComplete.selected.set(event.value.value);
    this.selection.emit(event.value.value);
    this.autoElem()?.clear();
  }

  constructor() {
    // effect(() => {
    //   const options = this.options();
    //   const autoElem = this.autoElem();
    //   if (!autoElem) {
    //     return;
    //   }
    //   autoElem.clear();
    // }, {allowSignalWrites: true});

  }


}

export interface FinderPrefill {
  main: {
    labels: FinderPrefillTitle;
  };
  bars?: FinderPrefillBars<any>;
  autoComplete?: FinderPrefillAutoComplete<any>;
  footer?: Partial<FinderPrefillTitle>;
}

export interface FinderPrefillTitle {
  title: string;
  subTitle?: string;
}

export interface FinderPrefillAutoComplete<T> {
  options: readonly {value: T, label: string}[];
  selected: WritableSignal<T|null>;
  label?: string;
  placeholder: string;
  hinting?: {
    label: string;
    options: T[];
  };
}

export interface FinderPrefillBars<T> {
  allowUnselected?: boolean;
  options: readonly {value: T, label: string, icon?: string, description?: string}[];
  selected: WritableSignal<T|null>;
}

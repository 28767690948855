import {StateAbv} from "@mindpath/shared";
import {Address, Schema} from "@mindpath/shared";
import ApiCalls = Wizard.ApiCalls;
import InsurancePlanOption = Wizard.InsurancePlanOption;
import {config} from "../config";

export namespace Wizard {




  export interface PatientInsurance {
    readonly insuranceId: number;
    readonly planId: number;
    readonly memberId: string;
    readonly groupNumber: string;
    readonly isNotPolicyHolder: boolean;
    readonly holderFullName: string|null;
    readonly holderDateOfBirth: Date|null;
    readonly isPrimary: boolean;
    readonly omitAttachment: boolean;
  }

  export interface Location {
    readonly id: number;
    readonly address: Address;
    readonly contactables: {contactableType: 'phone'|'email', value: string, isPrimary: boolean}[];
  }

  export interface Clinician {
    readonly id: number;
    readonly npi: number;
    readonly firstName: string;
    readonly lastName: string;
    readonly credentials: string;
    readonly photoUrl: string;
    readonly insurances: readonly number[];
    readonly conditions: readonly number[];
    readonly type: 'Prescriber'|'Therapist';
    readonly languages: readonly string[];
    readonly ageRange: {
      readonly min: number,
      readonly max: number
    },
  }

  export interface IntakeRequest {
    readonly id: string;
    readonly otpTarget?: 'email'|'sms'|'phone';
    readonly otpCode?: string;
    readonly patient: Omit<IIntakePatient, 'patientId'>;
    readonly hold: {
      readonly clinicianNpi: number;
      readonly date: Date;
      readonly duration: number;
      readonly locationId: number|null;
      readonly setting: 'Telehealth'|'InPerson'|'Hybrid';
    }
    readonly insurances?: PatientInsurance[];
    readonly reason: {
      readonly conditionId: number;
      readonly reasonType: 'Psychiatry'|'Therapy';
    }
  }

  export interface ApiCalls {
    initializeIntake: (data: IntakeRequest) => Promise<Token & {id: string}>;
    updateIntake: (token: Token, id: string, data: IntakeRequest) => Promise<Token & {id: string}>;
    uploadAttachment: (token: Token, id: string, data: ReadableStream|FormData) => Promise<Token>;
    finalizeIntake: (token: Token, id: string, data: IntakeRequest) => Promise<{formsLink: string, attachments: AttachmentToken[]}>;
    getInsurances: (state: string) => Promise<readonly OptionItem[]>;
    getInsurancePlans: (state: string, insuranceId: number) => Promise<readonly InsurancePlanOption[]>;
    getConditions: () => Promise<readonly OptionItem[]>;
  }

  export interface Token {
    readonly token: string;
    readonly expiresAt: number;
  }

  export interface AttachmentToken extends Token {
    readonly data: {
      forPrimary: boolean;
      side: 'front'|'back';
    }
  }


  export interface Hooks {
    readonly onCompleted?: () => void;
    readonly onReset?: () => void;
    readonly onError?: (error: Error) => void;
    readonly onDataChange?: (data: DataChangeHookInfo) => void;
    readonly onExit: (reason: ExitReason) => void;
  }

  export interface DataChangeHookInfo {
    insuranceId: number|null;
    planId: number|null;
    state: StateAbv|null;
    conditionId: number|null;
    reason: 'Psychiatry'|'Therapy'|null
    dateOfBirth: Date|null,
  }

  export enum ExitReason {
    BackOutFirstStep = 'back-out-first-step',
    OutOfState = 'out-of-state',
    OutOfNetwork = 'out-of-network',
    OutOfAgeRange = 'out-of-age-range',
    UnsupportedLanguage = 'unsupported-language',
    UnsupportedCondition = 'unsupported-condition',
    AppointmentSlotTaken = 'appointment-slot-taken',
  }

  export interface OptionItem {
    readonly id: number;
    readonly name: string;
    readonly sortOrder?: number;
  }

  export interface InsurancePlanOption {
    readonly id: number;
    readonly name: string;
    readonly bookingStatus: 'OkayToSchedule'|'DoNotSchedule'|'NeedsVerification';
  }


  export interface UserData {
    readonly insuranceId?: number|null;
    readonly planId?: number|null;
    readonly state?: string|null;
    readonly conditionId?: number|null;
    readonly visitPreference?: 'Telehealth'|'InPerson'|'Hybrid';
    readonly reason?: 'Psychiatry'|'Therapy'
    readonly clinician: Schema<'ClinicianDetailedInfo'>
    readonly appointment: {
      readonly date: Date;
      readonly duration: number;
      readonly location: Schema<'ClinicianAvailabilityInfo'>['location'];
      readonly setting: 'Telehealth'|'InPerson'|'Hybrid';
    }
  }
  export interface Args {
    readonly host: HTMLElement | string;
    readonly data: UserData,
    readonly useCache: boolean;
    readonly api: ApiCalls;
    readonly hooks: Hooks;
  }

  export interface Instance {
    readonly destroy: () => void;
    readonly reset: () => void;
    readonly tag: string;
    readonly data: () => UserData;
  }

}


export interface IContactDetails {
  readonly firstName: string;
  readonly lastName: string;
  readonly phoneNumbers: {type: 'mobile', number: string}[]
  readonly emails: {address: string}[]
  readonly addresses: {address: Address}[]
}

export interface IIntakePatient {
  readonly patientId: string;
  readonly contactDetails: IContactDetails;
  readonly sexAtBirth: 'male'|'female'|'none';
  readonly dateOfBirth: Date;

}

export interface IIntakeItem extends IIntakeItemGenericInfo {

  readonly insurances: readonly IIntakeInsuranceInfo[];
  readonly notes: {
    readonly id: number;
    readonly currentVersion: {
      readonly id: number;
      readonly subject?: string;
      readonly body: string;
      readonly createdAt: Date;
      readonly createdBy: string;
    }
  }[];
}


export interface IIntakeInsuranceInfo {
  readonly insurancePlanId: number;
  readonly memberNumber: number;
  readonly groupNumber: number;
  readonly isNotPolicyHolder: boolean;
}

export interface IIntakeItemGenericInfo {
  readonly id: number;
  readonly bucketId: number;
  readonly tags: IntakeTagValueMap;
  readonly patientInfo: IIntakePatient;
  readonly createdAt: Date;
  readonly patientContactDetails: {
    readonly firstName: string;
    readonly lastName: string;
  }
}

export interface IntakeTagValueMap {
  [IntakeTagType.Urgency]: IntakeUrgencyTag;
  [IntakeTagType.Owner]: string;
  [IntakeTagType.Custom]: readonly ICustomIntakeTag[];
  [IntakeTagType.HasDuplicates]: boolean;
}

export type IntakeTag<T extends keyof IntakeTagValueMap> = IntakeTagValueMap[T];

export interface ICustomIntakeTag {
  readonly id: number;
  readonly name: string;
  readonly value: unknown;
}

export enum IntakeBucketName
{
  None = 'None',
  Incomplete = 'Incomplete',
  NewPatient = 'NewPatient',
  PreAppointment = 'PreAppointment',
}


export enum IntakeTagType
{
  None = 'None',
  Urgency = 'Urgency',
  Owner = 'Owner',
  Custom = 'Custom',
  HasDuplicates = 'HasDuplicates',
}

export enum IntakeUrgencyTag
{
  None = 'None',
  Urgent = 'Urgent',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}


export const WizardApiStubs: ApiCalls = {
  getConditions: async () => {

    const getPage = async (page = 1):  Promise<any[]> => {
      const result = await fetch(`${config.endpoints.api}/api/conditions`)
        .then(res => {
          if (!res.ok) {
            throw new Error('Failed to fetch conditions');
          }
          return res.json();
        });
      if (result.hasMore) {
        return result.items.concat(await getPage(page + 1));
      }
      return result.items;
    }

    return  (await getPage()).map(({id, name}) => ({id, name}));

  },
  initializeIntake: async (data) => {
    return fetch(`${config.endpoints.api}/api/intake/requests`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }).then(async res => {
      if (!res.ok) {
        throw new Error(await res.text());
      }
      return await res.json();
    });
  },
  updateIntake: (token, id, data) => {

    return fetch(`${config.endpoints.api}/api/intake/requests/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      },
      body: JSON.stringify(data)
    }).then(async res => {
      if (!res.ok) {
        throw new Error(await res.text());
      }
      return {...await res.json(), id};
    });
  },
  finalizeIntake: (token, id, data) => {
    return fetch(`${config.endpoints.api}/api/intake/requests/${id}/hold`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      },
      body: JSON.stringify(data)
    }).then(async res => {
      if (!res.ok) {
        throw new Error(await res.text());
      }
      return {...await res.json(), id};
    });
  },
  uploadAttachment: async (token, id, formData) => {
    // console.log('uploading-stub', formData);
    if (formData instanceof ReadableStream) {
      throw new Error('Streams not supported');
    }
    return fetch(`${config.endpoints.api}/api/intake/requests/${id}/attachments`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token.token}`,
      },
      body: formData,
    }).then(async res => {
      if (res.status !== 202 && !res.ok) {
        throw new Error(await res.text());
      }
      return null as any;
    });
  },
  getInsurances: async (state: string) => {

    const getPage = async (page = 1):  Promise<any[]> => {
      const result = await fetch(`${config.endpoints.api}/api/insurances?pageSize=100&page=${page}&filter.state=eq '${state}'`)
        .then(res => {
          if (!res.ok) {
            throw new Error('Failed to fetch insurances');
          }
          return res.json();
        });
      if (result.hasMore) {
        return result.items.concat(await getPage(page + 1));
      }
      return result.items;
    }

    return  await getPage();



    // return [{id: 132, name: 'Blue Cross Blue Shield'}];
  },
  getInsurancePlans: async (state, insuranceId) => {
    const getPage = async (page = 1):  Promise<InsurancePlanOption[]> => {
      const result = await fetch(`${config.endpoints.api}/api/insurances/${insuranceId}/plans?pageSize=100&page=${page}&filter.state=eq '${state}'`)
        .then(res => {
          if (!res.ok) {
            throw new Error('Failed to fetch insurance plans');
          }
          return res.json();
        })
      if (result.hasMore) {
        return result.items.concat(await getPage(page + 1));
      }
      return result.items;
    }

    return await getPage();
    // return [{id: 456, name: 'PPO'}];
  }
}

import {Component, input} from '@angular/core';
import {CheckboxComponent} from "../checkbox/checkbox.component";

@Component({
  selector: 'lib-bar',
  standalone: true,
  imports: [
    CheckboxComponent
  ],
  templateUrl: './bar.component.html',
  styleUrl: './bar.component.css'
})
export class BarComponent {

  mode = input<'blend'|'standout'>('standout');
  customClass = input<string>('');

}

import {Component, computed, effect, inject, input, model, output, signal, viewChild} from '@angular/core';
import {AutoComplete, AutoCompleteCompleteEvent, AutoCompleteModule} from "primeng/autocomplete";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  Address,
  loadApiEndpoint,
  loadApiEndpointResp,
  MessageCode,
  Schema,
  Schemas
} from "@mindpath/shared";
import {AddressPipe, FormatAddress} from "@mindpath/shared";
import {DropdownModule} from "primeng/dropdown";
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {
  BarComponent,
  ButtonComponent,
  IconComponent,
  IconSet,
  IconType,
  InputComponent
} from "@mindpath/shared";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {OnlineSchedulerService} from "../../../online-scheduler.service";
import {NgTemplateOutlet} from "@angular/common";
import {AppStateService} from "../../../app-state.service";
import {config} from "../../../../config";
import {Logger} from "@mindpath/shared";
import {GetTrackEvent} from "../../../piwik";

@Component({
  selector: 'app-location-info-step',
  standalone: true,
  imports: [
    AutoCompleteModule,
    FormsModule,
    AddressPipe,
    DropdownModule,
    FlexModule,
    ReactiveFormsModule,
    InputComponent,
    ProgressSpinnerModule,
    ButtonComponent,
    IconComponent,
    BarComponent,
    ExtendedModule,
    NgTemplateOutlet,

  ],
  templateUrl: './location-info-step.component.html',
  styleUrl: './location-info-step.component.scss'
})
export class LocationInfoStepComponent {

  drowpdown = viewChild<AutoComplete>('drowpdown')

  justInputs = input<boolean>(false);

  finder = inject(OnlineSchedulerService);
  app = inject(AppStateService);
  matches = signal<readonly Schema<'Address'>[] | undefined>([]);
  isLoading = computed(() => {
    return this.matches() === undefined;
  });

  readonly canUseCurrentLocation = window.navigator.geolocation;
  onCompleted = output();
  state = computed(() => {
    const data = this.finder.data.demographics()?.address?.state;
    return data ? data : null;
  });
  canResume = computed(() => {
    return false;
    return !!this.finder.data.meta().resumableTo;
    // return false;
  });
  protected readonly IconType = IconType;

  protected readonly IconSet = IconSet;
  #abortController: AbortController | undefined;


  constructor() {
  }

  advance() {
    this.onCompleted.emit();
  }

  useCurrentLocation() {
    window.navigator.geolocation.getCurrentPosition(position => {
        this.reverseSearch({lat: position.coords.latitude, long: position.coords.longitude});
      },
      (err) => {
        if (err.code === 1) {
          alert('Please enable location services to use this feature');
          return;
        }
        alert('Unable to get current location');
      },
      {
        enableHighAccuracy: false,
        timeout: 5000,
      });
  }

  reset() {
    this.matches.set([]);
    this.#abortController?.abort?.();
    this.app.clearLocation();
  }

  itemLabel = (opt: Schema<'Address'>) => FormatAddress(opt, {inline: true});

  resume() {
    const resumableTo = this.finder.data.meta().resumableTo;
    if (!resumableTo) {
      return;
    }
    this.finder.piwik.trackEvent('meta', 'resumed', resumableTo as any);
    this.finder.goto(resumableTo.section, resumableTo.step);
  }

  chose(match: Schema<'Address'>) {
    const current = this.state();
    this.finder.piwik.trackEvent('schedule', 'change_location', {from: current,to: match.state});
    // if the state is changing, then we need to clear the resumable state
    if (current !== match.state) {
      this.finder.data.meta.update(m => {
        return {
          ...m,
          resumableTo: undefined
        }
      });
    }

    this.app.chooseLocation(match.state as any, match.coordinates!, match);
    if (!this.app.isStateValid()) {
      return;
    }
    this.advance();
  }

  async reverseSearch(coords: Schema<'Coordinates'>) {
    this.finder.showLoading('Validating location');
    this.#abortController?.abort?.();
    this.#abortController = new AbortController();
    this.matches.set(undefined);
    try {
      const reverseStart = Date.now();
      const resp = await loadApiEndpointResp(config.endpoints.api, '/api/Addressing/reverse').get({
        query: {lat: coords.lat, lon: coords.long},
        fetchOptions: {signal: this.#abortController.signal}
      });

      if (!resp.ok) {
        Logger.Error('LocationInfo', await resp.text());
        this.finder.showError(MessageCode.Unknown_Browser_Exception, 'Error validating location', 'An error occurred while validating your location');
        return;
      }
      let address = await resp.json() as Schema<'Address'>;
      address = {
        city: address.city,
        state: address.state,
        coordinates: address.coordinates,
      } as any;
      this.matches.set([address]);
      this.chose(address);
      setTimeout(() => {
        this.finder.hideLoading();
      }, Math.max(0, 350 - (Date.now() - reverseStart)));
    } catch (e) {
      Logger.Error('LocationInfo', e);
      this.finder.showError(MessageCode.Unknown_Browser_Exception, 'Error validating location', 'An error occurred while validating your location');
    }
  }

  async search(event: AutoCompleteCompleteEvent) {
    this.#abortController?.abort?.();
    this.matches.set(undefined);
    if (!event.query?.trim()) {
      this.matches.set([]);
      return
    }
    this.#abortController = new AbortController();
    try {
      this.matches.set(await loadApiEndpoint(config.endpoints.api, '/api/Addressing/matches').get({
        query: {query: event.query},
        fetchOptions: {signal: this.#abortController.signal}
      }));
    } catch (e: any) {
      if (e.name === 'AbortError') {
        return;
      }
    }
  }

  protected readonly config = config;
}

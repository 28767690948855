import {
  Component,
  EnvironmentInjector,
  inject,
  input, model, output,
  Signal,
  signal,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {FlexModule} from "@ngbracket/ngx-layout";
import {Filters, Filter} from "./filters";
import {NgComponentOutlet, NgTemplateOutlet} from "@angular/common";
import {QueryOptions} from "@mindpath/shared";
import {SettingFilterComponent} from "./setting-filter/setting-filter.component";
import {ServiceFilterViewComponent} from "./service-filter-view/service-filter-view.component";
import {AvailabilityFilterViewComponent} from "./availability-filter-view/availability-filter-view.component";
import {GenderFilterComponent} from "./gender-filter/gender-filter.component";
import {LanguageFilterComponent} from "./language-filter/language-filter.component";

@Component({
  selector: 'app-search-filter-bar',
  standalone: true,
  imports: [
    FlexModule,
    NgTemplateOutlet,
    NgComponentOutlet,
    SettingFilterComponent,
    ServiceFilterViewComponent,
    AvailabilityFilterViewComponent,
    GenderFilterComponent,
    LanguageFilterComponent
  ],
  templateUrl: './search-filter-bar.component.html',
  styleUrl: './search-filter-bar.component.scss'
})
export class SearchFilterBarComponent {

  filters = model.required<QueryOptions<'/api/Availability/slots'>>();

  ngOnInit() {
  }

  protected readonly Filters = Filters;
}

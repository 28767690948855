<lib-card
  [cardStyle]="{border: '1px solid var(--border-static-border-primary)', borderRadius: 'var(--size-16)', width: '100%'}"
  style="width: 100%;">
  <ng-template templateFor="title">
    <div fxLayout="row" fxLayoutAlign="start start" gap="16"
         row-gap="16" class="p-12">
      <div fxLayout="column" fxLayoutAlign="start center">
        @if (clinician(); as clinician) {

          @if (clinician.photoUrl; as photoUrl) {
            <!--          <img class="headshot" [ngSrc]="photoUrl" [priority]="false" width="80" height="80" alt="{{clinician() | clinicianName}}">-->
            <a href="{{(config.endpoints.mainSite + '/clinicians/' +clinician.npiNumber) | appendAppStateParams}}">

              <img ngSrc="{{ photoUrl }}" class="clinician-pic headshot"
                   priority
                   alt="{{clinician | clinicianName}}"
                   width="80" height="80" style="border-radius: 50%">
            </a>
          }
          @if (clinician.reviewStats; as reviewStats) {
            <span fxLayout="row" gap="4" class="rating" fxLayoutAlign="center center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" height="14" width="14">
                <path fill="currentColor" fill-rule="evenodd"
                      d="M8.018.667c.126 0 .241.071.298.184l2.174 4.364 4.892.713a.333.333 0 0 1 .181.572l-3.548 3.377.82 4.787a.333.333 0 0 1-.483.352l-4.375-2.277-4.384 2.251a.333.333 0 0 1-.48-.355l.85-4.781L.435 6.453a.333.333 0 0 1 .186-.57l4.897-.684L7.72.85a.33.33 0 0 1 .298-.182m-.003 1.076-1.98 3.913a.33.33 0 0 1-.252.18l-4.392.612 3.162 3.049c.08.077.117.189.097.298l-.763 4.295 3.94-2.023a.33.33 0 0 1 .305.001l3.932 2.047-.736-4.3a.33.33 0 0 1 .099-.298l3.18-3.028-4.386-.64a.33.33 0 0 1-.25-.181z"
                      clip-rule="evenodd"></path>
                <path fill="currentColor"
                      d="M8.018.667c.126 0 .241.071.298.184l2.174 4.364 4.892.713a.333.333 0 0 1 .181.572l-3.548 3.377.82 4.787a.333.333 0 0 1-.483.352l-4.375-2.277-4.384 2.251a.333.333 0 0 1-.48-.355l.85-4.781L.435 6.453a.333.333 0 0 1 .186-.57l4.897-.684L7.72.85a.33.33 0 0 1 .298-.182"></path>
                <path fill="currentColor" fill-rule="evenodd"
                      d="M8.645 8.053A.87.87 0 0 0 8 7.763a.88.88 0 0 0-.644.306c-.368.409-.871 1.138-.871 2.09 0 .779.339 1.398.662 1.812a3.8 3.8 0 0 0 .641.641l.014.01.004.003.001.001h.001L8 12.356l-.192.272a.33.33 0 0 0 .392-.005l-.2-.267.2.266.002-.001.004-.003.013-.01a2 2 0 0 0 .194-.169c.121-.114.283-.28.444-.494.321-.424.66-1.054.66-1.833 0-.945-.5-1.655-.868-2.055m-.654 3.86c.095-.093.213-.219.33-.374.271-.358.524-.85.524-1.43 0-.706-.374-1.261-.691-1.604m0 .001c-.059-.065-.114-.08-.152-.08-.038.001-.095.018-.155.085-.32.356-.7.931-.7 1.644 0 .58.252 1.059.52 1.403.116.148.23.267.324.354m.652-3.86-.002-.002zm0 0 .001.001"
                      clip-rule="evenodd"></path>
              </svg>
              <strong class="text-caption">{{ reviewStats.average | number:'1.1-1' }}</strong>
              <span>({{ reviewStats.count }})</span>
            </span>
          }
        }
      </div>
      <div fxLayout="column">
        @if (clinician(); as clinician) {
          <a class="link blend" href="{{(config.endpoints.mainSite + '/clinicians/' +clinician.npiNumber) | appendAppStateParams}}">
            <h4 class="heading-5">
              {{ clinician | clinicianName }}
            </h4>
          </a>
          <!--          <h5>{{ clinician.type | clinicianSpecialty }}</h5>-->

        }
        <span class="address">
          @if (info().location; as location) {
            @if (distanceFromUserInMiles(location.address!.coordinates!); as distance) {
              <div style="display: inline-block; height: 14px; width: 14px;">
                <span>
              <svg style="transform: translateY(1px)" height="14" width="14" xmlns="http://www.w3.org/2000/svg"
                   fill="none" viewBox="0 0 16 16"><g
                clip-path="url(#location_svg__a)"><path fill="#E4D1FF" stroke="#9146FF"
                                                        d="M.5 8C.5 3.865 3.865.5 8 .5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5S.5 12.135.5 8Z"></path><path
                fill="#41145F" d="m7.57 8.43.562 4.07 1.684-4 1.684-4-4 1.684-4 1.684z"></path></g><defs><clipPath
                id="location_svg__a"><path fill="#fff" d="M0 0h16v16H0z"></path></clipPath></defs></svg>
                </span>
              </div>

              <span class="pl-4">{{ distance }} {{ 'mile' | plural:distance }}</span>
              <span style="font-size: 8px; vertical-align: middle;" class="px-4">
                &bull;
              </span>
            }
            <span>{{ location.address!.city }}, {{ location.address!.state }}
            </span>
            <span style="font-size: 8px; vertical-align: middle;" class="px-4">
                &bull;
              </span>
            <span>{{ location.address!.address1 }}
              @if (location.address!.address2; as address2) {
                <span>, {{ address2 }}</span>
              }
            </span>
          }
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template templateFor="content">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="12" style="width: 100%;">
      @if (viewableGroups(); as grouped) {
        <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="8" style="width: 100%; height: 100%;">
          <button fxFlex="40px" class="page-arrow-holder p-16" fxLayout="column" fxLayoutAlign="center center" type="button"
                  (click)="groupPage() != 0 ? groupPage.set( groupPage() - 1) : null">
            @if (groupPage() != 0) {
              <span class="page-arrow btn btn-white btn-circle btn-icon arrow-left"  style="width: 32px">
              </span>
            }
          </button>
          <div fxLayout="row [wrap]" gap="4" fxLayoutAlign="start"
               ngClass.lt-sm="row-gap-8 gap-8" fxFlex class="py-12 day-holder" #dayHolder>
            @for (group of grouped; track group.appts) {
              <button fxLayout="column" fxLayoutAlign="space-between start" fxFlex="80px"
                      fxFlex.lt-sm="calc(50% - 12px)" fxLayoutAlign.lt-sm="center start"
                      [ngStyle.lt-sm]="{maxWidth: 'calc(50% - 12px)'}"
                      class="day-slot" (click)="dateClicked(group.date)">
              <span fxLayout="column" fxLayoutAlign="start start">
                <span class="day-slot-week-day">{{ group.date | date: 'EE':'UTC' }}</span>
                <span class="day-slot-date text-primary">
                  <strong>{{ group.date | date: 'MMM dd':'UTC' }}</strong>
                </span>
              </span>
                <span style="text-decoration: underline; font-size: 16px"
                      class="link text text-primary-light">{{ group.appts.length }} {{ 'spot' | plural:group.totalCount }}</span>
              </button>
            }
          </div>
          <button fxFlex="40px" class="page-arrow-holder p-16" fxLayout="column" fxLayoutAlign="center center" type="button" (click)="totalGroups() - 1 != groupPage() ? groupPage.set(groupPage() + 1) : null">
            @if (totalGroups() - 1 != groupPage()) {
              <span class="page-arrow btn btn-white btn-circle btn-icon arrow-right"
                    style="width: 32px">
              </span>
            }
          </button>

        </div>
      } @else {
        <div fxLayout="column">
          <p-skeleton styleClass="mb-2"/>
          <div fxLayout="row">
            <p-skeleton styleClass="mr-2" width="3rem" height="3rem"/>
            <p-skeleton styleClass="mr-2" width="3rem" height="3rem"/>
            <p-skeleton styleClass="mr-2" width="3rem" height="3rem"/>
          </div>
        </div>

      }
    </div>
  </ng-template>
</lib-card>

<ng-template #sideBar>
  <div fxLayout="row" fxLayoutAlign="start start" gap="16"
       row-gap="16">
    <div fxLayout="column" fxLayoutAlign="start center">
      @if (clinician(); as clinician) {

        @if (clinician.photoUrl; as photoUrl) {
          <img class="headshot" [ngSrc]="photoUrl" [priority]="false" width="80" height="80"
               alt="{{clinician | clinicianName}}">
        }
        @if (clinician.reviewStats; as reviewStats) {
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
            <path fill="currentColor" fill-rule="evenodd"
                  d="M8.018.667c.126 0 .241.071.298.184l2.174 4.364 4.892.713a.333.333 0 0 1 .181.572l-3.548 3.377.82 4.787a.333.333 0 0 1-.483.352l-4.375-2.277-4.384 2.251a.333.333 0 0 1-.48-.355l.85-4.781L.435 6.453a.333.333 0 0 1 .186-.57l4.897-.684L7.72.85a.33.33 0 0 1 .298-.182m-.003 1.076-1.98 3.913a.33.33 0 0 1-.252.18l-4.392.612 3.162 3.049c.08.077.117.189.097.298l-.763 4.295 3.94-2.023a.33.33 0 0 1 .305.001l3.932 2.047-.736-4.3a.33.33 0 0 1 .099-.298l3.18-3.028-4.386-.64a.33.33 0 0 1-.25-.181z"
                  clip-rule="evenodd"></path>
            <path fill="currentColor"
                  d="M8.018.667c.126 0 .241.071.298.184l2.174 4.364 4.892.713a.333.333 0 0 1 .181.572l-3.548 3.377.82 4.787a.333.333 0 0 1-.483.352l-4.375-2.277-4.384 2.251a.333.333 0 0 1-.48-.355l.85-4.781L.435 6.453a.333.333 0 0 1 .186-.57l4.897-.684L7.72.85a.33.33 0 0 1 .298-.182"></path>
            <path fill="currentColor" fill-rule="evenodd"
                  d="M8.645 8.053A.87.87 0 0 0 8 7.763a.88.88 0 0 0-.644.306c-.368.409-.871 1.138-.871 2.09 0 .779.339 1.398.662 1.812a3.8 3.8 0 0 0 .641.641l.014.01.004.003.001.001h.001L8 12.356l-.192.272a.33.33 0 0 0 .392-.005l-.2-.267.2.266.002-.001.004-.003.013-.01a2 2 0 0 0 .194-.169c.121-.114.283-.28.444-.494.321-.424.66-1.054.66-1.833 0-.945-.5-1.655-.868-2.055m-.654 3.86c.095-.093.213-.219.33-.374.271-.358.524-.85.524-1.43 0-.706-.374-1.261-.691-1.604m0 .001c-.059-.065-.114-.08-.152-.08-.038.001-.095.018-.155.085-.32.356-.7.931-.7 1.644 0 .58.252 1.059.52 1.403.116.148.23.267.324.354m.652-3.86-.002-.002zm0 0 .001.001"
                  clip-rule="evenodd"></path>
          </svg>
          <strong class="text-caption">{{ reviewStats.average | number:'1.1-1' }}</strong>
          <span>({{ reviewStats.count }})</span>
        }
      }
    </div>
    <div fxLayout="column">
      @if (clinician(); as clinician) {

        <a class="link blend pk_confirm_clinician-profile"
           href="{{(config.endpoints.mainSite + '/clinicians/' +clinician.npiNumber) | appendAppStateParams}}">
          <h5 class="heading-5">{{ clinician | clinicianName }}</h5>
        </a>
        <h6 class="heading-6">{{ clinician.type | clinicianSpecialty }}</h6>
        <span class="address">
          @if (info().location; as location) {
            @if (distanceFromUserInMiles(location.address!.coordinates!); as distance) {
              <div style="display: inline-block; height: 14px; width: 14px;">
                <span>
              <svg style="transform: translateY(3px)" height="14" width="14" xmlns="http://www.w3.org/2000/svg"
                   fill="none" viewBox="0 0 16 16"><g
                clip-path="url(#location_svg__a)"><path fill="#E4D1FF" stroke="#9146FF"
                                                        d="M.5 8C.5 3.865 3.865.5 8 .5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5S.5 12.135.5 8Z"></path><path
                fill="#41145F" d="m7.57 8.43.562 4.07 1.684-4 1.684-4-4 1.684-4 1.684z"></path></g><defs><clipPath
                id="location_svg__a"><path fill="#fff" d="M0 0h16v16H0z"></path></clipPath></defs></svg>
                </span>
              </div>

              <span style=" padding-left: 8px;">{{ distance }} {{ 'mile' | plural:distance }}</span>
              <span style="font-size: 8px; vertical-align: middle;" class="px-4">
                &bull;
              </span>
            }
            <span>{{ location.address!.city }}, {{ location.address!.state }}
              <span style="font-size: 8px; vertical-align: middle;" class="px-4">
                &bull;
              </span>
            </span>
            <span>{{ location.address!.address1 }}
              @if (location.address!.address2; as address2) {
                <span>, {{ address2 }}</span>
              }
            </span>
          }
        </span>
      }
    </div>
    <div>
      <button type="button" class="link text blend" (click)="finder.sidebar.set(null)">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"
                d="M21 3 3 21M3 3l18 18"></path>
        </svg>
      </button>
    </div>
  </div>
  <p-divider></p-divider>
  <!--  {{grouped()?.length}}-->
  <div fxLayout="column" row-gap="8" class="p-8">
    @for (group of grouped(); track group.appts) {
      <app-availability-slots-card-view
        [info]="info()"
        id="{{group.date.toISOString().split('T')[0] === focusedDate()?.toISOString?.()?.split?.('T')?.[0] ? 'focused' : ''}}"
        (slotClicked)="slotClicked.emit({date: group.date, slot: $event.slot, setting: $event.setting})"
        [isFocused]="group.date.toISOString().split('T')[0] === focusedDate()?.toISOString?.()?.split?.('T')?.[0]"
        [date]="group.date"
        [slots]="group.appts"></app-availability-slots-card-view>
    }
  </div>
</ng-template>
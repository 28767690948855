<span class="icon {{set()}} {{type() ?? IconType.None}} {{verticalAlign()}}" style="width: {{size()}}px; height: {{size()}}px; line-height: {{size()}}px; font-size: {{size() * 1.5}}px; box-sizing: unset !important;">
  @switch (set()) {
    @case (IconSet.Mindpath) {
      <span class="icon-mindpath  {{colorClass()}} svgmask-{{name()}}"></span>
      <!--      <img ngSrc="public/svg/{{name()}}" [alt]="alt()" [width]="size()" [height]="size()"/>-->
    }
    @case (IconSet.Text) {
<!--      <span class="icon-text {{colorClass()}}" style="width: {{size()}}px; height: {{size()}}px;">{{name()}}</span>-->
      {{name()}}
    }
  }
</span>

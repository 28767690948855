<section>
  <h3>
    Create your profile
  </h3>
  <h5 class="text-secondary" style="font-weight: normal">
    We'll use this information to create your Mindpath Health patient profile.
  </h5>
  <div fxLayout="column" ngClass.gt-xs="row-gap-40" ngClass.lt-sm="row-gap-16">
    <form [formGroup]="form()" class="text-left pt-24" gap="16" fxLayout="column" ngClass.gt-xs="row-gap-24" ngClass.lt-sm="row-gap-12"
    >
      <lib-grouped-elements>
        <lib-text-input flex="100%" autocompleteType="given-name" label="Patient first name" style="width: 100%"
                        class="pk_profile_first-name"
                        control="firstName"></lib-text-input>

        <lib-text-input flex="100%" autocompleteType="family-name" label="Patient last name" style="width: 100%"
                        class="pk_profile_last-name"
                        control="lastName"></lib-text-input>
      </lib-grouped-elements>

      <lib-grouped-elements>
        <lib-input fxFlex="40" label="Current residence state"
                   [valid]="form().controls.state.valid">


<!--          <p-select-->
<!--            [ngStyle]="{width: '100%'}"-->
<!--            styleClass="input-field form-field"-->
<!--            [options]="statesOptions"-->
<!--            formControlName="sexAtBirth"-->
<!--            optionLabel="label"-->
<!--            optionValue="value"-->
<!--            [filter]="true"-->
<!--            name="sex"-->
<!--            [showClear]="false"-->
<!--            placeholder="Select">-->
<!--            <ng-template pTemplate="header">-->

<!--            </ng-template>-->
<!--            <ng-template let-sex pTemplate="item">-->
<!--              <span class="p-4">{{ sex.label }}</span>-->
<!--            </ng-template>-->
<!--          </p-select>-->



          <p-autoComplete
            #drowpdown
            [ngStyle]="{width: '100%'}"
            class="{{ selectedState() ? 'pk_profile_state--' + selectedState() : ''}}"
            [suggestions]="$any(filteredStates())"
            formControlName="state"
            [multiple]="false"
            [optionLabel]="optionLabelFn"
            [optionValue]="optionValFn"
            [showClear]="false"
            [dropdown]="true"
            [forceSelection]="true"
            name="state"
            (completeMethod)="OptionFilter(statesOptions, filteredStates, {value: 'XX', label: 'I Don\'t see my state'})($event)"
            placeholder="Select">
            <ng-template let-state pTemplate="selectedItem">
              <span class="p-4">{{ state.label }}</span>
            </ng-template>
            <ng-template let-state pTemplate="item">
              <span class="p-4">{{ state.label }}</span>
            </ng-template>
          </p-autoComplete>
        </lib-input>
        <lib-input label="Sex assigned at birth" [valid]="form().controls.sexAtBirth.valid" fxFlex
                   tooltip="Required for insurance verification purposes">
          <p-select
            [ngStyle]="{width: '100%'}"
            class="pk_profile_birth-sex"
            styleClass="input-field form-field"
            [options]="sexOptions"
            formControlName="sexAtBirth"
            optionLabel="label"
            optionValue="value"
            name="sex"
            [showClear]="false"
            placeholder="Select">
            <ng-template pTemplate="header">

            </ng-template>
            <ng-template let-sex pTemplate="item">
              <span class="p-4">{{ sex.label }}</span>
            </ng-template>
          </p-select>
        </lib-input>
        <lib-input label="Date of birth" [valid]="form().controls.dateOfBirth.valid">
          <input type="text" pInputText
                 mask="00/00/0000"
                 class="pk_profile_dob"
                 autocomplete="bday"
                 placeholder="MM/DD/YYYY" formControlName="dateOfBirth"
                 (keyup.enter)="form().valid && finder.goForward()">
        </lib-input>
      </lib-grouped-elements>

      @if (outOfState()) {
        <div class="notice hide-empty pvk_profile_stop">
    <span>
          <lib-icon name="?" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Warn" [size]="8"></lib-icon>
    </span>
          <div class="notice-info">
            <h6 class="heading-6 text-heading-2 hide-empty">Out-of-state</h6>
            <span class="sub-text text-body-secondary hide-empty text-body-sm pb-16">
              @if (isDontSeeState()) {
                The appointment you have selected is in a different state than your current location.
              } @else {
              The appointment you have selected is in a different state than your current location.
              <br/>We currently offer services in Arizona, California, Florida, North Carolina, South Carolina, and Texas. We do not have appointments available outside of these states.
            }

        </span>
            <button type="button" class="btn btn-large step-next pk_profile_exit"
                    (click)="finder.goto('prefill', 'location')">
              View in-state clinicians
              <lib-icon [size]="16" class="icon" verticalAlign="top" name="chevron-right"
                        [type]="IconType.None"></lib-icon>
            </button>
          </div>
        </div>
      } @else if (isUnderAge()) {
      <div class="notice hide-empty pvk_profile_stop-under-age">
    <span>
          <lib-icon name="?" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Warn" [size]="8"></lib-icon>
    </span>
        <div class="notice-info">
          <h6 class="heading-6 text-heading-2 hide-empty">Unsupported Age</h6>
          <span class="sub-text text-body-secondary hide-empty text-body-sm pb-16">
              Online scheduling is currently unavailable for anyone under the age of 18. Call <a
            class="link blend pk_profile_intake"
            href="tel:855-501-1004">855-501-1004</a> to schedule an appointment.
        </span>
        </div>
      </div>
    } @else if (outOfAgeRange()) {
      <div class="notice hide-empty pvk_profile_stop">
    <span>
          <lib-icon name="?" verticalAlign="top" [set]="IconSet.Text" [type]="IconType.Warn" [size]="8"></lib-icon>
    </span>
        <div class="notice-info">
          <h6 class="heading-6 text-heading-2 hide-empty">Unsupported Age</h6>
          <span class="sub-text text-body-secondary hide-empty text-body-sm pb-16">
        The clinician you have selected does not treat patients in your age range.
        </span>
          <button type="button" class="btn btn-large step-next pk_profile_exit"
                  (click)="finder.goto('prefill', 'location')">
            Find different clinicians
            <lib-icon [size]="16" class="icon" verticalAlign="top" name="chevron-right"
                      [type]="IconType.None"></lib-icon>
          </button>
        </div>
      </div>
    } @else if (!form().valid && firedValidationCheck()) {
      <lib-callout [type]="ActionType.Danger" class="{{errorsAsTrackingTags() | join:' '}}">
        <ul>
          @if (form().controls.firstName.errors; as errors) {
            @if (errors['required']) {
              <li>
                <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                          verticalAlign="middle"></lib-icon>
                First name is required
              </li>
            } @if (errors['maxlength']) {
              <li>
                <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                          verticalAlign="middle"></lib-icon>
                First name cannot be longer than 35 characters
              </li>
            } @if (errors['pattern']) {
              <li>
                <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                          verticalAlign="middle"></lib-icon>
                First name can only contain letters and hyphens
              </li>
            }
          } @if (form().controls.lastName.errors; as errors) {

          @if (errors['required']) {
            <li>
              <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                        verticalAlign="middle"></lib-icon>
              Last name is required
            </li>
          } @if (errors['maxlength']) {
            <li>
              <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                        verticalAlign="middle"></lib-icon>
              Last name cannot be longer than 35 characters
            </li>
          } @if (errors['pattern']) {
            <li>
              <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                        verticalAlign="middle"></lib-icon>
              Last name can only contain letters and hyphens
            </li>
          }
        } @if (form().controls.state.errors?.['required']) {
          <li>
            <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                      verticalAlign="middle"></lib-icon>
            <span style="padding-left: 8px;">State is required.</span>
          </li>
        } @if (form().controls.sexAtBirth.errors?.['required']) {
          <li>
            <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                      verticalAlign="middle"></lib-icon>
            <span style="padding-left: 8px;">Sex assigned at birth is required.</span>
          </li>
        } @if (form().controls.dateOfBirth.errors; as errors) {

          @if (errors['required']) {
            <li>
              <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                        verticalAlign="middle"></lib-icon>
              Date of birth is required
            </li>
          } @if (errors['pattern']) {
            <li>
              <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                        verticalAlign="middle"></lib-icon>
              Date of birth format is invalid. Please use MM/DD/YYYY
            </li>
          } @if (errors['invalidDate']) {
            <li>
              <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                        verticalAlign="middle"></lib-icon>
              Date of birth is not a valid date
            </li>
          } @if (errors['pastOrPresent']) {
            <li>
              <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                        verticalAlign="middle"></lib-icon>
              Date of birth cannot be in the future
            </li>
          }
        }

        </ul>
      </lib-callout>
    }
    </form>

    @if (!outOfState() && !outOfAgeRange() && !isUnderAge()) {
      <button type="button" class="btn btn-large step-next pk_profile_continue arrow-right"
              [disabled]="firedValidationCheck() && !form().valid"
              (click)="form().valid || (firedValidationCheck() && form().valid) ? finder.goForward() : firedValidationCheck.set(true)">
        Continue
      </button>
    }
  </div>

</section>
<section>
  <h3>
    Provide your contact information
  </h3>
  <div fxLayout="column" ngClass.gt-xs="row-gap-40" ngClass.lt-sm="row-gap-16">
    <form [formGroup]="form()"
          fxLayout="column" ngClass.gt-xs="row-gap-32" ngClass.lt-sm="row-gap-16">

      <div class="flex flex-col">
        <form [formGroup]="form()" class="flex-col text-left flex" gap="16">
          <lib-grouped-elements>
            <lib-text-input fxFlex="70" autocompleteType="address-line1" label="Address 1"
                            class="pk_contact_address1"
                            control="address1"></lib-text-input>

            <lib-text-input fxFlex="30" [required]="false" autocompleteType="address-line2" label="Address 2"
                            class="pk_contact_address2"
                            control="address2"></lib-text-input>
          </lib-grouped-elements>

          <lib-grouped-elements>
            <lib-text-input fxFlex="40" autocompleteType="address-level2" label="City"
                            class="pk_contact_city"
                            control="city">

            </lib-text-input>
            <lib-input [fxFlex]="35" label="State" [requiredIndicator]="false"
                       [valid]="form().get('state')?.valid ?? false">
              <p-dropdown
                [ngStyle]="{width: '100%'}"
                class="pk_contact_state pk_contact_state--{{ form().get('state')}}"
                styleClass="input-field form-field"
                [class.no-dropdown-icon]="true"
                [options]="stateOptions"
                formControlName="state"
                [optionLabel]="'label'"
                optionValue="value"
                name="state"
                [filter]="true"
                filterBy="label"
                [showClear]="false"
                placeholder="Select">
                <ng-template let-state pTemplate="item">
                  <span class="p-4">{{ state.label }}</span>
                </ng-template>
              </p-dropdown>
            </lib-input>

            <lib-text-input fxFlex="20" autocompleteType="postal-code" label="ZIP code" control="zip" type="number"
                            mask="00000"
                            class="pk_contact_zip"
                            placeholder="00000"></lib-text-input>
          </lib-grouped-elements>
        </form>
      </div>

      <hr class="secondary"/>
      <lib-grouped-elements>
        <lib-text-input fxFlex="35" autocompleteType="tel" label="Phone number" control="phone" type="phone"
                        class="pk_contact_phone"
                        placeholder="###-###-####"></lib-text-input>
        <lib-text-input fxFlex
                        class="pk_contact_email"
                        autocompleteType="email" label="Email address" [required]="true" control="email" type="email"
        ></lib-text-input>
      </lib-grouped-elements>

      @if (showIntakeEmail()) {
        <p-divider></p-divider>

        <lib-text-input fxFlex
                        [label]="intakeEmailLabel"
                        autocompleteType="email" [required]="true" control="intakeEmail" type="email"
        >


        </lib-text-input>
      }


      <!--      {{-->
      <!--        !form().controls.phone.errors ||-->
      <!--        (!form().controls.phone.errors!['mask'] &&-->
      <!--          !form().controls.phone.errors!['required'] &&-->
      <!--          !form().controls.phone.errors!['pattern'])-->
      <!--      }}-->
      <hr class="secondary"/>
      @if ((!form().valid) && (finder.error() || firedValidationCheck())) {
        <lib-callout [type]="ActionType.Danger" class="{{errorsAsTrackingTags() | join:' '}}">
          <ul>
            @if (invalidDeviceError(); as code) {
              @if (!form().controls.phone.errors ||
              (!form().controls.phone.errors!['mask'] &&
                !form().controls.phone.errors!['required'] &&
                !form().controls.phone.errors!['pattern'])) {
                <span class="pvk_profile_error--code:{{code.code}}}">
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  <span style="padding-left: 8px;">{{ code.description! }}</span>
                </span>
              }
            }

            @if (form().controls.address1.errors; as errors) {

              @if (errors['required']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Address 1 is required
                </li>
              }

              @if (errors['maxlength']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Address 1 cannot be longer than 50 characters
                </li>
              }

              @if (errors['pattern']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Address 1 can only contain letters, numbers, periods, and hyphens
                </li>
              }
            }

            @if (form().controls.address2.errors; as errors) {

              @if (errors['maxlength']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Address 2 cannot be longer than 50 characters
                </li>
              }

              @if (errors['pattern']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Address 2 can only contain letters, numbers, pounds, periods, and hyphens
                </li>
              }
            }

            @if (form().controls.city.errors; as errors) {

              @if (errors['required']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  City is required
                </li>
              }

              @if (errors['maxlength']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  City cannot be longer than 50 characters
                </li>
              }
              @if (errors['pattern']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  City can only contain letters, periods, and hyphens
                </li>
              }
            }

            @if (form().controls.state.errors?.['required']) {
              <span>
          <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                    verticalAlign="middle"></lib-icon>
          <span style="padding-left: 8px;">State is required.</span>
            </span>
            }

            @if (form().controls.zip.errors; as errors) {

              @if (errors['required']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Zip code is required
                </li>
              }

              @if (errors['maxlength']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Zip code cannot be longer than 50 characters
                </li>
              }
              @if (errors['pattern']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Zip code can only contain numbers
                </li>
              }
            }
            @if (form().controls.email.errors; as errors) {
              @if (errors['required']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Email is required
                </li>
              }
              @if (errors['maxlength']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Email cannot be longer than 50 characters
                </li>
              }
              @if (errors['email']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Email is invalid
                </li>
              }
            }

            @if (form().controls.phone.errors; as errors) {

              @if (errors['required']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Phone number is required
                </li>
              }
              @if (errors['mask'] || errors['pattern']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Phone number is invalid
                </li>
              }
            }

            @if (form().controls.intakeEmail.errors; as errors) {

              @if (errors['required']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Intake email is required
                </li>
              }
              @if (errors['maxlength']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Intake email cannot be longer than 50 characters
                </li>
              }
              @if (errors['email']) {
                <li>
                  <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                            verticalAlign="middle"></lib-icon>
                  Intake email is invalid
                </li>
              }
            }

          </ul>
        </lib-callout>
      }

    </form>

    @if (finder.tokenStillValid() && !mustReRequestCode()) {
      <button type="button" class="btn btn-large step-next"
              [disabled]="(!form().valid) && (finder.error() || firedValidationCheck())"
              (click)="(!finder.error() && form().valid) ? finder.goForward() : firedValidationCheck.set(true)" fxFlex.lt-sm="100">Continue
      </button>
    } @else {
      <div class="notice hide-empty" fxLayout="column" fxLayoutAlign="start center" gap="24" >
    <span>
      Get verification code by
    </span>
        <div class="notice-info">
      <span class="sub-text text-body-secondary hide-empty text-body-sm">
        <span fxLayout="row" gap="40" fxLayout.lt-sm="column" fxLayoutAlign="stretch center">
          @if (!showIntakeEmail()) {

            <button type="button" class="btn step-next pk_contact_verify--text" fxFlex
                    [disabled]="(finder.error() || firedValidationCheck()) && !form().valid"
                    (click)="chooseOtpType('sms')" fxFlex.lt-sm="100">Text
            </button>
            <button type="button" class="btn step-next pk_contact_verify--call" fxFlex
                    [disabled]="(finder.error() || firedValidationCheck()) && !form().valid"
                    (click)="chooseOtpType('phone')" fxFlex.lt-sm="100">Call
            </button>
          }
          <button type="button" class="btn step-next pk_contact_verify--email" fxFlex
                  [disabled]="(finder.error() || firedValidationCheck()) && !form().valid"
                  (click)="chooseOtpType('email')" fxFlex.lt-sm="100">Email
          </button>
        </span>
        </span>
          <h6 class="center heading-6 text-heading-2 hide-empty">
            By selecting "Text" or "Call," you agree to receive passcode messages to your mobile number.
          </h6>
        </div>
      </div>
    }
  </div>

</section>
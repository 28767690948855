import {Component, computed, inject, input, OnInit} from '@angular/core';
import {DatePipe, LowerCasePipe} from "@angular/common";
import {AppointmentSettingType, CardComponent, ClinicianNamePipe, MessageCode} from "@mindpath/shared";
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {AddressPipe, FormatAddress} from "@mindpath/shared";
import {ButtonComponent, IconComponent, IconSet, IconType} from "@mindpath/shared";
import {DividerModule} from "primeng/divider";
import {
  AttachmentUploadProgressViewComponent
} from "./attachment-upload-progress-view/attachment-upload-progress-view.component";
import {CardPair} from "../insurance-cards-capture/insurance-cards-capture.component";
import {FieldsetModule} from "primeng/fieldset";
import {Address, Schema, Schemas} from "@mindpath/shared";
import {OnlineSchedulerService, SelectedAppointmentInfo} from "../../../../online-scheduler.service";
import {Logger} from "@mindpath/shared";
import DateUtils from "../../../../../../../shared/src/lib/utils/date.utils";

declare var window: any;


@Component({
  selector: 'app-booking-confirmation',
  standalone: true,
  imports: [
    DatePipe,
    AddressPipe,
    FlexModule,
    ButtonComponent,
    IconComponent,
    IconComponent,
    IconComponent,
    DividerModule,
    ClinicianNamePipe,
    CardComponent,
    AttachmentUploadProgressViewComponent,
    FieldsetModule,
    LowerCasePipe,
    ExtendedModule
  ],
  templateUrl: './booking-confirmation.component.html',
  styleUrl: './booking-confirmation.component.scss'
})
export class BookingConfirmationComponent implements OnInit {

  finder = inject(OnlineSchedulerService);
  constructor() {
  }

  location = computed(() => {
    return this.finder.data.appt()!.location!;
  });

  endTime = computed(() => {
    const data = this.finder.data.appt()!;
    const appt = data.dateTime!;

    const end = new Date(appt);
    end.setMinutes(end.getMinutes() + data.duration);
    return end;
  });


  isInPersonAppt = computed(() => {
    return this.finder.data.appt()!.chosenSetting === AppointmentSettingType.InPerson!
  });

  ngOnInit() {

  }

  hasAttachmentUploads = computed(() => {
    const data = this.finder.data.insurance();
    const cardData = this.finder.insuranceCardFiles();
    const files = [{
      omit: !data.primary?.hasCard,
      front: cardData.primary?.front,
      back: cardData.primary?.back
    }, {
      omit: !data.secondary?.hasCard,
      front: cardData.secondary?.front,
      back: cardData.secondary?.back
    }];
    return files.some(c => c && !c.omit && (c.front || c.back));

  });

  clinicianName = computed(() => {
    const clinician = this.finder.data.appt()!.clinician;
    let title = clinician.credentials ?? '';
    if (title) {
      title = ', ' + title;
    }
    return `${clinician.contactDetails.firstName} ${clinician.contactDetails.lastName}${title}`;
  });

  icsContent = computed(() => {

    try {

      const apptData = this.finder.data.appt()!;
      const desc = !this.isInPersonAppt() ? 'Telehealth Appointment' : 'In-Person Appointment';
      let locationStr: string | undefined;

      if (this.isInPersonAppt()) {
        locationStr = FormatAddress(apptData.location.address, {inline: true});
      }


      const dayOfWeek = new Date(apptData.dateTime).toLocaleString('en-US', {
        weekday: 'long',
        timeZone: 'UTC'
      });
      const month = new Date(apptData.dateTime).toLocaleString('en-US', {month: 'long', timeZone: 'UTC'});
      const day = new Date(apptData.dateTime).toLocaleString('en-US', {day: 'numeric', timeZone: 'UTC'});
      const year = new Date(apptData.dateTime).toLocaleString('en-US', {year: 'numeric', timeZone: 'UTC'});
      const time = new Date(apptData.dateTime).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'UTC'
      });
      const dateStr = `${dayOfWeek}, ${month} ${day}, ${year} at ${time}`;

      const phoneNumber = apptData.location.phoneNumber!;
      const phoneParts = phoneNumber.match(/(\d{3})(\d{3})(\d{4})/);
      let phone = '';
      if (phoneParts) {
        phone = `${phoneParts[1]}-${phoneParts[2]}-${phoneParts[3]}`;
      } else {
        phone = '855-501-1004';
      }

      const clinicianName = ClinicianNamePipe.FormatName(apptData.clinician);
      const subject = `${clinicianName}, ${this.isInPersonAppt() ? 'in-person' : 'online'} appointment reminder for ${dateStr}`;
      const demographics = this.finder.data.demographics()!;

      let body = `Dear ${demographics.firstName} ${demographics.lastName},\n`;
      if (this.isInPersonAppt()) {
        body += `You have an appointment scheduled for ${dateStr} with ${clinicianName}.`;
      } else {
        body += `You will receive your appointment link three days before your scheduled appointment with ${clinicianName} on ${dateStr}`
      }

      body += `\nIf you need to cancel, reschedule, or contact our office, call ::link(tel:${phone}, ${phone})::. 
Mindpath Health charges a fee for missed appointments and appointments canceled with less than one (1) business day’s notice.  
Thank you for choosing Mindpath Health on your mental health journey.  

Sincerely, 
Your team at Mindpath Health  
::link(https://mindpath.com, mindpath.com)::`;

      let address: Schema<'Address'>|undefined;
      if (this.isInPersonAppt()) {
        address = apptData.location.address;
      }
      const ics = GenerateIcsFile(apptData, subject, body, address);

      const blob = new Blob([ics], {type: 'text/calendar;charset=utf-8'});
      const url = URL.createObjectURL(blob);
      try {
        if ('icsHook' in window && typeof window['icsHook'] === 'function') {
          window['icsHook'](ics);
        }
      } catch (e) {
        Logger.Error('Ics', e);
      }
      return {content: ics, url};
    } catch (e) {
      Logger.Error('Ics', e);
      return null;
    }
  });
  downloadIcs() {

    const ics = this.icsContent();
    if (!ics) {
      return;
    }

    const a = document.createElement('a');
    a.href = ics.url;
    a.download = 'appointment.ics';
    a.click();
    URL.revokeObjectURL(ics.url);
  }

  // openMaps(lat: number, lng: number, pref: string): void {
  //   const params = encodeURIComponent(address);
  //   if (pref === 'google') {
  //     const mapsLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  //     window.open(mapsLink, '_system');
  //   } else if (pref === 'apple') {
  //     const mapsLink = `https://maps.apple.com/?q=${lat},${lng};
  //       window.open(mapsLink, '_system');
  //   }
// }

  protected readonly IconSet = IconSet;
  protected readonly IconType = IconType;
}

function GenerateIcsFile(data: SelectedAppointmentInfo, title: string, description: string, address?: Address): string {

  description = description.replaceAll('\n', '\\n');
  const chunkByWordTo75 = (str: string) => {
    if (str.length <= 75) {
      return str;
    }
    return str.split('\n').map(line => {
      if (line.length <= 75) {
        return line;
      }
      const words = line.split(' ');
      const chunks = [];
      let currentChunk = '';

      for (const word of words) {

        // plus 2 for the space as we are splitting on it and another as new lines need to be indented
        if (currentChunk.length + word.length + 2 > 75) {
          chunks.push(currentChunk);
          currentChunk = '';
        }

        currentChunk += word + ' ';
      }

      if (currentChunk) {
        chunks.push(currentChunk);
      }

      return chunks.join('\n ');
    }).join('\n');
  }

  const genFormat = (str: string) => {
    let plain = str;
    let html = `<!DOCTYPE html><html><body>${str.replaceAll('\\n', '<br/>')}</body></html>`;

    // get all links that are in ::link(URL, TEXT)::
    const linkRegex = /::link\(([^,]+), ([^)]+)\)::/g;
    const links = [];
    let match;
    while ((match = linkRegex.exec(str))) {
      links.push({content: match[0], url: match[1], text: match[2]});
    }

    // replace all links with HTML links in the HTML version and just the text in the plain version
    links.forEach(link => {
      // put a newline before and after the link in html so that the chunking function doesn't split the tag
      html = html.replaceAll(link.content, `\n <a href="${link.url}">${link.text}</a>\n `);
      plain = plain.replaceAll(link.content, link.text);
    });

    return {plain, html};
  }

  const dateStr = (date: Date) => {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}${month}${day}T${hours}${minutes}00`;

  }

  const formats = genFormat(description);
  const plainDescription = chunkByWordTo75('DESCRIPTION:' + formats.plain);
  const htmlDescription = chunkByWordTo75('X-ALT-DESC;FMTTYPE=text/html:' + formats.html);

  // const timestamp new Date().toLocaleString('en-US', { month: 'long', timeZone: 'UTC' });
  const timeStamp = dateStr(new Date());
  const dateTime = DateUtils.asUtcEquivalent(data.dateTime);
  const start = dateStr(dateTime);
  let end: Date|string = new Date(dateTime);
  end.setMinutes(end.getMinutes() + data.duration);
  end = dateStr(end);

  let location = '';
  let geo = '';
  if (address) {
    if (address.coordinates) {
      geo = '\n' + chunkByWordTo75(`GEO:${address.coordinates.lat};${address.coordinates.long}`);
    }
    let address2 = '';
    if (address.address2) {
      address2 = ', ' + address.address2;
    }
    location = '\n' + chunkByWordTo75(`LOCATION:${address.address1}${address2}, ${address.city}, ${address.state}, ${address.zip}`);
  }

  let icsExtra = '';
  try {
    if ('icsExtra' in window && typeof window['icsExtra'] === 'function') {
      icsExtra = window['icsExtra']();
      if (typeof icsExtra !== 'string') {
        Logger.Error('Ics', 'icsExtra must return a string');
      }
      icsExtra = '\n' + chunkByWordTo75(icsExtra);
    }
  } catch (e) {
    Logger.Error('Ics', e);
  }

  let ics = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Mindpath//EN
BEGIN:VEVENT
UID:${crypto.randomUUID()}
DTSTAMP:${timeStamp}
DTSTART:${start}
DTEND:${end}
${chunkByWordTo75('SUMMARY:' + title)}
${plainDescription}
${htmlDescription}${location}${geo}${icsExtra}
END:VEVENT
END:VCALENDAR`;

  try {
    if ('icsMod' in window && typeof window['icsMod'] === 'function') {
      ics = window['icsMod'](ics);
    }
  } catch (e) {
    Logger.Error('Ics', e);
  }

  return ics;
}

import {Component, computed, effect, inject, signal} from '@angular/core';
import {
  ActionType,
  AddressPipe,
  AppointmentSettingType,
  BarComponent,
  ButtonComponent,
  CalloutComponent,
  ClinicianNamePipe,
  ClinicianSlugPipe,
  IconComponent,
  IconSet,
  IconType,
  InputComponent
} from "@mindpath/shared";
import {ExtendedModule, FlexModule} from "@ngbracket/ngx-layout";
import {PaginatorModule} from "primeng/paginator";
import {DatePipe, JsonPipe, LowerCasePipe, NgOptimizedImage, NgStyle, NgTemplateOutlet} from "@angular/common";
import {MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";

import {AutoCompleteModule} from "primeng/autocomplete";
import {OnlineSchedulerService} from "../../../../online-scheduler.service";
import {ConditionsDataService} from "../../../../conditions-data.service";
import {OptionFilter, OptionItem} from "../../../../utils";
import {config} from "../../../../../config";
import {AppendAppStateParamsPipe} from "../../../../pipes/append-app-state-params.pipe";

@Component({
  selector: 'app-appointment-details-check',
  standalone: true,
  imports: [
    ButtonComponent,
    FlexModule,
    AddressPipe,
    BarComponent,
    IconComponent,
    PaginatorModule,
    NgStyle,
    AutoCompleteModule,
    MultiSelectModule,
    InputComponent,
    CalloutComponent,
    DatePipe,
    NgOptimizedImage,
    ClinicianNamePipe,
    ClinicianSlugPipe,
    LowerCasePipe,
    NgTemplateOutlet,
    FormsModule,
    BarComponent,
    IconComponent,
    InputComponent,
    JsonPipe,
    ExtendedModule,
    AppendAppStateParamsPipe,
  ],
  templateUrl: './appointment-details-check.component.html',
  styleUrl: './appointment-details-check.component.scss'
})
export class AppointmentDetailsCheckComponent {

  finder = inject(OnlineSchedulerService);
  reasonType = signal<'Therapy'|'Psychiatry'|null>(null);
  selectedConditionId = signal<number|null>(null);

  chosenApptSetting = signal<'InPerson'|'Telehealth'|null>('InPerson');

  conditionTrackingTag = computed(() => {
    const condition = this.selectedConditionFromOptions();
    if (!condition) {
      return '';
    }
    return 'pk_confirm_change-condition--' + condition.name.toLowerCase().replace(/[^a-z0-9]/g, '_');
  });

  selectedConditionFromOptions = computed(() => {
    const conditionId = this.selectedConditionId();
    if (conditionId == null) {
      return null;
    }
    return (this.possibleConditions() ?? []).find(c => c.id === conditionId);
  });

  isHybrid = computed(() => {
    return this.finder.data.appt()!.slot.setting === AppointmentSettingType.Hybrid;
  });

  valid = computed(() => {
    const reasonType = this.reasonType();
    const selectedCondition = this.selectedConditionId();
    const conditionFromOptions = this.selectedConditionFromOptions();
    const chosenApptSetting = this.chosenApptSetting();
    const unsupportedConditions = this.isUnsupportedCondition();
    if (!conditionFromOptions) {
      return false;
    }
    if (typeof selectedCondition !== 'number') {
      return false;
    }
    return !!reasonType?.trim()?.length && (chosenApptSetting === 'InPerson' || chosenApptSetting === 'Telehealth') && !unsupportedConditions
  })

  endTime = computed(() => {
    const data = this.finder.data.appt()!;
    const appt = data.dateTime;

    // console.log(appt, data.data.appointment.duration);
    const end = new Date(appt);
    end.setMinutes(end.getMinutes() + data.duration);
    return end;
  });

  protected readonly IconType = IconType;

  clinicianIsPrescriber = computed(() => {
    const clinician = this.finder.data.appt()?.clinician;
    return clinician?.type === 'Prescriber';
  });

  showAllTypeOfCareOptions = computed(() => {
    const reason = this.finder.data.search().clinicianTypePref;
    const clinicianIsPrescriber = this.clinicianIsPrescriber();
    // if the reason is given as Therapy or Psychiatry, then we should not show the option to choose the reason
    // so as not to confuse the user per business requirement
    if (reason === 'Therapy' || reason === 'Psychiatry') {
      return false;
    }
    // if the clinician is a prescriber, then we should show the option to choose the reason
    return clinicianIsPrescriber;
  });

  showPrescriberReason = computed(() => {
    const clinicianIsPrescriber = this.clinicianIsPrescriber();
    const showAllTypeOfCareOptions = this.showAllTypeOfCareOptions();
    const reasonType = this.reasonType();
    if (reasonType === 'Psychiatry') {
      return true;
    }
    return (clinicianIsPrescriber && !reasonType) || showAllTypeOfCareOptions;
  });

  showTherapyReason = computed(() => {
    const clinicianIsPrescriber = this.clinicianIsPrescriber();
    const showAllTypeOfCareOptions = this.showAllTypeOfCareOptions();
    const reasonType = this.reasonType();
    if (reasonType === 'Therapy') {
      return true;
    }
    return (!clinicianIsPrescriber && !reasonType) || showAllTypeOfCareOptions
  });

  conditionSvc = inject(ConditionsDataService);
  filteredConditions = signal<{id: number, name: string}[]>([]);
  possibleConditions = signal<OptionItem[]>([]);


  originalApptSetting: AppointmentSettingType.Telehealth|AppointmentSettingType.InPerson|AppointmentSettingType.Hybrid|null;

  constructor() {
    this.originalApptSetting = this.finder.data.appt()!.originalSetting;
    this.chosenApptSetting.set(this.finder.data.appt()!.chosenSetting ?? null);

    this.conditionSvc.getAllConditions().then(c => {
      this.possibleConditions.set([
        {id: -1, name: 'General Evaluation'},
        ...c!.map(c => ({name: c.name!, id: c.id!}))]);
    })

    let reasonType = this.finder.data.appt()?.typeOfCare ?? this.finder.data.search().clinicianTypePref;

    // if the clinician is not a prescriber, then the reason type can only be therapy
    if (!this.clinicianIsPrescriber() && reasonType !== 'Psychiatry') {
      reasonType = 'Therapy';
    }

    // If the reason type is not therapy or psychiatry, then we need to set it to undefined
    if (reasonType !== 'Therapy' && reasonType !== 'Psychiatry') {
      // If the clinician is a prescriber, then the reason type let the user choose
      // If the clinician is not a prescriber, then the reason type can only be therapy
      if (this.clinicianIsPrescriber()) {
        reasonType = null;
      } else {
        reasonType = 'Therapy';
      }
    }

    this.reasonType.set(reasonType!);

    let conditionId = this.finder.data.appt()?.conditionId ?? this.finder.data.search().conditionId;

    this.selectedConditionId.set(conditionId);

    effect(() => {
      const conditionId = this.selectedConditionId();
      const type = this.reasonType();
      const setting = this.chosenApptSetting();
      this.finder.data.appt.update(a => {
        return {
          ...a,
          conditionId,
          typeOfCare: type,
          setting: setting
        } as any
      })
    }, {allowSignalWrites: true});

  }

  isUnsupportedCondition = computed(() => {
    const clin = this.finder.data.appt()?.clinician;

    if (!this.selectedConditionId() || !clin) {
      return false;
    }
    const conditionId = this.selectedConditionId();
    return conditionId != null && conditionId !== -1 && !clin.supportedConditionIds!.includes(conditionId);
  });

  protected readonly IconSet = IconSet;
  protected readonly ActionType = ActionType;
  firedValidationCheck = signal(false);
  protected readonly OptionFilter = OptionFilter;
  protected readonly config = config;
  protected readonly AppointmentSettingType = AppointmentSettingType;
}

@if (justInputs()) {
  <div fxLayout="column" ngClass.gt-xs="row-gap-16" ngClass.lt-sm="row-gap-8">
    @if (!state() || app.isStateValid()) {
      <ng-container [ngTemplateOutlet]="inputsSection"></ng-container>
    } @else {
      <ng-container [ngTemplateOutlet]="errorSection"></ng-container>
    }
  </div>
} @else {
  @if (!state() || app.isStateValid()) {
    <section>
      <h3>
        Let's find your path
      </h3>
      <div fxLayout="column" ngClass.gt-xs="row-gap-16" ngClass.lt-sm="row-gap-8">

        @if ((!state() || app.isStateValid()) && canResume()) {
          <lib-bar style="padding-top: 8px; padding-bottom: 32px"
                   (click)="resume()">
        <span fxLayout="row" fxLayoutAlign="space-between center">
      <span>
        <div fxLayout="column">
          <span style="display: inline-block"
                class="text-primary">
            <strong>Resume your search</strong>
          </span>
          <span class="text-secondary">
            Pick up where you left off
          </span>
        </div>

      </span>
    </span>
          </lib-bar>
        }

        <ng-container [ngTemplateOutlet]="inputsSection"></ng-container>

        <hr style="width:100%;" class="my-56"/>
        <div class="notice hide-empty">
          <div class="notice-info">
            <h5 class="text-primary">
              <strong>Not ready to schedule an appointment?</strong>
            </h5>
            <span class="sub-text text-body-secondary hide-empty text-body-sm pb-16">
            View one of the directories below to find your path.
          </span>
            <div class="pt-16" style="width: 100%" fxLayout="row" fxLayoutAlign="center stretch" gap="16"
                 fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start stretch">
              <a class="btn btn-large step-next arrow-right" href="{{config.endpoints.mainSite}}/locations/" fxFlex="100%"
                 style=" width: 100%;">
                <strong>Locations</strong>
              </a>
              <a class="btn btn-large step-next btn-white arrow-right" href="{{config.endpoints.mainSite}}/directory/"
                 style="background-color: white; color: var(--font-color--primary); border: 1px solid var(--font-color--primary); width: 100%;"
                 fxFlex="100%">
                <strong>Clinicians</strong>
              </a>
            </div>
            <span class="text-secondary pt-12" style="font-size: 16px;">
              Or call <a class="link blend" style="color: var(--color-primary-primary)"
                         href="tel:855-501-1004">855-501-1004</a> to schedule an appointment.
            </span>

          </div>

        </div>
        <div class="pt-24">
            <span>
              <strong class="text-secondary" style="font-size: 14px;">Are you a current patient?&nbsp;
              <a class="link blend arrow-right" style="color: var(--color-primary-primary); font-size: 16px;"
                 href="{{config.endpoints.mainSite}}/current-patients/">Click here</a>
                 </strong>
            </span>
        </div>
      </div>
    </section>
  } @else {
    <ng-container [ngTemplateOutlet]="errorSection"></ng-container>
  }
}


<ng-template #errorSection>
  <section fxLayout="column" fxLayoutGap="24">
    <div fxLayout="row" gap="8">
      <h3>Let's find your path</h3>
      <span style="display: block; width: 32px; height: 32px; position: relative; transform: translateY(-8px)">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none"><g
          clip-path="url(#error-exclamation_svg__a)"><circle cx="24" cy="24" r="22.5" fill="#FFDCD0" stroke="#FA4B82"
                                                             stroke-width="3"></circle><path fill="#41145F"
                                                                                             d="M25.824 29.244h-3.96L21.324 12h5.04zm-1.98 8.568c-1.548 0-2.844-1.296-2.844-2.88s1.296-2.88 2.844-2.88c1.62 0 2.916 1.296 2.916 2.88s-1.296 2.88-2.916 2.88"></path></g><defs><clipPath
          id="error-exclamation_svg__a"><path fill="#fff" d="M0 0h48v48H0z"></path></clipPath></defs></svg>
      </span>
    </div>
    <p>
      We currently offer services in Arizona, California, Florida, North Carolina, South Carolina, and Texas. We do not
      have appointments available outside of these states.
    </p>
    <button type="button" class="btn btn-large step-next arrow-right"
            (click)="reset()">
      Select a different location
    </button>
  </section>

</ng-template>

<ng-template #inputsSection>
  <h3 class="text-body-secondary">What is your location?</h3>
  <div fxLayout="column" fxLayoutGap="4">
    <lib-input [valid]="true" fxFlex style="width: 100%">
      <p-autoComplete [suggestions]="$any(matches() ?? [])" #drowpdown
                      [lazy]="true"
                      [delay]="200"
                      [minLength]="2"
                      [optionLabel]="itemLabel"
                      [forceSelection]="true"
                      placeholder="Enter your address, ZIP code, or city"
                      [style]="{width: '100%'}"
                      (onSelect)="chose($event.value)"
                      (completeMethod)="search($event)">
        <ng-template let-address pTemplate="selectedItem">
          <div class="flex items-center gap-2">
            {{ address | address:true }}
          </div>
        </ng-template>
        <ng-template let-address pTemplate="item">
          <div class="flex items-center gap-2">
            {{ address | address:true }}
          </div>
        </ng-template>
        <ng-template pTemplate="empty">
          <div class="flex items-center gap-2">
            @if (isLoading()) {
              Loading Suggestions...
            } @else {
              No results found
            }
          </div>
        </ng-template>
      </p-autoComplete>
    </lib-input>
    @if (canUseCurrentLocation) {
      <button type="button" class="link text blend" (click)="useCurrentLocation()"
              style="color: var(--color-primary-primary); text-decoration: underline">
        <div style="display: block;">

      <span style="display: flex; align-items: center; gap: 8px; font-size: 16px;" class="pt-8">
        <span style="display: block; width: 16px; height: 16px; position: relative; transform: translateY(-1px)">

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
          <path fill="currentColor" fill-rule="evenodd"
                d="M8.001 0c.368 0 .667.298.667.667v2.666l-.002.048a4.67 4.67 0 0 1 3.955 3.954l.046-.001h2.666a.667.667 0 1 1 0 1.333h-2.666q-.024 0-.046-.002a4.67 4.67 0 0 1-3.955 3.955l.002.047v2.666a.667.667 0 1 1-1.333 0v-2.666l.001-.047a4.67 4.67 0 0 1-3.954-3.955l-.049.002H.667a.667.667 0 1 1 0-1.333h2.666l.049.001a4.67 4.67 0 0 1 3.954-3.954l-.001-.048V.667C7.335.298 7.633 0 8 0m0 4.667a3.333 3.333 0 1 0 0 6.667 3.333 3.333 0 0 0 0-6.667"
                clip-rule="evenodd"></path>
        </svg>
          </span>
        <span style="font-weight: 500">Find my location</span>
      </span>
        </div>
      </button>
      <br/>
    }
  </div>

  <h4>
    <strong>
      Mindpath Health currently serves

    </strong>
  </h4>
  <span class="text-secondary">Arizona, California, Florida, North Carolina, South Carolina, and Texas</span>
</ng-template>
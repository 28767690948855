import {Component, computed, DestroyRef, inject, input, OnInit, signal, WritableSignal} from '@angular/core';
import {CardPair} from "../../insurance-cards-capture/insurance-cards-capture.component";
import {IconComponent, IconSet, IconType, isMessageCode} from "@mindpath/shared";
import {FlexModule} from "@ngbracket/ngx-layout";
import {ProgressBarModule} from "primeng/progressbar";
import {BehaviorSubject, Observable} from "rxjs";
import {AsyncPipe, TitleCasePipe} from "@angular/common";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {OnlineSchedulerService} from "../../../../../online-scheduler.service";
import { Logger } from 'projects/shared/src/lib/logging';

@Component({
  selector: 'app-attachment-upload-progress-view',
  standalone: true,
  imports: [
    FlexModule,
    IconComponent,
    ProgressBarModule,
    AsyncPipe,
    TitleCasePipe,
    ProgressSpinnerModule
  ],
  templateUrl: './attachment-upload-progress-view.component.html',
  styleUrl: './attachment-upload-progress-view.component.scss'
})
export class AttachmentUploadProgressViewComponent implements OnInit {

  uploadStatuses = signal<ReadonlyArray<{
    progress: BehaviorSubject<number>,
    state: WritableSignal<'uploading' | 'error' | 'complete'>
  }>>([]);

  destroyRef = inject(DestroyRef);
  finder = inject(OnlineSchedulerService);

  hasSecondary = computed(() => {
    const insurance = this.finder.data.insurance();
    return !!insurance?.secondary;
  });

  cards = computed(() => {
    const data =  this.finder.insuranceCardFiles();
    const insurance = this.finder.data.insurance();
    const cardsData = {
      primary: insurance?.primary ? {
        omit: !insurance.primary.hasCard,
        front: data.primary.front,
        back: data.primary.back
      } : null,
      secondary: insurance?.secondary ? {
        omit: !insurance.secondary.hasCard,
        front: data.secondary.front,
        back: data.secondary.back
      } : null
    };

    return [cardsData.primary, cardsData.secondary].flatMap((c, i) => {
      if (!c || c.omit || (!c.front && !c.back)) {
        return;
      }
      return [
        {file: c.front, side: 'front', isPrimary: i === 0},
        {file: c.back, side: 'back', isPrimary: i === 0}
      ]
    }).filter(f => !!f && !!f.file);
  })
  protected readonly IconType = IconType;

  async ngOnInit() {

    this.uploadStatuses.set(this.cards().map((c, i) => {
      const progress = new BehaviorSubject(0);
      return {progress, state: signal('uploading')};
    }));

    for (let i = 0; i < this.cards().length; i++) {
      const card = this.cards()[i]!;
      const status = this.uploadStatuses()[i];
      try {
        const token = this.finder.data.meta().booking?.completionData?.attachments.find(a => a.data.forPrimary === card.isPrimary && a.data.side.toLowerCase() === card.side.toLowerCase());
        if (!token) {
          status.state.set('error');
          continue;
        }
        const form = new FormData();
        form.append('file', card.file!);
        form.append('side', card.side);
        form.append('isPrimary', card.isPrimary.toString());
        const data = this.buildData(form, status.progress.next.bind(status.progress));
        this.finder.uploadFile(token.token, data)
          .then((resp) => {
            if (isMessageCode(resp)) {
              status.state.set('error');
              return;
            }
            status.progress.next(100);
            status.state.set('complete');
          })
          .catch((e) => {
            Logger.Error('AttachmentUpload', 'Error uploading file', e);
            status.state.set('error');
          })
          .finally(() => {
            status.progress.complete();
          });
      } catch (e) {
        Logger.Error('AttachmentUpload', 'Error uploading file', e);
        status.progress.complete();
        status.state.set('error');
      }
    }
  }

  protected readonly supportsRequestStreams = (() => {
    return false;
    let duplexAccessed = false;

    const hasContentType = new Request('', {
      body: new ReadableStream(),
      method: 'POST',
      // @ts-ignore
      get duplex() {
        duplexAccessed = true;
        return 'half';
      },
    }).headers.has('Content-Type');

    // Safari does support streams in request objects, but doesn't allow them to be used with fetch, so the duplex option is tested, which Safari doesn't currently support.
    return duplexAccessed && !hasContentType;
  })();

  private buildData(data: FormData, onProgress: (p: number) => void): FormData|ReadableStream {
    if (!this.supportsRequestStreams) {
      return data;
    }
    const totalSize = Array.from(data.values()).reduce((acc, file) => {
      if (file instanceof File) {
        return acc + file.size;
      }
      return acc;
    }, 0);

    let uploadedSize = 0;

    return  new ReadableStream({
      async start(controller) {
        for (const [key, value] of data.entries()) {
          if (value instanceof File) {
            const reader = value.stream().getReader();
            while (true) {
              const { done, value: chunk } = await reader.read();
              if (done) {
                break;
              }
              uploadedSize += chunk.length;
              onProgress((uploadedSize / totalSize) * 100);
              controller.enqueue(new Uint8Array(chunk));
            }
          } else {
            controller.enqueue(new TextEncoder().encode(`${key}=${value}&`));
          }
        }
        controller.close();
      }
    });
  }


  protected readonly IconSet = IconSet;
}
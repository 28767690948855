import {IMessageCode, MessageCode} from "./message-codes";
import {LogLevel} from "../logging";

export async function HandleResponse<TSuccess>(resp: Response): Promise<{ success: TSuccess, error: null } | { success: null, error: IMessageCode }> {
  if (resp.ok) {
    return {success: await resp.json() as any, error: null};
  }
  return {success: null!, error: MapError(await resp.text())};
}
export function MapError(e: Error|string): IMessageCode & { data?: any | null } {
  let json: string | null = null;
  if (e instanceof Error) {
    json = e.message;
  } else if (typeof e === 'string') {
    json = e;
  }

  let err: any = e;
  if (!!json) {
    json = json.trim();
    if (!json.startsWith('{') && !json.endsWith('}')) {
      return MessageCode.Unknown_Browser_Exception;
    }
    err = JSON.parse(json);
  }
  console.log(e)

  try {
    // wordpress's fetch implementation that 10up is using is returning their own error object with a code and message
    //  as such we need to check for Mindpath code type format of [A-Z]{3}\d{4}
    if ('code' in err && /^[A-Z]{3}\d{4}$/.test(err.code)) {
      let code = MessageCode.FromCode(err.code)
        ?? new MessageCode(err.code, err.message ?? MessageCode.MPH0000.description, LogLevel.Error);
      if ('message' in err && typeof err.message === 'string') {
        code = code.with({description: err.message});
      }
      (code as any).data = err.data ?? null;
      (code as any).traceId = err.traceId ?? null;
      console.log(e, code);
      return code;
    }
    console.error('Error parsing error', e);
    return MessageCode.Unknown_Browser_Exception;
  } catch (e) {
    console.error('Error parsing error', e);
    return MessageCode.Unknown_Browser_Exception;
  }
}
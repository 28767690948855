

export function listenForKeyboardCode(msg: string, onMatch: () => void) {
  let typed = '';
  return function(event: KeyboardEvent) {
    if (!event.key) {
      typed = '';
      return;
    }

    if (event.key === 'Escape') {
      typed = '';
      return;
    }

    if (typed.length > msg.length) {
      return;
    }

    typed += event.key;
    if (typed === msg) {
      onMatch();
      typed = '';
    } else {
    }
  }
}
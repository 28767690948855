<section>
  <h3>
    Verify your
    @if (destinationType() === 'email') {
      email
    } @else {
      phone number
    }
  </h3>

  @switch (destinationType()) {
    @case ('email') {
      <p class="text-body-secondary">We sent a 6-digit code to {{ deviceDestination() }}. {{ expireInStr() }}</p>
    }
    @case ('sms') {
      <p class="text-body-secondary">We sent a 6-digit code to {{ deviceDestination() | phone }}
        . {{ expireInStr() }}</p>
    }
    @case ('phone') {
      <p class="text-body-secondary">We called {{ deviceDestination() | phone }} and read off a 6-digit
        code. {{ expireInStr() }}</p>
    }
  }



  <div fxLayout="column" ngClass.gt-xs="row-gap-40" ngClass.lt-sm="row-gap-16">

    <div fxLayout="row" fxLayoutAlign="center">

      <lib-input [valid]="isValid() || !firedValidationCheck()">
        <h4 slot="label" class="heading-4 text-center text-body-secondary"><strong>Enter Verification Code</strong></h4>
        <input
          type="number"
          name="auth-token"
          style="text-align: center; letter-spacing: var(--size-12); width: calc(calc(var(--size-18) * 1.9)*6); display: block"
          placeholder="_ _ _ _ _ _"
          inputmode="numeric"
          [class.ng-invalid.ng-dirty]="firedValidationCheck() && !isValid()"
          pattern="\d{6}"
          maxlength="6"
          max="999999"
          min="0"
          minlength="1"
          [(ngModel)]="code"
          #otpInput
          (input)="otpInput.value = otpInput.value.slice(0, 6); code.set(otpInput.value.slice(0, 6))"
          (keyup.enter)="(isValid() && !isInvalidCode()) ? verifyCode() : null"
          autocomplete="one-time-code"/>
      </lib-input>

    </div>


    @if (isInvalidCode(); as error) {
      <lib-callout [type]="ActionType.Danger" title="The code you entered is incorrect."
                   class="pvk_verify_error--incorrect-code"
                   content="The code you entered is incorrect. Please try again.">
      </lib-callout>
    }
    @if (firedValidationCheck() && !isValid()) {
      <lib-callout [type]="ActionType.Danger" [title]="'Code is required'"
                   class="pvk_verify_error--code:required"
                   content="Please enter the 6-digit code that was sent to you.">
      </lib-callout>
    }

    <button type="button" class="btn btn-large step-next pk_verify_continue arrow-right"
            [disabled]="firedValidationCheck() && !isValid()"
            (click)="firedValidationCheck.set(true); isValid() ? verifyCode() : null">
      Continue
    </button>

    <div>

      <div class="notice hide-empty" fxLayout="column" fxLayoutAlign="start center" gap="24">
        <div class="notice-info">
      <span class="sub-text text-body-secondary hide-empty text-body-sm">
      <span fxLayout="row [wrap]" class="p-20">
        <p>Didn't receive a code? Request a new <button class="text link blend underline pk_verify_new-code"
                                                        (click)="requestVerificationCode()"
                                                        [class.disabled]="timeUntilNewCode()">{{ requestNewDeviceStr() }}</button>
          @if (timeUntilNewCode(); as time) {
            in <strong>{{ time.amount }}</strong> {{ time.unit }}.
          } @else {
            .
          }
        </p>
      </span>
      </span>
        </div>
      </div>
      <!--    <span class="p-t-16">If you are having a psychiatric or medical emergency, please call 911 or go to the nearest emergency department.-->
      <!--      To reach the Suicide and Crisis Lifeline, call or text <strong><a class="text-gray-700" href="tel:998">998</a></strong>-->
      <!--    </span>-->
    </div>
  </div>
</section>

<ng-container>
  <button class="filter-pill" [class.selected]="!!gender()" type="button" (click)="openSideBar(sideBar)">

    @switch (gender()) {
      @case ('Male') {
        Male
      }
      @case ('Female') {
        Female
      }
      @case ('NonBinary') {
        Non-binary
      }
      @default {
        Gender
      }
    }
  </button>
</ng-container>

<ng-template #sideBar>
  <h4>Clinician's Gender</h4>
  <ul>
    @for (opt of [
      {value: null, label: 'No Preference', icon: 'none'},
      {value: 'Male', label: 'Male', icon: 'male'},
      {value: 'Female', label: 'Female', icon: 'female'},
      {value: 'NonBinary', label: 'Non-binary', icon: 'non-binary'},
    ]; track opt.value) {
      <li class="pt-12">
        <lib-bar style="padding-top: 8px" customClass="{{gender() === opt.value ? 'selected' : ''}}"
                 (click)="change(gender() === opt.value ? null : opt.value)">
          <span fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
              <span fxLayout="row" fxLayoutAlign="start center" gap="12" class="bar-header">
                <lib-icon name="{{opt.icon}}" verticalAlign="middle" [size]="32" [class.white]="gender() === opt.value" ></lib-icon>
                {{ opt.label }}
              </span>
            @if (gender() === opt.value) {
              <span class="text-primary">
                <lib-icon verticalAlign="middle" name="checkmark" [type]="IconType.Success"></lib-icon>
              </span>
            }
          </span>
        </lib-bar>

      </li>
    }
  </ul>

</ng-template>

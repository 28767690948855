import {AfterContentInit, Component, computed, DestroyRef, inject, input, model, OnInit} from '@angular/core';
import {ControlContainer, FormControl, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";
import {FlexModule} from "@ngbracket/ngx-layout";

@Component({
  selector: 'lib-checkbox',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FlexModule,
  ],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.css',
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class CheckboxComponent implements OnInit, AfterContentInit {
  label = input<string|undefined>(undefined);
  checked = model<boolean>(false);
  parentForm = inject(FormGroupDirective, {optional: true});
  control = input<FormControl | string | null>(null, {alias: 'control'});
  formControl = computed(() => {
    const control = this.control();
    const parentForm = this.parentForm?.form;
    if (control instanceof FormControl) {
      return control;
    }
    if (typeof control === 'string') {
      return parentForm?.get(control) as FormControl;
    }
    return null;
  });
  formControlName = computed(() => {
    const control = this.control();
    if (typeof control === 'string') {
      return control;
    }
    return null;
  });
  toggle() {
    this.checked.update((checked) => !checked);
  }
  destroyRef = inject(DestroyRef);

  ngOnInit() {


  }

  ngAfterContentInit() {
    let formControl = this.formControl();
    const formControlName = this.formControlName();
    if (formControlName) {
      formControl = this.parentForm?.form?.get(formControlName) as FormControl;
    }

    if (formControl) {
      if (typeof formControl.value === 'boolean' && formControl.value !== this.checked()) {
        this.checked.set(formControl.value);
      }
      formControl.valueChanges.subscribe((value) => {
        if (typeof value === 'boolean' && value !== this.checked()) {
          this.checked.set(value);
        }
      });
    }
  }
}

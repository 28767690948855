@if (config.inMaintenance()) {
  <lib-maintenance [config]="config.maintenance!"
                   supportNumber="855-501-1004"
                   logoPath="/media/mindpath-logo.svg"
                   appName="Online scheduling"></lib-maintenance>

} @else {


  @let dimming = finder.dimming() ;
  <lib-dimmer [dimmed]="dimming.dimmed"
              [title]="dimmerMsg().title!"
              [message]="dimmerMsg().message!"
              [showProgress]="!dimming.asStopMessage"
              [dimLevel]="dimming.dimLevel"
              position="top"
              [fullscreen]="true">
    @if (dimming.asStopMessage) {
      <lib-card
        style="max-width: 600px;"
        [title]="dimming.title"
        [type]="ActionType.Danger"
        ngClass.lt-md="p-24"
      >
        <ng-template templateFor="content">
          <div class="p-16 text-center" fxLayout="column">

            <span [innerHTML]="dimming.message | unsafeAllowHtml"></span>

            @if (dimming.action; as onActionClick) {
              <div slot="footer" fxLayout="row [wrap]" fxLayoutAlign="center bottom" fxLayoutGap="1rem" class="pt-24"
                   fxFlex>
                <button type="button" class="btn step-next"
                        (click)="onActionClick.onClick()">
                  {{ onActionClick.label }}
                </button>
                <br/>
              </div>
            }

            @if(finder.error(); as error) {
              <span style="opacity: 0.5" class="pt-12 text-secondary pk_dialog_code--{{error.code}}">Code: {{error.code}}</span>
              @if(error.traceId) {
                <span style="opacity: 0.35" class="pt-12 text-secondary">{{error.traceId}}</span>
              }
            }
          </div>
        </ng-template>
      </lib-card>
    }
  </lib-dimmer>

  <lib-inner-scrollable>
    <ng-template templateFor="header">
      <nav>
      <span style="position: absolute; top: 8px; left: 8px; opacity: 0.2; z-index: 999999;">
        {{ screenSize() }}
      </span>
        <div
          style="max-width: 1280px; margin-top:0;margin-bottom:0;padding:var(--size-12);" fxLayout="row"
          fxLayoutAlign="space-between center"
          fxFill>
          <div
            class="wp-block-group alignwide is-content-justification-space-between is-nowrap is-layout-flex wp-container-core-group-is-layout-2 wp-block-group-is-layout-flex"
            style="padding-top:0;padding-right:0;padding-bottom:0;padding-left:0">
            <div style="margin-right:var(--size-12);" class="wp-block-site-logo"><a
              href="{{config.endpoints.mainSite | appendAppStateParams}}"
              class="custom-logo-link"
              rel="home"><img
              width="172" height="30" src="/media/mindpath-logo.svg"
              class="custom-logo" alt="Mindpath Health" decoding="async"></a></div>


            <div
              class="wp-block-group wp-block-mindpath-wizard-header__actions is-content-justification-right is-nowrap is-layout-flex wp-container-core-group-is-layout-1 wp-block-group-is-layout-flex"></div>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" row-gap="4" gap="16">
            @if (finder.data.meta().section === 'find') {
              @if (stateFullName(); as state) {
                <button class="link text blend !absolute left-0 top-8 step-back"
                        style="color: var(--text-static-text-body-2);"
                        (click)="openSidebar(locationTemplate)">
                  <span style="display: inline-block; vertical-align: middle;">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g
                      clip-path="url(#clip0_11152_2657)"><path
                      d="M0.5 8C0.5 3.86507 3.86507 0.5 8 0.5C12.1349 0.5 15.5 3.86507 15.5 8C15.5 12.1349 12.1349 15.5 8 15.5C3.86507 15.5 0.5 12.1349 0.5 8Z"
                      fill="#E4D1FF" stroke="#812EFB"></path><path
                      d="M7.57018 8.42982L8.13158 12.5L9.81579 8.5L11.5 4.5L7.5 6.18421L3.5 7.86842L7.57018 8.42982Z"
                      fill="#41145F"></path></g><defs><clipPath id="clip0_11152_2657"><rect width="16" height="16"
                                                                                            fill="white"></rect></clipPath></defs></svg>
                  </span>
                  {{ state }}
                </button>
              }

              <button class="link text blend !absolute left-0 top-8 step-back"
                      style="color: var(--text-static-text-body-2); text-overflow: ellipsis;"
                      (click)="openSidebar(insuranceTemplate)">
                <span style="display: inline-block; vertical-align: middle;">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path
                    d="M18.6667 6H5.33333C4.59722 6 4 6.60789 4 7.35714V17.3095C4 18.0588 4.59722 18.6667 5.33333 18.6667H18.6667C19.4028 18.6667 20 18.0588 20 17.3095V7.35714C20 6.60789 19.4028 6 18.6667 6ZM8.88889 8.71429C9.86944 8.71429 10.6667 9.52574 10.6667 10.5238C10.6667 11.5219 9.86944 12.3333 8.88889 12.3333C7.90833 12.3333 7.11111 11.5219 7.11111 10.5238C7.11111 9.52574 7.90833 8.71429 8.88889 8.71429ZM12 15.4095C12 15.7092 11.7222 15.9524 11.3778 15.9524H6.4C6.05556 15.9524 5.77778 15.7092 5.77778 15.4095V14.8667C5.77778 13.9676 6.61389 13.2381 7.64444 13.2381H7.78333C8.125 13.3823 8.49722 13.4643 8.88889 13.4643C9.28056 13.4643 9.65556 13.3823 9.99444 13.2381H10.1333C11.1639 13.2381 12 13.9676 12 14.8667V15.4095ZM18.2222 13.9167C18.2222 14.0411 18.1222 14.1429 18 14.1429H14C13.8778 14.1429 13.7778 14.0411 13.7778 13.9167V13.4643C13.7778 13.3399 13.8778 13.2381 14 13.2381H18C18.1222 13.2381 18.2222 13.3399 18.2222 13.4643V13.9167ZM18.2222 12.1071C18.2222 12.2315 18.1222 12.3333 18 12.3333H14C13.8778 12.3333 13.7778 12.2315 13.7778 12.1071V11.6548C13.7778 11.5304 13.8778 11.4286 14 11.4286H18C18.1222 11.4286 18.2222 11.5304 18.2222 11.6548V12.1071ZM18.2222 10.2976C18.2222 10.422 18.1222 10.5238 18 10.5238H14C13.8778 10.5238 13.7778 10.422 13.7778 10.2976V9.84524C13.7778 9.72083 13.8778 9.61905 14 9.61905H18C18.1222 9.61905 18.2222 9.72083 18.2222 9.84524V10.2976Z"
                    fill="#812EFB"></path></svg>
                </span>
                {{ selectedInsuranceItem()?.name ?? 'Insurance' }}
              </button>

            }
          </div>
        </div>
      </nav>
      <lib-progress-bar class="mb-8" [indeterminate]="false" [max]="totalStepCount()"
                        [value]="currentStepNumber() + 1"></lib-progress-bar>
    </ng-template>
    <main style="position: relative">


      <div class="wizard flex flex-col relative">
        <div>
          <div class="wizard-content flex flex-col" gap="16">

            @if (canShowBackBtn()) {
              <div fxLayout="row" fxLayout.lt-sm="column" gap="8" row-gap="8" fxFlex fxLayoutAlign="space-between start"
                   fxLayoutAlign.lt-sm="start start">
                <button type="button" class="link text blend !absolute left-0 top-8 step-back arrow-left"
                        (click)="finder.goBackward()"
                        style="color: var(--color-primary-primary);">
                  <span style="font-weight: 500">Back</span>
                </button>
                <span class="text-body-secondary"
                      style="font-size: var(--size-16); line-height: calc(var(--size-16)* 1.5); align-content: center;">
              Need help scheduling an appointment? Call <a class="link blend pk_intake--need-help"
                                                           href="tel:855-501-1004">855-501-1004</a>.
            </span>
              </div>
            }

            <div class="grow section-{{finder.data.meta().section}}">

              @if (appState.stopPageData(); as stopPage) {
                <section fxLayout="column" fxLayoutGap="24" fxLayoutAlign="center" class="pt-32">
                  <div fxLayout="row" gap="8">
                    <h3>{{stopPage.title}}</h3>
                    <span style="display: block; width: 32px; height: 32px; position: relative; transform: translateY(-8px)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none"><g
                        clip-path="url(#error-exclamation_svg__a)"><circle cx="24" cy="24" r="22.5" fill="#FFDCD0" stroke="#FA4B82"
                                                                           stroke-width="3"></circle><path fill="#41145F"
                                                                                                           d="M25.824 29.244h-3.96L21.324 12h5.04zm-1.98 8.568c-1.548 0-2.844-1.296-2.844-2.88s1.296-2.88 2.844-2.88c1.62 0 2.916 1.296 2.916 2.88s-1.296 2.88-2.916 2.88"></path></g><defs><clipPath
                        id="error-exclamation_svg__a"><path fill="#fff" d="M0 0h48v48H0z"></path></clipPath></defs></svg>
                    </span>
                  </div>
                  <h4 class="sub-text text-body-secondary hide-empty text-body-sm pb-16" [innerHTML]="stopPage.message | unsafeAllowHtml">
                  </h4>
                  <button type="button" class="btn btn-large step-next arrow-right"
                          (click)="stopPage.action.onClick()">
                    {{stopPage.action.label}}
                  </button>
                </section>


              } @else {
                @switch (finder.data.meta().section) {
                  @case ('prefill') {
                    <app-prefill-section></app-prefill-section>
                  }
                  @case ('find') {
                    <app-find-appt-section></app-find-appt-section>
                  }
                  @case ('book') {
                    <app-booking-section></app-booking-section>
                  }
                }
              }
            </div>
          </div>
        </div>
      </div>
      <ng-content select="[slot='footer']"></ng-content>
    </main>
    <ng-template templateFor="footer">
      <div class="footer text-center py-16 row-gap-8" fxLayout="column" fxLayoutAlign="start center">
      <span>
        If you are having a psychiatric or medical emergency, call
        <a class="link blend" style="color: white; text-decoration: underline"
           href="tel:911">911</a>
        or go to the nearest emergency department.
      </span>

        <span>
        To reach the Suicide and Crisis Lifeline, call or text
        <a class="link blend" style="color: white; text-decoration: underline"
           href="sms:998">998</a>.
      </span>


      </div>
    </ng-template>
  </lib-inner-scrollable>


  <ng-template #insuranceTemplate>
    <app-insurance-filter></app-insurance-filter>
  </ng-template>

  <ng-template #locationTemplate>
    <app-location-filter></app-location-filter>
  </ng-template>
}
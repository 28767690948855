<section>
  <h4>Insurance</h4>
  <div fxLayout="column" fxLayoutAlign="start center" class="row-gap-16 pt-16">
    <lib-input label="Please select insurance" [valid]="!!selectedInsurance()" style="width: 100%">
      <p-dropdown
        [options]="$any(insurances()) ?? []"
        [loading]="!insurances()"
        [ngStyle]="{width: '100%'}"
        styleClass="input-field form-field"
        [(ngModel)]="selectedInsurance"
        optionLabel="label"
        optionValue="value"
        name="insuranceCompany"
        [filter]="true"
        filterBy="name"
        [showClear]="false"
        placeholder="Select"
      />
    </lib-input>

    <lib-input label="Please select insurance" [valid]="!!selectedInsurancePlan()"
     [disabled]="!selectedInsurance()" style="width: 100%">
      <p-dropdown
        [disabled]="!selectedInsurance()"
        [options]="$any(plans())"
        [loading]="!plans()"
        [ngStyle]="{width: '100%'}"
        styleClass="input-field form-field"
        [(ngModel)]="selectedInsurancePlan"
        optionLabel="label"
        optionValue="value"
        name="insuranceCompanyPlan"
        [filter]="true"
        filterBy="name"
        [showClear]="false"
        placeholder="Select"
      />
    </lib-input>

  </div>
</section>


<section>
  <h3>
    Enter your insurance details
  </h3>
  <div fxLayout="column" ngClass.gt-xs="row-gap-40" ngClass.lt-sm="row-gap-16">


    <form [formGroup]="form">
      <div class="pt-8">
        <app-insurance-form [unsupportedInsurance]="unsupportedInsurance()?.primary ?? null"
                            [outOfNetwork]="primaryOutOfNetworkInsurance()"
                            [form]="primaryForm"
                            [showErrors]="firedValidationCheck()"
                            [secondaryFormTemplate]="secondaryInsForm">
        </app-insurance-form>
      </div>

      <ng-template #secondaryInsForm>
        <app-insurance-form [unsupportedInsurance]="unsupportedInsurance()?.secondary ?? null"
                            [outOfNetwork]="secondaryOutOfNetworkInsurance()"
                            [showErrors]="firedValidationCheck()"
                            [form]="secondaryForm"/>
      </ng-template>

    </form>

    @if (!form.valid && firedValidationCheck()) {
      <lib-callout [type]="ActionType.Danger">
        @if (!primaryForm.valid) {
          @if (primaryForm.controls['hasSecondaryInsurance'].value) {
            <h5 class="heading-5 error">Primary Insurance</h5>
            <p-divider styleClass="error"></p-divider>
          }
          <ng-container [ngTemplateOutlet]="formErrorsTemplate"
                        [ngTemplateOutletContext]="{$implicit: primaryForm}"></ng-container>
        }
        @if (primaryForm.controls['hasSecondaryInsurance'].value && !secondaryForm.valid) {
          <h5 class="heading-5 error pt-16">Secondary Insurance</h5>
          <p-divider styleClass="error"></p-divider>
          <ng-container [ngTemplateOutlet]="formErrorsTemplate"
                        [ngTemplateOutletContext]="{$implicit: secondaryForm}"></ng-container>
        }
      </lib-callout>
    }

    @if (!unsupportedInsurance() && !primaryOutOfNetworkInsurance()) {
      <button type="button" class="btn btn-large step-next pk_insurance-details_continue arrow-right"
              [disabled]="firedValidationCheck() && !isValid()"
              (click)="nextPage()">
        Continue
      </button>
    }

    <div slot="footer">
      <!--    <p>Current patient? Access the <a class="text-gray-700" href="#"><strong>Patient Portal</strong></a></p>-->
      <!--    <span class="p-t-16">If you are having a psychiatric or medical emergency, please call 911 or go to the nearest emergency department.-->
      <!--      To reach the Suicide and Crisis Lifeline, call or text <strong><a class="text-gray-700" href="tel:998">998</a></strong>-->
      <!--    </span>-->
    </div>
  </div>


  <ng-template #formErrorsTemplate let-form>

    <div fxLayout="column" [class]="errorsAsTrackingTags() | join:' '">

      @if (form.controls["insuranceId"].errors?.['required']) {
        <span>
          <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                    verticalAlign="middle"></lib-icon>
          <span style="padding-left: 8px;">Insurance company is required.</span>
            </span>
      }
      @if (!!form.controls["insuranceId"].value) {


        @if (form.controls["insurancePlanId"].errors?.['required']) {
          <span>
          <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                    verticalAlign="middle"></lib-icon>
          <span style="padding-left: 8px;">Insurance plan is required.</span>
          </span>
        }

        @if (form.controls["memberNumber"].errors?.['required']) {
          <span>
          <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                    verticalAlign="middle"></lib-icon>
          <span style="padding-left: 8px;">Member ID is required.</span>
            </span>
        }

        @if (form.controls["policyHolderName"].errors?.['required']) {
          <span>
          <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                    verticalAlign="middle"></lib-icon>
          <span style="padding-left: 8px;">Policy holder name is required.</span>
            </span>
        }
        @if (form.controls["policyHolderDob"].errors?.['required']) {
          <span>
          <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                    verticalAlign="middle"></lib-icon>
          <span style="padding-left: 8px;">Policy holder date of birth is required.</span>
            </span>
        }

        @if (form.controls["policyHolderDob"].errors?.['pattern']) {
          <li>
            <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                      verticalAlign="middle"></lib-icon>
            Date of birth format is invalid. Please use MM/DD/YYYY
          </li>
        }

        @if (form.controls["policyHolderDob"].errors?.['invalidDate']) {
          <li>
            <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                      verticalAlign="middle"></lib-icon>
            Date of birth is not a valid date
          </li>
        }

        @if (form.controls["policyHolderDob"].errors?.['pastOrPresent']) {
          <li>
            <lib-icon [size]="8" name="!" [set]="IconSet.Text" [type]="IconType.Info" class="input-icon tooltip"
                      verticalAlign="middle"></lib-icon>
            Date of birth cannot be in the future
          </li>
        }

      }

    </div>
  </ng-template>
</section>
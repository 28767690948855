<div id="offline">
  <img src="{{logoPath()}}"
       alt="{{appName()}} - Mindpath Health"
       id="offline-logo">
  <hr class="tertiary"/>
  <section id="offline-message">
    <h1 [innerHTML]="title() | unsafeAllowHtml"></h1>
    <div class="pt-16">
      <p [innerHTML]="message() | unsafeAllowHtml"></p>
      @for (msg of extraMessages(); track msg) {
        <br/>
        <p [innerHTML]="msg | unsafeAllowHtml"></p>
      }
      <br/>
      <p>&mdash; Mindpath Health</p>
    </div>
  </section>
</div>
import {Component, computed, effect, inject, input, signal, TemplateRef, WritableSignal} from '@angular/core';
import {QueryOptions} from "@mindpath/shared";
import {BarComponent, IconComponent, IconType} from "@mindpath/shared";
import {FlexModule} from "@ngbracket/ngx-layout";
import {NgTemplateOutlet} from "@angular/common";
import {OnlineSchedulerService} from "../../../../../online-scheduler.service";

@Component({
  selector: 'app-language-filter',
  standalone: true,
  imports: [
    BarComponent,
    FlexModule,
    IconComponent,
    NgTemplateOutlet
  ],
  templateUrl: './language-filter.component.html',
  styleUrl: './language-filter.component.scss'
})
export class LanguageFilterComponent {
  onFilterChange = input.required<WritableSignal<QueryOptions<'/api/Availability/slots'>>>();
  language = computed(() => {
    const search = this.finder.data.search();
    return search.languagePref;
  })


  finder = inject(OnlineSchedulerService);
  openSideBar(ref: TemplateRef<any>) {
    this.finder.sidebar.set({
      template: ref,
      resetHook: () => {
        this.changeLang(null);
      }
    });
    // this.finder.sidebarTemplate.set(ref);
    // this.finder.sideBarResetHook.set(() => {
    //   this.language.set(null);
    // });
  }
  languages = [
    'English',
    'Spanish',
    'Hindi',
    'Urdu',
    'Portugese',
    'Japanese',
    'Chinese',
    'Farsi',
    'French',
    'German',
    'Mandarin',
    'Punjabi/Hindi',
    'Cantonese',
    'Italian',
  ].sort( (a, b) => a.localeCompare(b) );
  constructor() {




    effect(() => {
      const language = this.language();
      this.finder.data.search.update(search => ({
        ...search,
        languagePref: language
      }));
      // this.wizardSvc.updateStep(WizardStep.ApptSearch, data => {
      //   data ??= {appt: null, additionalFilters: {} as any};
      //   return {
      //     ...data,
      //     additionalFilters: {
      //       ...data.additionalFilters,
      //       language
      //     }
      //   };
      // });

      this.onFilterChange().update(filters => {
        if (language) {
          filters['filter.Clinician.language'] = `eq '${language}'`;
        } else {
          delete filters['filter.Clinician.language'];
        }
        return {...filters};
      });

    }, {allowSignalWrites: true});
  }

  protected readonly IconType = IconType;

  changeLang(lang: string|null) {
    this.finder.data.search.update(s => ({
      ...s,
      languagePref: lang
    }));
  }
}

import {components, paths} from './mindpath';
export type UrlPaths = keyof paths;

type PathMethod<T extends UrlPaths> = keyof paths[T];
export type RouteParams<P extends UrlPaths> = paths[P] extends {
    parameters: any;
  }
  ? paths[P]['parameters']
  : never;

export type ApiRequestParams<P extends UrlPaths, M extends PathMethod<P>> = paths[P][M] extends {
    parameters: any;
  }
  ? paths[P][M]['parameters']
  : never;

export type ApiRequestBody<P extends UrlPaths, M extends PathMethod<P>> = paths[P][M] extends {
    requestBody?: any;
  }
  ? paths[P][M]['requestBody']['application/json']
  :  never;

export type ApiResponseType<P extends UrlPaths, M extends PathMethod<P>> = paths[P][M] extends {
    responses: { 200: { content: { 'application/json': any } } };
  }
  ? paths[P][M]['responses'][200]['content']['application/json']
  : undefined;

export type PagedApiResponseType<P extends UrlPaths> = ApiResponseType<P, 'get'> extends GenericPage ? ApiResponseType<P, 'get'> : never;


export const ApiCall = <P extends UrlPaths, M extends PathMethod<P> & string>(
  baseUrl: string,
  url: P,
  method: M,
  params: { body?: ApiRequestBody<P, M>, fetchOptions?: RequestInit } & ApiRequestParams<P, M> extends undefined ? {} : ApiRequestParams<P, M>
): Promise<ApiResponseType<P, M>> => {
  return ApiCallResp(baseUrl, url, method, params).then((res) => {
    if (method.toLowerCase() === 'delete') {
      return res;
    }
    return res.json();
  });
};

export const ApiCallResp = <P extends string & UrlPaths, M extends PathMethod<P> & string>(
  baseUrl: string,
  url: P,
  method: M,
  params: { body: ApiRequestBody<P, M>, fetchOptions?: RequestInit } & ApiRequestParams<P, M> extends undefined ? {} : ApiRequestParams<P, M>
): Promise<Response> => {
  // console.log({params})
  params ??= {};
  // @ts-ignore
  const query = params.query ? '?' + new URLSearchParams(Object.entries(params.query).filter(p => p[1] !== undefined && p[1] !== null)).toString() : '';

  if (baseUrl.endsWith('/') && url.startsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }
  const urlPathParamsMap = url.match(/{[^}]+}/g);
  // console.log({urlPathParamsMap}, params.path);
  if (urlPathParamsMap) {
    urlPathParamsMap.forEach((param) => {
      const paramName = param.slice(1, -1);
      if (params.path && params.path[paramName]) {
        url = url.replace(param, params.path[paramName]) as P;
      } else {
        throw new Error(`Missing path parameter: ${paramName}`);
      }
    });
  }
  const assembledUrl = baseUrl + url + query;

  return fetch(assembledUrl, {
    ...(params.fetchOptions ?? {}),
    headers: {
      'Content-Type': 'application/json',
      ...params.fetchOptions?.headers,
    },
    method: method,
    body: params.body ? JSON.stringify(params.body) : undefined,
  });
};


export type IApiEndpoint<P extends UrlPaths> = {
  [M in PathMethod<P>]: (params: {fetchOptions?: RequestInit, body?: ApiRequestBody<P, M>} & (ApiRequestParams<P, M> extends undefined ? {} : ApiRequestParams<P, M>)) => Promise<ApiResponseType<P, M>>;
};

export type IApiEndpointRouteParams<P extends UrlPaths> = {
  [M in PathMethod<P>]: ApiRequestParams<P, M> extends undefined ? never :
    ApiRequestParams<P, M> extends { path: any } ? ApiRequestParams<P, M>['path'] : never;
};


export const loadApiEndpoint = <P extends UrlPaths & string>(baseUrl: string, url: P): IApiEndpoint<P> => {

  return new Proxy({}, {
    get: (target, prop) => {

      return async (params: any) => {
        return await ApiCall(baseUrl, url, prop as PathMethod<P> & string, params);
      };
    }
  }) as IApiEndpoint<P>;
}


export const loadApiEndpointResp = <P extends UrlPaths & string>(baseUrl: string, url: P): {
  [M in PathMethod<P>]: (params: {fetchOptions?: RequestInit, body?: ApiRequestBody<P, M>} & ApiRequestParams<P, M>) => Promise<Response>;
  // [M in PathMethod<P>]: (params: {
  //   body: ApiRequestBody<P, M>
  // } & ApiRequestParams<P, M>) => Promise<Response>;
} => {

  return new Proxy({}, {
    get: (target, prop) => {

      return async (params: any) => {
        return await ApiCallResp(baseUrl, url, prop as PathMethod<P> & string, params);
      };
    }
  }) as any;
}

export type PagedUrls = {
  [P in UrlPaths]: PagedApiResponseType<P>;
};

export type PagedUrl = keyof PagedUrls;


export type GenericPage = {
  readonly totalCount: number;
  readonly totalPages: number;
  readonly currentPage: number;
  readonly itemsInPageCount: number;
  readonly hasMore: boolean;
  readonly items: readonly any[] | null;
}

export type PossibleResponse<P extends UrlPaths, M extends PathMethod<P>>
  = paths[P][M] extends { responses: infer R } ? R : never;

// export type PossibleResponseCode<P extends UrlPaths, M extends PathMethod<P>, C>
//   = paths[P][M] extends { responses: { [K in keyof paths[P][M]['responses']]: { content: { 'application/json': any } } } } ? paths[P][M]['responses'][C] : never;

export type PossibleResponseCode<P extends UrlPaths, M extends PathMethod<P>, C>
  = PossibleResponse<P, M> extends never ? never :
  C extends keyof PossibleResponse<P, M> ? PossibleResponse<P, M>[C] extends { content: { 'application/json': infer T } } ? T : never
    : never;

export type aSuccessQueryItemType<P extends UrlPaths> = PossibleResponseCode<P, 'get', 200> extends GenericPage ? PossibleResponseCode<P, 'get', 200>['items'] extends any[] ? PossibleResponseCode<P, 'get', 200>['items'][0] : never : never;

export type SuccessQueryItemType<P extends UrlPaths> = PossibleResponseCode<P, 'get', 200> extends GenericPage ?  Exclude<PossibleResponseCode<P, 'get', 200>['items'], null|undefined>[0] : never;

export namespace Schemas {
  type Coordinates = components['schemas']['Coordinates'];
}

export type RoutePathParamsArgs<P extends UrlPaths> = RouteParams<P> extends { path: infer T } ? T : never;
export type RouteQueryParamsArgs<P extends UrlPaths> = ApiRequestParams<P, 'get'> extends { query?: infer T } ? T : never;

export type Schema<P extends keyof components['schemas']> = components['schemas'][P];

export type QueryFilter<P extends UrlPaths> = ExtractPrefixedProps<RouteQueryParamsArgs<P>, 'filter.'>;
export type QuerySort<P extends UrlPaths> = ExtractPrefixedProps<RouteQueryParamsArgs<P>, 'sort.'>;

export type QueryOptions<P extends UrlPaths> = QueryFilter<P> & QuerySort<P>;

type ExtractPrefixedProps<T, P extends string> = {
  -readonly [K in keyof T as K extends `${P}${infer _}` ? K : never]: T[K];
};

export type QueryParamFilter<T extends PagedUrl> =
  ApiRequestParams<T, 'get'> extends {query?: any} ?
    ApiRequestParams<T, 'get'>['query'] : false;

export type PathParams<T extends PagedUrl> =
  ApiRequestParams<T, 'get'> extends {path?: any} ? ApiRequestParams<T, 'get'>['path'] : never;
<div fxLayout="column" class="container" fxLayoutAlign="start stretch">
  @if(header(); as header) {
    <div class="header">
      <ng-container [ngTemplateOutlet]="header"></ng-container>
    </div>
  }
  <div class="content" #content>
    <ng-content></ng-content>
  </div>
  @if(footer(); as footer) {
    <div class="footer">
      <ng-container [ngTemplateOutlet]="footer"></ng-container>
    </div>
  }
</div>